import { Injectable } from "@angular/core";
import { AngularFireAuth } from "@angular/fire/auth";
import { Router } from "@angular/router";
import { auth } from "firebase/app";
import { User } from "firebase";

import * as firebase from "firebase/app";
import { AngularFirestore } from "@angular/fire/firestore";
import { HttpClient } from "@angular/common/http";
import { environment } from "src/environments/environment";
import { ResponseData } from "../interfaces/response-data";
import { timeout } from "rxjs/operators";

interface Data {
  code: number;
  message: string;
  data: any;
}

@Injectable({
  providedIn: "root",
})
export class AuthService {
  private apiUrl = `${environment.apiUrl + "api/"}`;

  user: User;
  showErrorMsg: boolean = false;
  user_id;
  user_data;
  user_name;
  role;

  constructor(
    public afAuth: AngularFireAuth,
    public router: Router,
    public firestore: AngularFirestore,
    private http: HttpClient
  ) {
    this.afAuth.authState.subscribe((user) => {
      if (user) {
        this.user = user;
        this.user_id = this.user.uid;
        localStorage.setItem("user", JSON.stringify(this.user));
      } else {
        localStorage.setItem("user", null);
      }
    });
  }

  async login(name: string, password: string) {
    return new Promise<Data>((resolve) => {
      this.http.post<Data>(this.apiUrl + "login", { name, password }).subscribe(
        (data) => {
          resolve(data);
        },
        (error) => {}
      );
    });
  }

  isLoggednIn() {
    var data = localStorage.getItem("loggedInUserRole") !== null;
    return data;
  }
  async logout() {
    this.afAuth.auth.signOut();

    localStorage.clear();
    this.router.navigate(["authentication/login"]);
  }
  get getUserName(): string {
    const user = JSON.parse(localStorage.getItem("user"));
    return user;
  }

  createUser(formValue, selectedFiles, hasFile) {
    let formData = new FormData();

    formData.append("name", formValue.name);
    formData.append("email", formValue.email);
    formData.append("phoneNumber", formValue.phoneNumber);
    formData.append("role", formValue.role);
    formData.append("status", "active");
    formData.append("password", formValue.password);
    formData.append("addressLine1", formValue.addressLine1);
    formData.append("addressLine2", formValue.addressLine2);
    formData.append("city", formValue.city);
    formData.append("state", formValue.state);
    formData.append("pincode", formValue.pincode);
    if (hasFile) {
      var fileToUpload = selectedFiles.item(0);
      console.log("fileToUpload", fileToUpload);
      formData.append("image", fileToUpload, fileToUpload.name);
    }

    if (formValue.role == "agent") {
      var zone = JSON.stringify(formValue.zone);
      formData.append("zone", zone);
    }

    return new Promise<Data>((resolve) => {
      this.http.post<Data>(this.apiUrl + "register", formData).subscribe(
        (data) => {
          console.log("Success", data);
          resolve(data);
        },
        (error) => {}
      );
    });
  }

  updateUser(user) {
    console.log("User", user);
    let formData = new FormData();

    formData.append("name", user.name);
    formData.append("phoneNumber", user.phoneNumber);
    //formData.append("email",user.email);
    formData.append("addressLine1", user.addressLine1);
    formData.append("addressLine2", user.addressLine2);
    formData.append("city", user.city);
    formData.append("state", user.state);
    formData.append("pincode", user.pincode);
    formData.append("id", user.id);
    formData.append("role", user.role);
    if (user.hasFile) {
      var fileToUpload = user.selectedFiles.item(0);
      formData.append("photo", fileToUpload, fileToUpload.name);
    }

    if (user.role == "agent") {
      var zone = JSON.stringify(user.zone);
      formData.append("zone", zone);
    }

    return new Promise<Data>((resolve) => {
      this.http.post<Data>(this.apiUrl + "update-user", formData).subscribe(
        (data) => {
          resolve(data);
        },
        (error) => {}
      );
    });
  }

  getAllUsers(role) {
    return new Promise<ResponseData>((resolve) => {
      this.http
        .get<ResponseData>(this.apiUrl + "users" + `/${role}`)
        .pipe(timeout(1000000))
        .subscribe(
          (data) => {
            console.log("data", data);
            resolve(data);
          },
          (error) => {
            console.log("Error", error);
          }
        );
    });
  }

  getUserById(id) {
    return new Promise<ResponseData>((resolve) => {
      this.http
        .get<ResponseData>(this.apiUrl + "user" + `/${id}`)
        .pipe(timeout(1000000))
        .subscribe(
          (data) => {
            console.log("data", data);
            resolve(data);
          },
          (error) => {
            console.log("Error", error);
          }
        );
    });
  }

  deleteUser(data) {
    return new Promise<ResponseData>((resolve) => {
      this.http
        .post<ResponseData>(this.apiUrl + "delete-user", data)
        .pipe(timeout(1000000))
        .subscribe(
          (data) => {
            console.log("data", data);
            resolve(data);
          },
          (error) => {
            console.log("Error", error);
          }
        );
    });
  }
}
