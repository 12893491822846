import { Component, Input, OnInit, Output, EventEmitter } from '@angular/core';
import { AngularFirestore } from '@angular/fire/firestore';
import { LedgerService } from 'src/app/shared/service/ledger.service';
import { DataStateChangeEvent, GridDataResult } from '@progress/kendo-angular-grid';
import { process, State } from '@progress/kendo-data-query';

@Component({
  selector: 'app-collection-report-details',
  templateUrl: './collection-report-details.component.html',
  styleUrls: ['./collection-report-details.component.scss']
})
export class CollectionReportDetailsComponent implements OnInit {
  @Input('collectioReportData') collectioReportData;
  @Output() close = new EventEmitter();

  show = 'view';
  collection;
  detailsArray:any = [];

  public gridData: GridDataResult;
  public state: State = {
    filter: {
      logic: 'and',
      filters: []
    }
  };
  
  constructor(private firestore:AngularFirestore, private ledgerService:LedgerService) { }

  ngOnInit() {
    console.log("collectioReportData", this.collectioReportData);
    this.getCollectionReport();
  }

  closed(){
    this.collectioReportData.paymentReceivedBank = this.collectioReportData.paymentReceivedBank == 'Corporation' ? 'Corporation-Union Bank':this.collectioReportData.paymentReceivedBank;
    this.close.emit("list");
  }

  getCollectionReport(){
    //this.collectioReportData.paymentReceivedBank = this.collectioReportData.paymentReceivedBank == 'Corporation-Union Bank' ? 'Corporation':this.collectioReportData.paymentReceivedBank;
    var data ={
      'bank':this.collectioReportData.paymentReceivedBank,
      'receiptDate': this.collectioReportData.receiptDate
    }
    this.ledgerService.getCollectionReportDetails(data).then(async response=>{
      if (response.code == 200) {
        this.detailsArray = await response.data;
        console.log("this.detailsArray", await this.detailsArray)
        this.gridData =  await process(this.detailsArray, this.state);
      }
    });
  }

  public dataStateChange(state: DataStateChangeEvent): void {
    this.state = state;
    this.gridData = process( this.detailsArray, this.state);
  }

}
