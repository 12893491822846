import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { timeout } from 'rxjs/operators';
import { environment } from 'src/environments/environment';
import { ResponseData } from '../interfaces/response-data';

interface Data  {
  code: number,
  message: string,
  data: any
}

@Injectable({
  providedIn: 'root'
})
export class BillingService {
  private apiUrl = `${environment.apiUrl + 'api/'}`
  constructor(private http:HttpClient) { }

  searchRRono(rrno){
    return new Promise<ResponseData>((resolve)=>{
      this.http.get<ResponseData>(this.apiUrl + 'bill/search-rrno'+`/${rrno}`).pipe(timeout(1000000))
      .subscribe(
          data => {
            console.log("data",data);            
            resolve(data);
          },
          error => {
            console.log("Error", error);
        });
    })
  }

  getMeterFixedDetails(rrno, meterFixedDate){
    var data = {
      "rrno": rrno,
      "meterFixedDate" :meterFixedDate
    }
    var stringfyData = JSON.stringify(data);

    return new Promise<ResponseData>((resolve)=>{
      this.http.get<ResponseData>(this.apiUrl + 'bill/meter-fixed-details'+`/${stringfyData}`).pipe(timeout(1000000))
      .subscribe(
          data => {
            console.log("data",data);            
            resolve(data);
          },
          error => {
            console.log("Error", error);
        });
    })
  }

  getLedgerByRrno(rrno){
    return new Promise<ResponseData>((resolve)=>{
      this.http.get<ResponseData>(this.apiUrl + 'bill/ledger'+`/${rrno}`).pipe(timeout(1000000))
      .subscribe(
          data => {
            console.log("data",data);            
            resolve(data);
          },
          error => {
            console.log("Error", error);
        });
    })
  }

  createBill(bill){
    return new Promise<Data>((resolve)=>{
      this.http.post<Data>(this.apiUrl + 'bill/create', bill)
      .subscribe(
          data => {
            console.log("Success", data);
            resolve(data);
          },
          error => {
        });
    })
  }

  getLedgerByRrnoWithBillDate(data){
    var searchData = JSON.stringify(data);
    return new Promise<ResponseData>((resolve)=>{
      this.http.get<ResponseData>(this.apiUrl + 'bill/ledger-rrno-billDateTime'+`/${searchData}`).pipe(timeout(1000000))
      .subscribe(
          data => {
            console.log("data",data);            
            resolve(data);
          },
          error => {
            console.log("Error", error);
        });
    })
  }


  getBillByRrnoWithBillDateTime(data){
    var searchData = JSON.stringify(data);
    return new Promise<ResponseData>((resolve)=>{
      this.http.get<ResponseData>(this.apiUrl + 'bill/bill-rrno-billDateTime'+`/${searchData}`).pipe(timeout(1000000))
      .subscribe(
          data => {
            console.log("data",data);            
            resolve(data);
          },
          error => {
            console.log("Error", error);
        });
    })
  }

  updateBill(bill){
    console.log("bill", bill);
    
    return new Promise<Data>((resolve)=>{
      this.http.post<Data>(this.apiUrl + 'bill/update', bill)
      .subscribe(
          data => {
            console.log("Success", data);
            resolve(data);
          },
          error => {
        });
    })
  }
}

