import { Injectable } from '@angular/core';
import { AngularFireAuth } from '@angular/fire/auth';
import { AngularFirestore } from '@angular/fire/firestore';
import { AngularFireStorage } from '@angular/fire/storage';
import { ToastrService } from 'ngx-toastr';
import Swal from 'sweetalert2';
import { finalize, timeout } from 'rxjs/operators';
import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment';
import { ResponseData } from '../interfaces/response-data';
import { HttpClient } from '@angular/common/http';

@Injectable({
  providedIn: 'root'
})
export class AgentService {
  public showErrorMsg: Boolean;
  public printErrorMsg:string;
  downloadURL: Observable<string>;
  imgPath;

  private apiUrl = `${environment.apiUrl + 'api/'}`

  constructor(public firestore:AngularFirestore, public fireAuth:AngularFireAuth, public toastrService: ToastrService, private storage:AngularFireStorage, private http:HttpClient) { }

  async signup(agent_details, imageUrl, validationForm, hasFile, selectedFiles){
  
      if(hasFile && selectedFiles.length != 0){
        var result = this.uploadpic(imageUrl, agent_details, 'add', null);
        this.reset(validationForm);
        return '200';
      }
      else{
        var result = this.storeAgent(null,agent_details);
        this.reset(validationForm);
        return '200';
      }
    
  }

  reset(validationForm){
    validationForm.setValue({
      name: "",
      email: "",
      phoneNumber: "",
      addressLine1: "",
      addressLine2: "",
      ward: "",
      city: "",
      state: "",
      pincode: ""
    });
  }

  storeAgent(imageUrl,agent_details)
  {
    
    this.firestore.collection('users').doc(agent_details.phoneNumber).set({
      name: agent_details.name,
      phoneNumber: agent_details.phoneNumber,
      email: agent_details.email,
      addressLine1: agent_details.addressLine1, 
      addressLine2: agent_details.addressLine2,
      //ward: agent_details.ward,
      city: agent_details.city,
      state: agent_details.state,
      pincode: agent_details.pincode,
      role: "agent",
      imagePath:imageUrl
    }).then((success) => {
      agent_details.ward.forEach(ward => {
        this.firestore.collection('users').ref.doc(agent_details.phoneNumber).collection('ward').add(ward).then((success)=>{
          
        })
      });
      this.toastrService.success('Agent Added Successfully!', 'Success!', {
        timeOut: 8000
      });
    });
  }

  deleteAgent(id){
    Swal({
      title: 'Are you sure?',
      text: "You won't be able to revert this!",
      type: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: 'Yes, delete it!'
    }).then((result) => {
      if (result.value) {
        this.firestore.doc('users/'+id).delete();
        this.firestore.collection('deleteUser').doc(id).set({delete: "yes"});
        Swal(
          'Deleted!',
          'Agent has been deleted.',
          'success'
        )
      }
    });
  }


  uploadpic(imageUrl, agent_details, type, id){
    let uniqkey = 'pic' + Math.floor(Math.random() * 1000000);
    const uploadTask = this.storage.upload('/agent/' + uniqkey, imageUrl);
    const ref = this.storage.ref('/agent/' + uniqkey);
    console.log("uploadTask", uploadTask);
    //this.imgsrc = uploadTask.downloadURL()
    uploadTask.snapshotChanges().pipe(
      finalize(() => {
        this.downloadURL = ref.getDownloadURL();
        console.log(this.downloadURL);
        this.downloadURL.subscribe(url => {
          this.imgPath= url;
          console.log("url",this.imgPath)
          this.toastrService.success("Profile image uploaded succcessfully!", "Success!", {
            timeOut:3000
          });
          if(type=='add'){
            this.storeAgent(this.imgPath, agent_details);
          }
          else{
            agent_details.imagePath = this.imgPath;
            this.editAgent(agent_details, id)
          }
        });
      })
    ).subscribe();
  }

  getAgents(){
    //return this.firestore.collection('/users', ref => ref.where('role', '==', 'agent')).snapshotChanges();
    var agents;
    agents = fetch(this.apiUrl + 'agents').then(function(response){
      return  response.json().then((data)=>{
        return data
      })
    })
    return agents;
  }

  getWards(){
    //return this.firestore.collection('/ward').snapshotChanges();
    var wards;
    wards = fetch(this.apiUrl + 'wards').then(function(response){
      return  response.json().then((data)=>{
        return data
      })
    })
    return wards;
  }

  getAreas(){
    //return this.firestore.collection('/area').snapshotChanges();
    var areas;
    areas = fetch(this.apiUrl + 'areas').then(function(response){
      return  response.json().then((data)=>{
        return data
      })
    })
    return areas;
  }

  getAllZoneNumbers(){
    //return this.firestore.collection('/area').snapshotChanges();
    var zoneNumbers;
    zoneNumbers = fetch(this.apiUrl + 'zones').then(function(response){
      return  response.json().then((data)=>{
        return data
      })
    })
    return zoneNumbers;
  }

  updateAgent(agent, id, hasFile, selectedFiles){
    console.log("agent", hasFile);
    if(hasFile){
      var imageUrl = selectedFiles.item(0);
      console.log("test agent photo", imageUrl);
      this.uploadpic(imageUrl, agent, 'edit', id)
    }
    else{
      this.editAgent(agent, id)
    }
  }

  editAgent(agent, id){
    var wards = agent.ward;
    delete agent.ward;
    this.firestore.doc('users/'+id).update(agent).then((success)=>{
      this.firestore.collection('users').ref.doc(id).collection('ward').get().then((querySnapshot) => {
        querySnapshot.forEach((doc) => {
          doc.ref.delete();
        });
      });
      wards.forEach(w => {
        this.firestore.collection('users').ref.doc(id).collection('ward').add(w).then((success)=>{
          
        })
      });
      this.toastrService.success('Agent Updated Successfully!', 'Success!', {
        timeOut: 8000
      });
    });
      //location.reload();
  }

  getWardsByAgentId(phoneNumber){
    return this.firestore.collection('users').doc(phoneNumber).collection('ward').valueChanges();
  }

  getAreaByUserId(userId){
    return new Promise<ResponseData>((resolve)=>{
      this.http.get<ResponseData>(this.apiUrl + 'area'+`/${userId}`).pipe(timeout(1000000))
      .subscribe(
          data => {
            console.log("data",data);            
            resolve(data);
          },
          error => {
            console.log("Error", error);
        });
    })
  }

  getZones(){
    var zones;
    zones = fetch(this.apiUrl + 'zones').then(function(response){
      return  response.json().then((data)=>{
        return data
      })
    })
    return zones;
  }

  getZoneByUserId(userId){
    return new Promise<ResponseData>((resolve)=>{
      this.http.get<ResponseData>(this.apiUrl + 'zone'+`/${userId}`).pipe(timeout(1000000))
      .subscribe(
          data => {
            console.log("data",data);            
            resolve(data);
          },
          error => {
            console.log("Error", error);
        });
    })
  }

  
}
