import { Component, OnInit } from '@angular/core';
import { AngularFirestoreCollection } from '@angular/fire/firestore';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { BankStaffService } from 'src/app/shared/bank-staff/bank-staff.service';
import { DataStateChangeEvent, GridDataResult, PageChangeEvent} from '@progress/kendo-angular-grid';
import { process, State } from '@progress/kendo-data-query';

declare  var require:any;
var converter = require('number-to-words');
import Swal from 'sweetalert2';
var moment = require('moment');

@Component({
  selector: 'app-abstract-report',
  templateUrl: './abstract-report.component.html',
  styleUrls: ['./abstract-report.component.scss']
})
export class AbstractReportComponent implements OnInit {

  filterForm = new FormGroup({
    fromDate: new FormControl('', Validators.required),
    toDate: new FormControl('', Validators.required)
  });
  submitted:Boolean;
  isVisible:Boolean;

  transaction_details : AngularFirestoreCollection;
  transaction_data:any[];
  customer_data;
  transaction:any;

  fromDate;
  toDate;


  public gridData: GridDataResult;
  public state: State = {
    skip: 0,
    take:10,
    filter: {
      logic: 'and',
      filters: []
    }
  };
  public buttonCount = 5;
  public info = true;
  public type: 'numeric' | 'input' = 'numeric';
  public previousNext = true;
  public pageSizes = [200,200,300,400, 500,600,700,800,900,1000, 50000];

  constructor( private modalService: NgbModal, private bankStaffService:BankStaffService) { }

  ngOnInit() {

  }

  onChangeDate(e, type){
    var date = new Date(e.target.value);
    var changed_date = ((date.getFullYear()) +"/"+ (("0"+(date.getMonth()+1)).slice(-2)) +"/"+("0"+date.getDate()).slice(-2));
    if(type == 'from'){
      this.fromDate = changed_date;
    }
    else{
      this.toDate = changed_date;
    }
  }

  filter(){
    this.transaction_data = [];
    this.gridData = process(this.transaction_data, this.state);
    this.submitted = true;
    if(this.filterForm.valid){
      this.isVisible = true;
      
      this.fromDate  = this.filterForm.value.fromDate;
      this.toDate = this.filterForm.value.toDate;
      var data = {
        fromDate : this.fromDate,
        toDate: this.toDate
      }

      this.getFilterData(data);
    }
  }

  getFilterData(data){
    this.bankStaffService.getAbstractCashCollection(data).then(response =>{
      if(response.code == 200){
        console.log("response data", response.data);
        this.transaction_data = response.data;
        this.gridData = process(this.transaction_data, this.state);
        this.submitted = false;
        this.filterForm.reset();
      }
    })
  }

  public dataStateChange(state: DataStateChangeEvent): void {
    this.state = state;
    this.gridData = process( this.transaction_data, this.state);
  }
  public pageChange({ skip, take }: PageChangeEvent): void {
    console.log("skip",skip,take )
    this.state.skip = skip;
    this.state.take = take; 
    this.gridData = process( this.transaction_data, this.state);
  }

  public allData = () => {
    return process(this.transaction_data, {
      filter: this.state.filter
    });
  }

  

}
