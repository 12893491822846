import { Component, OnInit, ViewChild } from "@angular/core";
import { FormControl, FormGroup, Validators } from "@angular/forms";
import { Observable } from "rxjs/Observable";
import {
  debounceTime,
  distinctUntilChanged,
  filter,
  map,
} from "rxjs/operators";
import { Subject, merge } from "rxjs";
import { NgbTypeahead } from "@ng-bootstrap/ng-bootstrap";
import { AngularFirestore } from "@angular/fire/firestore";
import { BankStaffService } from "src/app/shared/bank-staff/bank-staff.service";
import { ConnectionService } from "src/app/shared/service/connection.service";
import { ToastrService } from "ngx-toastr";

declare var require: any;
var converter = require("number-to-words");
var moment = require("moment");
import { ToWords } from "to-words";
import { createPureExpression } from "@angular/core/src/view/pure_expression";
const toWords = new ToWords({
  localeCode: "en-IN",
  converterOptions: {
    currency: true,
    ignoreDecimal: false,
    ignoreZeroCurrency: false,
    doNotAddOnly: true,
  },
});
@Component({
  selector: "app-cash-collection",
  templateUrl: "./cash-collection.component.html",
  styleUrls: ["./cash-collection.component.scss"],
})
export class CashCollectionComponent implements OnInit {
  rrnoForm = new FormGroup({
    rrno: new FormControl("", Validators.required),
  });

  click$ = new Subject<string>();
  @ViewChild("instance") instance: NgbTypeahead;
  focus$ = new Subject<string>();
  crrno;
  isVisible: boolean = false;
  btnCalclate: boolean;
  RRNOsubmitted: boolean;

  connectionId;
  connectionDetail;

  ownerName;
  houseNo;
  address;
  meterNo;
  ward;
  tariff;
  subDivision;
  userName;
  phoneNumber;
  receiptNo;
  dateTime;
  submitted: boolean;
  latestBillNo;
  cba;

  validationForm;
  amountInWords;
  receiptDate;

  constructor(
    private firestore: AngularFirestore,
    private bankStaffService: BankStaffService,
    private connectionService: ConnectionService,
    private toastrService: ToastrService
  ) {
    var today = new Date();
    this.dateTime =
      today.getFullYear() +
      "/" +
      ("0" + (today.getMonth() + 1)).slice(-2) +
      "/" +
      ("0" + today.getDate()).slice(-2) +
      " " +
      ("0" + today.getHours()).slice(-2) +
      ":" +
      ("0" + today.getMinutes()).slice(-2) +
      ":" +
      ("0" + today.getSeconds()).slice(-2);
    this.receiptDate =
      today.getFullYear() +
      "-" +
      ("0" + (today.getMonth() + 1)).slice(-2) +
      "-" +
      ("0" + today.getDate()).slice(-2);
  }

  ngOnInit() {
    this.validationForm = new FormGroup({
      connectionId: new FormControl({
        value: this.connectionId,
        disabled: true,
      }),
      ownerName: new FormControl({ value: this.ownerName, disabled: true }),
      houseNo: new FormControl({ value: this.houseNo, disabled: true }),
      phoneNumber: new FormControl({ value: this.phoneNumber, disabled: true }),
      receiptNo: new FormControl({
        value: "OFFLINE" + this.receiptNo,
        disabled: true,
      }),
      billDateTime: new FormControl({ value: this.dateTime, disabled: true }),
      mode: new FormControl("cash", Validators.required),
      amount: new FormControl("", Validators.required),
      billNo: new FormControl(""),
      chequeDdNo: new FormControl(""),
      chequeDdDate: new FormControl(""),
      chequeDdBranch: new FormControl(""),
      paymentReceivedBank: new FormControl("Corporation-Union Bank"),
    });

    this.getLastId();
  }

  searchFocusOnRRNO = (text$: Observable<string>) => {
    const debouncedText$ = text$.pipe(
      debounceTime(200),
      distinctUntilChanged()
    );
    const clicksWithClosedPopup$ = this.click$.pipe(
      filter(() => !this.instance.isPopupOpen())
    );
    const inputFocus$ = this.focus$;
    // this.crrno = this.firestore.collection('/connections');
    let crrnoArray = [];
    this.connectionService.getAllConnection().then((response) => {
      if (response.code == 200) {
        response.data.forEach((element) => {
          crrnoArray.push({ RRNO: element.rrno, name: element.name });
        });
      }
    });
    /* this.crrno.valueChanges().subscribe((data)=>{
      data.forEach(element => {
        crrnoArray.push({RRNO:element.RRNO, name:element.name});
      });
    }); */
    return merge(debouncedText$, inputFocus$, clicksWithClosedPopup$).pipe(
      map((term) =>
        term === ""
          ? []
          : crrnoArray.filter((v) => v.RRNO.toLowerCase() == term.toLowerCase())
      )
    );
  };
  rrnoFormatter = (x: { RRNO: string }) => x.RRNO;

  browseConnectionDetail(event) {
    //this.btnCalclate = false;
    //this.reset();
    this.isVisible = false;
  }

  filterByRRNO() {
    this.RRNOsubmitted = true;
    console.log("filter by rrno");
    if (this.rrnoForm.valid) {
      var today = new Date();
      this.dateTime =
        today.getFullYear() +
        "/" +
        ("0" + (today.getMonth() + 1)).slice(-2) +
        "/" +
        ("0" + today.getDate()).slice(-2) +
        " " +
        ("0" + today.getHours()).slice(-2) +
        ":" +
        ("0" + today.getMinutes()).slice(-2) +
        ":" +
        ("0" + today.getSeconds()).slice(-2);
      this.receiptDate =
        today.getFullYear() +
        "-" +
        ("0" + (today.getMonth() + 1)).slice(-2) +
        "-" +
        ("0" + today.getDate()).slice(-2);
      //this.isVisible = true;

      let rrnoFormValue: string = this.rrnoForm.get("rrno").value;
      console.log("rrno", rrnoFormValue["RRNO"]);
      let rrno = rrnoFormValue["RRNO"];

      this.RRNOsubmitted = false;
      this.rrnoForm.reset();

      this.connectionId = rrno;
      console.log("connectionId", this.connectionId);

      /* this.connectionDetail = this.firestore.collection('/connections', ref => ref.where('RRNO', '==', rrno));
      this.connectionDetail.valueChanges().subscribe((data) => {
        console.log("connections detail", data["name"]);
        data.forEach(e => {
          this.ownerName = e.name;
          this.houseNo = e.doorNo.slice(1, -1)
          this.address = e.streetName;
          this.meterNo = e.meterSerialNumber;
          this.ward = e.ward;
          this.tariff = e.tariff;
          this.subDivision = e.subDivision;
          this.userName = e.user;
          this.phoneNumber = e.phoneNumber;
        })
      }); */

      this.bankStaffService.checkRrnoExist(rrno).then((response) => {
        if (response.code == 200) {
          console.log("data", response.data[0]);

          var e = response.data[0];
          this.ownerName = e.name;
          this.houseNo = e.doorNo;
          this.address = e.streetName;
          this.meterNo = e.meterSerialNumber;
          this.ward = e.ward;
          this.tariff = e.tariff;
          this.subDivision = e.subDivision;
          this.userName = e.user;
          this.phoneNumber = e.phoneNumber;
          this.latestBillNo = e.latestBillNo;
          this.cba = e.cba;
          this.validationForm.controls.amount.setValue(e.cba);
        } else {
          this.toastrService.error(response.message, "Error!");
        }
      });
    }
  }

  onSubmit() {
    console.log("this.connectionId", this.validationForm.value);

    this.submitted = true;
    if (this.validationForm.valid) {
      var cashCollection = {
        rrno: this.connectionId,
        receiptNo: this.receiptNo,
        receiptDateTime: moment().format("YYYY/MM/DD HH:mm:ss"),
        mode: this.validationForm.get("mode").value,
        amount: this.cba,
        billNo: this.latestBillNo,
        chequeDdNumber: this.validationForm.get("chequeDdNo").value,
        chequeDdDate: this.validationForm.get("chequeDdDate").value,
        chequeDdBranch: this.validationForm.get("chequeDdBranch").value,
        paymentReceivedBank: this.validationForm.get("paymentReceivedBank")
          .value,
        userId: localStorage.getItem("user_id"),
        receiptDate: this.receiptDate,
      };

      this.bankStaffService
        .createCashCollection(cashCollection)
        .then((response) => {
          if (response.code == 200) {
            this.submitted = false;
            this.print();
          }
        });
      //this.firestore.collection('bankCashCollection').add();
    }
  }

  onchangeBillNo(e) {
    this.latestBillNo = e.target.value;
  }

  async print() {
    console.log("printing");

    var dataItem = await {
      receiptNo: this.receiptNo,
      billDate: moment().format("YYYY-MM-DD HH:mm:ss"),
      name: this.ownerName.replace(/\/+/g, ""),
      doorNo: this.houseNo.replace(/\/+/g, "-"),
      rrno: this.connectionId,
      amount: this.validationForm.controls.amount.value,
      // amountInWords: converter.toWords(
      //   this.validationForm.controls.amount.value + ".00"
      // ),
      amountInWords: toWords.convert(
        Number(this.validationForm.controls.amount.value + ".00")
      ),
      mode: this.validationForm.controls.mode.value,
      chequeDdNo: this.validationForm.controls.chequeDdNo.value,
      chequeDdDate: this.validationForm.controls.chequeDdDate.value,
      chequeDdBranch:
        this.validationForm.controls.chequeDdBranch.value != null ||
        this.validationForm.controls.chequeDdBranch.value != ""
          ? this.validationForm.controls.chequeDdBranch.value.replace(
              /\/+/g,
              ""
            )
          : this.validationForm.controls.chequeDdBranch.value,
    };
    var receipt = await JSON.stringify(dataItem);
    await window.open(
      "https://printbill.nixies.in/public/mn-compu-solution-cash-collect-pdf/" +
        receipt,
      "_blank"
    );
    await this.validationForm.reset();
    await this.validationForm.controls.billDateTime.setValue(this.dateTime);
    await this.validationForm.controls.chequeDdNo.setValue("");
    await this.validationForm.controls.chequeDdDate.setValue("");
    await this.validationForm.controls.chequeDdBranch.setValue("");
    await this.validationForm.controls.mode.setValue("cash");
    await this.validationForm.controls.paymentReceivedBank.setValue(
      "Corporation-Union Bank"
    );
    await this.getLastId();
  }

  getLastId() {
    this.bankStaffService.getLastId().then((response) => {
      if (response.code == 200) {
        var receptNo = response.data[0]["receiptNo"];
        this.receiptNo = Number(receptNo) + 1;
      } else {
        this.receiptNo = "202100001";
      }
    });
    /* var cash_collection = this.firestore.collection('/bankCashCollection', ref => ref.orderBy('receiptNo', 'desc'));
  cash_collection.valueChanges().subscribe((data) => {
   console.log("length", data);
    if(data.length > 0){
      var recept_no = data[0]['receiptNo'];
      var thenum = recept_no.replace( /^\D+/g, '');
      var addOne = Number(thenum) + 1;
      this.receiptNo = 'OFFLINE ' + addOne; 
    }
    else{
      this.receiptNo =  'OFFLINE ' + 1;
    }
  });  */
  }

  onChangeAmount(e) {
    this.amountInWords = toWords.convert(e.target.value);
    this.cba = e.target.value;
    // var objRegex = /^\d+\.\d\d$/

    //     //check for numeric characters
    //     if(objRegex.test(e.target.value))
    //     {
    //         //alert("Your Given Input \""+e.target.value+"\" Is Correct.");
    //     }
    //     else
    //     {
    //         alert("Please enter valid amount. Example:10.00");
    //     }
  }

  cancel() {
    location.reload();
  }
}
