import { Component, OnInit } from '@angular/core';
import { JsonService } from 'src/app/shared/json/json.service';
import { AngularFirestore } from '@angular/fire/firestore';
declare var require:any;
var moment = require('moment');

@Component({
  selector: 'app-customers',
  templateUrl: './customers.component.html',
  styleUrls: ['./customers.component.scss']
})
export class CustomersComponent implements OnInit {

  constructor(private jsonService: JsonService, private firestore: AngularFirestore) { }
  customer_array = [];

  ngOnInit() {

    //console.log("date", moment('04/02/2019', 'DD/MM/YYYY').format('YYYY-MM-DD'));
    
    /* this.jsonService.getCustomerJson().subscribe((data) => {
      console.log("customer json data", data);
      data.forEach((element, index) => {
        this.firestore.collection('connections').add({
          applicationDate: element.APPLICATION_DATE,
          areaName: element.AreaName,
          CB: element.CB,
          connectionApproval: element.CONNECTION_APPROVAL,
          custId: element.CUSTID,
          consumerConnectionStatusType: element.ConsumerConnectionStatusType,
          depositAmount: element.DEPOSIT_AMOUNT,
          depositChallanNo: element.DEPOSIT_CHALLAN_NO,
          depositDate: element.DEPOSIT_DATE,
          enteredDate: element.ENTERED_DATE,
          emailId: element.Email_Id,
          meterImage: element.METERIMAGE,
          meterConstant: element.MeterConstant,
          meterSerialNumber: element.MeterSerialNumber,
          meterStatus: element.MeterStatus,
          metersReaderName: element.meterreadername,
          noOfFlats: element.NO_OF_FLATS,
          name: element.Name,
          orderNo: element.ORDER_NO,
          office: element.Office,
          propertyImage: element.PROPERTYIMAGE,
          phoneNumber: element.PhoneNo,
          RRNO: element.RRNo,
          readingDate: element.ReadingDate,
          remarks: element.Remarks,
          streetName: element.StreetName,
          subDivision: element.Sub_Division,
          tariff: element.Tariff,
          userId: element.USER_ID,
          ward: element.Ward,
          consumerId: element.consumerid,
          doorNo: element.doorno,
          latitude: element.latitude,
          longitude: element.longitude,
          meterReaderName: element.meterreadername,
          plumberName: element.plumber_name,
          serviceDate: element.servicedate
        }).then(function(docRef) {
                console.log("Document written with ID: ", docRef.id,  index);
         });
      });
    }); */

    /* this.jsonService.getLedgerTableJson().subscribe((data) => {
      console.log("ledger json data", data);
      data.forEach((element, index) => {
      
        this.firestore.collection('ledger').add({
          connectionId : element.RRNo,
          billDateTime : element.billdate,
          noOfDays : element.nodays,
          status : element.LATEST_STATUS ,
          ledgerStatus:element.ledger_status,
          consumption : element.Consumption,
          OBA : element.OB,
          previousReadingDate : element.readingdate,
          demand : element.Demand,
          waterCharge : element.Watercharges,
          serviceCharge : element.service_charge,
          penalty : element.penalty,
          adjustment : element.AdjAmt,
          CBA : element.CB,
          remarks : element.REMARKS,
          arrears : element.arrears,
          billNo : element.billno,
          total : element.Total,
          receiptDate : element.ReceiptDate,
          receiptNo : element.ReceiptNo,
          previousReading : element.IR,
          presentReading : element.FR,
          consumerId: element.consumerid,
          year: element.Year,
          month: element.Month,
          cid: element.cid,
          agent: element.agent_code,
          paidAmount: element.PaidAmount,
          previousBillDate: element.PreviousBilldate,
          rivision: element.revision,
          meterReaderName: element.MeterreaderName
        }).then(function(docRef) {
          console.log("Document written with ID: ", docRef.id,  index);
        });
      
      });
    }); */


    /*this.jsonService.getConnectionJosnForBank().subscribe((data) => {
      console.log("ledger json data", data);
      data.forEach((element, index) => {
        this.firestore.collection('connections').add({
          RRNO:element.rrno,
          name:element.name,
          doorNo:element.doorno,
          phoneNumber:element.phoneno
        }).then(function(docRef) {
          console.log("Document written with ID: ", docRef.id,  index);
        });
    
      });
    });*/

    /* this.jsonService.getMetrChnageJson().subscribe((data)=>{
      console.log("meter-change json data", data);
      data.forEach((element, index) =>{
        this.firestore.collection('meter-change').add({
          sl_no:element.sl_no,
          rrno:element.RRNo,
          consumerName: element.CONSUMER_OLD_NAME,
          userId: element.USER_ID,
          enteredDate: element.ENTERED_DATE,
          oldMeterSerialNumber: element.OLD_METERSERIAL_NO,
          newMeterSerialNumber: element.NEW_METERSERIAL_NO,
          meterFixedDate: element.METER_FIXED_DATE,
          releasedDate: element.RELEASED_DATE,
          orderNo: element.ORDER_NO,
          remarks: element.REMARKS,
          creationDate: element.CREATION_DATE,
          ir:element.IR,
          fr:element.FR,
          previousStatus: element.PRESTATUS,
          status: element.STATUS,
          phoneNumber:element.PHONE
        }).then(function(docRef) {
          console.log("Document written with ID: ", docRef.id,  index);
        });
      })
    }) */


    /* this.jsonService.getBankCollectionJson().subscribe((data) => {
      console.log("customer json data", data);
      data.forEach((element, index) => {
      
        
        this.firestore.collection('bankCashCollection').add({
          RRNO: element.RRNO,
          name:element.NAME,
          doorNo:element.DOORNO,
          phoneNumber:element.PHONE,
          receiptNo: element.RECEIPT,
          billDateTime: moment(element.DATE, 'YYYY-MM-DD').format('YYYY/MM/DD HH:mm:ss'),
          mode: element.MODE,
          amount: element.AMOUNT,
          chequeDdNo: element.CHQDDNO,
          chequeDdBranch : element.CHQDDBRANCH,
          paymentReceivedBank : element.PAYMENTRECEIVEDBANK,
          user_id: element.USER_ID,
        
          date: element.DATE,
          chequeDdDate: element.CHQDDDATE,
          processed: element.PROCESSED,
        }).then(function(docRef) {
            console.log("Document written with ID: ", docRef.id,  index);
        });
      });
    }); */

    /* this.jsonService.getNameChangeJson().subscribe((data) => {
      console.log("name change json data", data);
      data.forEach((element, index) => {
        this.firestore.collection('name-change').add({
          rrno:element.RRNo,
          oldConsumerName: element.CONSUMER_OLD_NAME,
          newConsumerName: element.CONSUMER_NEW_NAME,
          streetName: element.STREET_NAME,
          oldDoorNo: element.OLD_DOOR_NO,
          newDoorNo : element.NEW_DOOR_NO,
          userId: element.USER_ID,
          applicationDate: element.APPLICATION_DATE,
          enteredDate: element.	ENTERED_DATE,
          orderNo: element.ORDER_NO,
          remarks: element.REMARKS,
          phoneNumber: element.PHONE_NO,
          serviceDate: element.SERVICEDATE
        }).then(function(docRef) {
            console.log("Document written with ID: ", docRef.id,  index);
         });
      });
    }); */

    /* this.jsonService.getMeterDisconnectionJson().subscribe((data) => {
      console.log("name change json data", data);
      data.forEach((element, index) => {
        this.firestore.collection('meter-disconnect').add({
          rrno:element.RRNo,
          consumerName: element.CONSUMER_OLD_NAME,
          meterSerialNumber :element.OLD_METERSERIAL_NO,
          newMeterSerialNumber: element.NEW_METERSERIAL_NO,
          disconnectionDate:element.DISCONNECTION_DATE,
          reconnectionDate: element.RECONNECTION_DATE,
          enteredDate: element.ENTERED_DATE,
          orderNo: element.ORDER_NO,
          remarks: element.REMARKS,
          userId: element.USER_ID,
          craetionDate: element.CREATION_DATE,
          connection_status: element.STATUS,
          phoneNumber: element.PHONE
        }).then(function(docRef) {
            console.log("Document written with ID: ", docRef.id,  index);
         });
      });
    }); */

    /* this.jsonService.getTariffChangeJson().subscribe((data) => {
      console.log("name change json data", data);
      data.forEach((element, index) => {
        this.firestore.collection('type-change').add({
          rrno:element.RRNo,
          consumerName: element.NAME,
          oldTariff: element.OLD_CONNECTION_TYPE,
          newTariff: element.CONNECTION_TYPE,
          oldNoOfFlats: element.OLD_NO_OF_FLATS,
          newNoOfFlats: element.NO_OF_FLATS,
          applicationDate: element.APPLICATION_DATE,
          enteredDate: element.ENTERED_DATE,
          recordDate : element.record_date,
          oldDoorNo: element.OLD_HOUSE_NO,
          newDoorNo: element.HOUSE_NO,
          userId: element.USER_ID,
          orderNo: element.ORDER_NO,
          remarks: element.REMARKS,
          phoneNumber: element.PHONE,
        }).then(function(docRef) {
            console.log("Document written with ID: ", docRef.id,  index);
         });
      });
    }); */

    /* this.jsonService.getAddressChangeJson().subscribe((data) => {
      console.log("name change json data", data);
      data.forEach((element, index) => {
        this.firestore.collection('address-change').add({
          rrno:element.RRNo,
          consumerName: element.CONSUMER_OLD_NAME,
          oldStreetName: element.OLD_STREET_NAME,
          newStreetName: element.NEW_STREET_NAME,
          oldDoorNo: element.OLD_DOOR_NO,
          newDoorNo: element.NEW_DOOR_NO,
          userId: element.USER_ID,
          enteredDate: element.ENTERED_DATE,
          orderNo: element.OLD_ORDER_NO,
          newOrderNo:element.NEW_ORDER_NO,
          remarks: element.REMARKS,
          phoneNumber: element.PHONE_NO,
          creationDate: element.CREATION_DATE
        }).then(function(docRef) {
            console.log("Document written with ID: ", docRef.id,  index);
         });
      });
    }); */

    /* this.jsonService.getNoticeDetailsJson().subscribe((data) => {
      console.log("name change json data", data);
      data.forEach((element, index) => {
        this.firestore.collection('notice-details').add({
          rrno: element.RRNo,
          consumerName: element.CONSUMER_NAME,
          noticeDate: element.NOTICE_DATE,
          remarks: element.REMARKS,
          enteredDate: element.ENTERED_DATE,
          userId: element.USER_ID
        }).then(function(docRef) {
            console.log("Document written with ID: ", docRef.id,  index);
        });
      });
    }); */

    /* this.jsonService.getConnectionDateChangeJson().subscribe((data) => {
      console.log("name change json data", data);
      data.forEach((element, index) => {
        this.firestore.collection('connection-date-change').add({
          rrno: element.RRNo,
          consumerName: element.CONSUMER_NAME,
          oldServiceDate: element.OLD_CONNECTION_DATE,
          newServiceDate: element.NEW_CONNECTION_DATE,
          userId: element.USER_ID,
          enteredDate: element.ENTERED_DATE,
          orderNo: element.ORDER_NO,
          remarks: element.REMARKS,
          phoneNumber: element.PHONE_NO
        }).then(function(docRef) {
            console.log("Document written with ID: ", docRef.id,  index);
        });
      });
    }); */

    /* this.jsonService.getRevisionJson().subscribe((data) => {
      console.log("name change json data", data);
      data.forEach((element, index) => {
        this.firestore.collection('revision').add({
          rrno:element.RRNO,
          consumerName: element.NAME,
          address: element.StreetName,
          balance: element.BALANCE,
          rebate: element.REBATE,
          userId: element.USER_ID,
          enteredDate: element.REMARKS,
          remarks: element.CREATION_DATE,
        }).then(function(docRef) {
            console.log("Document written with ID: ", docRef.id,  index);
        });
      });
    }); */

    /* this.jsonService.getRevisionJson().subscribe((data) => {
      console.log("name change json data", data);
      data.forEach((element, index) => {
        this.firestore.collection('revision').add({
          rrno:element.RRNO,
          consumerName: element.NAME,
          address: element.StreetName,
          balance: element.BALANCE,
          rebate: element.REBATE,
          userId: element.USER_ID,
          enteredDate: element.REMARKS,
          remarks: element.CREATION_DATE,
        }).then(function(docRef) {
            console.log("Document written with ID: ", docRef.id,  index);
        });
      });
    }); */

  }

}
