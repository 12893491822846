// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.
//development
// export const environment = {
//   production: false,
//   firebase : {
//     apiKey: "AIzaSyC5QZW-9448QN8m5YYBACoa9lf81n39vdk",
//     authDomain: "mnccompusolution-83617.firebaseapp.com",
//     databaseURL: "https://mnccompusolution-83617.firebaseio.com",
//     projectId: "mnccompusolution-83617",
//     storageBucket: "mnccompusolution-83617.appspot.com",
//     messagingSenderId: "683502353275"
//   }
// };

// export const environment = {
//    production: false,
//    firebase : {
//      apiKey: "AIzaSyCoAQAA2mD-Os_kFNNZ_cUSA3N1VnyODNY",
//      authDomain: "mn-compu-solution.firebaseapp.com",
//      databaseURL: "https://mn-compu-solution.firebaseio.com",
//      projectId: "mn-compu-solution",
//      storageBucket: "mn-compu-solution.appspot.com",
//      messagingSenderId: "1042628175290",
//      appId: "1:1042628175290:web:92984f49652376a2db6983",
//      measurementId: "G-GKENMHZM89"
//    }
// };

//production
/* export const environment = {
  production: false,
  firebase : {
    apiKey: "AIzaSyAwh3MgoTYgaKRjxqTWBdva0hdAPaczOyA",
    authDomain: "mn-compu-solutions.firebaseapp.com",
    databaseURL: "https://mn-compu-solutions.firebaseio.com",
    projectId: "mn-compu-solutions",
    storageBucket: "mn-compu-solutions.appspot.com",
    messagingSenderId: "607056144357",
    appId: "1:607056144357:web:c7ccdc42589d867056710c",
    measurementId: "G-LM0LLTRK1K"
  }
}; */

//bank
// export const environment = {
//   production: false,
//   firebase : {
//     apiKey: "AIzaSyCvCCsMVlMvUNWOgaQqHrCnMXYgq6g-f1U",
//     authDomain: "mn-compu-solution-80aae.firebaseapp.com",
//     projectId: "mn-compu-solution-80aae",
//     storageBucket: "mn-compu-solution-80aae.appspot.com",
//     messagingSenderId: "338138230928",
//     appId: "1:338138230928:web:02bc1252a8ee69fc43a815",
//     measurementId: "G-VECGVH0SPN"
//   }
// };

//ledger
export const environment = {
  production: false,
  firebase: {
    apiKey: "AIzaSyAqEVRigKq9lr7VKjDs2KVLBx62NexrDAY",
    authDomain: "mn-compu-solution-ledger.firebaseapp.com",
    projectId: "mn-compu-solution-ledger",
    storageBucket: "mn-compu-solution-ledger.appspot.com",
    messagingSenderId: "679272638157",
    appId: "1:679272638157:web:2b2d85878a62ff67166b84",
    measurementId: "G-KR574FDW56",
  },
  //apiUrl: "http://localhost:4000/"
  apiUrl: "https://billing-backend.hassan.nixies.in/",
};
