import { Component, OnInit } from '@angular/core';
import { AngularFirestore } from '@angular/fire/firestore';
import { FormBuilder, FormGroup } from '@angular/forms';
import { DataStateChangeEvent, GridDataResult, PageChangeEvent } from '@progress/kendo-angular-grid';
import { process, State } from '@progress/kendo-data-query';
import { LedgerService } from 'src/app/shared/service/ledger.service';

declare var require:any;
var moment = require('moment');

@Component({
  selector: 'app-suspense-transfer-details',
  templateUrl: './suspense-transfer-details.component.html',
  styleUrls: ['./suspense-transfer-details.component.scss']
})
export class SuspenseTransferDetailsComponent implements OnInit {
  filterForm:FormGroup;
  detailsArray:any = [];
  xlsxName;

  public gridData: GridDataResult;
  public state: State = {
    skip: 0,
    take:10,
    filter: {
      logic: 'and',
      filters: []
    }
  };
  public buttonCount = 5;
  public info = true;
  public type: 'numeric' | 'input' = 'numeric';
  public previousNext = true;
  public pageSizes = [200,200,300,400, 500,600,700,800,900,1000, 50000];

  constructor(private fb:FormBuilder, private firestore:AngularFirestore, private ledgerService:LedgerService) { }

  ngOnInit() {
    this.filterForm = this.fb.group({
      from_date: [],
      to_date: []
    });
  }

  onFilterSubmit(){
    this.detailsArray = [];
    this.gridData =  process(this.detailsArray, this.state);
    let fromDate = this.filterForm.value.from_date;
    let toDate = this.filterForm.value.to_date;

    var fromDateTime = fromDate + ' ' + moment('2021-06-05 00:00:00').format('HH:mm:ss');
    var toDateTime = toDate + ' ' + moment('2021-06-05 23:59:59').format('HH:mm:ss');

  
    this.xlsxName = fromDate+'-to-'+toDate;

    console.log("Form Value" , fromDateTime, toDateTime);
    var data = {
      'fromDate': fromDateTime,
      'toDate':toDateTime,
      'type':'suspense'
    }

    this.ledgerService.getCashTransfer(data).then(async response=>{
      if (response.code == 200) {
        this.detailsArray = await response.data;
        console.log("this.detailsArray", await this.detailsArray)
        this.gridData =  await process(this.detailsArray, this.state);
      }
    });
    
  }

  refresh(){
    location.reload();
  }

  onchangeDate(e){
    this.detailsArray = [];
  }

  public dataStateChange(state: DataStateChangeEvent): void {
    this.state = state;
    this.gridData = process( this.detailsArray, this.state);
  }

  public pageChange({ skip, take }: PageChangeEvent): void {
    console.log("skip",skip,take )
    this.state.skip = skip;
    this.state.take = take; 
    this.gridData = process( this.detailsArray, this.state);
  }

  public allData = () => {
    return process(this.detailsArray, {
      filter: this.state.filter
    });
  }

}
