import { Injectable } from '@angular/core';
import { AngularFirestore } from '@angular/fire/firestore';
import { AngularFireAuth } from '@angular/fire/auth';
import { ToastrService } from 'ngx-toastr';
import { AngularFireStorage } from '@angular/fire/storage';
import { finalize } from 'rxjs/operators';
import { Observable } from 'rxjs';
import Swal from 'sweetalert2';

@Injectable({
  providedIn: 'root'
})
export class SupervisorService {

  public showErrorMsg: Boolean;
  public printErrorMsg:string;
  downloadURL: Observable<string>;
  imgPath;

  constructor(public firestore:AngularFirestore, public fireAuth:AngularFireAuth, public toastrService: ToastrService, private storage:AngularFireStorage) { }

  getSupervisor(){
    return this.firestore.collection('/users', ref => ref.where('role', '==', 'supervisor')).snapshotChanges();
  }

  async signup(supervisor_details, imageUrl, validationForm, hasFile, selectedFiles){
    var result = await this.fireAuth.auth.createUserWithEmailAndPassword(supervisor_details.email, supervisor_details.password).then((success) => {
      var supervisorId = this.fireAuth.auth.currentUser.uid;
      if(hasFile && selectedFiles.length != 0){
        this.uploadpic(supervisorId, imageUrl, supervisor_details);
        this.reset(validationForm);
      }
      else{
        this.storeSupervisor(null,supervisorId,supervisor_details);
        this.reset(validationForm);
      }
      return "200";
    }).catch((error => {
      this.showErrorMsg = true;
      setTimeout(() => this.showErrorMsg = false, 3000);
      //this.printErrorMsg = error;
      this.toastrService.error(error, "Error!", {timeOut:8000});
      return "204";
    }));
    return result;
  }

  reset(validationForm){
    validationForm.setValue({
      name: "",
      email: "",
      phoneNumber: "",
      password: "",
      confirmPassword: "",
      addressLine1: "",
      addressLine2: "",
      ward: "",
      city: "",
      state: "",
      pincode: ""
    });
  }

  storeSupervisor(imageUrl,supervisorId,supervisor_details)
  {
    this.firestore.collection('users').doc(supervisorId).set({
      name: supervisor_details.name,
      phoneNumber: supervisor_details.phoneNumber,
      email: supervisor_details.email,
      password: supervisor_details.password,
      addressLine1: supervisor_details.addressLine1, 
      addressLine2: supervisor_details.addressLine2,
      ward: supervisor_details.ward,
      city: supervisor_details.city,
      state: supervisor_details.state,
      pincode: supervisor_details.pincode,
      role: "supervisor",
      imagePath:imageUrl
    }).then((success) => {
      this.toastrService.success('Supervisor Added Successfully!', 'Success!', {
        timeOut: 8000
      });
    });
  }

  deleteSupervisor(id){
    Swal({
      title: 'Are you sure?',
      text: "You won't be able to revert this!",
      type: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: 'Yes, delete it!'
    }).then((result) => {
      if (result.value) {
        this.firestore.doc('users/'+id).delete();
        this.firestore.collection('deleteUser').doc(id).set({delete: "yes"});
        Swal(
          'Deleted!',
          'Supervisor has been deleted.',
          'success'
        )
      }
    });
  }

  updateSupervisor(supervisor, id){
    this.firestore.doc('users/'+id).update(supervisor);
    location.reload();
    //this.router.navigate(['/admins']);
  }

  uploadpic(supervisorId, imageUrl, supervisor_details){
    let uniqkey = 'pic' + Math.floor(Math.random() * 1000000);
    const uploadTask = this.storage.upload('/supervisor/' + uniqkey, imageUrl);
    const ref = this.storage.ref('/supervisor/' + uniqkey);
    console.log("uploadTask", uploadTask);
    //this.imgsrc = uploadTask.downloadURL()
    uploadTask.snapshotChanges().pipe(
      finalize(() => {
        this.downloadURL = ref.getDownloadURL();
        console.log(this.downloadURL);
        this.downloadURL.subscribe(url => {
          this.imgPath= url;
          console.log("url",this.imgPath)
          this.toastrService.success("Profile image uploaded succcessfully!", "Success!", {
            timeOut:3000
          });
          this.storeSupervisor(this.imgPath, supervisorId,supervisor_details);
        });
      })
    ).subscribe();
  }
}
