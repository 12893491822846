import { Component, OnInit } from "@angular/core";
import { FirebaseStorage } from "@angular/fire";
import { AngularFirestore } from "@angular/fire/firestore";
import { FormBuilder, FormGroup, Validators } from "@angular/forms";
import {
  DataStateChangeEvent,
  GridDataResult,
  PageChangeEvent,
} from "@progress/kendo-angular-grid";
import { process, SortDescriptor, State } from "@progress/kendo-data-query";
import { ToastrService } from "ngx-toastr";
import { ExcelExportData } from "@progress/kendo-angular-excel-export";
import { flatMap } from "rxjs/operators";
import { LedgerService } from "src/app/shared/service/ledger.service";
import { environment } from "src/environments/environment";

declare var require: any;
var moment = require("moment");

@Component({
  selector: "app-change-details",
  templateUrl: "./change-details.component.html",
  styleUrls: ["./change-details.component.scss"],
})
export class ChangeDetailsComponent implements OnInit {
  filterForm: FormGroup;
  detailsArray: any = [];
  type;
  public gridData: GridDataResult;
  xlsxName;

  public state: State = {
    skip: 0,
    take: 10,
    filter: {
      logic: "and",
      filters: [],
    },
  };
  public buttonCount = 5;
  public info = true;
  public type1: "numeric" | "input" = "numeric";
  public previousNext = true;
  public pageSizes = [200, 200, 300, 400, 500, 600, 700, 800, 900, 1000, 50000];
  userRole = localStorage.getItem("loggedInUserRole");

  constructor(
    private fb: FormBuilder,
    private firestore: AngularFirestore,
    private totastrService: ToastrService,
    private ledgerService: LedgerService
  ) {}

  ngOnInit() {
    this.filterForm = this.fb.group({
      type: ["", Validators.required],
      from_date: ["", Validators.required],
      to_date: ["", Validators.required],
    });
  }

  onFilterSubmit() {
    //console.log("date", );
    this.detailsArray = [];
    this.gridData = process(this.detailsArray, this.state);
    var data;

    var fromDate = this.filterForm.controls.from_date.value;
    var toDate = this.filterForm.controls.to_date.value;
    this.type = this.filterForm.controls.type.value;

    this.xlsxName = fromDate && toDate ? "-" + fromDate + "-to-" + toDate : "";

    if (
      this.type != "" &&
      ((fromDate != "" && toDate == "") || (fromDate == "" && toDate != ""))
    ) {
      alert("please select Date");
    } else {
      var fromDateTime =
        fromDate + " " + moment("2021-06-05 00:00:00").format("HH:mm:ss");
      var toDateTime =
        toDate + " " + moment("2021-06-05 23:59:59").format("HH:mm:ss");
      var collection;

      if (fromDate != "" && toDate != "") {
        /* collection = this.firestore.collection(this.type, ref => ref
                          .where('enteredDate', '>', fromDateTime)
                          .where('enteredDate', '<', toDateTime)); */
        data = {
          fromDate: fromDateTime,
          toDate: toDateTime,
          type: this.type,
        };
      } else {
        //collection = this.firestore.collection(this.type);
        data = {
          type: this.type,
          filterBy: "only-type",
        };
      }

      this.ledgerService.changedDetails(data).then(async (response) => {
        if (response.code == 200) {
          this.detailsArray = await response.data;
          console.log("this.detailsArray", await this.detailsArray);
          this.gridData = await process(this.detailsArray, this.state);
        }
      });

      /* collection.snapshotChanges().subscribe(c => {
        c.map(async item => {
          var doc = item.payload.doc.data();
          doc.id = item.payload.doc.id;

          if(!!item.payload.doc.data().userKey){
            await this.firestore.doc('users/'+item.payload.doc.data().userKey).valueChanges().subscribe(u =>{
              doc["userName"] =  u["name"];
            })
          }
          else{
            doc["userName"] = item.payload.doc.data().userId;
          }
          await this.detailsArray.push(doc);
          
          this.gridData = await process( this.detailsArray, this.state);
        });
      }); */
    }
  }

  public dataStateChange(state: DataStateChangeEvent): void {
    this.state = state;
    this.gridData = process(this.detailsArray, this.state);
  }
  public pageChange({ skip, take }: PageChangeEvent): void {
    console.log("skip", skip, take);
    this.state.skip = skip;
    this.state.take = take;
    this.gridData = process(this.detailsArray, this.state);
  }

  public allData = () => {
    return process(this.detailsArray, {
      filter: this.state.filter,
    });
  };

  refresh() {
    location.reload();
  }

  view(data) {
    console.log("view data", data);
    var path =
      this.type == "name_change"
        ? "name-change"
        : this.type == "address_change"
        ? "address-change"
        : this.type == "connection_date_change"
        ? "connection-date-change"
        : this.type == "meter_change"
        ? "meter-change"
        : this.type == "meter_disconnection"
        ? "meter-disconnection"
        : this.type == "meter_reconnection"
        ? "meter-reconnection"
        : this.type == "notice_detail"
        ? "notice-detail"
        : this.type == "tariff_change"
        ? "tariff-change"
        : "revision";
    var url = `${environment.apiUrl}` + "images/" + path + "/" + data;
    window.open(url);
  }

  async delete(dataItem, type) {
    console.log("delete data", await dataItem);
    dataItem.type = type;
    console.log("type", type);
    console.log("dataitem type", dataItem);
    /* var data = {};
    data["rrno"] = await dataItem.rrno;
    data["enteredDate"] =await  dataItem.enteredDate;
    data["id"]= await dataItem.id;
    data["type"] = await type;
    if(await type=='name-change'){
      data["name"] = await dataItem.oldConsumerName
      
    }
    else if(type == 'address-change'){
      data = await  {
        streetName:dataItem.oldStreetName,
        doorNo:dataItem.oldDoorNo
      }
    }
    else if(type == 'meter-change'){
      data = {
        meterSerialNumber:dataItem.oldMeterSerialNumber
      }
    }
    else if(type == 'meter-disconnect'){
      data = {
        consumerConnectionStatusType:'Live'
      }
    }
    else if(type == 'type-change'){
      data = {
        tariff:dataItem.oldTariff,
        doorNo:dataItem.oldDoorNo
      }
    }
    else if(type == 'meter-reconnection'){
      data = {
        meterSerialNumber:dataItem.oldMeterSerialNumber,
        consumerConnectionStatusType:dataItem.oldConnectionStatus
      }
    }
    else if(type == 'connection-date-change'){
      data = {
        serviceDate: dataItem.oldServiceDate
      }
    } */

    await this.ledgerService
      .deleteChangedDetails(dataItem)
      .then(async (response) => {
        if (response.code == 200) {
          this.onFilterSubmit();
          this.totastrService.success("Deleted Successfully");
        }
      });

    /* if(type=='revision'){
      var d  = {
        CBA: dataItem.balance
      }
      var keepGoing = true;
      var updateLedger = this.firestore.collection('ledger', ref=> ref.where('connectionId', '==', dataItem.rrno).where('billDateTime', '<=', dataItem.enteredDate).orderBy('billDateTime', 'desc').limit(1)).snapshotChanges().pipe(flatMap(clients => clients));
      updateLedger.subscribe(doc => {
        if(keepGoing){
          keepGoing = false;
          this.firestore.collection('ledger').doc(doc.payload.doc.id).update(d).then(response =>{
            this.firestore.doc(type+'/'+dataItem.id).delete().then((success)=>{
              this.onFilterSubmit();
              this.totastrService.success('Deleted Successfully');
            });
          })
        }
      });
    }
    else{
      var updateConnection = this.firestore.collection('connections', ref=> ref.where('RRNO', '==', dataItem.rrno).limit(1)).snapshotChanges().pipe(flatMap(clients => clients));
      updateConnection.subscribe(doc => {
        this.firestore.collection('connections').doc(doc.payload.doc.id).update(data).then(response =>{
          this.firestore.doc(type+'/'+dataItem.id).delete().then((success)=>{
            this.onFilterSubmit();
            this.totastrService.success('Deleted Successfully');
          });
        })
      });
    } */
  }
}
