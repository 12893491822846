import { Component, Input, OnInit, Output, EventEmitter } from '@angular/core';
import { AngularFirestore } from '@angular/fire/firestore';
import { AngularFireStorage } from '@angular/fire/storage';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute } from '@angular/router';
import { CustomValidators } from 'ng2-validation';
import { ToastrService } from 'ngx-toastr';
import { Observable } from 'rxjs';
import { BankStaffService } from 'src/app/shared/bank-staff/bank-staff.service';
import { BankStaff } from 'src/app/shared/bank-staff/bankStaff.model';
import { finalize } from 'rxjs/operators';
import { environment } from 'src/environments/environment';
import { THIS_EXPR } from '@angular/compiler/src/output/output_ast';

@Component({
  selector: 'app-view-bank-staffs',
  templateUrl: './view-bank-staffs.component.html',
  styleUrls: ['./view-bank-staffs.component.scss']
})
export class ViewBankStaffsComponent implements OnInit {
  @Input('data') data;
  @Output('onCloseModal') onCloseModal =  new EventEmitter<string>();

  imgsrc;
  apiUrl = `${environment.apiUrl}`;

  constructor(private route:ActivatedRoute, private firestore:AngularFirestore, private bankStaffService:BankStaffService, private toastrService:ToastrService, private storage:AngularFireStorage) {
   
  }

  ngOnInit() {
    this.imgsrc = this.data['image'] ? this.apiUrl + 'images/bank-staffs/'+this.data['image'] : 'assets/images/user/1.jpg';
  }

  onClose(){
    this.onCloseModal.emit('close');
  }
}
