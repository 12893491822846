import { Component, OnInit } from '@angular/core';
import { AngularFirestore } from '@angular/fire/firestore';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { ToastrService } from 'ngx-toastr';
import { Agent } from 'src/app/shared/agent/agent.model';
import { AgentService } from 'src/app/shared/agent/agent.service';
import { DataStateChangeEvent, GridDataResult } from '@progress/kendo-angular-grid';
import { process, State } from '@progress/kendo-data-query';
import { AuthService } from 'src/app/shared/auth/auth.service';

import Swal from 'sweetalert2';

@Component({
  selector: 'app-agents',
  templateUrl: './agents.component.html',
  styleUrls: ['./agents.component.scss']
})
export class AgentsComponent implements OnInit {
  agents:Agent[];
  show:string = 'list';
  data:any;

  public gridData: GridDataResult;
  public state: State = {
    filter: {
      logic: 'and',
      filters: []
    }
  };

  constructor(private agentService:AgentService, private modalService:NgbModal, private authService:AuthService) { 
    this.getAllAgents();
  }

  ngOnInit() {
    
  }

  getAllAgents(){
    this.authService.getAllUsers('agent').then((response)=>{
      if(response.code == 200){
        this.agents = response.data;
        this.gridData =  process( this.agents, this.state);
      }
    })
  }

  onClose(){

  }

  view(dataItem, content){
    this.data = dataItem;
    this.modalService.open(content, {centered:true, size:'lg'});
  }

  edit(dataItem){
    this.data = dataItem;
    this.show = 'edit'
  }

  close()
  {
    this.show = 'list'
    this.getAllAgents();
  }

  onCloseModal()
  {
    this.modalService.dismissAll();
  }

  delete(data){
    //this.agentService.deleteAgent(data.id);
    Swal({
      title: 'Are you sure?',
      text: "You won't be able to revert this!",
      type: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: 'Yes, delete it!'
    }).then((result) => {
      if (result.value) {
        this.authService.deleteUser(data).then(response => {
          if(response.code == 200){
            this.getAllAgents();
            Swal(
              'Deleted!',
              'User has been deleted.',
              'success'
            )
          }
        })
       
      }
    });
  }

  public dataStateChange(state: DataStateChangeEvent): void {
    this.state = state;
    this.gridData = process( this.agents, this.state);
  }

  tabChange(data){
    //console.log("tab change", data);
    this.getAllAgents();
  }
}
