import { Injectable } from '@angular/core';
import { CanActivate, ActivatedRouteSnapshot, Router, RouterStateSnapshot } from '@angular/router';
import { Observable } from 'rxjs/Rx';
import { AngularFireAuth } from '@angular/fire/auth';
import 'rxjs/add/operator/do';
import 'rxjs/add/operator/map';
import 'rxjs/add/operator/take';
import { AuthService } from '../shared/auth/auth.service';
@Injectable({
  providedIn: 'root'
})
export class AuthGuard implements CanActivate {

  constructor(private auth: AngularFireAuth, private router: Router, private authService: AuthService) {

  }

  /* canActivate(route: ActivatedRouteSnapshot): Observable<boolean> {
    return Observable.from(this.auth.authState)
    .take(1)
    .map(state => !!state)
    .do(authenticated => {
      if(!authenticated) this.router.navigate(['/authentication/login']);
      else if(localStorage.getItem('loggedInUserRole') == "admin" && route.data['onlySuperAdmin'])
        {
          this.router.navigate([ 'error/404' ]);
        }

        else if(localStorage.getItem('loggedInUserRole') == "supervisor")
        {
          this.router.navigate([ '/authentication/login' ]);
        }
    })
  } */

  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): boolean {
    if (this.authService.isLoggednIn()) {
      if(localStorage.getItem('loggedInUserRole') == "superadmin" && route.data['onlyBankStaff'] || localStorage.getItem('loggedInUserRole') == "admin" && route.data['onlyBankStaff'] || localStorage.getItem('loggedInUserRole') == "supervisor" && route.data['onlyBankStaff']){
        console.log("1");
        this.router.navigate(['/authentication/login']);
        return false
      }
      else if (localStorage.getItem('loggedInUserRole') == "admin" && route.data['onlySuperAdmin']) {
        console.log("2");
        this.router.navigate(['/authentication/login']);
        return false
      }
      else if (localStorage.getItem('loggedInUserRole') == "supervisor" && route.data['onlySuperAdmin'] || localStorage.getItem('loggedInUserRole') == "supervisor" && route.data['notAllowed']) {
        console.log("3");
        this.router.navigate(['/authentication/login']);
        return false
      }
      console.log("4");
      return true;
    }
    else{
      console.log("5");
      this.router.navigate(['/authentication/login']);
        return false
    }

  }


}
