import { Component, HostListener, Inject } from '@angular/core';
import { AuthService } from './shared/auth/auth.service';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent {
  auth:any;
  isRefreshed = false;

  /* @HostListener('window:beforeunload')
  unloadHandler(event) {
    if (!this.isRefreshed) {
      
      this.auth.logout()
    }
  } */

  constructor(private authService:AuthService) {
    this.auth = authService;
   /*  window.addEventListener('keydown', event => {
       console.log('Key Pressed ==>', event, this.isRefreshed);
       if (event.key == 'r' || event.key == 'F5') this.isRefreshed = true;
    }); */
 }
}
