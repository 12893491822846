import { Routes, RouterModule } from '@angular/router';
import { LoginComponent } from 'src/app/layouts/authentication/login/login.component';

export const authcontent: Routes = [
    {
        path: '',
        redirectTo : 'login',
        pathMatch: "full"
    },
    {
        path: 'login',
        component: LoginComponent
    }
];