import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';
import { ResponseData } from '../interfaces/response-data';

interface Data {
  code: number;
  message: string;
  data: any;
}
@Injectable({
  providedIn: 'root'
})
export class LedgerService {
  private apiUrl = `${environment.apiUrl + 'api'}`;

  constructor(private http:HttpClient) {
   
  }


  getLedgerReports(data){
    var filter = JSON.stringify(data);
    console.log("sds",this.apiUrl + '/report/ledger'+`/${filter}`);
   
    
    return new Promise<ResponseData>((resolve)=>{
      this.http.get<ResponseData>(this.apiUrl + '/report/ledger'+`/${filter}`)
      .subscribe(
          data => {
            console.log("data",data);            
            resolve(data);
          },
          error => {
            console.log("Error", error);
          });
    })
  }

  getDailyBilling(data){
    var filter = JSON.stringify(data);
    console.log(this.apiUrl + '/report/daily-billing'+`/${filter}`);
    
    return new Promise<ResponseData>((resolve)=>{
      this.http.get<ResponseData>(this.apiUrl + '/report/daily-billing'+`/${filter}`)
      .subscribe(
          data => {
            console.log("data",data);            
            resolve(data);
          },
          error => {
            console.log("Error", error);
          });
    })
  }

  getDailyBillingOld(data){
    var filter = JSON.stringify(data);
  
    return new Promise<ResponseData>((resolve)=>{
      this.http.get<ResponseData>(this.apiUrl + '/report/daily-billing-old'+`/${filter}`)
      .subscribe(
          data => {
            console.log("data",data);            
            resolve(data);
          },
          error => {
            console.log("Error", error);
          });
    })
  }

  getDCB(data){
    var filter = JSON.stringify(data);
    //console.log(this.apiUrl + '/report/daily-billing'+`/${filter}`);
    
    return new Promise<ResponseData>((resolve)=>{
      this.http.get<ResponseData>(this.apiUrl + '/report/dcb'+`/${filter}`)
      .subscribe(
          data => {
            console.log("data",data);            
            resolve(data);
          },
          error => {
            console.log("Error", error);
          });
    })
  }

  getDCBDetails(data){
    var filter = JSON.stringify(data);
    
    return new Promise<ResponseData>((resolve)=>{
      this.http.get<ResponseData>(this.apiUrl + '/report/dcb-details'+`/${filter}`)
      .subscribe(
          data => {
            console.log("data",data);            
            resolve(data);
          },
          error => {
            console.log("Error", error);
          });
    })
  }

  getPendingBalance(data){
    var filter = JSON.stringify(data);
    
    return new Promise<ResponseData>((resolve)=>{
      this.http.get<ResponseData>(this.apiUrl + '/report/pending-balance'+`/${filter}`)
      .subscribe(
          data => {
            console.log("data",data);            
            resolve(data);
          },
          error => {
            console.log("Error", error);
          });
    })
  }

  getCollectionReport(data){
    var filter = JSON.stringify(data);
    console.log(this.apiUrl + '/report/collection-report'+`/${filter}`);
    return new Promise<ResponseData>((resolve)=>{
      this.http.get<ResponseData>(this.apiUrl + '/report/collection-report'+`/${filter}`)
      .subscribe(
          data => {
            console.log("data",data);            
            resolve(data);
          },
          error => {
            console.log("Error", error);
        });
    })
  }

  getCollectionReportDetails(data){
    var filter = JSON.stringify(data);
    console.log(this.apiUrl + '/report/cash-collection-detail'+`/${filter}`);
    return new Promise<ResponseData>((resolve)=>{
      this.http.get<ResponseData>(this.apiUrl + '/report/cash-collection-detail'+`/${filter}`)
      .subscribe(
          data => {
            console.log("data",data);            
            resolve(data);
          },
          error => {
            console.log("Error", error);
        });
    })
  }

  changedDetails(data){
    var filter = JSON.stringify(data);
    console.log(this.apiUrl + '/report/changed-details'+`/${filter}`);
    return new Promise<ResponseData>((resolve)=>{
      this.http.get<ResponseData>(this.apiUrl + '/report/changed-details'+`/${filter}`)
      .subscribe(
          data => {
            console.log("data",data);            
            resolve(data);
          },
          error => {
            console.log("Error", error);
        });
    })
  }

  connection(data){
    var filter = JSON.stringify(data);
    console.log(this.apiUrl + '/report/connection'+`/${filter}`);
    return new Promise<ResponseData>((resolve)=>{
      this.http.get<ResponseData>(this.apiUrl + '/report/connection'+`/${filter}`)
      .subscribe(
          data => {
            console.log("data",data);            
            resolve(data);
          },
          error => {
            console.log("Error", error);
        });
    })
  }

  getWardDetails(data){
    var filter = JSON.stringify(data);
    console.log(this.apiUrl + '/report/ward-details'+`/${filter}`);
    return new Promise<ResponseData>((resolve)=>{
      this.http.get<ResponseData>(this.apiUrl + '/report/ward-details'+`/${filter}`)
      .subscribe(
          data => {
            console.log("data",data);            
            resolve(data);
          },
          error => {
            console.log("Error", error);
        });
    })
  }

  getCashTransfer(data){
    var filter = JSON.stringify(data);
    console.log(this.apiUrl + '/report/cash-transfer'+`/${filter}`);
    return new Promise<ResponseData>((resolve)=>{
      this.http.get<ResponseData>(this.apiUrl + '/report/cash-transfer'+`/${filter}`)
      .subscribe(
          data => {
            console.log("data",data);            
            resolve(data);
          },
          error => {
            console.log("Error", error);
        });
    })
  }

  getMeterReaderDayWise(data){
    var filter = JSON.stringify(data);
  
    return new Promise<ResponseData>((resolve)=>{
      this.http.get<ResponseData>(this.apiUrl + '/report/meter-reader-day-wise'+`/${filter}`)
      .subscribe(
          data => {
            console.log("data",data);            
            resolve(data);
          },
          error => {
            console.log("Error", error);
        });
    })
  }

  getLedgerRectify(data){
    var filter = JSON.stringify(data);
  
    console.log('ledger rec service', filter)
    return new Promise<ResponseData>((resolve)=>{
      this.http.get<ResponseData>(this.apiUrl + '/report/get-ledger-rectify'+`/${filter}`)
      .subscribe(
          data => {
            console.log("data",data);            
            resolve(data);
          },
          error => {
            console.log("Error", error);
        });
    })
  }

rectifyLedger(data){
  console.log('ddd',data)
    var filter = JSON.stringify(data);

  console.log(filter)
    return new Promise<Data>((resolve) => {
      this.http.post<Data>(this.apiUrl + "/report/rectify-ledger", data).subscribe(
        (res) => {
          console.log("Success", res);
          resolve(res);
        },
        (error) => {
          console.log("Error", error);
        }
      );
    });

    // console.log('ledger rec service', filter)
    // return new Promise<ResponseData>((resolve)=>{
    //   this.http.post<ResponseData>(this.apiUrl + '/rectify-ledger'+`/${filter}`)
    //   .subscribe(
    //       data => {
    //         console.log("data",data);            
    //         resolve(data);
    //       },
    //       error => {
    //         console.log("Error", error);
    //     });
    // })
  }


  movePayments(data){
    console.log('ddd',data)
      var filter = JSON.stringify(data);
  
    console.log(filter)
      return new Promise<Data>((resolve) => {
        this.http.post<Data>(this.apiUrl + "/report/move-payment-to-another-ledger", data).subscribe(
          (res) => {
            console.log("Success", res);
            resolve(res);
          },
          (error) => {
            console.log("Error", error);
          }
        );
      });
    }
  getAgentConnections(data){
    var filter = JSON.stringify(data);
  
    return new Promise<ResponseData>((resolve)=>{
      this.http.get<ResponseData>(this.apiUrl + '/agents-connection'+`/${filter}`)
      .subscribe(
          data => {
            console.log("data",data);            
            resolve(data);
          },
          error => {
            console.log("Error", error);
        });
    })
  }

  getAgentLedger(data){
    var filter = JSON.stringify(data);
  
    return new Promise<ResponseData>((resolve)=>{
      this.http.get<ResponseData>(this.apiUrl + '/agents-ledger'+`/${filter}`)
      .subscribe(
          data => {
            console.log("data",data);            
            resolve(data);
          },
          error => {
            console.log("Error", error);
        });
    })
  }

  getUnbilledRecord(data){
    var filter = JSON.stringify(data);
  
    return new Promise<ResponseData>((resolve)=>{
      this.http.get<ResponseData>(this.apiUrl + '/report/unbilled-record'+`/${filter}`)
      .subscribe(
          data => {
            console.log("data",data);            
            resolve(data);
          },
          error => {
            console.log("Error", error);
        });
    })
  }
  getCurrentMonthCashCollection(){
    return new Promise<ResponseData>((resolve)=>{
      this.http.get<ResponseData>(this.apiUrl + '/report/get-currentMonthPayment')
      .subscribe(
          data => {
            console.log("data",data);            
            resolve(data);
          },
          error => {
            console.log("Error", error);
        });
    })
  }

  getUnbilledRecordDetails(data){
    var filter = JSON.stringify(data);
  
    return new Promise<ResponseData>((resolve)=>{
      this.http.get<ResponseData>(this.apiUrl + '/report/unbilled-record-details'+`/${filter}`)
      .subscribe(
          data => {
            console.log("data",data);            
            resolve(data);
          },
          error => {
            console.log("Error", error);
        });
    })
  }

  getBilledRecord(data){
    var filter = JSON.stringify(data);
  
    return new Promise<ResponseData>((resolve)=>{
      this.http.get<ResponseData>(this.apiUrl + '/report/billed-record'+`/${filter}`)
      .subscribe(
          data => {
            console.log("data",data);            
            resolve(data);
          },
          error => {
            console.log("Error", error);
        });
    })
  }

  getBilledRecordDetails(data){
    var filter = JSON.stringify(data);
  
    return new Promise<ResponseData>((resolve)=>{
      this.http.get<ResponseData>(this.apiUrl + '/report/billed-record-details'+`/${filter}`)
      .subscribe(
          data => {
            console.log("data",data);            
            resolve(data);
          },
          error => {
            console.log("Error", error);
        });
    })
  }


  deleteChangedDetails(data){
    return new Promise<ResponseData>((resolve)=>{
      this.http.post<ResponseData>(this.apiUrl + '/report/delete-change-details', data)
      .subscribe(
          data => {
            console.log("data",data);            
            resolve(data);
          },
          error => {
            console.log("Error", error);
            resolve(error);
        });
    })
  }

  getConnectionByWard(ward){
    console.log("ward in ", ward);
    return new Promise<ResponseData>((resolve)=>{
      this.http.get<ResponseData>(this.apiUrl + '/get-connnection-ward'+`/${ward}`)
      .subscribe(
          data => {
            console.log("data",data);            
            resolve(data);
          },
          error => {
            console.log("Error", error);
        });
    })
  }


  getDuplicateBills(data){
    var filter = JSON.stringify(data);
    return new Promise<ResponseData>((resolve)=>{
      this.http.get<ResponseData>(this.apiUrl + '/report/get-duplicate-bills'+`/${filter}`)
      .subscribe(
          data => {
            console.log("data",data);            
            resolve(data);
          },
          error => {
            console.log("Error", error);
        });
    })
  }


  deleteDuplicateBills(data){
    console.log('ddd',data)
      var filter = JSON.stringify(data);
  
    console.log(filter)
      return new Promise<Data>((resolve) => {
        this.http.post<Data>(this.apiUrl + "/report/delete-duplicate-bills", data).subscribe(
          (res) => {
            console.log("Success", res);
            resolve(res);
          },
          (error) => {
            console.log("Error", error);
          }
        );
      });
    }


    getCashCollection(data){
      var filter = JSON.stringify(data);
    
      return new Promise<ResponseData>((resolve)=>{
        this.http.get<ResponseData>(this.apiUrl +"/report/get-cash-collection-by-rrno"+`/${filter}`)
        .subscribe(
            data => {
              console.log("data",data);            
              resolve(data);
            },
            error => {
              console.log("Error", error);
          });
      })
    }

    getLedgerBillNo(data){
      var filter = JSON.stringify(data);
    
      return new Promise<ResponseData>((resolve)=>{
        this.http.get<ResponseData>(this.apiUrl +"/report/get-ledger-billNo-by-month-year"+`/${filter}`)
        .subscribe(
            data => {
              console.log("data",data);            
              resolve(data);
            },
            error => {
              console.log("Error", error);
          });
      })
    }
  
    updateCashCollection(cashCollection){
      return new Promise<Data>((resolve)=>{
        this.http.post<Data>(this.apiUrl + "/report/move-payment-to-another-ledger", cashCollection)
        .subscribe(
            data => {
              console.log("Success", data);
              resolve(data);
            },
            error => {
          });
      })
    }
}
