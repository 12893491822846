import { Component, OnInit } from '@angular/core';
import { AngularFirestore } from '@angular/fire/firestore';
import { FormBuilder, FormGroup } from '@angular/forms';
import { DataStateChangeEvent, GridDataResult, PageChangeEvent} from '@progress/kendo-angular-grid';
import { process, State } from '@progress/kendo-data-query';
import { AgentService } from 'src/app/shared/agent/agent.service';
import { LedgerService } from 'src/app/shared/service/ledger.service';

declare var require:any;
var moment  = require('moment');

@Component({
  selector: 'app-agent-wise-ledger-old',
  templateUrl: './agent-wise-ledger-old.component.html',
  styleUrls: ['./agent-wise-ledger-old.component.scss']
})
export class AgentWiseLedgerOldComponent implements OnInit {

  agents:any = [];
  filterForm:FormGroup;
  detailsArray:any = [];
  xlsxName;

  showLoader:boolean = false;

  public gridData: GridDataResult;
  public state: State = {
    skip: 0,
    take:10,
    filter: {
      logic: 'and',
      filters: []
    }
  };
  public buttonCount = 5;
  public info = true;
  public type: 'numeric' | 'input' = 'numeric';
  public previousNext = true;
  public pageSizes = [200,200,300,400, 500,600,700,800,900,1000, 50000];

  //public allData:any;

  constructor(private agentService:AgentService, private fb:FormBuilder, private ledgerService:LedgerService) { }

  ngOnInit() {
    this.getAllAgents();
    this.filterForm = this.fb.group({
      agent: [''],
      from_date: [],
      to_date: []
    });
  }

  getAllAgents(){
    this.agentService.getAgents().then((response)=>{
     
      if (response.code == 200) {
        this.agents =  response.data;
      }

      this.agents.push({"name":"All"})
    });
  }

  onFilterSubmit(){
    this.showLoader = true;
    this.detailsArray = [];
    this.gridData = process(this.detailsArray, this.state);
    let agent = this.filterForm.value.agent.name;
    let fromDate = this.filterForm.value.from_date;
    let toDate = this.filterForm.value.to_date;

    var fromDateTime = fromDate + ' ' + moment('2021-06-05 00:00:00').format('HH:mm:ss');
    var toDateTime = toDate + ' ' + moment('2021-06-05 23:59:59').format('HH:mm:ss');

    console.log("Form Value", agent, fromDate, toDate);
    var collection;

    this.xlsxName= agent != undefined && fromDate != undefined && toDate!= undefined ? agent+'-'+fromDate+'-to-'+toDate:+fromDate+'-to-'+toDate
   

    if(agent != undefined && fromDate != undefined && toDate != undefined){
 
      var data = {
        'type':'All',
        'value': agent,
        'fromDate' : fromDate,
        'toDate' : toDate
      }

      console.log("data", data);
      
      this.ledgerService.getDailyBillingOld(data).then(response=>{
        console.log('response data');
        if (response.code == 200) {
          console.log("response.data", response.data[0]["name"]);
          

          this.detailsArray = response.data;
          this.showLoader = false;
          this.gridData = process(this.detailsArray, this.state);
          //this.allData = process(this.detailsArray, this.state);
        }
      });
    }
    else if(agent != ''){
     
      var data1 = {
        'type':'agent',
        'value': agent,
      }

      console.log("this", data1);
      this.ledgerService.getDailyBillingOld(data1).then(async response=>{
        if (response.code == 200) {
        
          
           
             this.detailsArray = await response.data;
            this.gridData = await process(this.detailsArray, this.state);
        
          
        }
      });
    }
  }

  refresh(){
    location.reload();
  }

  public dataStateChange(state: DataStateChangeEvent): void {
    this.state = state;
    this.gridData = process( this.detailsArray, this.state);
  }
  public pageChange({ skip, take }: PageChangeEvent): void {
    console.log("skip",skip,take )
    this.state.skip = skip;
    this.state.take = take; 
    this.gridData = process( this.detailsArray, this.state);
  }

  public allData = () => {
    return process(this.detailsArray, {
      filter: this.state.filter
    });
  }

}
