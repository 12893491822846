import { Routes, RouterModule} from '@angular/router';
import { Error404Component } from 'src/app/layouts/error/error404/error404.component';

export const error: Routes = [
    {
        path : '',
        redirectTo : '404',
        pathMatch : "full"
    },
    {
        path : '404',
        component : Error404Component
    }
];

