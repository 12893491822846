import { Component, OnInit } from '@angular/core';
import { AngularFirestore } from '@angular/fire/firestore';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { CustomValidators } from 'ng2-validation';
import { ToastrService } from 'ngx-toastr';
import { BankStaffService } from 'src/app/shared/bank-staff/bank-staff.service';
import { BankStaff } from 'src/app/shared/bank-staff/bankStaff.model';
import { DataStateChangeEvent, GridDataResult } from '@progress/kendo-angular-grid';
import { process, State } from '@progress/kendo-data-query';
import { AuthService } from 'src/app/shared/auth/auth.service';
import Swal from 'sweetalert2';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { AngularFireAuth } from '@angular/fire/auth';

@Component({
  selector: 'app-bank-staffs',
  templateUrl: './bank-staffs.component.html',
  styleUrls: ['./bank-staffs.component.scss']
})
export class BankStaffsComponent implements OnInit {
  bankStaffs: BankStaff[];
  data:any;
  show = 'list'

  public gridData: GridDataResult;
  public state: State = {
    filter: {
      logic: 'and',
      filters: []
    }
  };

  constructor(public bankStaffService:BankStaffService, private toastrService:ToastrService, private router:Router, private authService:AuthService, private modalService:NgbModal, private fireAuth: AngularFireAuth) { 
    
  }

  ngOnInit() {
    this.getAllUsers();
  }

  getAllUsers(){
    this.authService.getAllUsers('bankstaff').then((response)=>{
      if(response.code == 200){
        this.bankStaffs = response.data;
        this.gridData =  process( this.bankStaffs, this.state);
      }
    })
  }

  view(data, content){
    //this.router.navigate(['/view-bank-staff'], { queryParams : {key: bankStaff.id} });
    this.data = data;
    this.modalService.open(content, {centered:true, size:'lg'})
  }

  delete(data){
    //this.bankStaffService.deleteBankStaff(data.id);
    Swal({
      title: 'Are you sure?',
      text: "You won't be able to revert this!",
      type: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: 'Yes, delete it!'
    }).then((result) => {
      if (result.value) {
        this.authService.deleteUser(data).then(response => {
          if(response.code == 200){
            this.getAllUsers();
            Swal(
              'Deleted!',
              'User has been deleted.',
              'success'
            )
          }
        })
       
      }
    });
  }

  public dataStateChange(state: DataStateChangeEvent): void {
    this.state = state;
    this.gridData = process( this.bankStaffs, this.state);
  }

  edit(data)
  {
    this.data = data;
    this.show = 'edit'
  }


  close()
  {
    this.show = 'list'
    this.getAllUsers();
  }

  onChangeTab(){
    this.getAllUsers();
  }

  onCloseModal(){
    this.modalService.dismissAll();
  }

  resetPassword(){
    console.log("reset Password", localStorage.getItem("LoggedInUserEmail"));
    var email = localStorage.getItem("LoggedInUserEmail");
    this.fireAuth.auth.sendPasswordResetEmail(email).then(()=>{
      console.log("email sent");
      this.toastrService.success("Please check your email", 'Success!') 
    }).catch((error) =>{
      console.log("error while reseting password", error);
    })
  }

}
