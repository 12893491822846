import { Component, Input, OnInit, Output, EventEmitter } from "@angular/core";
import { AngularFirestore } from "@angular/fire/firestore";
import { AngularFireStorage } from "@angular/fire/storage";
import { FormBuilder, FormGroup, Validators } from "@angular/forms";
import { ToastrService } from "ngx-toastr";
import { Observable } from "rxjs";
import { finalize } from "rxjs/operators";
import { ChangeDetailsService } from "src/app/shared/service/change-details.service";

@Component({
  selector: "app-address-change",
  templateUrl: "./address-change.component.html",
  styleUrls: ["./address-change.component.scss"],
})
export class AddressChangeComponent implements OnInit {
  @Input("addressChangeData") addressChangeData;
  @Output("onCloseModal") onCloseModal = new EventEmitter();

  validationForm: FormGroup;
  dateTime;
  hasFile: boolean;
  selectedFiles: FileList;
  userId;
  userName;
  downloadURL: Observable<String>;
  imgPath;

  constructor(
    private fb: FormBuilder,
    private firestore: AngularFirestore,
    private toastrService: ToastrService,
    private storage: AngularFireStorage,
    private changeService: ChangeDetailsService
  ) {
    var today = new Date();
    this.dateTime =
      today.getFullYear() +
      "-" +
      ("0" + (today.getMonth() + 1)).slice(-2) +
      "-" +
      ("0" + today.getDate()).slice(-2) +
      " " +
      ("0" + today.getHours()).slice(-2) +
      ":" +
      ("0" + today.getMinutes()).slice(-2) +
      ":" +
      ("0" + today.getSeconds()).slice(-2);
    this.userId = localStorage.getItem("user_id");
    this.userName = localStorage.getItem("user_name");
  }

  ngOnInit() {
    this.validationForm = this.fb.group({
      rrno: [this.addressChangeData.rrno],
      consumerName: [this.addressChangeData.name],
      oldStreetName: [this.addressChangeData.streetName],
      newStreetName: ["", Validators.required],
      oldDoorNo: [this.addressChangeData.doorNo],
      newDoorNo: [""],
      userId: [this.userName],
      enteredDate: [this.dateTime],
      oldOrderNo: ["", this.addressChangeData.orderNo],
      newOrderNo: [""],
      remarks: ["", Validators.required],
      phoneNumber: [this.addressChangeData.phoneNumber],
      file: [""],
    });
  }

  detectFiles(event) {
    this.hasFile = true;
    this.selectedFiles = event.target.files;
    //console.log("event.target.files", event.target.files[0].name);

    this.validationForm.controls.file.setValue(event.target.files[0].name);
  }

  onSubmit() {
    console.log("id", this.addressChangeData.id);
    //this.validationForm.controls.userId.setValue(this.userId);

    if (this.validationForm.valid) {
      /* if(this.hasFile){
        var file = this.selectedFiles.item(0);    
       // this.uploadFile(file);
      }
      else{ */
      this.store();
      // }
    }
  }

  /*  uploadFile(imageUrl){
    let uniqkey = 'pic' + Math.floor(Math.random() * 1000000);
    const uploadTask = this.storage.upload('/address-change/' + uniqkey, imageUrl);
    const ref = this.storage.ref('/address-change/' + uniqkey);
    //console.log("uploadTask", uploadTask);
    uploadTask.snapshotChanges().pipe(
      finalize(() => {
        this.downloadURL = ref.getDownloadURL();
        console.log(this.downloadURL);
        this.downloadURL.subscribe(url => {
          this.imgPath = url;
          //console.log("url",this.imgPath)
          this.toastrService.success("Image uploaded succcessfully!", "Success!", {
            timeOut:3000
          });
          this.store();
        });
      })
    ).subscribe();
  } */

  store() {
    var formValue = this.validationForm.value;
    delete formValue.userId;
    formValue.userId = this.userId;

    if (this.hasFile) {
      // formValue.file = this.imgPath;
      // formValue.photo, this.selectedFiles.item(0);
    }

    /*  this.firestore.collection('address-change').add(formValue).then(response=>{
      this.firestore.collection('connections').doc(this.addressChangeData.id).update({streetName:this.validationForm.value.newStreetName, doorNo:this.validationForm.value.newDoorNo}).then(response =>{
        this.toastrService.success("Address Changed Successfully", "Success!", {
          timeOut: 8000
        });
        this.onCloseModal.emit('close');
      })
    }).catch(error=>{
      this.toastrService.error(error, "Error!", {
        timeOut: 8000
      });
    }); */

    this.changeService
      .addressChange(formValue, this.hasFile, this.selectedFiles)
      .then(async (response) => {
        console.log("address_change_response", response.code);
        if (response.code == 200) {
          /* this.addressChangeData.streetName = formValue.newStreetName;
        this.addressChangeData.doorNo = formValue.newDoorNo;
      
        this.changeService.updateConnection(this.addressChangeData).then(async response=>{
          this.toastrService.success("Address Changed Successfully", "Success!", {
            timeOut: 8000
          });
          this.onCloseModal.emit('close');
        }) */
          this.toastrService.success(
            "Address Changed Successfully",
            "Success!",
            {
              timeOut: 8000,
            }
          );
          this.onCloseModal.emit("close");
        } else {
          this.toastrService.error(response.message, "Error!", {
            timeOut: 8000,
          });
        }
      });
  }

  onClose(close) {
    this.onCloseModal.emit("close");
  }
}
