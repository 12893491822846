import { Component, Input, OnInit, EventEmitter, Output } from '@angular/core';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-agent-details',
  templateUrl: './agent-details.component.html',
  styleUrls: ['./agent-details.component.scss']
})
export class AgentDetailsComponent implements OnInit {
  @Input('data') data;
  @Output("onCloseModal") onCloseModal = new EventEmitter<string>();
  apiUrl = `${environment.apiUrl}`

  imgsrc
  constructor() { }

  ngOnInit() {
    this.imgsrc = this.data.imagePath?this.data.imagePath:"assets/images/profile.png";
    this.imgsrc = this.data.image == '' ? "assets/images/profile.png" : this.apiUrl + '/images/agents/' + this.data.image;
  }

  onClose(close)
  {
    this.onCloseModal.emit('close');
  }

}
