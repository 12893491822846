import { Component, Input, OnInit, Output, EventEmitter } from '@angular/core';
import { Supervisor } from 'src/app/shared/supervisor/supervisor.model';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { Observable } from 'rxjs';
import { SupervisorService } from 'src/app/shared/supervisor/supervisor.service';
import { AngularFireStorage } from '@angular/fire/storage';
import { finalize } from 'rxjs/operators';
import { AngularFirestore } from '@angular/fire/firestore';
import { Route, ActivatedRoute } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { CustomValidators } from 'ng2-validation';
import { AuthService } from 'src/app/shared/auth/auth.service';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-view-supervisor',
  templateUrl: './view-supervisor.component.html',
  styleUrls: ['./view-supervisor.component.scss']
})
export class ViewSupervisorComponent implements OnInit {
  @Input('data') data;
  @Output('onCloseModal') onCloseModal = new EventEmitter<string>();

  supervisorId;
  submitted: boolean;
  supervisorForm: FormGroup;
  hasFile:boolean;
  selectedFiles: FileList;
  downloadURL:Observable<string>;
  imgsrc;
  supervisor;

  apiUrl = `${environment.apiUrl}`;

  constructor(private supervisorService:SupervisorService,  private route:ActivatedRoute, private toastrService:ToastrService, private authService:AuthService) { 
   
  }

  ngOnInit() {
    /* this.supervisorId= this.route.snapshot.queryParamMap.get('key');
    
    this.getUserById(); */
    this.imgsrc = this.data['image'] ? this.apiUrl + 'images/supervisors/'+this.data['image'] : "assets/images/profile.png";
  }

  getUserById(){
    this.authService.getUserById(this.supervisorId).then((response)=>{
      if(response.code == 200){
        this.supervisor = response.data[0];
        this.imgsrc = this.supervisor['image'] ?  this.apiUrl + 'images/supervisors/'+this.supervisor['image'] : "assets/images/profile.png";
      }
    })
  }

  onClose(){
    this.onCloseModal.emit('close')
  }

}
