import { Component, Input, OnInit, Output, EventEmitter } from "@angular/core";
import { AngularFirestore } from "@angular/fire/firestore";
import { AngularFireStorage } from "@angular/fire/storage";
import { FormBuilder, FormGroup, Validators } from "@angular/forms";
import { ToastrService } from "ngx-toastr";
import { Observable } from "rxjs";
import { finalize } from "rxjs/operators";
import { BillingService } from "src/app/shared/service/billing.service";
import { ChangeDetailsService } from "src/app/shared/service/change-details.service";
import { ConnectionService } from "src/app/shared/service/connection.service";

@Component({
  selector: "app-meter-disconnection",
  templateUrl: "./meter-disconnection.component.html",
  styleUrls: ["./meter-disconnection.component.scss"],
})
export class MeterDisconnectionComponent implements OnInit {
  @Input("meterDisconnectionData") meterDisconnectionData;
  @Output("onCloseModal") onCloseModal = new EventEmitter<String>();

  validationForm: FormGroup;
  dateTime;
  maxDate;
  minDate;
  hasFile: boolean;
  selectedFiles: FileList;
  userId;
  userName;
  downloadURL: Observable<String>;
  imgPath;
  billDetails;
  billDetailsArray: any = [];
  lastBillDate;

  constructor(
    private fb: FormBuilder,
    private firestore: AngularFirestore,
    private toastrService: ToastrService,
    private storage: AngularFireStorage,
    private changeService: ChangeDetailsService,
    private connectionService: ConnectionService,
  ) {
    var today = new Date();
    this.dateTime =
      today.getFullYear() +
      "-" +
      ("0" + (today.getMonth() + 1)).slice(-2) +
      "-" +
      ("0" + today.getDate()).slice(-2) +
      " " +
      ("0" + today.getHours()).slice(-2) +
      ":" +
      ("0" + today.getMinutes()).slice(-2) +
      ":" +
      ("0" + today.getSeconds()).slice(-2);
    this.userId = localStorage.getItem("user_id");
    this.userName = localStorage.getItem("user_name");
  }

  ngOnInit() {
    this.lastBillData();
    this.getMaxDate();
    this.validationForm = this.fb.group({
      rrno: [this.meterDisconnectionData.rrno],
      consumerName: [this.meterDisconnectionData.name],
      userId: [this.userName],
      enteredDate: [this.dateTime],
      meterSerialNumber: [this.meterDisconnectionData.meterSerialNumber],
      meterStatus: [this.meterDisconnectionData.previousStatus],
      disconnectionDate: ["", Validators.required],
      orderNo: ["", Validators.required],
      remarks: ["", Validators.required],
      finalReading: [this.meterDisconnectionData.previousReading],
      connection_status: ["", Validators.required],
      phoneNumber: [this.meterDisconnectionData.phoneNumber],
      file: [""],
    });
  }

  onClose(close) {
    this.onCloseModal.emit("close");
  }

  onSubmit() {
    console.log("id", this.meterDisconnectionData);
    //this.validationForm.controls.userId.setValue(this.userId);

    if (this.validationForm.valid) {
      this.store();

      /* if(this.hasFile){
        var file = this.selectedFiles.item(0);
        this.uploadFile(file);
      }
      else{
        this.store();
      } */
    }
  }

  uploadFile(imageUrl) {
    let uniqkey = "pic" + Math.floor(Math.random() * 1000000);
    const uploadTask = this.storage.upload(
      "/meter-disconnect/" + uniqkey,
      imageUrl
    );
    const ref = this.storage.ref("/meter-disconnect/" + uniqkey);
    //console.log("uploadTask", uploadTask);
    uploadTask
      .snapshotChanges()
      .pipe(
        finalize(() => {
          this.downloadURL = ref.getDownloadURL();
          console.log(this.downloadURL);
          this.downloadURL.subscribe((url) => {
            this.imgPath = url;
            //console.log("url",this.imgPath)
            this.toastrService.success(
              "Image uploaded succcessfully!",
              "Success!",
              {
                timeOut: 3000,
              }
            );
            this.store();
          });
        })
      )
      .subscribe();
  }

  store() {
    var formValue = this.validationForm.value;
    delete formValue.userId;
    formValue.userId = this.userId;

    /*  if(this.hasFile){
      formValue.file = this.imgPath;
    } */

    /* this.firestore.collection('meter-disconnect').add(formValue).then(response=>{
      this.firestore.collection('connections').doc(this.meterDisconnectionData.id).update({consumerConnectionStatusType:this.validationForm.value.connection_status}).then(response =>{
        this.toastrService.success("Meter Disconnected Successfully", "Success!", {
          timeOut: 8000
        });
        this.onCloseModal.emit('close');
      })
      
    }).catch(error=>{
      this.toastrService.error(error, "Error!", {
        timeOut: 8000
      });
    }); */

    this.changeService
      .meterDisconnection(formValue, this.hasFile, this.selectedFiles)
      .then(async (response) => {
        console.log("meterdisconnection_response", response.code);
        if (response.code == 200) {
          /* this.meterDisconnectionData.connectionStatus = formValue.connection_status;
        this.changeService.updateConnection(this.meterDisconnectionData).then(async response=>{
          this.toastrService.success("Meter Disconnected Successfully", "Success!", {
            timeOut: 8000
          });
          this.onCloseModal.emit('close');
        }) */
          this.toastrService.success(
            "Meter Disconnected Successfully",
            "Success!",
            {
              timeOut: 8000,
            }
          );
          this.onCloseModal.emit("close");
        } else {
          this.toastrService.error(response.message, "Error!", {
            timeOut: 8000,
          });
        }
      });
  }

  detectFiles(event) {
    this.hasFile = true;
    this.selectedFiles = event.target.files;
    //console.log("event.target.files", event.target.files[0].name);

    this.validationForm.controls.file.setValue(event.target.files[0].name);
  }

  lastBillData() {
    // this.billDetails = this.firestore.collection("/ledger", (ref) =>
    //   ref
    //     .where("connectionId", "==", this.meterDisconnectionData.RRNO)
    //     .orderBy("billDateTime", "desc")
    // );
    // this.billDetails.valueChanges().subscribe((data) => {
    //   data.forEach((element) => {
    //     console.log("element reconnection", element);
    //     this.billDetailsArray.push(element);
    //   });
    //   if (this.billDetailsArray.length > 0) {
    //     this.lastBillDate = this.billDetailsArray[0].billDateTime;
    //   }
    // });

    console.log('this.meterDisconnectionData.rrno',this.meterDisconnectionData.rrno)
    this.connectionService.getLatestLedgerById(this.meterDisconnectionData.rrno).then((response) => {
      if (response.code == 200) {
        
        this.billDetailsArray = response.data;
        console.log('ddddopopopopopop',response.data)
        this.minDate = this.billDetailsArray[0].billDateTime;
        if(this.minDate.length > 0){
          this.minDate = this.minDate.substring(0, 10);
        }
      }
    });

    
  }
  getMaxDate(){
    var today = new Date();
    this.maxDate =today.getFullYear() +
      "-" +
      ("0" + (today.getMonth() + 1)).slice(-2) +
      "-" +
      ("0" + today.getDate()).slice(-2)

    console.log('max date is',this.maxDate);
  }
}
