import { Component, Input, OnInit, Output, EventEmitter } from "@angular/core";
import { AngularFirestore } from "@angular/fire/firestore";
import { FormControl, FormGroup, Validators } from "@angular/forms";
import { ToastrService } from "ngx-toastr";
import { flatMap } from "rxjs/operators";
import { BillingService } from "src/app/shared/service/billing.service";
@Component({
  selector: 'app-agm-maps',
  templateUrl: './agm-maps.component.html',
  styleUrls: ['./agm-maps.component.scss']
})


export class AgmMapsComponent implements OnInit {
  @Input("mapData") mapData;
  @Output("onCloseModal") onCloseModal = new EventEmitter();
  public lat: any;
  public longitude:any;
  constructor() { }

  ngOnInit() {
    this.lat = parseFloat(this.mapData.latitude)
    this.longitude = parseFloat(this.mapData.longitude)
  }

  viewLocation() {
      console.log('inside ')
      this.lat = parseFloat(this.mapData.latitude)
      this.longitude = parseFloat(this.mapData.longitude)

      var url = `https://maps.google.com/maps?q=${this.lat},${this.longitude}&z=21&ie=UTF8`; 
      window.open(url, "_blank")
  }

  onClose(close) {
    this.onCloseModal.emit("close");
  }

}
