import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { AngularFireAuth } from '@angular/fire/auth';
import { AngularFirestore } from '@angular/fire/firestore';
import { AngularFireStorage } from '@angular/fire/storage';
import { ToastrService } from 'ngx-toastr';
import { Observable } from 'rxjs';
import { finalize, timeout } from 'rxjs/operators';
import { environment } from 'src/environments/environment';
import Swal from 'sweetalert2';
import { ResponseData } from '../interfaces/response-data';

interface Data  {
  code: number,
  message: string,
  data: any
}

@Injectable({
  providedIn: 'root'
})
export class BankStaffService {
  apiUrl = `${environment.apiUrl + 'api/'}`
  public showErrorMsg: Boolean;
  public printErrorMsg:string;
  downloadURL: Observable<string>;
  imgPath;

  constructor(public firestore:AngularFirestore, public fireAuth:AngularFireAuth, private storage:AngularFireStorage, public toastrService: ToastrService, private http:HttpClient) { }

  async signup(bank_staff_details, imageUrl, validationForm, hasFile, selectedFiles){
    var result = await this.fireAuth.auth.createUserWithEmailAndPassword(bank_staff_details.email, bank_staff_details.password).then((success) => {
      var bankStaffId = this.fireAuth.auth.currentUser.uid;
      if(hasFile && selectedFiles.length != 0){
        this.uploadpic(bankStaffId, imageUrl, bank_staff_details);
        this.reset(validationForm);
      }
      else{
        this.storeBankStaff(null,bankStaffId,bank_staff_details);
        this.reset(validationForm);
      }
      return "200";
    }).catch((error => {
      this.showErrorMsg = true;
      setTimeout(() => this.showErrorMsg = false, 3000);
      //this.printErrorMsg = error;
      this.toastrService.error(error, "Error!", {timeOut:8000});
      return "204";
    }));
    return result;
  }

  uploadpic(bankStaffId, imageUrl, bank_staff_details){
    let uniqkey = 'pic' + Math.floor(Math.random() * 1000000);
    const uploadTask = this.storage.upload('/supervisor/' + uniqkey, imageUrl);
    const ref = this.storage.ref('/supervisor/' + uniqkey);
    console.log("uploadTask", uploadTask);
    //this.imgsrc = uploadTask.downloadURL()
    uploadTask.snapshotChanges().pipe(
      finalize(() => {
        this.downloadURL = ref.getDownloadURL();
        console.log(this.downloadURL);
        this.downloadURL.subscribe(url => {
          this.imgPath= url;
          console.log("url",this.imgPath)
          this.toastrService.success("Profile image uploaded succcessfully!", "Success!", {
            timeOut:3000
          });
          this.storeBankStaff(this.imgPath, bankStaffId,bank_staff_details);
        });
      })
    ).subscribe();
  }

  storeBankStaff(imageUrl,bankStaffId,bank_staff_details)
  {
    this.firestore.collection('users').doc(bankStaffId).set({
      name: bank_staff_details.name,
      phoneNumber: bank_staff_details.phoneNumber,
      email: bank_staff_details.email,
      password: bank_staff_details.password,
      role: "bankStaff",
      imagePath:imageUrl
    }).then((success) => {
      this.toastrService.success('Bank Staff Added Successfully!', 'Success!', {
        timeOut: 8000
      });
    });
  }

  reset(validationForm){
    validationForm.setValue({
      name: "",
      email: "",
      phoneNumber: "",
      password: "",
      confirmPassword: ""
    });
  }

  getBankStaff(){
    return this.firestore.collection('/users', ref => ref.where('role', '==', 'bankStaff')).snapshotChanges();
  }

  updateBankStaff(bankStaff, id){
    this.firestore.doc('users/'+id).update(bankStaff);
    location.reload();
    //this.router.navigate(['/admins']);
  }

  deleteBankStaff(id){
    Swal({
      title: 'Are you sure?',
      text: "You won't be able to revert this!",
      type: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: 'Yes, delete it!'
    }).then((result) => {
      if (result.value) {
        this.firestore.doc('users/'+id).delete();
        this.firestore.collection('deleteUser').doc(id).set({delete: "yes"});
        Swal(
          'Deleted!',
          'BankStaff has been deleted.',
          'success'
        )
      }
    });
  }

  checkRrnoExist(rrno){
    return new Promise<ResponseData>((resolve)=>{
      this.http.get<ResponseData>(this.apiUrl + 'check-rrno-exist-bank'+`/${rrno}`).pipe(timeout(1000000))
      .subscribe(
          data => {
            console.log("data",data);            
            resolve(data);
          },
          error => {
            console.log("Error", error);
        });
    })
  }

  createCashCollection(data){
    return new Promise<Data>((resolve)=>{
      this.http.post<Data>(this.apiUrl + 'cash-collection', data)
      .subscribe(
          data => {
            console.log("Success", data);
            resolve(data);
          },
          error => {
        });
    })
  }

  getLastId(){
    return new Promise<ResponseData>((resolve)=>{
      this.http.get<ResponseData>(this.apiUrl + 'cash-collection-last-receiptNo').pipe(timeout(1000000))
      .subscribe(
          data => {
            console.log("data",data);            
            resolve(data);
          },
          error => {
            console.log("Error", error);
        });
    })
  }

  getCashCollection(data){
    var filter = JSON.stringify(data);
  
    return new Promise<ResponseData>((resolve)=>{
      this.http.get<ResponseData>(this.apiUrl + 'cash-collection'+`/${filter}`).pipe(timeout(1000000))
      .subscribe(
          data => {
            console.log("data",data);            
            resolve(data);
          },
          error => {
            console.log("Error", error);
        });
    })
  }

  updateCashCollection(cashCollection){
    return new Promise<Data>((resolve)=>{
      this.http.post<Data>(this.apiUrl + 'update-cash-collection', cashCollection)
      .subscribe(
          data => {
            console.log("Success", data);
            resolve(data);
          },
          error => {
        });
    })
  }

  deleteCashCollection(data){
    return new Promise<ResponseData>((resolve)=>{
      this.http.post<ResponseData>(this.apiUrl + 'delete-cash-collection', data).pipe(timeout(1000000))
      .subscribe(
          data => {
            console.log("data",data);            
            resolve(data);
          },
          error => {
            console.log("Error", error);
        });
    })
  }

  getAbstractCashCollection(data){
    var filter = JSON.stringify(data);
  
    return new Promise<ResponseData>((resolve)=>{
      this.http.get<ResponseData>(this.apiUrl + 'abstract-cash-collection'+`/${filter}`).pipe(timeout(1000000))
      .subscribe(
          data => {
            console.log("data",data);            
            resolve(data);
          },
          error => {
            console.log("Error", error);
        });
    })
  }

}
