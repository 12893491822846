import { Component, Input, OnInit } from '@angular/core';
import { AngularFirestore } from '@angular/fire/firestore';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { ToastrService } from 'ngx-toastr';
import { Agent } from 'src/app/shared/agent/agent.model';
import { AgentService } from 'src/app/shared/agent/agent.service';
import { AuthService } from 'src/app/shared/auth/auth.service';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-agent-form',
  templateUrl: './agent-form.component.html',
  styleUrls: ['./agent-form.component.scss']
})
export class AgentFormComponent implements OnInit {
  @Input('from') from = 'add';
  @Input('editableData') editableData;

  imgsrc = "assets/images/profile.png";
  submitted:boolean;
  hasFile:Boolean;
  selectedFiles:FileList;
  file;
  areas:any = [];
  selected:any = [];
  zones:any = [];

  apiUrl = `${environment.apiUrl}`

  validationForm =  new FormGroup({
    name: new FormControl('', Validators.required),
    phoneNumber: new FormControl('', [Validators.required, Validators.minLength(10)]),
    email: new FormControl('', [Validators.required,Validators.email]),
    addressLine1: new FormControl('', Validators.required),
    addressLine2: new FormControl('', Validators.required),
    zone:new FormControl('', Validators.required),
    city: new FormControl('', Validators.required),
    state: new FormControl('', Validators.required),
    pincode: new FormControl('', [Validators.required, Validators.minLength(6)])
  });
  
  //formControls = this.validationForm.controls;

  constructor(private firestore:AngularFirestore, private toastrService:ToastrService, private agentService:AgentService, private authService:AuthService) {
   
   
   }

   ngOnInit() {
    
    this.agentService.getAreas().then(async (response) =>{
      
      this.areas =await  response.data;
      console.log("areas sdsfs",  await this.areas);
    
      
    })

    this.agentService.getZones().then(async(response)=>{
      
      this.zones = await response.data;
      console.log("areas sdsfs",  await this.zones);
    })
   
    if(this.editableData)
      this.fillable()
      
  }

  async fillable(){
   
    console.log("this.edita", await  this.selected);
    
    this.imgsrc = await this.editableData.image == '' ? "assets/images/profile.png" : this.apiUrl + '/images/agents/' + this.editableData.image;
    await this.validationForm.setValue({
      name: this.editableData.name,
      phoneNumber: this.editableData.phoneNumber,
      email: this.editableData.email,
      addressLine1: this.editableData.addressLine1,
      addressLine2: this.editableData.addressLine2,
      zone: '',
      city: this.editableData.city,
      state: this.editableData.state,
      pincode: this.editableData.pincode
    })
    /* await this.editableData.area.forEach(element => {
      this.selected.push(element.code)
    }); */

    await this.agentService.getZoneByUserId(this.editableData.id).then((response)=>{
      if(response.code == 200){
        console.log("code", response.data);
       this.selected = response.data;
      }
    })
  }

  onSubmit(){
    console.log("this.from", this.validationForm.value);
    
    this.from === 'add'?this.addAgent():this.editAgent();
  }
  
  addAgent(){
    console.log("form value", this.validationForm.value);

    var formValue = this.validationForm.value;
    formValue.role = 'agent';
      this.authService.createUser(formValue,this.selectedFiles, this.hasFile).then((response)=>{
      
        response.code === 200 ? this.toastrService.success(response.message, 'Success!') : this.toastrService.error(response.message, 'Error!'); 
        if(response.code === 200){
          this.validationForm.reset();
          this.imgsrc = "assets/images/profile.png";
        }
      }).catch((err)=>{console.log("error",err);
    })
   
  }

  editAgent(){
    var user = this.validationForm.value;
    user.id = this.editableData.id;
    user.selectedFiles = this.selectedFiles;
    user.hasFile = this.hasFile;
    user.role = 'agent';
    this.authService.updateUser(user).then((response)=>{
      response.code === 200 ? this.toastrService.success(response.message, 'Success!') : this.toastrService.error(response.message, 'Error!'); 
    })
    .catch((err)=>{console.log("Error", err);
    })
   
  }

  public chooseFile(event) {
    this.hasFile = true;
    this.selectedFiles = event.target.files;
    //this.uploadpic()
    this.readUrl(event);
  }

  public readUrl(event:any) {
    if (event.target.files && event.target.files[0]) {
      var reader = new FileReader();
      reader.onload = (event:any) => {
        this.imgsrc = event.target.result;
      } 
      reader.readAsDataURL(event.target.files[0]);
    }
  }

  public getAllArea(){
    
  }

  public getWardsById(){
    this.agentService.getWardsByAgentId(this.editableData.phoneNumber).subscribe((data)=>{
    
    })
  }

}
