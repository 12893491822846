import { Component, OnInit } from '@angular/core';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { AuthService } from '../../../shared/auth/auth.service';

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss']
})
export class LoginComponent implements OnInit {

  submitted:boolean;

  loginForm = new FormGroup({
    name : new FormControl('', [Validators.required]),
    password: new FormControl('', Validators.required)
  });

  formControls = this.loginForm.controls;

  showErrorMsg:boolean = false

  constructor(private authService:AuthService, private toastrService:ToastrService, private router:Router) { }

  ngOnInit() {
  }

  

  onSubmit(){
    this.submitted = true;
    if(this.loginForm.valid)
    {
      var name = this.loginForm.get('name').value;
      var password = this.loginForm.get('password').value;
      this.authService.login(name, password).then((response)=>{
        response.code === 200 ? this.toastrService.success(response.message, 'Success!') : this.toastrService.error(response.message, 'Error!'); 
        if(response.code == 200){
          console.log(response.data[0])
          localStorage.setItem('user_id',response.data[0].id);
          localStorage.setItem('user_name', response.data[0].name)
          localStorage.setItem('loggedInUserRole', response.data[0].role); 
          localStorage.setItem('userImage',response.data[0].image)
          localStorage.setItem('LoggedInUserEmail',response.data[0].email)
          if(response.data[0].role == 'superadmin' || response.data[0].role == 'admin' || response.data[0].role == 'staff')
          {
            this.router.navigate(['/dashboard']);
          }
          else if(response.data[0].role == 'bankstaff'){
            this.router.navigate(['/cash-collection-report']);
          }
          else
          {
            this.showErrorMsg = true;
          }
        }
      })
      this.submitted = false;
    }
  }

}
