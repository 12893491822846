import { Component, Input, OnInit, Output, EventEmitter } from "@angular/core";
import { AngularFirestore } from "@angular/fire/firestore";
import { AngularFireStorage } from "@angular/fire/storage";
import { FormBuilder, FormGroup, Validators } from "@angular/forms";
import { ToastrService } from "ngx-toastr";
import { Observable } from "rxjs";
import { finalize } from "rxjs/operators";
import { ChangeDetailsService } from "src/app/shared/service/change-details.service";

@Component({
  selector: "app-name-change",
  templateUrl: "./name-change.component.html",
  styleUrls: ["./name-change.component.scss"],
})
export class NameChangeComponent implements OnInit {
  @Input("nameChangeData") nameChangeData;
  @Output("onCloseModal") onCloseModal = new EventEmitter<string>();

  validationForm: FormGroup;
  dateTime;
  hasFile: boolean;
  selectedFiles: FileList;
  user_id;
  user_name;
  downloadURL: Observable<String>;
  imgPath;

  constructor(
    private fb: FormBuilder,
    private firestore: AngularFirestore,
    private toastrService: ToastrService,
    private storage: AngularFireStorage,
    private changeService: ChangeDetailsService
  ) {
    var today = new Date();
    this.dateTime =
      today.getFullYear() +
      "-" +
      ("0" + (today.getMonth() + 1)).slice(-2) +
      "-" +
      ("0" + today.getDate()).slice(-2) +
      " " +
      ("0" + today.getHours()).slice(-2) +
      ":" +
      ("0" + today.getMinutes()).slice(-2) +
      ":" +
      ("0" + today.getSeconds()).slice(-2);
    this.user_id = localStorage.getItem("user_id");
    this.user_name = localStorage.getItem("user_name");
    // console.log("date, user_id, name", this.dateTime, this.user_id, this.user_name );
  }

  ngOnInit() {
    this.validationForm = this.fb.group({
      rrno: [this.nameChangeData.rrno],
      oldName: [this.nameChangeData.name],
      newName: ["", Validators.required],
      userId: [this.user_name],
      enteredDate: [this.dateTime],
      orderNo: [this.nameChangeData.orderNo],
      remarks: ["", Validators.required],
      phoneNumber: [this.nameChangeData.phoneNumber],
      file: [""],
    });
  }

  detectFiles(event) {
    this.hasFile = true;
    this.selectedFiles = event.target.files;
    this.validationForm.controls.file.setValue(event.target.files[0].name);
  }

  onSubmit() {
    console.log("id", this.nameChangeData.id);
    //this.validationForm.controls.userId.setValue(this.user_id);

    if (this.validationForm.valid) {
      this.store();
    }
  }

  uploadFile(imageUrl) {
    let uniqkey = "pic" + Math.floor(Math.random() * 1000000);
    const uploadTask = this.storage.upload("/name-change/" + uniqkey, imageUrl);
    const ref = this.storage.ref("/name-change/" + uniqkey);
    //console.log("uploadTask", uploadTask);
    uploadTask
      .snapshotChanges()
      .pipe(
        finalize(() => {
          this.downloadURL = ref.getDownloadURL();
          console.log(this.downloadURL);
          this.downloadURL.subscribe((url) => {
            this.imgPath = url;
            //console.log("url",this.imgPath)
            this.toastrService.success(
              "Profile image uploaded succcessfully!",
              "Success!",
              {
                timeOut: 3000,
              }
            );
            this.store();
          });
        })
      )
      .subscribe();
  }

  store() {
    var formValue = this.validationForm.value;

    formValue.userId = this.user_id;

    /* if(this.hasFile){
      formValue.file = this.imgPath;
    }
   */
    /*  this.firestore.collection('name-change').add(formValue).then(response=>{
      this.firestore.collection('connections').doc(this.nameChangeData.id).update({name:this.validationForm.value.newConsumerName}).then(response =>{
        this.toastrService.success("Name Changed Successfully", "Success!", {
          timeOut: 8000
        });
        this.onCloseModal.emit('close');
      })
    }).catch(error=>{
      this.toastrService.error(error, "Error!", {
        timeOut: 8000
      });
    }); */

    this.changeService
      .namechanged(formValue, this.hasFile, this.selectedFiles)
      .then(async (response) => {
        console.log("namechange_response", response.code);
        if (response.code == 200) {
          /* this.nameChangeData.name = formValue.newName;
        this.changeService.updateConnection(this.nameChangeData).then(async response=>{
          this.toastrService.success("Name Changed Successfully", "Success!", {
            timeOut: 8000
          });
          this.onCloseModal.emit('close');
        }) */
          this.toastrService.success("Name Changed Successfully", "Success!", {
            timeOut: 8000,
          });
          this.onCloseModal.emit("close");
        } else {
        }
      });
  }

  onClose(close) {
    this.onCloseModal.emit("close");
  }
}
