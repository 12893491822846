import { NgModule } from '@angular/core';
import { RouterModule } from '@angular/router';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { SharedModule } from "./shared/shared.module";
import { AppRoutingModule } from './app-routing.module';
import { HttpClientModule } from '@angular/common/http'; 

import { AppComponent } from './app.component';
import { ContentComponent } from './layouts/content/content.component';
import * as $ from 'jquery';

import { DashboardComponent } from './layouts/dashboard/dashboard.component';
import { AuthenticationComponent } from './layouts/authentication/authentication.component';
import { LoginComponent } from './layouts/authentication/login/login.component';
import { FirstSectionComponent } from './layouts/dashboard/first-section/first-section.component';
import { Ng2GoogleChartsModule } from 'ng2-google-charts';
import { ChartsModule } from 'ng2-charts';
import { ChartistModule } from 'ng-chartist';
import { OwlModule } from 'ngx-owl-carousel';
import { CountUpModule } from 'countup.js-angular2';

import { AngularFireModule } from '@angular/fire';  
import { AngularFireAuthModule } from '@angular/fire/auth';
import { environment } from 'src/environments/environment';
import { AngularFirestoreModule } from '@angular/fire/firestore';
import { FindConnectionComponent } from './layouts/find-connection/find-connection.component';
import { FormsModule, ReactiveFormsModule } from '@angular/forms'
import { DataTablesModule } from 'angular-datatables';
import { NgxDatatableModule } from '@swimlane/ngx-datatable';
import { Ng2SmartTableModule } from 'ng2-smart-table';

import { AgmCoreModule } from '@agm/core';
import { FindConnectionOneComponent } from './layouts/connection/find-connection-one/find-connection-one.component';
import { AdminsComponent } from './layouts/users/admins/admins.component';
import { AdminService } from './shared/admin/admin.service';
import { ViewAdminComponent } from './layouts/users/admins/view-admin/view-admin.component';
import { AngularFireStorageModule, AngularFireStorage, AngularFireUploadTask } from '@angular/fire/storage';
import { SupervisorsComponent } from './layouts/users/supervisors/supervisors.component';
import { ViewSupervisorComponent } from './layouts/users/supervisors/view-supervisor/view-supervisor.component';
import { AuthGuard } from './core/auth.guard';
import { ToastrModule } from 'ngx-toastr';
import { CustomersComponent } from './layouts/users/customers/customers.component';

//import { FilterPipe} from './shared/Filter/filter.pipe';

import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { BillingLedgerComponent } from './layouts/connection/billing-ledger/billing-ledger.component';
import { ErrorComponent } from './layouts/error/error.component';
import { Error404Component } from './layouts/error/error404/error404.component';

import { NgPipesModule }    from 'ngx-pipes';
import { Ng2SearchPipeModule } from 'ng2-search-filter';
import {DataTableModule} from "angular2-datatable";
import { ExcelModule, GridModule, PDFModule } from '@progress/kendo-angular-grid';
import { PDFExportModule } from "@progress/kendo-angular-pdf-export";
import { CashCollectionComponent } from './layouts/bank/cash-collection/cash-collection.component';
import { BankStaffsComponent } from './layouts/users/bank-staffs/bank-staffs.component';
import { ViewBankStaffsComponent } from './layouts/users/bank-staffs/view-bank-staffs/view-bank-staffs.component';
import { CashCollectionReportComponent } from './layouts/bank/cash-collection-report/cash-collection-report.component';
import { AgentsComponent } from './layouts/users/agents/agents.component';
import { AgentFormComponent } from './layouts/users/agents/agent-form/agent-form.component';
import { AgentDetailsComponent } from './layouts/users/agents/agent-details/agent-details.component';
import { RxReactiveFormsModule } from '@rxweb/reactive-form-validators';
import { NameChangeComponent } from './layouts/connection/find-connection-one/name-change/name-change.component';
import { MeterChangeComponent } from './layouts/connection/find-connection-one/meter-change/meter-change.component';
import { MeterDisconnectionComponent } from './layouts/connection/find-connection-one/meter-disconnection/meter-disconnection.component';
import { TypeChangeComponent } from './layouts/connection/find-connection-one/type-change/type-change.component';
import { AddressChangeComponent } from './layouts/connection/find-connection-one/address-change/address-change.component';
import { ChangeDetailsComponent } from './layouts/reports/change-details/change-details.component';
import { MeterReconnectionComponent } from './layouts/connection/find-connection-one/meter-reconnection/meter-reconnection.component';
import { NoticeDetailsComponent } from './layouts/connection/find-connection-one/notice-details/notice-details.component';
import { EditConnectionComponent } from './layouts/connection/find-connection-one/edit-connection/edit-connection.component';
import { ViewImageComponent } from './layouts/connection/find-connection-one/view-image/view-image.component';
import { BillPdfComponent } from './layouts/connection/bill-pdf/bill-pdf.component';
import { ConnectionDateChangeComponent } from './layouts/connection/find-connection-one/connection-date-change/connection-date-change.component';
import { EditBillComponent } from './layouts/connection/find-connection-one/edit-bill/edit-bill.component';
import { LedgerComponent } from './layouts/reports/ledger/ledger.component';
import { MeterReaderDaywiseComponent } from './layouts/reports/meter-reader-daywise/meter-reader-daywise.component';
import { AgentWiseLedgerComponent } from './layouts/reports/agent-wise-ledger/agent-wise-ledger.component';
import { DCBComponent } from './layouts/reports/dcb/dcb.component';
import { ConnectionTypeComponent } from './layouts/reports/connection-type/connection-type.component';
import { WardWisePendingBalanceComponent } from './layouts/reports/ward-wise-pending-balance/ward-wise-pending-balance.component';
import { CollectionReportComponent } from './layouts/reports/collection-report/collection-report.component';
import { RivisionComponent } from './layouts/connection/find-connection-one/rivision/rivision.component';
import { CollectionReportDetailsComponent } from './layouts/reports/collection-report/collection-report-details/collection-report-details.component';
import { StatusWiseBilledUnbilledComponent } from './layouts/reports/status-wise-billed-unbilled/status-wise-billed-unbilled.component';
import { WardDetailsComponent } from './layouts/reports/ward-details/ward-details.component';
import { CashTransferComponent } from './layouts/bank/cash-transfer/cash-transfer.component';
import { DcbDetailsComponent } from './layouts/reports/dcb-details/dcb-details.component';
import { CashTransferReportComponent } from './layouts/reports/cash-transfer-report/cash-transfer-report.component';
import { ImportExcelComponent } from './layouts/import-excel/import-excel.component';
import { SuspenseCashTransferComponent } from './layouts/suspense-cash-transfer/suspense-cash-transfer.component';
import { SuspensionDetailComponent } from './layouts/suspense-cash-transfer/suspension-detail/suspension-detail.component';
import { SuspenseTransferDetailsComponent } from './layouts/reports/suspense-transfer-details/suspense-transfer-details.component';
import { AdminFormComponent } from './layouts/users/admins/admin-form/admin-form.component';
import { SupervisorFormComponent } from './layouts/users/supervisors/supervisor-form/supervisor-form.component';
import { BankStaffFormComponent } from './layouts/users/bank-staffs/bank-staff-form/bank-staff-form.component';
import { UnbilledRecordDetailsComponent } from './layouts/reports/unbilled-record-details/unbilled-record-details.component';
import { AbstractReportComponent } from './layouts/bank/abstract-report/abstract-report.component';
import { AgentWiseLedgerOldComponent } from './layouts/reports/agent-wise-ledger-old/agent-wise-ledger-old.component';
import { StatusWiseBilledRecordsComponent } from './layouts/reports/status-wise-billed-records/status-wise-billed-records.component';
import { BilledRecordDetailsComponent } from './layouts/reports/billed-record-details/billed-record-details.component';
import { LedgerRectifyComponent } from './layouts/reports/ledger-rectify/ledger-rectify.component';
import { AgmMapsComponent } from './layouts/connection/find-connection-one/agm-maps/agm-maps.component';
import { ChangeDetailsUserPicComponent } from './layouts/connection/find-connection-one/change-details-user-pic/change-details-user-pic.component';
import { PopupMessageModalComponent } from './layouts/connection/find-connection-one/popup-message-modal/popup-message-modal.component';
import { DuplicateBillsReportComponent } from './layouts/reports/duplicate-bills-report/duplicate-bills-report.component';
import { MovePaymentToAnotherLedgerComponent } from './layouts/reports/move-payment-to-another-ledger/move-payment-to-another-ledger.component';
import { MeterReaderDaywiseNewComponent } from './layouts/reports/meter-reader-daywise-new/meter-reader-daywise-new.component';



@NgModule({
  declarations: [
    AppComponent,
    ContentComponent,
    DashboardComponent,
    AuthenticationComponent,
    LoginComponent,
    FirstSectionComponent,
    FindConnectionComponent,
    FindConnectionOneComponent,
    AdminsComponent,
    ViewAdminComponent,
    SupervisorsComponent,
    ViewSupervisorComponent,
    CustomersComponent,
    //FilterPipe,
    BillingLedgerComponent,
    ErrorComponent,
    Error404Component,
    CashCollectionComponent,
    BankStaffsComponent,
    ViewBankStaffsComponent,
    CashCollectionReportComponent,
    AgentsComponent,
    AgentFormComponent,
    AgentDetailsComponent,
    NameChangeComponent,
    MeterChangeComponent,
    MeterDisconnectionComponent,
    TypeChangeComponent,
    AddressChangeComponent,
    ChangeDetailsComponent,
    MeterReconnectionComponent,
    NoticeDetailsComponent,
    EditConnectionComponent,
    ViewImageComponent,
    BillPdfComponent,
    ConnectionDateChangeComponent,
    EditBillComponent,
    LedgerComponent,
    MeterReaderDaywiseComponent,
    AgentWiseLedgerComponent,
    DCBComponent,
    ConnectionTypeComponent,
    WardWisePendingBalanceComponent,
    CollectionReportComponent,
    RivisionComponent,
    CollectionReportDetailsComponent,
    StatusWiseBilledUnbilledComponent,
    WardDetailsComponent,
    CashTransferComponent,
    DcbDetailsComponent,
    CashTransferReportComponent,
    ImportExcelComponent,
    SuspenseCashTransferComponent,
    SuspensionDetailComponent,
    SuspenseTransferDetailsComponent,
    AdminFormComponent,
    SupervisorFormComponent,
    BankStaffFormComponent,
    UnbilledRecordDetailsComponent,
    AbstractReportComponent,
    AgentWiseLedgerOldComponent,
    StatusWiseBilledRecordsComponent,
    BilledRecordDetailsComponent,
    LedgerRectifyComponent,
    AgmMapsComponent,
    ChangeDetailsUserPicComponent,
    PopupMessageModalComponent,
    DuplicateBillsReportComponent,
    MovePaymentToAnotherLedgerComponent,
    MeterReaderDaywiseNewComponent
  ],
  imports: [
    BrowserModule,
    BrowserAnimationsModule,
    SharedModule,
    AppRoutingModule,
    HttpClientModule,
    Ng2GoogleChartsModule,
    ChartsModule,
    ChartistModule,
    CountUpModule,
    OwlModule,
    AngularFireModule.initializeApp(environment.firebase) ,
    AngularFireAuthModule,
    AngularFirestoreModule,
    FormsModule, 
    ReactiveFormsModule,
    DataTablesModule,
    NgxDatatableModule,
    Ng2SmartTableModule,
    AngularFireStorageModule,
    NgPipesModule,
    Ng2SearchPipeModule,
    DataTableModule,
    AgmCoreModule.forRoot({
      apiKey: 'AIzaSyCa1xhsOabLcxOUcQrsLX83I6UIUEGOwPc'
    }),
    ToastrModule.forRoot({
      timeOut: 1000
    }),
    NgbModule,
    GridModule,
    PDFModule,
    ExcelModule,
    RxReactiveFormsModule,
    PDFExportModule
  ],
  providers: [AdminService, AuthGuard],
  bootstrap: [AppComponent]
})
export class AppModule { }
