import { Component, OnInit } from '@angular/core';
import { AngularFirestore } from '@angular/fire/firestore';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { data } from 'jquery';
import { DataStateChangeEvent, GridDataResult } from '@progress/kendo-angular-grid';
import { process, State } from '@progress/kendo-data-query';
import { LedgerService } from 'src/app/shared/service/ledger.service';
import { ToastrService } from 'ngx-toastr';

declare var require:any;
var moment = require('moment');

@Component({
  selector: 'app-collection-report',
  templateUrl: './collection-report.component.html',
  styleUrls: ['./collection-report.component.scss']
})
export class CollectionReportComponent implements OnInit {
  show:string = 'list';
  filterForm:FormGroup;
  detailsArray:any = [];
  collection;
  cashCollectionArray:any = [];
  data:any;
  grandTotalCount = 0;
  grandTotalShow:boolean = false;
  xlsx;

  public gridData: GridDataResult;
  public state: State = {
    filter: {
      logic: 'and',
      filters: []
    }
  };

  showLoader:boolean = false;

  constructor(private fb:FormBuilder, private firestore:AngularFirestore, private ledgerService:LedgerService, private toastrService: ToastrService) { }

  ngOnInit() {
    this.filterForm = this.fb.group({
      from_date: ['', Validators.required],
      to_date: ['', Validators.required],
      bank: [''],
    });
    this.firestore.collection('bankCashCollection', ref => ref.where('paymentReceivedBank', '==', 'Corporation Bank')).valueChanges().subscribe(data =>{
      console.log("data", data.length);
      
    })
  }

  /* onFilterSubmit(){
    this.detailsArray = [];
    this.cashCollectionArray = [];
    this.grandTotalCount = 0;

    let fromDate = this.filterForm.value.from_date;
    let toDate = this.filterForm.value.to_date;
    let bank = this.filterForm.value.bank;



    console.log("Form Value", bank, fromDate, toDate);
    
    if(bank != '' && fromDate != '' && toDate != ''){
      console.log("1");
      this.detailsArray = [];
      this.collection  = this.firestore.collection('bankCashCollection', ref => ref
      .where('paymentReceivedBank', '==', bank)
      .where('date', '>=', fromDate)
      .where('date', '<=', toDate)
      .orderBy('date', 'asc'));
      this.xlsx = bank+'-'+fromDate+'-to-'+toDate;
    }
    else if(fromDate != '' && toDate != ''){
      console.log("2");

      this.collection  = this.firestore.collection('bankCashCollection', ref => ref
      .where('date', '>=', fromDate)
      .where('date', '<=', toDate)
      .orderBy('date', 'asc'));
    }
    
    this.collection.valueChanges().subscribe(data => {
      data.forEach(element => {
        this.cashCollectionArray.push(element);
      });

      if(this.cashCollectionArray.length > 0){
        this.cashCollectionArray.forEach(cashCollect => {
          var c;
          if(this.detailsArray.length > 0){
            console.log("data", cashCollect.date, cashCollect.paymentReceivedBank);
            
            let itemIndex =  this.detailsArray.findIndex(item => item.paymentDate == cashCollect.date && item.bank == cashCollect.paymentReceivedBank);
  
            if(itemIndex >= 0){
              this.detailsArray[itemIndex].total = this.detailsArray[itemIndex].total + Number(cashCollect.amount);
            }
            else{
              c = {
                bank:cashCollect.paymentReceivedBank,
                paymentDate: cashCollect.date,
                total:Number(cashCollect.amount)
              }
              this.detailsArray.push(c);
              this.gridData = process( this.detailsArray, this.state);
            }
          }
          else{
            c = {
              bank:cashCollect.paymentReceivedBank,
              paymentDate: cashCollect.date,
              total:Number(cashCollect.amount)
            }
            this.detailsArray.push(c);
            this.gridData = process( this.detailsArray, this.state);
          }
          
        });
      }
    });
  } */

  onFilterSubmit(){
    this.showLoader = true;
    this.detailsArray = [];
    this.gridData = process(this.detailsArray, this.state);
    this.grandTotalCount = 0;

    let fromDate = this.filterForm.value.from_date;
    let toDate = this.filterForm.value.to_date;
    let bank = this.filterForm.value.bank;
    var data;

    console.log("Form Value", bank, fromDate, toDate);

    if(bank=='All'){
      data ={
        'type': 'all',
        'fromDate': fromDate,
        'toDate': toDate
      }

      
    }
    else if(bank != '' && fromDate != '' && toDate != ''){
      console.log("1");
    
      this.xlsx = bank+'-'+fromDate+'-to-'+toDate;
      data ={
        'type': 'bank',
        'value': bank,
        'fromDate': fromDate,
        'toDate': toDate
      }

      
    }
    else if(fromDate != '' && toDate != ''){
      console.log("2");
      data ={
        'type': 'all',
        'fromDate': fromDate,
        'toDate': toDate
      }

      
    }

    this.ledgerService.getCollectionReport(data).then(async response=>{
      if (response.code == 200) {
        this.detailsArray = await response.data;
        this.showLoader = false;
        console.log("this.detailsArray", await this.detailsArray)
        this.gridData =  await process(this.detailsArray, this.state);
      }
      else{
        this.showLoader = false;
        this.toastrService.error("No records", 'Error!'); 
      }
    });
  
  }

  view(dataItem){
    this.data = dataItem;
    this.show = 'view';
  }
  display(event){
    console.log("Event from house details", event);
    this.show = "list";
  }

  grandTotal(){
    this.grandTotalCount = 0;
    this.grandTotalShow = true;
    this.detailsArray.forEach(element => {
      this.grandTotalCount = this.grandTotalCount + Number(element.total);
    });
  }

  public dataStateChange(state: DataStateChangeEvent): void {
    this.state = state;
    this.gridData = process( this.detailsArray, this.state);
  }

  
}
