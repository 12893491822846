import { THIS_EXPR } from '@angular/compiler/src/output/output_ast';
import { Component, OnInit } from '@angular/core';
import { AngularFirestore } from '@angular/fire/firestore';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { firestore } from 'firebase';
import { filter } from 'rxjs/operators';
import { AgentService } from 'src/app/shared/agent/agent.service';
import { DataStateChangeEvent, GridDataResult } from '@progress/kendo-angular-grid';
import { process, State } from '@progress/kendo-data-query';
import { LedgerService } from 'src/app/shared/service/ledger.service';

declare var require:any;
var moment = require('moment');

@Component({
  selector: 'app-dcb',
  templateUrl: './dcb.component.html',
  styleUrls: ['./dcb.component.scss']
})
export class DCBComponent implements OnInit {
  filterForm:FormGroup;
  detailsArray:any = [];
  wards:any = [];
  masterArray:any = [];
  connectionCollection;
  connectionArray:any = [];
  type;
  lastDate;
  xlsxName;
  showLoader:boolean = false;
  
  public gridData: GridDataResult;
  public state: State = {
    filter: {
      logic: 'and',
      filters: []
    }
  };

  
  totalConnectionCount = 0;
  liveConnectionCount = 0;
  disconnectedConnectionCount = 0;
  billedCount = 0;
  consumptionCount = 0;
  obaCount = 0;
  waterChargeCount = 0;
  serviceChargeCount = 0;
  adjustmentCount = 0;
  revisionCount = 0;
  demandCount = 0;
  collectedAmountCount = 0;
  cbaCount = 0;

  constructor(private fb:FormBuilder, private firestore:AngularFirestore, private agentService:AgentService, private ledgerService:LedgerService) { }

  ngOnInit() {
    this.getAllWards();
    this.filterForm = this.fb.group({
      type: ['', Validators.required],
      month: ['', Validators.required],
      year: ['', Validators.required]
    });
  }

   /* onFilterSubmit(){
    console.log("date",this.filterForm.value );
    this.detailsArray = [];
    this.connectionArray = [];

    this.type = this.filterForm.controls.type.value;
    var month = this.filterForm.controls.month.value;
    var year = this.filterForm.controls.year.value;
    var filterBy;

    this.xlsxName= this.type && month && year ? this.type+'-'+month+'-'+year:'';

   
    if(this.type == 'status'){
    console.log("hai");
      this.statusFilter(month, year)
    }
    else{
     

      var first =  this.firestore.collection('/connections', ref => ref.limit(1000));
      first.get().subscribe(con =>{
        var lastVisible = con.docs[con.docs.length-1];
        con.forEach(element => {
          var connection = element.data();
          var totalConnection = 0;
          var liveConnection = 0;
          var disconnectedConnection = 0
          var billed = 0;
          var consumption = 0
          var OB = 0;
          var waterCharges = 0 ;
          var serviceCharge = 0;
          var adjustment = 0;
          var revision = 0;
          var demand = 0;
          var collectedAmount = 0;
          var CB = 0;
          


         if(this.type == 'ward'){
            filterBy = connection.ward;
          }
          else if(this.type == 'tariff'){
            filterBy = connection.tariff;
          }

          if(this.detailsArray.length > 0){
            console.log("details array", this.detailsArray);
            
                var itemIndex = this.detailsArray.findIndex(item => item.ward == filterBy);
                console.log("itemIndex", itemIndex);
                if(itemIndex >= 0){
                  
                  
                  this.detailsArray[itemIndex].totalConnection = this.detailsArray[itemIndex].totalConnection + 1
                  if(connection.consumerConnectionStatusType == 'Live'){
                    this.detailsArray[itemIndex].liveConnection = this.detailsArray[itemIndex].liveConnection + 1;
                  }
                  else{
                    this.detailsArray[itemIndex].disconnectedConnection = this.detailsArray[itemIndex].disconnectedConnection + 1;
                  }

                  this.firestore.collection('ledger', ref => ref.where('connectionId', '==', connection.RRNO).where('month', '==', month).where('year', '==', year).limit(1))
                    .valueChanges().subscribe(ledger =>{
                      console.log("Ledger 1", ledger);
                      
                      if(ledger.length > 0){
                        this.detailsArray[itemIndex].billed = this.detailsArray[itemIndex].billed + 1;
                        this.detailsArray[itemIndex].consumption = Number(this.detailsArray[itemIndex].consumption) + Number(ledger[0]["consumption"]);
                        this.detailsArray[itemIndex].OB = this.detailsArray[itemIndex].OB + Number(ledger[0]["OBA"]);
                        this.detailsArray[itemIndex].waterCharges = this.detailsArray[itemIndex].waterCharges + Number(ledger[0]["waterCharge"]);
                        this.detailsArray[itemIndex].adjustment = this.detailsArray[itemIndex].adjustment + Number(ledger[0]["adjustment"]);
                        this.detailsArray[itemIndex].serviceCharge = this.detailsArray[itemIndex].serviceCharge + Number(ledger[0]["serviceCharge"]);
                        this.detailsArray[itemIndex].revision = this.detailsArray[itemIndex].revision + Number(ledger[0]["rivision"]);
                        this.detailsArray[itemIndex].demand = this.detailsArray[itemIndex].demand + Number(ledger[0]["demand"]);
                        this.detailsArray[itemIndex].collectedAmount = this.detailsArray[itemIndex].collectedAmount + Number(ledger[0]["paidAmount"]);
                        this.detailsArray[itemIndex].CB = this.detailsArray[itemIndex].CB + Number(ledger[0]["CBA"]);
                      }
                    })
                }else{

                  totalConnection = totalConnection + 1;
      
                  if(connection.consumerConnectionStatusType == 'Live'){
                    liveConnection = liveConnection + 1;
                  }else{
                    disconnectedConnection = disconnectedConnection + 1;
                  }

                  this.firestore.collection('ledger', ref => ref.where('connectionId', '==', connection.RRNO).where('month', '==', month).where('year', '==', year).limit(1))
                    .valueChanges().subscribe(ledger =>{
                      console.log("Ledger 2", ledger);
                      if(ledger.length > 0){
                        billed = billed + 1;
                        consumption = consumption + Number(ledger[0]["consumption"]);
                        OB = OB + Number(ledger[0]["OBA"]);
                        waterCharges = waterCharges + Number(ledger[0]["waterCharge"]);
                        serviceCharge = serviceCharge + Number(ledger[0]["serviceCharge"]);
                        adjustment = adjustment + Number(ledger[0]["adjustment"]);
                        revision = revision + Number(ledger[0]["rivision"]);
                        demand = demand + Number(ledger[0]["demand"]);
                        collectedAmount = collectedAmount + Number(ledger[0]["paidAmount"]);
                        CB = CB + Number(ledger[0]["CBA"]);
                      } 
                    })
                    var c = {
                      ward: filterBy,
                      totalConnection:totalConnection,
                      liveConnection:liveConnection,
                      disconnectedConnection:disconnectedConnection,
                      billed:billed,
                      consumption:consumption,
                      OB:OB,
                      waterCharges:waterCharges,
                      serviceCharge:serviceCharge,
                      adjustment:adjustment,
                      revision:revision,
                      demand:demand,
                      collectedAmount:collectedAmount,
                      CB:CB
                    }
                    this.detailsArray.push(c);
                    this.gridData =  process( this.detailsArray, this.state);
                }
              
          }
          else{
            var c = {
              ward: filterBy,
              totalConnection:0,
              liveConnection:0,
              disconnectedConnection:0,
              billed:0,
              consumption:0,
              OB:0,
              waterCharges:0,
              serviceCharge:0,
              adjustment:0,
              revision:0,
              demand:0,
              collectedAmount:0,
              CB:0
            }
            this.detailsArray.push(c);
            this.gridData = process( this.detailsArray, this.state);
          }
        });

        this.recursionConnection(lastVisible, month, year);
      })

      

    }
  } */


  onFilterSubmit(){
    this.showLoader = true;
    console.log("date",this.filterForm.value );
    this.detailsArray = [];
    this.connectionArray = [];
    this.totalConnectionCount = 0;
    this.liveConnectionCount = 0;
    this.disconnectedConnectionCount = 0;
    this.billedCount = 0;
    this.consumptionCount = 0;
    this.obaCount = 0;
    this.waterChargeCount = 0;
    this.serviceChargeCount = 0;
    this.adjustmentCount = 0;
    this.revisionCount = 0;
    this.demandCount = 0;
    this.collectedAmountCount = 0;
    this.cbaCount = 0;
  
    this.type = this.filterForm.controls.type.value;
    var month = this.filterForm.controls.month.value;
    var year = this.filterForm.controls.year.value;
    var filterBy;

    this.xlsxName= this.type && month && year ? this.type+'-'+month+'-'+year:'';

    var data = {
      'type': this.type,
      'year' : year,
      'month' : month
    }
    this.ledgerService.getDCB(data).then(async response=>{
      if (response.code == 200) {

        this.detailsArray = await response.data;
        await this.detailsArray.forEach((m, key) => {
          this.totalConnectionCount = this.totalConnectionCount + m.totalConnection;
          this.liveConnectionCount = this.liveConnectionCount + m.liveConnection;
          this.disconnectedConnectionCount = this.disconnectedConnectionCount + m.disconnectedConnection;
          this.billedCount = this.billedCount + m.billed;
          this.consumptionCount = this.consumptionCount + m.consumption;
          this.obaCount = this.obaCount  + m.oba;
          this.waterChargeCount = this.waterChargeCount + m.waterCharge;
          this.serviceChargeCount = this.serviceChargeCount + m.serviceCharge;
          this.adjustmentCount = this.adjustmentCount + m.adjustment;
          this.revisionCount = this.revisionCount + m.revision;
          this.demandCount = this.demandCount + m.demand;
          this.collectedAmountCount = this.collectedAmountCount + m.collectedAmount;
          this.cbaCount = this.cbaCount + m.cba;
          
          if(this.detailsArray.length-1 == key) {
            var obj ={
              "type": "Grand Total",
              "status": "Grand Total",
              "totalConnection": this.totalConnectionCount,
              "liveConnection": this.liveConnectionCount,
              "disconnectedConnection": this.disconnectedConnectionCount,
              "billed": this.billedCount,
              "consumption": this.consumptionCount,
              "oba": this.obaCount,
              "waterCharge" : this.waterChargeCount,
              "serviceCharge" : this.serviceChargeCount,
              "adjustment" : this.adjustmentCount,
              "revision" : this.revisionCount,
              "demand" : this.demandCount, 
              //"demand": this.waterChargeCount + this.serviceChargeCount - this.adjustmentCount,
              "collectedAmount" : this.collectedAmountCount,
              "cba" : this.cbaCount
             /*  "cba" : this.cbaCount +  (this.obaCount + this.waterChargeCount + this.serviceChargeCount - this.adjustmentCount) - this.revisionCount - this.collectedAmountCount */
            }
            this.detailsArray.push(obj);
          }
        });
        this.showLoader = false;
        console.log("this.detailsArray1", await this.detailsArray)
        this.gridData =  await process(this.detailsArray, this.state);
      
      }
    });

  }

  /* recursionConnection(lastVisible, month, year){
    var filterBy ;
    var next =  this.firestore.collection('/connections', ref=>ref.startAfter(lastVisible).limit(1000));
      next.get().subscribe(con =>{
        var lastVisibleRecursion = con.docs[con.docs.length-1];
        con.forEach(element => {
      
          var connection = element.data();
          var totalConnection = 0;
          var liveConnection = 0;
          var disconnectedConnection = 0
          var billed = 0;
          var consumption = 0
          var OB = 0;
          var waterCharges = 0 ;
          var serviceCharge = 0;
          var adjustment = 0;
          var revision = 0;
          var demand = 0;
          var collectedAmount = 0;
          var CB = 0;
          


         if(this.type == 'ward'){
            filterBy = connection.ward;
          }
          else if(this.type == 'tariff'){
            filterBy = connection.tariff;
          }

          if(this.detailsArray.length > 0){
            console.log("details array", this.detailsArray);
            
                var itemIndex = this.detailsArray.findIndex(item => item.ward == filterBy);
                console.log("itemIndex", itemIndex);
                if(itemIndex >= 0){
                  
                  
                  this.detailsArray[itemIndex].totalConnection = this.detailsArray[itemIndex].totalConnection + 1
                  if(connection.consumerConnectionStatusType == 'Live'){
                    this.detailsArray[itemIndex].liveConnection = this.detailsArray[itemIndex].liveConnection + 1;
                  }
                  else{
                    this.detailsArray[itemIndex].disconnectedConnection = this.detailsArray[itemIndex].disconnectedConnection + 1;
                  }

                  this.firestore.collection('ledger', ref => ref.where('connectionId', '==', connection.RRNO).where('month', '==', month).where('year', '==', year).limit(1))
                    .valueChanges().subscribe(ledger =>{
                      console.log("Ledger 1", ledger);
                      
                      if(ledger.length > 0){
                        this.detailsArray[itemIndex].billed = this.detailsArray[itemIndex].billed + 1;
                        this.detailsArray[itemIndex].consumption = Number(this.detailsArray[itemIndex].consumption) + Number(ledger[0]["consumption"]);
                        this.detailsArray[itemIndex].OB = this.detailsArray[itemIndex].OB + Number(ledger[0]["OBA"]);
                        this.detailsArray[itemIndex].waterCharges = this.detailsArray[itemIndex].waterCharges + Number(ledger[0]["waterCharge"]);
                        this.detailsArray[itemIndex].adjustment = this.detailsArray[itemIndex].adjustment + Number(ledger[0]["adjustment"]);
                        this.detailsArray[itemIndex].serviceCharge = this.detailsArray[itemIndex].serviceCharge + Number(ledger[0]["serviceCharge"]);
                        this.detailsArray[itemIndex].revision = this.detailsArray[itemIndex].revision + Number(ledger[0]["rivision"]);
                        this.detailsArray[itemIndex].demand = this.detailsArray[itemIndex].demand + Number(ledger[0]["demand"]);
                        this.detailsArray[itemIndex].collectedAmount = this.detailsArray[itemIndex].collectedAmount + Number(ledger[0]["paidAmount"]);
                        this.detailsArray[itemIndex].CB = this.detailsArray[itemIndex].CB + Number(ledger[0]["CBA"]);
                      }
                    })
                }else{

                  totalConnection = totalConnection + 1;
      
                  if(connection.consumerConnectionStatusType == 'Live'){
                    liveConnection = liveConnection + 1;
                  }else{
                    disconnectedConnection = disconnectedConnection + 1;
                  }

                  this.firestore.collection('ledger', ref => ref.where('connectionId', '==', connection.RRNO).where('month', '==', month).where('year', '==', year).limit(1))
                    .valueChanges().subscribe(ledger =>{
                      console.log("Ledger 2", ledger);
                      if(ledger.length > 0){
                        billed = billed + 1;
                        consumption = consumption + Number(ledger[0]["consumption"]);
                        OB = OB + Number(ledger[0]["OBA"]);
                        waterCharges = waterCharges + Number(ledger[0]["waterCharge"]);
                        serviceCharge = serviceCharge + Number(ledger[0]["serviceCharge"]);
                        adjustment = adjustment + Number(ledger[0]["adjustment"]);
                        revision = revision + Number(ledger[0]["rivision"]);
                        demand = demand + Number(ledger[0]["demand"]);
                        collectedAmount = collectedAmount + Number(ledger[0]["paidAmount"]);
                        CB = CB + Number(ledger[0]["CBA"]);
                      } 
                    })
                    var c = {
                      ward: filterBy,
                      totalConnection:totalConnection,
                      liveConnection:liveConnection,
                      disconnectedConnection:disconnectedConnection,
                      billed:billed,
                      consumption:consumption,
                      OB:OB,
                      waterCharges:waterCharges,
                      serviceCharge:serviceCharge,
                      adjustment:adjustment,
                      revision:revision,
                      demand:demand,
                      collectedAmount:collectedAmount,
                      CB:CB
                    }
                    this.detailsArray.push(c);
                    this.gridData =  process( this.detailsArray, this.state);
                }
              
          }
          else{
            var c = {
              ward: filterBy,
              totalConnection:0,
              liveConnection:0,
              disconnectedConnection:0,
              billed:0,
              consumption:0,
              OB:0,
              waterCharges:0,
              serviceCharge:0,
              adjustment:0,
              revision:0,
              demand:0,
              collectedAmount:0,
              CB:0
            }
            this.detailsArray.push(c);
            this.gridData =  process( this.detailsArray, this.state);
          }
        });

        if(lastVisibleRecursion != undefined){
          this.recursionConnection(lastVisibleRecursion, month, year);
        }
        
      })
  }



  async statusFilter(month, year){
    console.log("status filter");
    this.connectionArray = [];
    this.detailsArray = [];
  
  
    this.connectionCollection = await this.firestore.collection('ledger', ref=>ref.where('month', '==', month).where('year', '==', year).limit(500));
    this.connectionCollection.valueChanges().subscribe(data=>{
      data.forEach(element => {
        console.log("element", element);
        
        this.connectionArray.push(element);
      });

      this.connectionArray.forEach(async ledger => {
        var billed = 0;
        var liveConnection = 0;
        var disconnectedCon = 0;
        var totalConnection = 0;
        var consumption = 0
        var OB = 0;
        var waterCharges = 0 ;
        var serviceCharge = 0;
        var adjustment = 0;
        var revision = 0;
        var demand = 0;
        var collectedAmount = 0;
        var CB = 0;
        var l;
        console.log("Details array 1", this.detailsArray, ledger.status);
        if(this.detailsArray.length > 0){
          console.log("Details array 2", this.detailsArray, ledger.status);
           await this.firestore.collection('connections', ref => ref.where('RRNO', '==', ledger.connectionId).limit(1)).valueChanges().subscribe( con=>{
               console.log("con 3", con);
              let itemIndex =   this.detailsArray.findIndex(item => item.status == ledger.status);
              console.log("itemIndex",  itemIndex);
              if(itemIndex >= 0){
                console.log("match found");
                
                
                this.detailsArray[itemIndex].billed =  this.detailsArray[itemIndex].billed + 1;
                if(con[0]['consumerConnectionStatusType'] == 'Live'){
                  this.detailsArray[itemIndex].liveConnection =  this.detailsArray[itemIndex].liveConnection + 1;
                }
                else{
                  this.detailsArray[itemIndex].disconnectedCon =  this.detailsArray[itemIndex].disconnectedCon + 1;
                }
  
                this.detailsArray[itemIndex].totalConnection =   this.detailsArray[itemIndex].liveConnection + this.detailsArray[itemIndex].disconnectedCon;

                this.detailsArray[itemIndex].consumption = Number(this.detailsArray[itemIndex].consumption) + Number(ledger.consumption);
                this.detailsArray[itemIndex].OB = this.detailsArray[itemIndex].OB + Number(ledger.OBA);
                this.detailsArray[itemIndex].waterCharges = this.detailsArray[itemIndex].waterCharges + Number(ledger.waterCharge);
                this.detailsArray[itemIndex].adjustment = this.detailsArray[itemIndex].adjustment + Number(ledger.adjustment);
                this.detailsArray[itemIndex].serviceCharge = this.detailsArray[itemIndex].serviceCharge + Number(ledger.serviceCharge);
                this.detailsArray[itemIndex].revision = this.detailsArray[itemIndex].revision + Number(ledger.rivision);
                this.detailsArray[itemIndex].demand = this.detailsArray[itemIndex].demand + Number(ledger.demand);
                this.detailsArray[itemIndex].collectedAmount = this.detailsArray[itemIndex].collectedAmount + Number(ledger.paidAmount);
                this.detailsArray[itemIndex].CB = this.detailsArray[itemIndex].CB + Number(ledger.CBA);
              }
              else{
                billed =  billed + 1;
                if(con[0]['consumerConnectionStatusType'] == 'Live'){
                  liveConnection = liveConnection + 1;
                }
                else{
                 disconnectedCon = disconnectedCon + 1;
                }
  
                totalConnection = liveConnection + disconnectedCon;

                consumption = consumption + Number(ledger.consumption);
                OB = OB + Number(ledger.OBA);
                waterCharges = waterCharges + Number(ledger.waterCharge);
                serviceCharge = serviceCharge + Number(ledger.serviceCharge);
                adjustment = adjustment + Number(ledger.adjustment);
                revision = revision + Number(ledger.rivision);
                demand = demand + Number(ledger.demand);
                collectedAmount = collectedAmount + Number(ledger.paidAmount);
                CB = CB + Number(ledger.CBA);
  
                l =   {
                  status:ledger.status,
                  billed:billed,
                  liveConnection:liveConnection,
                  disconnectedCon: disconnectedCon,
                  totalConnection: totalConnection,
                  consumption:consumption,
                  OB:OB,
                  waterCharges:waterCharges,
                  serviceCharge:serviceCharge,
                  adjustment:adjustment,
                  revision:revision,
                  demand:demand,
                  collectedAmount:collectedAmount,
                  CB:CB
                  
                }
                 this.detailsArray.push(l);
                 this.gridData =  process( this.detailsArray, this.state);
              
              }
            
          });
        }
        else{
          console.log("first");
          
           l =  {
            status:'NORMAL',
            billed:0,
            liveConnection:0,
            disconnectedCon: 0,
            totalConnection: 0,
            consumption:0,
            OB:0,
            waterCharges:0,
            serviceCharge:0,
            adjustment:0,
            revision:0,
            demand:0,
            collectedAmount:0,
            CB:0
          }
           this.detailsArray.push(l);
           this.gridData =  process( this.detailsArray, this.state);
        }
      });
    })

  } */

  getAllWards(){
    this.agentService.getWards().then(async (response) =>{
      this.wards =await  response.data;
    })
  }


  getDaysInMonth(month, year) {
    month = month - 1;
    var date = new Date(year, month, 1);
   
    var d = new Date(year, month + 1, 0);
    this.lastDate = moment(d.toString()).format('YYYY-MM-DD HH:mm:ss');
    console.log("last date", this.lastDate);
  
   //return days;
  }

  refresh(){
    location.reload();
  }

  public dataStateChange(state: DataStateChangeEvent): void {
    this.state = state;
    this.gridData = process( this.detailsArray, this.state);
  }

}
