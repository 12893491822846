import { Component, OnInit } from '@angular/core';
import { AngularFirestore } from '@angular/fire/firestore';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { DataStateChangeEvent, GridDataResult } from '@progress/kendo-angular-grid';
import { process, State } from '@progress/kendo-data-query';
import { LedgerService } from 'src/app/shared/service/ledger.service';

declare var require:any;
var moment = require('moment');

@Component({
  selector: 'app-dcb-details',
  templateUrl: './dcb-details.component.html',
  styleUrls: ['./dcb-details.component.scss']
})
export class DcbDetailsComponent implements OnInit {
  filterForm:FormGroup;
  detailsArray:any = [];
  wards:any = [];
  masterArray:any = [];
  connectionCollection;
  connectionArray:any = [];
  type;
  fromDate;
  toDate;
  xlsxName;
  totalConnectionCount = 0;
  billedCount = 0;
  obaCount = 0;
  adjustmentCount = 0;
  demandCount = 0;
  collectedAmountCount = 0;
  cbaCount = 0;
  waterChargeCount = 0;
  consumptionCount = 0;
  serviceChargeCount = 0;
  revisionCount = 0;
  liveConnectionCount = 0;
  public gridData: GridDataResult;
  public state: State = {
    filter: {
      logic: 'and',
      filters: []
    }
  };

  showLoader:boolean = false;

  constructor(private fb:FormBuilder, private firestore:AngularFirestore, private ledgerService:LedgerService) { }

  ngOnInit() {
    this.filterForm = this.fb.group({
      type: ['', Validators.required],
      fromMonth: ['', Validators.required],
      fromYear: ['', Validators.required],
      toMonth: ['', Validators.required],
      toYear: ['', Validators.required]
    });
  }

  /* onFilterSubmit(){
    console.log("date",this.filterForm.value );
    this.detailsArray = [];
    this.connectionArray = [];

    this.type = this.filterForm.controls.type.value;
    var fromMonth = this.filterForm.controls.fromMonth.value;
    var fromYear = this.filterForm.controls.fromYear.value;
    var toMonth = this.filterForm.controls.toMonth.value;
    var toYear = this.filterForm.controls.toYear.value;
    var filterBy;

    this.getFromDate(fromMonth, fromYear)
    this.getToDate(toMonth ,toYear)

    this.xlsxName= this.type + '-' + fromMonth + '-'+fromYear+'-to-'+toMonth+'-'+toYear;

   
    if(this.type == 'status'){
   
      this.statusFilter();
    }
    else{
     
      this.connectionCollection =  this.firestore.collection('ledger', ref=>ref.where('billDateTime', '>=', this.fromDate).where('billDateTime', '<=', this.toDate));
      this.connectionCollection.valueChanges().subscribe(async data=>{
        console.log("element", data.length);
        data.forEach(element => {
          this.connectionArray.push(element);
        });
  
        await this.connectionArray.forEach(async (ledger, key) => {
          var billed = 0;
          var liveConnection = 0;
          var disconnectedConnection = 0;
          var totalConnection = 0;
          var consumption = 0
          var OB = 0;
          var waterCharges = 0 ;
          var serviceCharge = 0;
          var adjustment = 0;
          var revision = 0;
          var demand = 0;
          var collectedAmount = 0;
          var CB = 0;
          var l;
        
          if(this.detailsArray.length > 0){
      
             await this.firestore.collection('connections', ref => ref.where('RRNO', '==', ledger.connectionId).limit(1)).valueChanges().subscribe(async con=>{
                console.log("con 3", );
                 var itemIndex;
                 if(this.type == 'ward'){
                  itemIndex = await this.detailsArray.findIndex(item => item.ward == con[0]['ward']);
                 }
                 else{
                  itemIndex =  await this.detailsArray.findIndex(item => item.ward == con[0]['tariff']);
                 }
                
                console.log("itemIndex",  itemIndex);
                if(itemIndex >= 0){
                  console.log("match found",key );
                  
                  
                  this.detailsArray[itemIndex].billed = await this.detailsArray[itemIndex].billed + 1;
                  if(con[0]['consumerConnectionStatusType'] == 'Live'){
                    this.detailsArray[itemIndex].liveConnection = await Number(this.detailsArray[itemIndex].liveConnection) + 1;
                  }
                  else{
                    this.detailsArray[itemIndex].disconnectedConnection = await Number(this.detailsArray[itemIndex].disconnectedConnection) + 1;
                  }
    
                  this.detailsArray[itemIndex].totalConnection =await   Number(this.detailsArray[itemIndex].liveConnection) + Number(this.detailsArray[itemIndex].disconnectedConnection);
  
                  this.detailsArray[itemIndex].consumption = await Number(this.detailsArray[itemIndex].consumption) + Number(ledger.consumption);
                  this.detailsArray[itemIndex].OB = await this.detailsArray[itemIndex].OB + Number(ledger.OBA);
                  this.detailsArray[itemIndex].waterCharges = await this.detailsArray[itemIndex].waterCharges + Number(ledger.waterCharge);
                  this.detailsArray[itemIndex].adjustment = await this.detailsArray[itemIndex].adjustment + Number(ledger.adjustment);
                  this.detailsArray[itemIndex].serviceCharge = await  this.detailsArray[itemIndex].serviceCharge + Number(ledger.serviceCharge);
                  this.detailsArray[itemIndex].revision = await this.detailsArray[itemIndex].revision + Number(ledger.rivision);
                  this.detailsArray[itemIndex].demand = await this.detailsArray[itemIndex].demand + Number(ledger.demand);
                  this.detailsArray[itemIndex].collectedAmount = await this.detailsArray[itemIndex].collectedAmount + Number(ledger.paidAmount);
                  this.detailsArray[itemIndex].CB = await this.detailsArray[itemIndex].CB + Number(ledger.CBA);
                }
                else{
                  console.log("match not found", key);
                  
                  billed = await billed + 1;
                  if(con[0]['consumerConnectionStatusType'] == 'Live'){
                    liveConnection =await liveConnection + 1;
                  }
                  else{
                   disconnectedConnection = await disconnectedConnection + 1;
                  }
    
                  totalConnection = await liveConnection + disconnectedConnection;
  
                  consumption = await consumption + Number(ledger.consumption);
                  OB = await OB + Number(ledger.OBA);
                  waterCharges = await waterCharges + Number(ledger.waterCharge);
                  serviceCharge = await serviceCharge + Number(ledger.serviceCharge);
                  adjustment = await adjustment + Number(ledger.adjustment);
                  revision = await revision + Number(ledger.rivision);
                  demand = await demand + Number(ledger.demand);
                  collectedAmount = await collectedAmount + Number(ledger.paidAmount);
                  CB = await CB + Number(ledger.CBA);
                  
                  var f = this.type == 'ward'?con[0]['ward']:con[0]['tariff'];
                  l = await  {
                    ward:f,
                    billed:billed,
                    liveConnection:liveConnection,
                    disconnectedConnection: disconnectedConnection,
                    totalConnection: totalConnection,
                    consumption:consumption,
                    OB:OB,
                    waterCharges:waterCharges,
                    serviceCharge:serviceCharge,
                    adjustment:adjustment,
                    revision:revision,
                    demand:demand,
                    collectedAmount:collectedAmount,
                    CB:CB
                    
                  }
                  await this.detailsArray.push(l);
                  this.gridData = await  process( this.detailsArray, this.state);
                }
              
            });
          }
          else{
            console.log("first");
             var t = this.type == 'ward' ? '76 BADAGUBETTU' : 'Residential'
             l =  {
              ward:t,
              billed:0,
              liveConnection:0,
              disconnectedConnection: 0,
              totalConnection: 0,
              consumption:0,
              OB:0,
              waterCharges:0,
              serviceCharge:0,
              adjustment:0,
              revision:0,
              demand:0,
              collectedAmount:0,
              CB:0
            }
             this.detailsArray.push(l);
             this.gridData =  process( this.detailsArray, this.state);
          }
        });
        
      })

    }
  } */

  onFilterSubmit(){
    this.showLoader = true;
    console.log("date",this.filterForm.value );
    this.detailsArray = [];
    this.connectionArray = [];
    this.liveConnectionCount = 0;
    this.totalConnectionCount = 0;
    this.billedCount = 0;
    this.obaCount = 0;
    this.adjustmentCount = 0;
    this.demandCount = 0;
    this.collectedAmountCount = 0;
    this.cbaCount = 0;
    this.waterChargeCount = 0;
    this.serviceChargeCount = 0;
    this.revisionCount = 0;
    this.consumptionCount = 0;
    this.type = this.filterForm.controls.type.value;
    var fromMonth = this.filterForm.controls.fromMonth.value;
    var fromYear = this.filterForm.controls.fromYear.value;
    var toMonth = this.filterForm.controls.toMonth.value;
    var toYear = this.filterForm.controls.toYear.value;
  
    /* this.getFromDate(fromMonth, fromYear)
    this.getToDate(toMonth ,toYear) */

    this.getFromDate('4', fromYear)
    this.getToDate('3' ,toYear)

    this.xlsxName= this.type + '-' + fromMonth + '-'+fromYear+'-to-'+toMonth+'-'+toYear;

    var data = {
      'type': this.type,
      'fromDate' : this.fromDate,
      'toDate' : this.toDate
    }
    this.ledgerService.getDCBDetails(data).then(async response=>{
      if (response.code == 200) {

        this.detailsArray = await response.data;

        console.log(this.detailsArray)
        await this.detailsArray.forEach((m, key) => {
          this.totalConnectionCount = this.totalConnectionCount + m.totalConnection;
          this.liveConnectionCount = Number(this.liveConnectionCount) + Number(m.liveConnection);
          this.consumptionCount = this.consumptionCount + m.consumption;
          this.billedCount = this.billedCount + m.billed;
          
          this.obaCount = this.obaCount  + m.oba;
          this.adjustmentCount = this.adjustmentCount + m.adjustment;
          this.demandCount = this.demandCount + m.demand;
          this.collectedAmountCount = this.collectedAmountCount + m.collectedAmount;
          this.cbaCount = this.cbaCount + m.cba;
          this.waterChargeCount = this.waterChargeCount + m.waterCharge;
          this.serviceChargeCount = this.serviceChargeCount + m.serviceCharge;
          this.revisionCount = this.revisionCount + m.revision;

          if(this.detailsArray.length-1 == key) {
            var obj ={
              "type": "Grand Total",
              "status": "Grand Total",
              "totalConnection": this.totalConnectionCount,
              "liveConnection": this.liveConnectionCount,
              "consumption": this.consumptionCount,
              "billed": this.billedCount,
              "oba": this.obaCount,
              "adjustment" : this.adjustmentCount,
              /* "demand" : this.demandCount, */
              "demand": this.waterChargeCount + this.serviceChargeCount - this.adjustmentCount,
              "collectedAmount" : this.collectedAmountCount,
              "cba" : this.cbaCount,
              "waterCharge" : this.waterChargeCount,
              "serviceCharge" : this.serviceChargeCount,
              "revision" : this.revisionCount
            }
            this.detailsArray.push(obj);
          }
        });
        this.showLoader = false
        console.log("this.detailsArray", await this.detailsArray)
       
        this.gridData =  await process(this.detailsArray, this.state);
      
      }
      else{
        this.showLoader = false;
        this.detailsArray = [];
        this.gridData =  await process(this.detailsArray, this.state);
      } 
    });

  }

  /* async statusFilter(){
    console.log("status filter");
    this.connectionArray = [];
    this.detailsArray = [];

    this.connectionCollection = await this.firestore.collection('ledger', ref=>ref.where('billDateTime', '>=', this.fromDate).where('billDateTime', '<=', this.toDate).limit(1000));
    this.connectionCollection.valueChanges().subscribe(data=>{
      data.forEach(element => {
        console.log("element", element);
        
        this.connectionArray.push(element);
      });

      this.connectionArray.forEach(async ledger => {
        var billed = 0;
        var liveConnection = 0;
        var disconnectedConnection = 0;
        var totalConnection = 0;
        var consumption = 0
        var OB = 0;
        var waterCharges = 0 ;
        var serviceCharge = 0;
        var adjustment = 0;
        var revision = 0;
        var demand = 0;
        var collectedAmount = 0;
        var CB = 0;
        var l;
        console.log("Details array 1", this.detailsArray, ledger.status);
        if(this.detailsArray.length > 0){
          console.log("Details array 2", this.detailsArray, ledger.status);
           await this.firestore.collection('connections', ref => ref.where('RRNO', '==', ledger.connectionId).limit(1)).valueChanges().subscribe( con=>{
               console.log("con 3", con);
              let itemIndex =   this.detailsArray.findIndex(item => item.status == ledger.status);
              console.log("itemIndex",  itemIndex);
              if(itemIndex >= 0){
                console.log("match found");
                
                
                this.detailsArray[itemIndex].billed =  this.detailsArray[itemIndex].billed + 1;
                if(con[0]['consumerConnectionStatusType'] == 'Live'){
                  this.detailsArray[itemIndex].liveConnection =  this.detailsArray[itemIndex].liveConnection + 1;
                }
                else{
                  this.detailsArray[itemIndex].disconnectedConnection =  this.detailsArray[itemIndex].disconnectedConnection + 1;
                }
  
                this.detailsArray[itemIndex].totalConnection =   this.detailsArray[itemIndex].liveConnection + this.detailsArray[itemIndex].disconnectedConnection;

                this.detailsArray[itemIndex].consumption = Number(this.detailsArray[itemIndex].consumption) + Number(ledger.consumption);
                this.detailsArray[itemIndex].OB = this.detailsArray[itemIndex].OB + Number(ledger.OBA);
                this.detailsArray[itemIndex].waterCharges = this.detailsArray[itemIndex].waterCharges + Number(ledger.waterCharge);
                this.detailsArray[itemIndex].adjustment = this.detailsArray[itemIndex].adjustment + Number(ledger.adjustment);
                this.detailsArray[itemIndex].serviceCharge = this.detailsArray[itemIndex].serviceCharge + Number(ledger.serviceCharge);
                this.detailsArray[itemIndex].revision = this.detailsArray[itemIndex].revision + Number(ledger.rivision);
                this.detailsArray[itemIndex].demand = this.detailsArray[itemIndex].demand + Number(ledger.demand);
                this.detailsArray[itemIndex].collectedAmount = this.detailsArray[itemIndex].collectedAmount + Number(ledger.paidAmount);
                this.detailsArray[itemIndex].CB = this.detailsArray[itemIndex].CB + Number(ledger.CBA);
              }
              else{
                billed =  billed + 1;
                if(con[0]['consumerConnectionStatusType'] == 'Live'){
                  liveConnection = liveConnection + 1;
                }
                else{
                 disconnectedConnection = disconnectedConnection + 1;
                }
  
                totalConnection = liveConnection + disconnectedConnection;

                consumption = consumption + Number(ledger.consumption);
                OB = OB + Number(ledger.OBA);
                waterCharges = waterCharges + Number(ledger.waterCharge);
                serviceCharge = serviceCharge + Number(ledger.serviceCharge);
                adjustment = adjustment + Number(ledger.adjustment);
                revision = revision + Number(ledger.rivision);
                demand = demand + Number(ledger.demand);
                collectedAmount = collectedAmount + Number(ledger.paidAmount);
                CB = CB + Number(ledger.CBA);
  
                l =   {
                  status:ledger.status,
                  billed:billed,
                  liveConnection:liveConnection,
                  disconnectedConnection: disconnectedConnection,
                  totalConnection: totalConnection,
                  consumption:consumption,
                  OB:OB,
                  waterCharges:waterCharges,
                  serviceCharge:serviceCharge,
                  adjustment:adjustment,
                  revision:revision,
                  demand:demand,
                  collectedAmount:collectedAmount,
                  CB:CB
                  
                }
                 this.detailsArray.push(l);
                 this.gridData =  process( this.detailsArray, this.state);
              }
            
          });
        }
        else{
          console.log("first");
          
           l =  {
            status:'NORMAL',
            billed:0,
            liveConnection:0,
            disconnectedConnection: 0,
            totalConnection: 0,
            consumption:0,
            OB:0,
            waterCharges:0,
            serviceCharge:0,
            adjustment:0,
            revision:0,
            demand:0,
            collectedAmount:0,
            CB:0
          }
           this.detailsArray.push(l);
           this.gridData =  process( this.detailsArray, this.state);
        }
      });
      
    })

  } */

  getToDate(month, year) {
    month = month - 1;
    var date = new Date(year, month, 1);
   
    var d = new Date(year, month + 1, 0);
    this.toDate = moment(d.toString()).format('YYYY-MM-DD HH:mm:ss');
    console.log("last date", this.toDate);
  
   //return days;
  }

  getFromDate(month, year){
    month = month - 1;
    var d = new Date(year, month , 1);
    this.fromDate = moment(d.toString()).format('YYYY-MM-DD HH:mm:ss');
    console.log("from date", this.fromDate);
  }

  public dataStateChange(state: DataStateChangeEvent): void {
    this.state = state;
    this.gridData = process( this.detailsArray, this.state);
  }
}
