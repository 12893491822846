import { Component, Input, OnInit, Output, EventEmitter } from "@angular/core";
import { AngularFirestore } from "@angular/fire/firestore";
import { FormControl, FormGroup, Validators } from "@angular/forms";
import { ToastrService } from "ngx-toastr";
import { flatMap } from "rxjs/operators";
import { BillingService } from "src/app/shared/service/billing.service";
declare var require: any;
var moment = require("moment");
@Component({
  selector: "app-edit-bill",
  templateUrl: "./edit-bill.component.html",
  styleUrls: ["./edit-bill.component.scss"],
})
export class EditBillComponent implements OnInit {
  @Input("billDetailsData") billDetailsData;
  @Output("onCloseModal") onCloseModal = new EventEmitter();

  btnCalclate: boolean = false;
  meterConstant;
  connectionId;
  selectedItem;
  ownerName;
  houseNo;
  address;
  meterNo;
  ward;
  tariff;
  previousStatus;
  subDivision;
  userName;
  phoneNumber;
  readingDate;
  noOfDays;
  serviceDate;
  connectionType;
  dateTime;
  previousReading;
  consumption;
  waterCharge = 0;
  serviceCharge = 5;
  penalty = 0;
  adjustment = 0;
  CBA = 0;
  OBA = 0;
  demand = 0;
  presentReadingValue;
  total = 0;
  dueDate;
  userId;
  isVisible: boolean = true;
  meterFixedDate;
  meterFixedEnterDate;
  previousStatusOfMeterFixed;
  previousReadingDateOfMeterFixed;
  sumOfWaterCharges = 0;
  sumOfAdjustmentConsumption = 0;
  sumOfAdjustmentWaterCharge = 0;
  sumOfAbnormalConsumption = 0;
  sumOfAbnormalDays = 0;
  sumOfNormalConsumption = 0;
  sumOfNormalDays = 0;
  fixedCharge = 0;
  slab1 = 0;
  slab2 = 0;
  slab3 = 0;
  submitted: boolean = false;

  connectionDetail;
  billDetails;
  billDetailsArray: any = [];
  meterChangeDetails;
  meterChangeDetailsArray: any = [];
  beforeMeterFixedBillDetails;
  beforeMeterFixedBillDetailsArray: any = [];
  inBetweenAdjustmentDetailsIssuedDetails;
  inBetweenAdjustmentWaterChargeArray: any = [];
  inBetweenAdjustmentConsumptionArray: any = [];
  waterChargeArray: any = [];
  abnormalConsumptionArray: any = [];
  lastNormalBillDate: any = [];
  abnormalNoOfDaysArray: any = [];
  normalConsumptionArray: any = [];
  normalNoOfDaysArray: any = [];
  dateTimeDifferntFormat: any;
  validationForm: FormGroup;
  formControls;

  constructor(
    private firestore: AngularFirestore,
    private toastrService: ToastrService,
    private billingService: BillingService
  ) {
    var today = new Date();
    this.dateTime =
      today.getFullYear() +
      "-" +
      ("0" + (today.getMonth() + 1)).slice(-2) +
      "-" +
      ("0" + today.getDate()).slice(-2);

    this.dateTime = console.log("diff", this.dateTimeDifferntFormat);
    this.userId = localStorage.getItem("user_id");
    this.userName = localStorage.getItem("user_name");

    this.validationForm = new FormGroup({
      connectionId: new FormControl({ value: "", disabled: true }),
      ownerName: new FormControl({ value: this.ownerName, disabled: true }),
      houseNo: new FormControl({ value: this.houseNo, disabled: true }),
      phoneNumber: new FormControl({ value: this.phoneNumber, disabled: true }),
      address: new FormControl({ value: this.address, disabled: true }),
      meterNo: new FormControl({ value: this.meterNo, disabled: true }),
      wardNo: new FormControl({ value: this.ward, disabled: true }),
      tariff: new FormControl({ value: this.tariff, disabled: true }),
     // subDivision: new FormControl({ value: this.subDivision, disabled: true }),
      userName: new FormControl({ value: this.userName, disabled: true }),
      previousStatus: new FormControl({
        value: this.previousStatus,
        disabled: true 
      }),
      //billDateTime : new FormControl({value: this.dateTime, disabled: true} ),
      billDateTime: new FormControl({ value: this.dateTime }),
      // billNo: new FormControl(""),
      billNo: new FormControl("", Validators.required),
      //noOfDays : new FormControl({value: this.noOfDays, disabled: true}),
      noOfDays: new FormControl(""),
      status: new FormControl("normal", Validators.required),
      previousReading: new FormControl({
        value: this.previousReading,
        disabled: true 
      }),
      presentReading: new FormControl(
        { value: this.presentReadingValue},
        Validators.required
      ),
      consumption: new FormControl({ value: this.consumption }),
      OBA: new FormControl({ value: this.OBA }),
      demand: new FormControl({ value: this.demand }),
      previousBillDate: new FormControl({ value: this.readingDate }),
      waterCharge: new FormControl({ value: this.waterCharge }),
      serviceCharge: new FormControl({ value: this.serviceCharge }),
      penalty: new FormControl({ value: this.penalty }),
      adjustment: new FormControl({ value: this.adjustment }),
      CBA: new FormControl({ value: this.CBA }),
      total: new FormControl({ value: this.total }),
      dueDate: new FormControl({ value: this.dueDate }),
      remarks: new FormControl(""),
    });

    if(this.userId == '46'){
      this.validationForm.get("previousReading").enable();
      this.validationForm.get("previousStatus").enable();
      this.validationForm.get("status").enable();
      this.validationForm.get("presentReading").enable();
    }
  }

  async ngOnInit() {
    await this.getConnectionDetails();

    /*  setTimeout(() => { */
    console.log("billDetailsArray", await this.billDetailsArray);

    /* }, 1000); */
  }

  onClose(close) {
    this.onCloseModal.emit("close");
  }

  getConnectionDetails() {
    console.log(
      "billDetailsData",
      this.billDetailsData,
      this.billDetailsData.rrno
    );
    this.noOfDays = this.billDetailsData.noOfDays;

    this.billingService
      .searchRRono(this.billDetailsData.rrno)
      .then((response) => {
        if (response.code == 200) {
          console.log("result", response.data);

          var connection = response.data["connectionResult"];
          if (connection[0].connectionStatus == "Live") {
            this.connectionId = this.billDetailsData.rrno;
            this.ownerName = connection[0].name;
            this.houseNo = connection[0].doorNumber;
            this.address = connection[0].address;
            this.meterNo = connection[0].meterNumber;
            this.ward = connection[0].ward;
            this.tariff = connection[0].tariff;
           // this.subDivision = '';
            this.phoneNumber = connection[0].phoneNumber;
            this.serviceDate = connection[0].connectionDate;
            this.connectionType = connection[0].consumerConnectionStatusType;
            this.meterConstant = connection[0].meterConstant;

            var searchData = {
              rrno: this.billDetailsData.rrno,
              previousBillDate: this.billDetailsData.previousBillDate,
            };

            console.log("search Data", searchData);
            this.billingService
              .getLedgerByRrnoWithBillDate(searchData)
              .then(async (response) => {
                console.log("respnose on sear", response.data);
                if ((await response.code) == 200) {
                  response.data.forEach((element) => {
                    console.log("test1");

                    this.billDetailsArray.push(element);
                  });
                  console.log("test2");
                  if (
                    (await this.billDetailsArray[0].status.toLowerCase()) ==
                    "mnr"
                  ) {
                    this.validationForm.controls.status.setValue("mnr");
                    if(this.userId == '46'){
                      this.validationForm.get("previousReading").enable();
                      this.validationForm.get("previousStatus").enable();
                      this.validationForm.get("status").enable();
                      this.validationForm.get("presentReading").enable();
                    }else{
                        this.validationForm.get("status").disable();
                        this.validationForm.get("presentReading").disable();
                    }
                   
                  } else if (
                    this.billDetailsArray[0].status.toLowerCase() == "dc"
                  ) {
                    this.validationForm.controls.status.setValue("dc");
                    if(this.userId == '46'){
                      this.validationForm.get("previousReading").enable();
                      this.validationForm.get("previousStatus").enable();
                      this.validationForm.get("status").enable();
                      this.validationForm.get("presentReading").enable();
                    }else{
                      this.validationForm.get("status").disable();
                    }
                  
                  }
                  this.readingDate = this.billDetailsData.previousBillDate;
                  this.previousStatus = this.billDetailsArray[0].status;
                  this.consumption = this.billDetailsData.consumption;
                  this.OBA = this.billDetailsData.OBA;
                  this.waterCharge = this.billDetailsData.waterCharge;
                  this.serviceCharge = this.billDetailsData.serviceCharge;
                  this.penalty = this.billDetailsData.penalty;
                  this.demand = this.billDetailsData.demand;
                  this.CBA = this.billDetailsData.CBA;
                  this.adjustment = this.billDetailsData.adjustment;
                  this.total = this.billDetailsData.total;
                  this.dueDate = this.billDetailsData.dueDate;

                  this.validationForm.setValue({
                    connectionId: this.connectionId,
                    ownerName: this.ownerName,
                    houseNo: this.houseNo,
                    phoneNumber: this.phoneNumber,
                    address: this.address,
                    meterNo: this.meterNo,
                    wardNo: this.ward,
                    tariff: this.tariff,
                    subDivision: this.subDivision,
                    userName: this.userName,
                    previousStatus: this.previousStatus,

                    billDateTime: moment(
                      this.billDetailsData.billDateTime
                    ).format("YYYY-MM-DDTHH:mm:ss"),
                    billNo: this.billDetailsData.billNo,
                    //noOfDays :  this.noOfDays,
                    noOfDays: this.billDetailsData.noOfDays,
                    status: this.billDetailsData.status.toLowerCase(),
                    previousReading: this.billDetailsData.previousReading,
                    presentReading: this.billDetailsData.presentReading,
                    consumption: this.consumption,
                    OBA: this.OBA,
                    demand: this.demand,
                    previousBillDate: this.readingDate,
                    waterCharge: this.waterCharge,
                    serviceCharge: this.serviceCharge,
                    penalty: this.penalty,
                    adjustment: this.adjustment,
                    CBA: this.CBA,
                    total: this.total,
                    dueDate: this.dueDate,
                    remarks: this.billDetailsData.remarks,
                  });
                }
              });

            this.meterChangeDetailsArray =
              response.data["meterReconnectionResult"];
            if (this.meterChangeDetailsArray.length > 0) {
              console.log(
                "this.meterChangeDetailsArray",
                this.meterChangeDetailsArray
              );

              this.meterFixedEnterDate =
                this.meterChangeDetailsArray[0].enteredDate;

              this.meterFixedDate =
                this.meterChangeDetailsArray[0].meterFixedDate;

              this.billingService
                .getMeterFixedDetails(
                  this.billDetailsData.rrno,
                  this.meterFixedDate
                )
                .then((response) => {
                  this.beforeMeterFixedBillDetailsArray =
                    response.data["beforeMeterFixedBillDetails"];

                  if (this.beforeMeterFixedBillDetailsArray.length > 0) {
                    console.log(
                      "beforeMeterFixedBillDetailsArray",
                      this.meterChangeDetailsArray
                    );

                    this.previousStatusOfMeterFixed =
                      this.beforeMeterFixedBillDetailsArray[0].status;
                    this.previousReadingDateOfMeterFixed =
                      this.beforeMeterFixedBillDetailsArray[0].billDateTime;

                    if (
                      this.meterFixedEnterDate >=
                        this.billDetailsData.previousbillDate &&
                      this.meterFixedEnterDate <
                        this.billDetailsData.billDateTime
                    ) {
                      //this.previousReading = this.meterChangeDetailsArray[0].fr;
                      this.validationForm.get("status").enable();
                      this.validationForm.controls.status.setValue("normal");
                      this.validationForm.get("presentReading").enable();
                    }
                  }

                  response.data["inBetweenAdjustmentIssuedDetails"].forEach(
                    (element) => {
                      this.inBetweenAdjustmentConsumptionArray.push(
                        element["consumption"]
                      );
                      this.inBetweenAdjustmentWaterChargeArray.push(
                        element["waterCharge"]
                      );
                    }
                  );
                });
            }
          } else {
            this.toastrService.error("Meter Disconnected", "Error!", {
              timeOut: 8000,
            });
          }
        }
      });

    /* var connectionDetail = this.firestore.collection('/connections', ref => ref.where('RRNO', '==', this.billDetailsData.RRNO));
    connectionDetail.valueChanges().subscribe((con) => {
      if(con.length > 0){
        
        if(con[0]['consumerConnectionStatusType'] == 'Live'){
       
          this.connectionDetail = this.firestore.collection('/connections', ref => ref.where('RRNO', '==', this.billDetailsData.RRNO));
          this.connectionDetail.valueChanges().subscribe((data) => {
            console.log("connections detail", data);
            data.forEach(e => {
              this.connectionId = e.RRNO;
              this.ownerName = e.name;
              this.houseNo = e.doorNo;
              this.address = e.streetName;
              this.meterNo = e.meterSerialNumber;
              this.ward = e.ward;
              this.tariff = e.tariff;
              this.subDivision = e.subDivision;
              this.phoneNumber = e.phoneNumber;
              this.serviceDate = e.serviceDate;
              this.connectionType = e.consumerConnectionStatusType;
              this.meterConstant = e.meterConstant;
            })
          });

          

          this.billDetails = this.firestore.collection('/ledger', ref => ref.where('connectionId', '==', this.billDetailsData.RRNO).where('billDateTime', '==', this.billDetailsData.previousBillDate));
          this.billDetails.valueChanges().subscribe((data) => {
            data.forEach(element => {
              
              this.billDetailsArray.push(element);
            });

            if(this.billDetailsArray[0].status.toLowerCase() == 'mnr'){
              this.validationForm.controls.status.setValue('mnr');
              this.validationForm.get('status').disable();
              this.validationForm.get('presentReading').disable();
         
           }
           else if(this.billDetailsArray[0].status.toLowerCase() == 'dc'){
             this.validationForm.controls.status.setValue('dc');
             this.validationForm.get('status').disable();
           }
          });
          
          this.meterChangeDetails = this.firestore.collection('/meter-change', ref => ref.where('rrno', '==', this.billDetailsData.RRNO).orderBy('enteredDate', 'desc'));
             this.meterChangeDetails.valueChanges().subscribe((data) => {
               data.forEach(element => {
                 console.log("element",element);
                 this.meterChangeDetailsArray.push(element);
               });
       
               
       
               if(this.meterChangeDetailsArray.length > 0){
                 this.meterFixedEnterDate = this.meterChangeDetailsArray[0].enteredDate;
               
                 this.meterFixedDate = this.meterChangeDetailsArray[0].meterFixedDate;
                 this.beforeMeterFixedBillDetails = this.firestore.collection('/ledger', ref => ref.where('connectionId', '==', this.billDetailsData.RRNO).where('billDateTime', '<=' ,this.meterFixedDate).orderBy('billDateTime', 'desc'));
                 this.beforeMeterFixedBillDetails.valueChanges().subscribe((data)=>{
                   data.forEach(element => {
                     this.beforeMeterFixedBillDetailsArray.push(element);
                   });
       
                   if(this.beforeMeterFixedBillDetailsArray.length > 0){
                     console.log("beforeMeterFixedBillDetailsArray", this.meterChangeDetailsArray);
                     
                     this.previousStatusOfMeterFixed = this.beforeMeterFixedBillDetailsArray[0].status;
                     this.previousReadingDateOfMeterFixed = this.beforeMeterFixedBillDetailsArray[0].billDateTime;
                     if(this.meterFixedEnterDate >= this.billDetailsData.previousbillDateTime && this.meterFixedEnterDate < this.billDetailsData.billDateTime){  
                       this.validationForm.get('status').enable();
                       this.validationForm.controls.status.setValue('normal');
                       this.validationForm.get('presentReading').enable();
                     }
                   }
       
                   this.inBetweenAdjustmentDetailsIssuedDetails = this.firestore.collection('/ledger', ref => ref.where('connectionId', '==', this.billDetailsData.RRNO).where('billDateTime', '>' ,this.meterFixedDate).where('billDateTime', '<' ,this.dateTime).orderBy('billDateTime', 'desc'));
                   this.inBetweenAdjustmentDetailsIssuedDetails.valueChanges().subscribe((data)=>{
                     
                     data.forEach(element => {
                       this.inBetweenAdjustmentConsumptionArray.push(element['consumption']);
                       this.inBetweenAdjustmentWaterChargeArray.push(element['waterCharge']);
                     });
                   })
                 })
               }
             })
        }
        else{
          this.toastrService.error("Meter Disconnected", "Error!", {
            timeOut: 8000
          });
        }
      }
    }) */
  }

  calculate() {
    console.log("pp", this.validationForm.value.status, this.previousStatus);
    //this.presentReadingValue = this.validationForm.value.presentReading;
    this.isVisible = true;
    this.btnCalclate = false;
    this.consumption = 0;
    this.sumOfWaterCharges = 0;
    if (this.noOfDays >= 60) {
      console.log("inside no of daya");

      this.serviceCharge = Math.floor(this.noOfDays / 30) * 5;
    }

    console.log(
      "testing",
      this.presentReadingValue,
      this.previousStatusOfMeterFixed,
      this.validationForm.value.status,
      this.meterFixedEnterDate,
      this.readingDate,
      this.consumption
    );

    if (
      this.validationForm.value.status == "normal" &&
      this.meterFixedEnterDate >= this.billDetailsData.previousbillDateTime &&
      this.meterFixedEnterDate < this.billDetailsData.billDateTime
    ) {
      console.log("meter changed", this.meterFixedDate);
      this.sumOfAdjustmentConsumption = 0;
      this.serviceCharge = 5;
      const a = new Date(this.meterFixedDate),
        b = new Date(this.billDetailsData.billDateTime);
      var noOfDaysAfterMeterfixed = this.dateDiffInDays(a, b);
      this.noOfDays = noOfDaysAfterMeterfixed;
      var presentReading = this.validationForm.value.presentReading;
      this.consumption =
        (presentReading - this.validationForm.value.previousReading) * 1000;
      var waterChargeAfterMeterfixedDate = this.slabsForMeterChange(
        this.consumption
      );
      // console.log("noOfDaysAfterMeterfixed", noOfDaysAfterMeterfixed, this.consumption, waterChargeAfterMeterfixedDate);

      if (waterChargeAfterMeterfixedDate > 0) {
        //console.log("beforeMeterFixed calculation",this.previousReadingDateOfMeterFixed,this.meterFixedDate, this.inBetweenAdjustmentConsumptionArray)
        const c = new Date(this.previousReadingDateOfMeterFixed),
          d = new Date(this.meterFixedDate);
        var noOfDayBeforeMeterFixed = this.dateDiffInDays(c, d);
        this.noOfDays = noOfDayBeforeMeterFixed;
        this.consumption =
          (this.beforeMeterFixedBillDetailsArray[0].consumption /
            this.beforeMeterFixedBillDetailsArray[0].noOfDays) *
          noOfDayBeforeMeterFixed;
        var waterChargeBeforeMeterfixedDate = 0;
        if (this.consumption > 0) {
          waterChargeBeforeMeterfixedDate = this.slabsForMeterChange(
            this.consumption
          );
        }

        // console.log("noOfDaysBeforeMeterfixed", noOfDayBeforeMeterFixed, this.consumption, waterChargeBeforeMeterfixedDate);

        this.noOfDays = noOfDaysAfterMeterfixed + noOfDayBeforeMeterFixed;
        this.waterCharge =
          waterChargeAfterMeterfixedDate + waterChargeBeforeMeterfixedDate;
        // console.log("sas",waterChargeAfterMeterfixedDate, waterChargeBeforeMeterfixedDate);
        if (this.inBetweenAdjustmentConsumptionArray.length > 0) {
          this.inBetweenAdjustmentConsumptionArray.forEach((consumption) => {
            this.sumOfAdjustmentConsumption =
              this.sumOfAdjustmentConsumption + Number(consumption);
          });

          this.inBetweenAdjustmentWaterChargeArray.forEach((consumption) => {
            this.sumOfAdjustmentWaterCharge =
              this.sumOfAdjustmentWaterCharge + Number(consumption);
          });
        }

        this.consumption = Math.round(
          (this.validationForm.value.presentReading -
            this.validationForm.value.previousReading) *
            1000 +
            (this.beforeMeterFixedBillDetailsArray[0].consumption /
              this.beforeMeterFixedBillDetailsArray[0].noOfDays) *
              noOfDayBeforeMeterFixed -
            this.sumOfAdjustmentConsumption
        );
        this.adjustment = this.sumOfAdjustmentWaterCharge;
        this.demand =
          Number(this.waterCharge) +
          Number(this.serviceCharge) +
          Number(this.penalty) -
          Number(this.sumOfAdjustmentWaterCharge);
        this.OBA = this.billDetailsData.OBA;
        this.CBA =
          Number(this.OBA) +
          Number(this.waterCharge) +
          Number(this.serviceCharge) -
          Number(this.sumOfAdjustmentWaterCharge);
        var date = new Date(this.billDetailsData.billDateTime);
        var newdate = new Date(date);
        newdate.setDate(newdate.getDate() + 15);
        this.dueDate =
          newdate.getFullYear() +
          "-" +
          ("0" + (newdate.getMonth() + 1)).slice(-2) +
          "-" +
          ("0" + newdate.getDate()).slice(-2);
        this.total = this.OBA + this.demand;
      }
    } else {
      if (this.validationForm.value.status == "dc") {
        this.consumption = 0;
        this.slabs(this.consumption);
      } else if (this.validationForm.value.status == "do") {
        console.log(
          "abnormal to do cons",
          this.consumption,
          this.billDetailsData.previousReading,
          this.validationForm.value.presentReading
        );

        if (this.billDetailsArray[0].status.toLowerCase() == "normal") {
          this.consumption = Math.round(
            (10000 -
              this.billDetailsData.previousReading +
              this.validationForm.value.presentReading) *
              1000
          );
          this.slabs(this.consumption);
        } else {
          var keepGoing = true;
          this.waterChargeArray = [];
          this.abnormalConsumptionArray = [];
          this.sumOfWaterCharges = 0;
          this.lastNormalBillDate = [];
          this.adjustment = 0;
          this.sumOfAbnormalConsumption = 0;

          this.consumption = Math.round(
            (10000 -
              this.billDetailsData.previousReading +
              this.validationForm.value.presentReading) *
              1000
          );
          //console.log("abnormal to do", this.previousReading, this.presentReadingValue, this.consumption);
          var billData = {
            rrno: this.connectionId,
            billDateTime: this.billDetailsData.billDateTime,
          };
          this.billingService
            .getBillByRrnoWithBillDateTime(billData)
            .then((response) => {
              if (response.code == 200) {
                console.log("getBillByRrnoWithBillDateTime", response.data);

                var billings = response.data;
                billings.forEach((e) => {
                  if (e["status"].toLowerCase() == "normal" && keepGoing) {
                    if (this.lastNormalBillDate.length < 1) {
                      this.lastNormalBillDate.push(e["billDateTime"]);
                    }
                  }

                  if (e["status"].toLowerCase() == "normal" && keepGoing) {
                    keepGoing = false;
                  } else if (
                    e["status"].toLowerCase() != "normal" &&
                    keepGoing
                  ) {
                    this.abnormalConsumptionArray.push(e["consumption"]);
                    this.waterChargeArray.push(e["waterCharge"]);
                  }
                });

                this.abnormalConsumptionArray.forEach((consumption) => {
                  this.sumOfAbnormalConsumption =
                    this.sumOfAbnormalConsumption + Number(consumption);
                });

                //console.log("abnormalConsumptionArray", this.abnormalConsumptionArray, this.sumOfAbnormalConsumption);

                this.waterChargeArray.forEach((charge) => {
                  this.sumOfWaterCharges =
                    this.sumOfWaterCharges + Number(charge);
                });

                this.adjustment = this.sumOfWaterCharges;
                this.lastNormalBillDate.forEach((lastNormalBilldate) => {
                  const a = new Date(lastNormalBilldate),
                    b = new Date(this.dateTime);
                  this.noOfDays = this.dateDiffInDays(a, b);
                });
                var consumption =
                  (10000 -
                    this.previousReading +
                    this.validationForm.value.presentReading) *
                    1000 -
                  this.sumOfAbnormalConsumption;
                this.slabs(consumption);
              }
            });
        }
      } else if (
        this.validationForm.value.status == "normal" &&
        this.billDetailsArray[0].status.toLowerCase() == "normal"
      ) {
        console.log("no of days", this.noOfDays);

        var presentReading = this.validationForm.value.presentReading;
        this.consumption = Math.round(
          (presentReading - this.billDetailsData.previousReading) * 1000
        );
        this.slabs(this.consumption);
      } else if (
        this.validationForm.value.status == "normal" &&
        this.billDetailsArray[0].status.toLowerCase() != "normal"
      ) {
        console.log("comes here");
        this.abnormalConsumptionArray = [];
        this.waterChargeArray = [];
        this.sumOfAbnormalConsumption = 0;
        this.sumOfWaterCharges = 0;
        var keepGoing = true;
        this.noOfDays = 0;
        this.serviceCharge = 5;

        var billData = {
          rrno: this.connectionId,
          billDateTime: this.billDetailsData.billDateTime,
        };

        this.billingService
          .getBillByRrnoWithBillDateTime(billData)
          .then((response) => {
            if (response.code == 200) {
              var billings = response.data;
              billings.forEach((e) => {
                if (e["status"].toLowerCase() == "normal" && keepGoing) {
                  if (this.lastNormalBillDate.length < 1) {
                    this.lastNormalBillDate.push(e["billDateTime"]);
                  }
                }

                if (e["status"].toLowerCase() == "normal" && keepGoing) {
                  keepGoing = false;
                } else if (e["status"].toLowerCase() != "normal" && keepGoing) {
                  this.abnormalConsumptionArray.push(e["consumption"]);
                  this.waterChargeArray.push(e["waterCharge"]);
                }
              });

              console.log(
                "last normal bill date",
                this.lastNormalBillDate,
                this.abnormalConsumptionArray,
                this.waterChargeArray
              );

              if (this.lastNormalBillDate.length > 0) {
                this.lastNormalBillDate.forEach((lastNormalBilldate) => {
                  const a = new Date(lastNormalBilldate),
                    b = new Date(this.billDetailsData.billDateTime);
                  this.noOfDays = this.dateDiffInDays(a, b);
                  // this.readingDate = lastNormalBilldate;
                  console.log(
                    "number of days for abnormal connections",
                    this.noOfDays,
                    lastNormalBilldate,
                    this.dateTime,
                    keepGoing
                  );
                });
              } else {
                const a = new Date(this.serviceDate),
                  b = new Date(this.billDetailsData.billDateTime);
                this.noOfDays = this.dateDiffInDays(a, b);
                this.readingDate = this.serviceDate;
              }

              this.abnormalConsumptionArray.forEach((consumption) => {
                this.sumOfAbnormalConsumption =
                  this.sumOfAbnormalConsumption + Number(consumption);
              });
              this.abnormalNoOfDaysArray.forEach((n) => {
                this.sumOfAbnormalDays = this.sumOfAbnormalDays + Number(n);
              });

              this.waterChargeArray.forEach((charge) => {
                this.sumOfWaterCharges =
                  this.sumOfWaterCharges + Number(charge);
                this.adjustment = this.sumOfWaterCharges;
              });

              console.log(
                "Abnormal consumption array",
                this.abnormalConsumptionArray
              );
              this.consumption = Math.round(
                (this.validationForm.value.presentReading -
                  this.billDetailsData.previousReading) *
                  1000
              );
              //this.consumption = Math.round((this.presentReadingValue -this.previousReading)*1000);
              var consumption =
                (this.validationForm.value.presentReading -
                  this.billDetailsData.previousReading) *
                  1000 -
                this.sumOfAbnormalConsumption;
              console.log(
                "sum of abnormalConsumption",
                this.sumOfAbnormalConsumption
              );
              setTimeout(() => {
                this.slabs(consumption);
              }, 2000);
            }
          });
      } else if (this.validationForm.value.status != "normal") {
        console.log("abnormal 1");

        this.normalConsumptionArray = [];
        this.normalNoOfDaysArray = [];
        this.sumOfNormalConsumption = 0;
        this.sumOfNormalDays = 0;

        billData = {
          rrno: this.connectionId,
          billDateTime: this.billDetailsData.billDateTime,
        };
        this.billingService
          .getBillByRrnoWithBillDateTime(billData)
          .then((response) => {
            if (response.code == 200) {
              var consumption = response.data;
              consumption.forEach((e) => {
                if (e["consumption"] > "0") {
                  if (
                    this.normalConsumptionArray.length < 3 &&
                    this.normalNoOfDaysArray.length < 3
                  ) {
                    this.normalConsumptionArray.push(e["consumption"]);
                    this.normalNoOfDaysArray.push(e["noOfDays"]);
                  }
                  //this.sumOfNormalConsumption. = this.sumOfNormalConsumption + Number(e['consumption']);
                  //this.sumOfNormalDays = this.sumOfNormalDays + Number(e['noOfDays'])
                }
              });

              console.log(
                "3 normal consumption array",
                this.normalConsumptionArray,
                this.sumOfNormalConsumption
              );

              this.normalConsumptionArray.forEach((c) => {
                this.sumOfNormalConsumption =
                  this.sumOfNormalConsumption + Number(c);
              });

              this.normalNoOfDaysArray.forEach((n) => {
                this.sumOfNormalDays = this.sumOfNormalDays + Number(n);
              });

              setTimeout(() => {
                this.consumption = Math.round(
                  (this.sumOfNormalConsumption / this.sumOfNormalDays) *
                    this.noOfDays
                );
                if (isNaN(this.consumption)) this.consumption = 0;
                console.log(
                  "sumOfNormalConsumption 4",
                  this.sumOfNormalConsumption,
                  this.sumOfNormalDays,
                  this.noOfDays,
                  this.consumption
                );
                this.slabs(this.consumption);
              }, 2000);
            }
          });
      }
    }
  }

  slabs(consumption) {
    console.log("slab inside consumption", consumption, this.demand);

    // this.consumption = consumption;
    switch (this.tariff.toLowerCase()) {
      case "residential":
        this.tariffConsumption(
          (this.fixedCharge = 56),
          (this.slab1 = 9),
          (this.slab2 = 11),
          (this.slab3 = 13)
        );
        break;
      case "non-domestic":
        this.tariffConsumption(
          (this.fixedCharge = 112),
          (this.slab1 = 18),
          (this.slab2 = 22),
          (this.slab3 = 26)
        );
        break;
      case "commercial":
        this.tariffConsumption(
          (this.fixedCharge = 224),
          (this.slab1 = 36),
          (this.slab2 = 44),
          (this.slab3 = 52)
        );
        break;
      case "industrial":
        this.tariffConsumption(
          (this.fixedCharge = 224),
          (this.slab1 = 36),
          (this.slab2 = 44),
          (this.slab3 = 52)
        );
        break;
      case "panchayath":
        if (this.noOfDays == 30) {
          this.consumptionFor30DaysPanchayath(this.consumption);
        } else {
          var perDayConsumption = this.consumption / this.noOfDays;
          var for30DaysConsumption = perDayConsumption * 30;
          var charge =
            this.consumptionFor30DaysPanchayath(for30DaysConsumption);
          var perDayCharge = charge / 30;
          var forNdaysCharge = perDayCharge * this.noOfDays;
          this.waterCharge = Math.round(forNdaysCharge);
        }

        break;
      case "tebma shipping yard":
        if (this.noOfDays == 30) {
          this.consumptionFor30DaysTebma(this.consumption);
        } else {
          var perDayConsumption = this.consumption / this.noOfDays;
          var for30DaysConsumption = perDayConsumption * 30;
          var charge = this.consumptionFor30DaysTebma(for30DaysConsumption);
          var perDayCharge = charge / 30;
          var forNdaysCharge = perDayCharge * this.noOfDays;
          this.waterCharge = Math.round(forNdaysCharge);
        }
        break;
    }

    console.log("this.sumOfWaterCharges", this.sumOfWaterCharges);

    // this.demand = this.waterCharge + this.serviceCharge + this.penalty  + this.previousCBA - this.sumOfWaterCharges;
    this.demand =
      Number(this.waterCharge) +
      Number(this.serviceCharge) +
      Number(this.penalty) -
      Number(this.sumOfWaterCharges);

    // console.log("after tariff",this.waterCharge, this.demand );
    this.OBA = this.billDetailsData.OBA;
    //var taotal =
    this.CBA =
      Number(this.OBA) +
      Number(this.waterCharge) +
      Number(this.serviceCharge) -
      Number(this.sumOfWaterCharges);
    var date = new Date(this.dateTime);
    var newdate = new Date(date);

    newdate.setDate(newdate.getDate() + 15);
    this.dueDate =
      newdate.getFullYear() +
      "-" +
      ("0" + (newdate.getMonth() + 1)).slice(-2) +
      "-" +
      ("0" + newdate.getDate()).slice(-2);
    console.log("someFormattedDate", this.dueDate);
    this.total = Number(this.OBA) + Number(this.demand);
    this.consumption = consumption;
    return this.waterCharge;
  }

  slabsForMeterChange(consumption) {
    console.log("slab inside consumption", consumption);

    this.consumption = consumption;
    switch (this.tariff.toLowerCase()) {
      case "residential":
        this.tariffConsumption(
          (this.fixedCharge = 56),
          (this.slab1 = 9),
          (this.slab2 = 11),
          (this.slab3 = 13)
        );
        break;
      case "non-domestic":
        this.tariffConsumption(
          (this.fixedCharge = 112),
          (this.slab1 = 18),
          (this.slab2 = 22),
          (this.slab3 = 26)
        );
        break;
      case "commercial":
        this.tariffConsumption(
          (this.fixedCharge = 224),
          (this.slab1 = 36),
          (this.slab2 = 44),
          (this.slab3 = 52)
        );
        break;
      case "industrial":
        this.tariffConsumption(
          (this.fixedCharge = 224),
          (this.slab1 = 36),
          (this.slab2 = 44),
          (this.slab3 = 52)
        );
        break;
      case "panchayath":
        if (this.noOfDays == 30) {
          this.consumptionFor30DaysPanchayath(this.consumption);
        } else {
          var perDayConsumption = this.consumption / this.noOfDays;
          var for30DaysConsumption = perDayConsumption * 30;
          var charge =
            this.consumptionFor30DaysPanchayath(for30DaysConsumption);
          var perDayCharge = charge / 30;
          var forNdaysCharge = perDayCharge * this.noOfDays;
          this.waterCharge = Math.round(forNdaysCharge);
        }

        break;
      case "tebma-shipping-yard":
        if (this.noOfDays == 30) {
          this.consumptionFor30DaysTebma(this.consumption);
        } else {
          var perDayConsumption = this.consumption / this.noOfDays;
          var for30DaysConsumption = perDayConsumption * 30;
          var charge = this.consumptionFor30DaysTebma(for30DaysConsumption);
          var perDayCharge = charge / 30;
          var forNdaysCharge = perDayCharge * this.noOfDays;
          this.waterCharge = Math.round(forNdaysCharge);
        }
        break;
    }
    return this.waterCharge;
  }

  tariffConsumption(fixedCharge, slab1, slab2, slab3) {
    console.log("fixed charge", fixedCharge);
    if (this.noOfDays == 30) {
      this.consumptionFor30Days(
        this.consumption,
        fixedCharge,
        slab1,
        slab2,
        slab3
      );
    } else {
      var perDayConsumption = this.consumption / this.noOfDays;
      if (isNaN(perDayConsumption)) perDayConsumption = 0;
      var for30DaysConsumption = perDayConsumption * 30;
      if (isNaN(for30DaysConsumption)) for30DaysConsumption = 0;
      var charge = this.consumptionFor30Days(
        for30DaysConsumption,
        fixedCharge,
        slab1,
        slab2,
        slab3
      );
      var perDayCharge = charge / 30;
      var forNdaysCharge = perDayCharge * this.noOfDays;
      if (isNaN(forNdaysCharge)) forNdaysCharge = 0;
      this.waterCharge = Math.round(forNdaysCharge);
      console.log("water chargeqq", charge, forNdaysCharge, this.waterCharge);
    }
  }

  consumptionFor30Days(consumption, fixedCharge, slab1, slab2, slab3) {
    if (consumption <= 8000) {
      this.waterCharge = fixedCharge;
      return fixedCharge;
    } else if (consumption > 8000 && consumption <= 15000) {
      var minimumWaterCharge = fixedCharge;
      var remainingConsumption = consumption - 8000;
      var remainingCharge = (remainingConsumption * slab1) / 1000;
      //this.waterCharge = minimumWaterCharge + remainingCharge;
      this.waterCharge = Math.round(minimumWaterCharge + remainingCharge);
      return minimumWaterCharge + remainingCharge;
    } else if (consumption > 15000 && consumption <= 25000) {
      var minimumWaterCharge = fixedCharge + 7 * slab1;
      var remainingConsumption = consumption - 15000;
      var remainingCharge = (remainingConsumption * slab2) / 1000;
      //this.waterCharge = minimumWaterCharge + remainingCharge;
      this.waterCharge = Math.round(minimumWaterCharge + remainingCharge);
      return minimumWaterCharge + remainingCharge;
    } else if (consumption > 25000) {
      var minimumWaterCharge = fixedCharge + 7 * slab1 + 10 * slab2;
      var remainingConsumption = consumption - 25000;
      var remainingCharge = (remainingConsumption * slab3) / 1000;
      // this.waterCharge = minimumWaterCharge + remainingCharge;
      this.waterCharge = Math.round(minimumWaterCharge + remainingCharge);
      return minimumWaterCharge + remainingCharge;
    }
  }

  consumptionFor30DaysPanchayath(consumption) {
    if (consumption <= 5000000) {
      this.waterCharge = Math.round((consumption * 4) / 1000);
      return (consumption * 4) / 1000;
    } else {
      var minimumWaterCharge = 20000;
      var remainingConsumption = consumption - 5000000;
      var remainingCharge = (remainingConsumption * 7.5) / 1000;
      //this.waterCharge = minimumWaterCharge + remainingCharge;
      this.waterCharge = Math.round(minimumWaterCharge + remainingCharge);
      return minimumWaterCharge + remainingCharge;
    }
  }

  consumptionFor30DaysTebma(consumption) {
    if (consumption <= 30000) {
      this.waterCharge = Math.round((consumption * 20) / 1000);
      return (consumption * 20) / 1000;
    } else if (consumption > 30000 && consumption <= 91000) {
      var minimumWaterCharge = 600;
      var remainingConsumption = consumption - 30000;
      var remainingCharge = (remainingConsumption * 30) / 1000;
      //this.waterCharge = minimumWaterCharge + remainingCharge;
      this.waterCharge = Math.round(minimumWaterCharge + remainingCharge);
      return minimumWaterCharge + remainingCharge;
    } else {
      var minimumWaterCharge = 600 + 1830;
      var remainingConsumption = consumption - 61000;
      var remainingCharge = (remainingConsumption * 50) / 1000;
      //this.waterCharge = minimumWaterCharge + remainingCharge;
      this.waterCharge = Math.round(minimumWaterCharge + remainingCharge);
      return minimumWaterCharge + remainingCharge;
    }
  }

  dateDiffInDays(a, b) {
    const _MS_PER_DAY = 1000 * 60 * 60 * 24;
    const utc1 = Date.UTC(a.getFullYear(), a.getMonth(), a.getDate());
    const utc2 = Date.UTC(b.getFullYear(), b.getMonth(), b.getDate());
    return Math.floor((utc2 - utc1) / _MS_PER_DAY);
  }

  onChangePresentReading() {
    this.btnCalclate = true;
  }

  onStatusSelect(event) {
    this.validationForm.get("presentReading").enable();

    if (
      this.validationForm.value.status == "do" ||
      this.validationForm.value.status == "normal"
    ) {
      //this.presentReadingValue = "";
      this.validationForm.controls.presentReading.setValue("");
    } else {
      this.validationForm.controls.presentReading.setValue(
        this.billDetailsData.previousReading
      );
      if(this.userId == '46'){
     
          this.validationForm.get("previousReading").enable();
                      this.validationForm.get("previousStatus").enable();
                      this.validationForm.get("status").enable();
                      this.validationForm.get("presentReading").enable();
      }else{
        this.validationForm.get("presentReading").disable();
    }
    }

    this.consumption = 0;
    this.OBA = 0;
    this.CBA = 0;
    this.demand = 0;
    this.waterCharge = 0;
    this.penalty = 0;
    this.adjustment = 0;
    this.btnCalclate = true;
  }

  onSubmit() {
    this.submitted = true;

    this.validationForm.get("status").enable();
    this.validationForm.get("presentReading").enable();

    var bill = {
      rrno: this.connectionId,
      billDateTime: moment(this.validationForm.value.billDateTime, "YYYY-MM-DDTHH:mm:ss").format(
        "YYYY-MM-DD HH:mm:ss"
      ),
      billNo: this.validationForm.value.billNo,
      noOfDays: this.validationForm.value.noOfDays,
      status: this.validationForm.value.status.toUpperCase(),
      previousReading: this.validationForm.value.previousReading,
      presentReading: this.validationForm.value.presentReading,
      consumption: this.consumption.toString(),
      oba: this.OBA.toString(),
      demand: this.demand.toString(),
      waterCharge: this.waterCharge.toString(),
      serviceCharge: this.serviceCharge.toString(),
      penalty: this.penalty.toString(),
      adjustment: this.adjustment.toString(),
      cba: this.CBA.toString(),
      dueDate: this.validationForm.value.dueDate,
      remarks: this.validationForm.value.remarks,
      previousBillDate: this.validationForm.value.previousBillDate,
      userId: this.userId,
      total: this.validationForm.value.total.toString(),
    };
    bill["id"] = this.billDetailsData.id;
    this.billingService.updateBill(bill).then((response) => {
      //console.log("response", response);

      if (response.code == 200) {
        this.submitted = false;
        this.isVisible = false;
        this.btnCalclate = false;
        this.toastrService.success(response.message, "Success!");
        this.onCloseModal.emit("close");
      } else {
        this.toastrService.error(response.message, "Error!");
      }
    });

    /* console.log("inside", this.consumption);
    this.firestore.collection('ledger').doc(this.billDetailsData.id).update({
      
     }).then((docref)=>{
        var updateConnection = this.firestore.collection('connections', ref=> ref.where('rrno', '==', this.billDetailsData.RRNO).limit(1)).snapshotChanges().pipe(flatMap(clients => clients));
        if(this.validationForm.value.status == 'mnr' || this.validationForm.value.status == 'dc'){
          var meterStatus = this.validationForm.value.status.toUpperCase(); 
          updateConnection.subscribe(doc => {
            this.firestore.collection('connections').doc(doc.payload.doc.id).update({meterStatus:meterStatus}).then(response =>{})
          });
        }
        else{
          updateConnection.subscribe(doc => {
            this.firestore.collection('connections').doc(doc.payload.doc.id).update({meterStatus:'Good'}).then(response =>{})
          });
        }
       
      
     }) */
  }

  onChange(e, type) {
    if (type == "consumption") {
      this.consumption = e.target.value;
    } else if (type == "OBA") {
      this.OBA = e.target.value;
    } else if (type == "waterCharge") {
      this.waterCharge = e.target.value;
    } else if (type == "serviceCharge") {
      this.serviceCharge = e.target.value;
    } else if (type == "penalty") {
      this.penalty = e.target.value;
    } else if (type == "demand") {
      this.demand = e.target.value;
    } else if (type == "CBA") {
      this.CBA = e.target.value;
    } else if (type == "adjustment") {
      this.adjustment = e.target.value;
    } else if (type == "total") {
      this.total = e.target.value;
    }
  }
}
