import { Component, OnInit } from '@angular/core';
import { AngularFirestore } from '@angular/fire/firestore';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { AgentService } from 'src/app/shared/agent/agent.service';
import { DataStateChangeEvent, GridDataResult } from '@progress/kendo-angular-grid';
import { process, State } from '@progress/kendo-data-query';
import { LedgerService } from 'src/app/shared/service/ledger.service';

@Component({
  selector: 'app-ward-details',
  templateUrl: './ward-details.component.html',
  styleUrls: ['./ward-details.component.scss']
})
export class WardDetailsComponent implements OnInit {
  filterForm:FormGroup;
  wards:any = [];
  detailsArray:any = [];
  connectionsDetails;
  connectionDetailsArray: any = [];
  xlsxName;

  public gridData: GridDataResult;
  public state: State = {
    filter: {
      logic: 'and',
      filters: []
    }
  };

  constructor(private fb:FormBuilder, private agentService:AgentService, private firestore:AngularFirestore, private ledgerService:LedgerService) { }

  ngOnInit() {
    this.getAllWards();

    this.filterForm = this.fb.group({
      ward: ['', Validators.required]
    });
  }

  getAllWards(){
    /* this.agentService.getWards().subscribe((data)=>{
      this.wards = data.map(item => { 
      
        var ward = item.payload.doc.data();
        const name = ward["name"];
        const id = item.payload.doc.id;
        return { name };
      });
    }); */

    this.agentService.getWards().then(async (response) =>{
      
      this.wards =await  response.data;
      this.wards.push({"name":'All'})
     // console.log("areas sdsfs",  await this.areas);
    })
  }

  onFilterSubmit(){
    this.detailsArray = [];
    this.gridData =   process(this.detailsArray, this.state);
    this.connectionDetailsArray = [];

    
    let ward =  this.filterForm.value.ward;
    var totalCount = 0;
    var liveCount = 0;
    var disconnectedCount = 0;

    this.xlsxName = ward;
    
    console.log("filter form",  ward);

    var data = {
      'ward': ward
    }

    this.ledgerService.getWardDetails(data).then(async response=>{
      if (response.code == 200) {
        this.detailsArray = await response.data;
        console.log("this.detailsArray", await this.detailsArray)
        this.gridData =  await process(this.detailsArray, this.state);
      }
    });
    
      
      /* this.connectionsDetails = this.firestore.collection('/connections', ref => ref.where('ward', '==', ward));
    
    
    this.connectionsDetails.valueChanges().subscribe((data) => {
      data.forEach(element => {
        console.log("element",element);
        this.connectionDetailsArray.push(element);
      });
      if(this.connectionDetailsArray.length > 0){
        console.log("this.connectionDetailsArray", this.connectionDetailsArray.length)
        var c;
        this.connectionDetailsArray.forEach(connection => {

          if(this.detailsArray.length > 0){
            
            let itemIndex =  this.detailsArray.findIndex(item => item.tariff == connection.tariff);
           
            
            if(itemIndex >= 0){
              console.log("this.detailsArray[itemIndex][total]", this.detailsArray[itemIndex].total);
              
              this.detailsArray[itemIndex].total =  this.detailsArray[itemIndex].total + 1;
              if(connection.consumerConnectionStatusType == 'Live'){
                this.detailsArray[itemIndex].live = this.detailsArray[itemIndex].live+ 1;
              }
              this.detailsArray[itemIndex].disconnected= this.detailsArray[itemIndex].total - this.detailsArray[itemIndex].live;
            }
            else{
              c  = {
                tariff: connection.tariff,
                total: 0,
                live : 0,
                disconnected : 0
              }
              this.detailsArray.push(c)
              this.gridData =  process( this.detailsArray, this.state);
            }
          }
          else{
            totalCount = totalCount + 1
            if(connection.consumerConnectionStatusType == 'Live'){
              liveCount = liveCount + 1;
            }
            disconnectedCount = totalCount - liveCount;
            c  = {
              tariff: connection.tariff,
              total: totalCount,
              live : liveCount,
              disconnected : disconnectedCount
            }
            this.detailsArray.push(c)
            this.gridData =  process( this.detailsArray, this.state);
          }
        });
      
      }
    }); */


    
}

refresh(){
  location.reload();
}

public dataStateChange(state: DataStateChangeEvent): void {
  this.state = state;
  this.gridData = process( this.detailsArray, this.state);
}

}
