import { Component, OnInit, OnDestroy, ViewChild } from '@angular/core';
import { DataTableDirective } from 'angular-datatables';
import { FormControl, FormGroup, Validators } from '@angular/forms';
declare var $: any;


@Component({
  selector: 'app-find-connection',
  templateUrl: './find-connection.component.html',
  styleUrls: ['./find-connection.component.scss']
})
export class FindConnectionComponent implements OnInit, OnDestroy{
  
  @ViewChild(DataTableDirective)
  datatableElement: DataTableDirective;
  min: number;
  max: number;

  title: string = 'My first AGM project';
  lat: number = 12.8745783;
  lng: number = 74.8464408;
  zoom: number = 10;

  public isVisible = false;

  //dtOptions: DataTables.Settings = {};

  constructor() { }

  ngOnInit() {
    	// We need to call the $.fn.dataTable like this because DataTables typings do not have the "ext" property
      $.fn['dataTable'].ext.search.push((settings, data, dataIndex) => {
        const id = parseFloat(data[0]) || 0; // use data for the id column
        if ((isNaN(this.min) && isNaN(this.max)) ||
          (isNaN(this.min) && id <= this.max) ||
          (this.min <= id && isNaN(this.max)) ||
          (this.min <= id && id <= this.max)) {
          return true;
        }
        return false;
      });
      
      /* this.dtOptions = {
        ajax: 'https://l-lin.github.io/angular-datatables/data/data.json',
        columns: [{
          title: 'ID',
          data: 'id'
        }, {
          title: 'First name',
          data: 'firstName'
        }, {
          title: 'Last name',
          data: 'lastName'
        }]
      }; */
  }

  ngOnDestroy(): void {
    // We remove the last function in the global ext search array so we do not add the fn each time the component is drawn
    // /!\ This is not the ideal solution as other components may add other search function in this array, so be careful when
    // handling this global variable
    /* $.fn['dataTable'].ext.search.pop(); */
  }

  filterById(): void {
    this.isVisible = true;
  }

}
