import { Component, OnInit } from '@angular/core';
import { AngularFirestore } from '@angular/fire/firestore';
import { FormBuilder, FormGroup } from '@angular/forms';
import { AgentService } from 'src/app/shared/agent/agent.service';
import { DataStateChangeEvent, GridDataResult, PageChangeEvent} from '@progress/kendo-angular-grid';
import { process, State } from '@progress/kendo-data-query';
import { LedgerService } from 'src/app/shared/service/ledger.service';
import { Observable } from 'rxjs';

declare var require:any;
var moment  = require('moment');

@Component({
  selector: 'app-agent-wise-ledger',
  templateUrl: './agent-wise-ledger.component.html',
  styleUrls: ['./agent-wise-ledger.component.scss']
})
export class AgentWiseLedgerComponent implements OnInit {
  agents:any = [];
  filterForm:FormGroup;
  detailsArray:any = [];
  xlsxName;

  showLoader:boolean = false;

  public gridData: GridDataResult;
  public state: State = {
    skip: 0,
    take:10,
    filter: {
      logic: 'and',
      filters: []
    }
  };
  public buttonCount = 5;
  public info = true;
  public type: 'numeric' | 'input' = 'numeric';
  public previousNext = true;
  public pageSizes = [200,200,300,400, 500,600,700,800,900,1000, 50000];

  //public allData:any;

  constructor(private agentService:AgentService, private fb:FormBuilder, private firestore:AngularFirestore, private ledgerService:LedgerService) { }

  ngOnInit() {
    this.getAllAgents();
    this.filterForm = this.fb.group({
      agent: [''],
      from_date: [],
      to_date: []
    });
  }

  getAllAgents(){
    this.agentService.getAgents().then((response)=>{
     
      if (response.code == 200) {
        this.agents =  response.data;
      }

      this.agents.push({"name":"All"})
    });
  }

  onFilterSubmit(){
    this.showLoader = true;
    this.detailsArray = [];
    this.gridData = process(this.detailsArray, this.state);
    let agent = this.filterForm.value.agent.name;
    let fromDate = this.filterForm.value.from_date;
    let toDate = this.filterForm.value.to_date;

    var fromDateTime = fromDate + ' ' + moment('2021-06-05 00:00:00').format('HH:mm:ss');
    var toDateTime = toDate + ' ' + moment('2021-06-05 23:59:59').format('HH:mm:ss');

    console.log("Form Value", agent, fromDate, toDate);
    var collection;

    this.xlsxName= agent != undefined && fromDate != undefined && toDate!= undefined ? agent+'-'+fromDate+'-to-'+toDate:+fromDate+'-to-'+toDate
   
    /* collection  = this.firestore.collection('ledger', ref => ref
      .where('agent', '==', agent)
      .where('billDateTime', '>=', fromDateTime)
      .where('billDateTime', '<=', toDateTime)
      .orderBy('billDateTime', 'desc'));
    
    collection.snapshotChanges().subscribe(c => {
      console.log("length", c.length)
      c.map(async item => {
        var doc = await item.payload.doc.data();
        doc.id = await item.payload.doc.id;
        await this.firestore.collection('connections', ref => ref.where('RRNO', '==', item.payload.doc.data().connectionId)).valueChanges().subscribe(con =>{
          doc["name"] =  con[0]["name"];
          doc["doorNo"] = con[0]["doorNo"];
          doc["streetName"] = con[0]["streetName"];
          doc["meterSerialNumber"] = con[0]["meterSerialNumber"];
          doc["phoneNumber"] = con[0]["phoneNumber"];
          doc["ward"] = con[0]["ward"];
          doc["tariff"] = con[0]["tariff"];
        })
        await this.detailsArray.push(doc);
        this.gridData = await process( this.detailsArray, this.state);
      });
    }); */

    if(agent != undefined && fromDate != undefined && toDate != undefined){
 
      var data = {
        'type':'All',
        'value': agent,
        'fromDate' : fromDate,
        'toDate' : toDate
      }

      console.log("data", data);
      
      this.ledgerService.getDailyBilling(data).then(response=>{
        console.log('response data', response);
        if (response.code == 200) {
          console.log("response.data", response.data);
          

          this.detailsArray = response.data;
          this.showLoader = false;
          this.gridData = process(this.detailsArray, this.state);
          //this.allData = process(this.detailsArray, this.state);
        }
        else{
          this.detailsArray = [];
          this.showLoader = false;
          this.gridData = process(this.detailsArray, this.state);
        }
        
      });
    }
    else if(agent != ''){
     
      var data1 = {
        'type':'agent',
        'value': agent,
      }

      console.log("this", data1);
      this.ledgerService.getDailyBilling(data1).then(async response=>{
        console.log("dsd", response);
        
        if (response.code == 200) {
            this.detailsArray = await response.data;
            this.showLoader = false;
            this.gridData = await process(this.detailsArray, this.state);
          
        }
      });
    }
  }

  refresh(){
    location.reload();
  }

  public dataStateChange(state: DataStateChangeEvent): void {
    this.state = state;
    this.gridData = process( this.detailsArray, this.state);
  }
  public pageChange({ skip, take }: PageChangeEvent): void {
    console.log("skip",skip,take )
    this.state.skip = skip;
    this.state.take = take; 
    this.gridData = process( this.detailsArray, this.state);
  }

  public allData = () => {
    return process(this.detailsArray, {
      filter: this.state.filter
    });
  }


}
