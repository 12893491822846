import { Component, OnInit, ViewChild } from "@angular/core";
import { AngularFirestore } from "@angular/fire/firestore";
import { FormBuilder, FormGroup, Validators } from "@angular/forms";
import { NgbTypeahead } from "@ng-bootstrap/ng-bootstrap";
import { Subject, merge } from "rxjs";
import { Observable } from "rxjs/Observable";
import {
  DataStateChangeEvent,
  GridDataResult,
  PageChangeEvent,
} from "@progress/kendo-angular-grid";
import { process, State } from "@progress/kendo-data-query";
import {
  debounceTime,
  distinctUntilChanged,
  filter,
  flatMap,
  map,
} from "rxjs/operators";
import { LedgerService } from "src/app/shared/service/ledger.service";
import { ConnectionService } from "src/app/shared/service/connection.service";
import { ToastrService } from "ngx-toastr";

declare var require: any;
var moment = require("moment");

@Component({
  selector: "app-ledger",
  templateUrl: "./ledger.component.html",
  styleUrls: ["./ledger.component.scss"],
})
export class LedgerComponent implements OnInit {
  filterForm: FormGroup;
  crrno;
  detailsArray: any = [];

  singleCustomerGrid = false;

  todays_date = moment().format("YYYY-MM-DD");

  name;
  doorNo;
  streetName;
  meterSerialNumber;
  phoneNumber;
  ward;
  tariff;
  serviceDate;
  area;
  meterStatus;
  RRNO;
  consumerConnectionStatusType;
  meterReaderName;
  plumberName;
  xlsxName;

  showLoader: boolean = false;

  click$ = new Subject<string>();
  @ViewChild("instance") instance: NgbTypeahead;
  focus$ = new Subject<string>();

  public gridData: GridDataResult;
  public state: State = {
    skip: 0,
    take: 10,
    filter: {
      logic: "and",
      filters: [],
    },
  };
  public buttonCount = 5;
  public info = true;
  public type: "numeric" | "input" = "numeric";
  public previousNext = true;
  public pageSizes = [200, 200, 300, 400, 500, 600, 700, 800, 900, 1000, 50000];

  constructor(
    private fb: FormBuilder,
    private firestore: AngularFirestore,
    private ledgerService: LedgerService,
    private connectionService: ConnectionService,
    private toastrService: ToastrService
  ) {}

  ngOnInit() {
    this.filterForm = this.fb.group({
      rrno: [""],
      from_date: [],
      to_date: [],
    });
  }

  searchFocusOnRRNO = (text$: Observable<string>) => {
    const debouncedText$ = text$.pipe(
      debounceTime(200),
      distinctUntilChanged()
    );
    const clicksWithClosedPopup$ = this.click$.pipe(
      filter(() => !this.instance.isPopupOpen())
    );
    const inputFocus$ = this.focus$;
    let crrnoArray = [];
    this.connectionService.getAllConnection().then((response) => {
      if (response.code == 200) {
        response.data.forEach((element) => {
          crrnoArray.push({ RRNO: element.rrno, name: element.name });
        });
      }
    });
    /*   this.crrno = this.firestore.collection('/connections');
    
    this.crrno.valueChanges().subscribe((data)=>{
      data.forEach(element => {
        crrnoArray.push({RRNO:element.RRNO, name:element.name});
      });
    }); */
    return merge(debouncedText$, inputFocus$, clicksWithClosedPopup$).pipe(
      map((term) =>
        (term === ""
          ? []
          : crrnoArray.filter(
              (v) => v.RRNO.toLowerCase().indexOf(term.toLowerCase()) > -1
            )
        ).slice(0, 10)
      )
    );
  };
  rrnoFormatter = (x: { RRNO: string }) => x.RRNO;

  onFilterSubmit() {
    this.showLoader = true;
    this.singleCustomerGrid = false;
    this.detailsArray = [];
    this.gridData = process(this.detailsArray, this.state);
    let rrnoFormValue: string = this.filterForm.get("rrno").value;
    let rrno = rrnoFormValue["RRNO"];
    let fromDate = this.filterForm.value.from_date;
    let toDate = this.filterForm.value.to_date;

    this.xlsxName =
      rrno != undefined && fromDate != undefined && toDate != undefined
        ? rrno + "-" + fromDate + "-to-" + toDate
        : rrno != undefined
        ? rrno
        : fromDate + "-to-" + toDate;

    var fromDateTime =
      fromDate + " " + moment("2021-06-05 00:00:00").format("HH:mm:ss");
    var toDateTime =
      toDate + " " + moment("2021-06-05 23:59:59").format("HH:mm:ss");

    console.log("Form Value", rrno, fromDate, toDate);
    var collection;
    if (rrno != undefined && fromDate != undefined && toDate != undefined) {
      this.singleCustomerGrid = true;
      var data = {
        type: "RRNO",
        value: rrno,
        fromDate: fromDate,
        toDate: toDate,
      };
      this.ledgerService
        .getLedgerReports(data)
        .then((response) => {
          console.log("response data", response);
          if (response.code == 200) {
            console.log("response.data", response.data[0]["name"]);

            var l = response.data.length -1;
            this.name = response.data[l]["name"];
            this.doorNo = response.data[l]["doorNo"];
            this.streetName = response.data[l]["streetName"];
            this.meterSerialNumber = response.data[l]["meterSerialNumber"];
            this.phoneNumber = response.data[l]["phoneNumber"];
            this.ward = response.data[l]["ward"];
            this.tariff = response.data[l]["tariff"];
            this.serviceDate = response.data[l]["serviceDate"];
            this.area = response.data[l]["area"];
            this.meterStatus = response.data[l]["meterStatus"];
            this.RRNO = response.data[l]["rrno"];
            this.consumerConnectionStatusType =
              response.data[l]["connectionStatus"];
            this.meterReaderName = response.data[l]["agent"];
            this.plumberName = response.data[l]["plumberName"];

            this.detailsArray = response.data;
            this.showLoader = false;
            this.gridData = process(this.detailsArray, this.state);
          } else {
            this.detailsArray = [];
            this.showLoader = false;
            this.gridData = process(this.detailsArray, this.state);
            this.toastrService.error("No records", "Error!");
          }
        })
        .catch((error) => {
          console.log("error in ts", error);
        });
    } else if (fromDate != "" && toDate != "") {
      console.log("this");
      var data1 = {
        type: "All",
        value: null,
        fromDate: fromDate,
        toDate: toDate,
      };
      this.ledgerService
        .getLedgerReports(data1)
        .then(async (response) => {
          if (response.code == 200) {
            this.detailsArray = await response.data;
            console.log("this.detailsArray", await this.detailsArray);
            this.showLoader = false;
            this.gridData = await process(this.detailsArray, this.state);
          } else {
            this.detailsArray = [];
            this.showLoader = false;
            this.gridData = await process(this.detailsArray, this.state);
          }
        })
        .catch((error) => {
          console.log("error in ts", error);
        });
    }
  }

  refresh() {
    location.reload();
  }

  public dataStateChange(state: DataStateChangeEvent): void {
    this.state = state;
    this.gridData = process(this.detailsArray, this.state);
  }

  public pageChange({ skip, take }: PageChangeEvent): void {
    console.log("skip", skip, take);
    this.state.skip = skip;
    this.state.take = take;
    this.gridData = process(this.detailsArray, this.state);
  }

  public allData = () => {
    return process(this.detailsArray, {
      filter: this.state.filter,
    });
  };
}
