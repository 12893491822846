import { Component, OnInit, ViewChild, OnDestroy } from '@angular/core';
import { DataTableDirective } from 'angular-datatables';
import { FormGroup, FormBuilder, Validators, FormControl } from '@angular/forms';
import { AdminService } from 'src/app/shared/admin/admin.service';
import { Admin } from 'src/app/shared/admin/admin.model';
import { Router } from '@angular/router';
import { Upload } from 'src/app/shared/upload/upload';
import { AngularFireStorage } from '@angular/fire/storage';
import { finalize } from 'rxjs/operators';
import { Observable } from 'rxjs';
import { CustomValidators } from "ng2-validation";
import { ToastrService } from 'ngx-toastr';
import { AngularFirestore } from '@angular/fire/firestore';
import { DataStateChangeEvent, GridDataResult } from '@progress/kendo-angular-grid';
import { process, State } from '@progress/kendo-data-query';
import { AuthService } from 'src/app/shared/auth/auth.service';

import Swal from 'sweetalert2';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { AngularFireAuth } from '@angular/fire/auth';

@Component({
  selector: 'app-admins',
  templateUrl: './admins.component.html',
  styleUrls: ['./admins.component.scss']
})
export class AdminsComponent implements OnInit, OnDestroy {

  admins:Admin[];
  @ViewChild(DataTableDirective)
  datatableElement: DataTableDirective;
  min: number;
  max: number;
  adminTest;
  adminAraay = [];
  data:any;
  show = 'list';

  public isVisible = false;

  dataTableOptions: DataTables.Settings = {};

  submitted:boolean;

  currentUpload:Upload;
  downloadURL: Observable<string>;
  file;

  public sortOrder: string = "desc";
  public sortBy: string = "";
  public rowsOnPage: number = 10;
  public filterQuery: string = "";
  test:any;

  public gridData: GridDataResult;
  public state: State = {
    filter: {
      logic: 'and',
      filters: []
    }
  };
  


  constructor(private authService:AuthService, private router:Router, private modalService:NgbModal, private fireAuth: AngularFireAuth, private toastrService:ToastrService) {
 
    //this.getLastBillId()
    /* this.adminService.getAdmins().subscribe((data)=>{
      console.log("items",data);
      this.admins = data.map(item => { 
       console.log("payload",item.payload.doc.id);
        return {
          id: item.payload.doc.id,
          ...item.payload.doc.data()
        } as Admin;
      });
    }); */

  }

  ngOnInit(): void {
    this.getAllUsers();
  }

  getAllUsers(){
    this.authService.getAllUsers('admin').then((response)=>{
      if(response.code == 200){
        this.admins = response.data;
        this.gridData =  process( this.admins, this.state);
      }
    })
  }

  view(data, content){
    console.log(data);
    //this.router.navigate(['/view-admin'], { queryParams : {key: data.id} });
    this.data = data;
    this.modalService.open(content, {centered:true, size:'lg'});
  }

  delete(data){
    Swal({
      title: 'Are you sure?',
      text: "You won't be able to revert this!",
      type: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: 'Yes, delete it!'
    }).then((result) => {
      if (result.value) {
        this.authService.deleteUser(data).then(response => {
          if(response.code == 200){
            this.getAllUsers();
            Swal(
              'Deleted!',
              'User has been deleted.',
              'success'
            )
          }
        })
       
      }
    });
  }

  ngOnDestroy(): void {
   
  }

  filterById(): void {
    this.isVisible = true;
  }

  refresh()
  {
    console.log("refresh");
    this.sortBy = "";
    this.filterQuery = "";
  }

  public dataStateChange(state: DataStateChangeEvent): void {
    this.state = state;
    this.gridData = process( this.admins, this.state);
  }

  edit(data)
  {
    this.data = data;
    this.show = 'edit'
  }


  close()
  {
    this.show = 'list'
    this.getAllUsers();
  }

  onChangeTab(){
    console.log("tab clicked");
    
    this.getAllUsers();
  }

  onCloseModal()
  {
    this.modalService.dismissAll();
  }

  resetPassword(){
    console.log("reset Password", localStorage.getItem("LoggedInUserEmail"));
    var email = localStorage.getItem("LoggedInUserEmail");
    this.fireAuth.auth.sendPasswordResetEmail(email).then(()=>{
      console.log("email sent");
      this.toastrService.success("Please check your email", 'Success!') 
    }).catch((error) =>{
      console.log("error while reseting password", error);
    })
  }
}
