import { Component, Input, OnInit, Output, EventEmitter } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { AngularFirestore } from '@angular/fire/firestore';
import { FormBuilder, FormGroup, Validators, FormControl } from '@angular/forms';
import { AdminService } from 'src/app/shared/admin/admin.service';
import { Admin } from 'src/app/shared/admin/admin.model';
import { AngularFireStorage } from '@angular/fire/storage';
import { finalize } from 'rxjs/operators';
import { Observable } from 'rxjs';
import { ToastrService } from 'ngx-toastr';
import { CustomValidators } from 'ng2-validation';
import { AuthService } from 'src/app/shared/auth/auth.service';
import { environment } from 'src/environments/environment';


@Component({
  selector: 'app-view-admin',
  templateUrl: './view-admin.component.html',
  styleUrls: ['./view-admin.component.scss']
})
export class ViewAdminComponent implements OnInit {
  @Input('data') data;
  @Output('onCloseModal') onCloseModal =  new EventEmitter<string>();

  public admin:Admin;
  getAdmin;
  adminId;
  submitted: boolean;
  adminForm: FormGroup;
  hasFile:boolean;
  selectedFiles: FileList;
  downloadURL:Observable<string>;
  imgsrc;

  apiUrl= `${environment.apiUrl}`

  password = new FormControl('', [Validators.required, Validators.minLength(6)]);

  constructor(private route:ActivatedRoute, private router:Router, private toastrService:ToastrService, private authService:AuthService) { 
  
  }

  ngOnInit() {
    /* this.adminId= this.route.snapshot.queryParamMap.get('key');
    this.getUserById(); */
    this.imgsrc = this.data['image'] ? this.apiUrl + 'images/admins/'+this.data['image'] : "assets/images/profile.png";
  }

  onClose(){
    this.onCloseModal.emit('close');
  }


  getUserById(){
    this.authService.getUserById(this.adminId).then((response)=>{
      if(response.code == 200){
        this.admin = response.data[0];
        this.imgsrc = this.admin['image'] ?this.apiUrl + '/images/admins/'+this.admin['image'] : "assets/images/profile.png";;
      }
    })
  }
 

}
