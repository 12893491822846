import { Component, Input, OnInit, Output, EventEmitter } from "@angular/core";
import { AngularFirestore } from "@angular/fire/firestore";
import { AngularFireStorage } from "@angular/fire/storage";
import { FormBuilder, FormGroup, Validators } from "@angular/forms";
import { ToastrService } from "ngx-toastr";
import { Observable } from "rxjs";
import { finalize } from "rxjs/operators";
import { ChangeDetailsService } from "src/app/shared/service/change-details.service";
import { ConnectionService } from "src/app/shared/service/connection.service";

@Component({
  selector: "app-meter-reconnection",
  templateUrl: "./meter-reconnection.component.html",
  styleUrls: ["./meter-reconnection.component.scss"],
})
export class MeterReconnectionComponent implements OnInit {
  @Input("meterReconnectionData") meterReconnectionData;
  @Output("onCloseModal") onCloseModal = new EventEmitter<String>();

  validationForm: FormGroup;
  dateTime;
  hasFile: boolean;
  selectedFiles: FileList;
  userId;
  userName;
  downloadURL: Observable<String>;
  imgPath;
  maxDate;
  disconnectionDetails;
  disconnectionDetailsArray: any = [];
  disconnectDate;

  constructor(
    private fb: FormBuilder,
    private firestore: AngularFirestore,
    private toastrService: ToastrService,
    private storage: AngularFireStorage,
    private changeService: ChangeDetailsService,
    private connectionService: ConnectionService
  ) {
    var today = new Date();
    this.dateTime =
      today.getFullYear() +
      "-" +
      ("0" + (today.getMonth() + 1)).slice(-2) +
      "-" +
      ("0" + today.getDate()).slice(-2) +
      " " +
      ("0" + today.getHours()).slice(-2) +
      ":" +
      ("0" + today.getMinutes()).slice(-2) +
      ":" +
      ("0" + today.getSeconds()).slice(-2);
    this.userId = localStorage.getItem("user_id");
    this.userName = localStorage.getItem("user_name");
  }

  ngOnInit() {

    this.meterDisconnectionData();
    this.getMaxDate();

    console.log("meterReconnectionData", this.meterReconnectionData);
    this.validationForm = this.fb.group({
      rrno: [this.meterReconnectionData.rrno],
      consumerName: [this.meterReconnectionData.name],
      userId: [this.userName],
      enteredDate: [this.dateTime],
      oldMeterSerialNo: [this.meterReconnectionData.meterSerialNumber],
      newMeterSerialNo: [""],
      reconnectionDate: ["", Validators.required],
      orderNo: ["", Validators.required],
      remarks: ["", Validators.required],
      previousReading: [this.meterReconnectionData.previousReading],
      presentReading: [""],
      oldConnectionStatus: [this.meterReconnectionData.connectionStatus],
      connectionStatus: ["Live", Validators.required],
      phoneNumber: [this.meterReconnectionData.phoneNumber],
      file: [""],
    });
  }

  onClose(close) {
    this.onCloseModal.emit("close");
  }

  onSubmit() {
    // console.log("id", this.meterReconnectionData.id);
    //this.validationForm.controls.userId.setValue(this.userId);

    if (this.validationForm.valid) {
      /*  if(this.hasFile){
        var file = this.selectedFiles.item(0);
        this.uploadFile(file);
      }
      else{ */
      this.store();
      //}
    }
  }

  uploadFile(imageUrl) {
    let uniqkey = "pic" + Math.floor(Math.random() * 1000000);
    const uploadTask = this.storage.upload(
      "/meter-reconnection/" + uniqkey,
      imageUrl
    );
    const ref = this.storage.ref("/meter-reconnection/" + uniqkey);
    //console.log("uploadTask", uploadTask);
    uploadTask
      .snapshotChanges()
      .pipe(
        finalize(() => {
          this.downloadURL = ref.getDownloadURL();
          console.log(this.downloadURL);
          this.downloadURL.subscribe((url) => {
            this.imgPath = url;
            //console.log("url",this.imgPath)
            this.toastrService.success(
              "Image uploaded succcessfully!",
              "Success!",
              {
                timeOut: 3000,
              }
            );
            this.store();
          });
        })
      )
      .subscribe();
  }

  store() {
    console.log("formValue", this.validationForm.value);

    var formValue = this.validationForm.value;
    delete formValue.userId;
    formValue.userId = this.userId;

    /*  if(this.hasFile){
      formValue.file = this.imgPath;
    }
     */
    /* this.firestore.collection('meter-reconnection').add(formValue).then(response=>{
      this.firestore.collection('connections').doc(this.meterReconnectionData.id).update({consumerConnectionStatusType:this.validationForm.value.connectionStatus, meterSerialNumber:this.validationForm.value.newMeterSerialNumber}).then(response =>{
        this.toastrService.success("Meter reconnected Successfully", "Success!", {
        timeOut: 8000
      });
      this.onCloseModal.emit('close');
      })
      
    }).catch(error=>{
      this.toastrService.error(error, "Error!", {
        timeOut: 8000
      });
    }); */

    this.changeService
      .meterReconnection(formValue, this.hasFile, this.selectedFiles)
      .then(async (response) => {
        console.log("meterrecconnection_response", response.code);
        if (response.code == 200) {
          /* this.meterReconnectionData.connectionStatus = formValue.connectionStatus;
        this.meterReconnectionData.meterSerialNumber = formValue.newMeterSerialNo;
        this.changeService.updateConnection(this.meterReconnectionData).then(async response=>{
          this.toastrService.success("Meter reconnected Successfully", "Success!", {
            timeOut: 8000
          });
          this.onCloseModal.emit('close');
        }) */
          this.toastrService.success(
            "Meter reconnected Successfully",
            "Success!",
            {
              timeOut: 8000,
            }
          );
          this.onCloseModal.emit("close");
        } else {
          this.toastrService.error(response.message, "Error!", {
            timeOut: 8000,
          });
        }
      });
  }

  detectFiles(event) {
    this.hasFile = true;
    this.selectedFiles = event.target.files;
    //console.log("event.target.files", event.target.files[0].name);

    this.validationForm.controls.file.setValue(event.target.files[0].name);
  }

  meterDisconnectionData() {
    // this.disconnectionDetails = this.firestore.collection('/meter-disconnect', ref => ref.where('rrno', '==', this.meterReconnectionData.RRNO).orderBy('enteredDate', 'desc'));
    // this.disconnectionDetails.valueChanges().subscribe((data) => {
    //   data.forEach(element => {
    //     console.log("element reconnection",element);
    //     this.disconnectionDetailsArray.push(element);
    //   });
    //   if(this.disconnectionDetailsArray.length > 0){
    //     this.disconnectDate = this.disconnectionDetailsArray[0].disconnectionDate;
    //   }
    // })

    this.connectionService
      .getMeterDisconnectionChangeDetailsByRrno(this.meterReconnectionData.rrno)
      .then((response) => {
        this.disconnectionDetailsArray = response.data;

        if (this.disconnectionDetailsArray.length > 0) {
          this.disconnectDate =
            this.disconnectionDetailsArray[
              this.disconnectionDetailsArray.length - 1
            ].disconnectionDate;
        }
        console.log("meter disconnection details", response);
      });
  }

  getMaxDate(){
    var today = new Date();
    this.maxDate =today.getFullYear() +
      "-" +
      ("0" + (today.getMonth() + 1)).slice(-2) +
      "-" +
      ("0" + today.getDate()).slice(-2)

    console.log('max date is',this.maxDate);
  }
}
