import { Component, Input, OnInit, Output, EventEmitter } from '@angular/core';
import { AngularFirestore } from '@angular/fire/firestore';
import { LedgerService } from 'src/app/shared/service/ledger.service';
import { DataStateChangeEvent, GridDataResult, PageChangeEvent } from '@progress/kendo-angular-grid';
import { process, State } from '@progress/kendo-data-query';
import { ToastrService } from 'ngx-toastr';

@Component({
  selector: 'app-move-payment-to-another-ledger',
  templateUrl: './move-payment-to-another-ledger.component.html',
  styleUrls: ['./move-payment-to-another-ledger.component.scss']
})
export class MovePaymentToAnotherLedgerComponent implements OnInit { 
  show = 'view';
  collection;
  detailsArray:any = [];
  showLoader: boolean = false;

  public gridData: GridDataResult;
  public state: State = {
    skip: 0,
    take:10,
    filter: {
      logic: 'and',
      filters: []
    }
  };
  public buttonCount = 5;
  public info = true;
  public type: 'numeric' | 'input' = 'numeric';
  public previousNext = true;
  public pageSizes = [200,200,300,400, 500,600,700,800,900,1000, 50000];
  
  constructor(private firestore:AngularFirestore, private ledgerService:LedgerService,private toastrService:ToastrService) { }

  ngOnInit() {
    this.getCollectionReport();
  }

  getCollectionReport(){
    this.showLoader=true;
    //this.collectioReportData.paymentReceivedBank = this.collectioReportData.paymentReceivedBank == 'Corporation-Union Bank' ? 'Corporation':this.collectioReportData.paymentReceivedBank;
    this.ledgerService.getCurrentMonthCashCollection().then(async response=>{
      if (response.code == 200) {
        this.showLoader=false;
        this.detailsArray = await response.data;
        console.log("this.detailsArray", await this.detailsArray)
        this.gridData =  await process(this.detailsArray, this.state);
      }
    });
  }




  movePayment(data){
      this.ledgerService.movePayments(data).then(async (response) => {
        if (response.code == 200) {
          this.detailsArray = [];
          console.log("updated", response.data);   
         // this.toastrService.success("Moved successfully", "Success")
          this.toastrService.success("Moved Successfully", "Success",
          {timeOut: 5000})
    }
  })
} 
  public dataStateChange(state: DataStateChangeEvent): void {
    this.state = state;
    this.gridData = process( this.detailsArray, this.state);
  }

  public pageChange({ skip, take }: PageChangeEvent): void {
    console.log("skip",skip,take )
    this.state.skip = skip;
    this.state.take = take; 
    this.gridData = process( this.detailsArray, this.state);
  }


}
