import { Component, OnInit } from '@angular/core';
import { AngularFirestore } from '@angular/fire/firestore';
import { ActivatedRoute } from '@angular/router';

@Component({
  selector: 'app-bill-pdf',
  templateUrl: './bill-pdf.component.html',
  styleUrls: ['./bill-pdf.component.scss']
})
export class BillPdfComponent implements OnInit {
  billId;
  constructor(private route : ActivatedRoute, private firestore:AngularFirestore) { }

  ngOnInit() {
    this.billId= this.route.snapshot.queryParamMap.get('key');
    console.log("this.rplDriverId", this.billId);
  }

  getBillDetails(){
    this.firestore.collection('ledger').doc(this.billId).valueChanges().subscribe()
  }

}
