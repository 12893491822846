import { Component, EventEmitter, Input, OnInit, Output, ViewChild } from '@angular/core';
import { AngularFirestore } from '@angular/fire/firestore';
import { FormControl, FormGroup } from '@angular/forms';
import { ToastrService } from 'ngx-toastr';
import { Observable } from 'rxjs/Observable';
import { debounceTime, distinctUntilChanged, filter, flatMap, map} from 'rxjs/operators';
import { Subject, merge} from 'rxjs';
import { NgbTypeahead } from '@ng-bootstrap/ng-bootstrap';
import { CashTransferService } from 'src/app/shared/service/cash-transfer.service';
import { ConnectionService } from 'src/app/shared/service/connection.service';

declare var require:any;
var moment = require('moment');

@Component({
  selector: 'app-suspension-detail',
  templateUrl: './suspension-detail.component.html',
  styleUrls: ['./suspension-detail.component.scss']
})
export class SuspensionDetailComponent implements OnInit {
  @Input('suspensionData') suspensionData;
  @Output() close = new EventEmitter();

  show='view';
  connectionCollection;
  RRNO;
  ownerName;
  houseNo;
  phoneNumber;
  receiptNo;
  ledgerDetail;
  receiptDateTime;
  RRNOShow:boolean = false;
  crrno

  validationForm:FormGroup;

  click$ = new Subject<string>();
  @ViewChild('instance') instance: NgbTypeahead;
  focus$ = new Subject<string>();

  constructor(private firestore:AngularFirestore, private totastrService:ToastrService, private cashTransferService:CashTransferService, private connectionService:ConnectionService) { }

  async ngOnInit() {
    //log
    this.validationForm = new FormGroup({
      suspenseRrno: new FormControl(),
      suspenseName: new FormControl(),
      rrno : new FormControl(),
      name : new FormControl(),
      doorNo : new FormControl(),
      phoneNumber : new FormControl(),
      receiptNo: new FormControl(),
      receiptDateTime : new FormControl(),
      mode: new FormControl(),
      amount: new FormControl(),
      chequeDdNumber: new FormControl(''),
      chequeDdDate: new FormControl(''),
      chequeDdBranch: new FormControl(''),
      paymentReceivedBank: new FormControl(),
      receiptDate: new FormControl()
    });
    
    await this.getLastId();
    await this.getConnectionDetails();
    this.receiptDateTime = moment(this.suspensionData.date).format('YYYY/MM/DD HH:mm:ss');
  }

  closed(){
    this.close.emit("list");
  }

  getConnectionDetails(){
    console.log('suspense data', this.suspensionData);
    
    this.cashTransferService.getSuspenseDetail(this.suspensionData.name).then(async response=>{
      if (response.code == 200) {
        console.log("response", response.data);
        //this.rrnoFormatter = response.data[0]["rrno"];
        this.validationForm.setValue({
          suspenseRrno : !!this.suspensionData.rrno?this.suspensionData.rrno:'',
          suspenseName : !!this.suspensionData.name?this.suspensionData.name:'',
          rrno : response.data[0]["rrno"],
          name : response.data[0]["name"],
          doorNo :response.data[0]["doorNo"],
          phoneNumber :response.data[0]["phoneNumber"],
          receiptNo: this.receiptNo,
          receiptDateTime : moment(this.suspensionData.receiptDate).format('YYYY-MM-DD HH:mm:ss'),
          mode: this.suspensionData.mode,
          amount: this.suspensionData.amount,
          chequeDdNumber: this.suspensionData.chequeDdNumber,
          chequeDdDate: this.suspensionData.chequeDdDate,
          chequeDdBranch: this.suspensionData.chequeDdBranch,
          paymentReceivedBank: this.suspensionData.paymentReceivedBank,
          receiptDate : this.suspensionData.receiptDate
        })
      }
      
    });
    
  }

  async getLastId(){
    console.log("2");
    this.cashTransferService.getLastId().then(async response=>{
      if(response.code == 200){
        console.log("max id ", response.data);
        
        var receptNo = response.data[0]['receiptNo'];
        this.receiptNo  = Number(receptNo) + 1;
      }
      else{
        this.receiptNo =  '202100001'
      }
    })
  }

  onSubmit(){
    var formValue = this.validationForm.value;
    console.log("suspense form value", formValue);
    formValue.id = this.suspensionData.id;
    formValue.chequeDdNumber = formValue.chequeDdNo;

    this.cashTransferService.createSuspenseTransfer(formValue).then(async response=>{
      if (response.code == 200) {
        this.totastrService.success("Cash Transfered successfully", "Success");
        this.close.emit("list");
      }
      else{
        this.totastrService.error("Cash Transfer Failed", "Failed")
      }
    });
   
    /* console.log("formValue", formValue);
    formValue.createdAt = moment().format('YYYY-MM-DD HH:mm:ss');
    this.firestore.collection('suspense-cash-transfer').add(formValue).then(success =>{
      this.firestore.collection('suspense').doc(this.suspensionData.id).delete();
    })
    
    this.firestore.collection('bankCashCollection').add(formValue).then(success =>{
    })

    var billDate = moment(this.suspensionData.date).format('YYYY-MM-DD HH:mm:ss');
    
    var keepGoing1 = true;
    this.ledgerDetail = this.firestore.collection('ledger', ref => ref.where('connectionId', '==', this.validationForm.value.RRNO).where('billDateTime', '<=', billDate).orderBy('billDateTime', 'desc').limit(1)).snapshotChanges().pipe(flatMap(clients => clients));
    this.ledgerDetail.subscribe(doc => {
      var data = doc.payload.doc.data();
      if(keepGoing1){
        keepGoing1 = false;
        var newCBA = Number(data['CBA']) - Number(this.validationForm.value.amount);


        var paidAmount;
        var receiptDate;
        var receiptNo;
        if(data['paidAmount'] != ''){
          paidAmount = data['paidAmount'] + ',' + data.amount;
          receiptDate = data['receiptDate'] + ',' + this.receiptDateTime;
          receiptNo = data['receiptNo'] + ',' + this.receiptNo;
        }
        else{
          paidAmount = data.amount;
          receiptDate = this.receiptDateTime;
          receiptNo = this.receiptNo
        }
        console.log("doc.payload.doc.id", doc.payload.doc.id, newCBA, keepGoing1);
        this.firestore.collection('ledger').doc(doc.payload.doc.id).update({'CBA':newCBA.toString(), 'paidAmount': paidAmount, 'receiptDate':receiptDate, 'receiptNo':receiptNo}).then(response =>{
          console.log("updated");
         
          
          var keepGoingForOldConnection1 = true;
          this.firestore.collection('ledger', ref => ref.where('connectionId', '==', this.validationForm.value.RRNO).where('billDateTime', '>', data['billDateTime']).orderBy('billDateTime', 'asc')).snapshotChanges().subscribe(update=>{
           update.map((ul, key) =>{
              if(update.length > 0){
                if(keepGoingForOldConnection1){
                  if(key == update.length - 1){
                    keepGoingForOldConnection1 = false;
                    this.validationForm.reset();
                    this.totastrService.success("Cash Transfered successfully", "Success")
                  }
                  console.log("inside id", ul.payload.doc.id, ul.payload.doc.data(), key);
                  var ulData = ul.payload.doc.data()
                  var OBA = newCBA;
                  var CBA = OBA + Number(ulData['demand']);
                  var paidAmount = ulData['paidAmount'] == ''?'': CBA + Number(ulData['paidAmount']);
                  console.log("paidAmount", paidAmount);
                  newCBA = CBA;
                  this.firestore.collection('ledger').doc(ul.payload.doc.id).update({'OBA':OBA.toString(),'CBA':CBA.toString(), 'paidAmount': paidAmount.toString()}).then(response =>{

                  })
                }
                else{
                  this.validationForm.reset();
                  this.totastrService.success("Cash Transfered successfully", "Success");
                }
              }
            })
          })
        })
      }
    }); */
  }

  searchFocusOnRRNO = (text$: Observable<string>) => {
    const debouncedText$ = text$.pipe(debounceTime(200), distinctUntilChanged());
    const clicksWithClosedPopup$ = this.click$.pipe(filter(() => !this.instance.isPopupOpen()));
    const inputFocus$ = this.focus$;
    /* this.crrno = this.firestore.collection('/connections');
    let crrnoArray = [];
    this.crrno.valueChanges().subscribe((data)=>{
      data.forEach(element => {
        crrnoArray.push({RRNO:element.RRNO, name:element.name});
      });
    }); */
    let crrnoArray = [];
    this.connectionService.getAllConnection().then(response =>{
      if(response.code == 200){
        response.data.forEach(element => {
          crrnoArray.push({RRNO:element.rrno, name:element.name});
        });
      }
    })
    return merge(debouncedText$, inputFocus$, clicksWithClosedPopup$).pipe(
      map(term => (term === '' ? []
        : crrnoArray.filter(v => v.RRNO.toLowerCase().indexOf(term.toLowerCase()) > -1)).slice(0, 10))
    );
  }
  rrnoFormatter = (x: {RRNO: string}) => x.RRNO;

  browseConnectionDetail(event){
    this.RRNOShow = true;
    let fromRrno = event.item.RRNO;
   console.log("value", event.item.RRNO);
    
    this.connectionCollection = this.firestore.collection('connections', ref => ref.where('RRNO', '==', fromRrno).limit(1));
    this.connectionCollection.valueChanges().subscribe(con =>{
      if(con.length > 0){
        this.validationForm.patchValue({
          rrno: con[0]["RRNO"],
          name : con[0]["name"],
          doorNo : con[0]["doorNo"],
          phoneNumber : con[0]["phoneNumber"],
        })
      }
    })
  }

}
