import { Routes, RouterModule } from '@angular/router';
import { DashboardComponent } from 'src/app/layouts/dashboard/dashboard.component';
import { FindConnectionComponent } from 'src/app/layouts/find-connection/find-connection.component';
import { FindConnectionOneComponent } from 'src/app/layouts/connection/find-connection-one/find-connection-one.component';
import { AdminsComponent } from 'src/app/layouts/users/admins/admins.component';
import { ViewAdminComponent } from 'src/app/layouts/users/admins/view-admin/view-admin.component';
import { SupervisorsComponent } from 'src/app/layouts/users/supervisors/supervisors.component';
import { ViewSupervisorComponent } from 'src/app/layouts/users/supervisors/view-supervisor/view-supervisor.component';
import { AuthGuard } from '../../core/auth.guard';
import { CustomersComponent } from 'src/app/layouts/users/customers/customers.component';
import { BillingLedgerComponent } from 'src/app/layouts/connection/billing-ledger/billing-ledger.component';
import { CashCollectionComponent } from 'src/app/layouts/bank/cash-collection/cash-collection.component';
import { BankStaffsComponent } from 'src/app/layouts/users/bank-staffs/bank-staffs.component';
import { ViewBankStaffsComponent } from 'src/app/layouts/users/bank-staffs/view-bank-staffs/view-bank-staffs.component';
import { CashCollectionReportComponent } from 'src/app/layouts/bank/cash-collection-report/cash-collection-report.component';
import { AgentsComponent } from 'src/app/layouts/users/agents/agents.component';
import { ChangeDetailsComponent } from 'src/app/layouts/reports/change-details/change-details.component';
import { BillPdfComponent } from 'src/app/layouts/connection/bill-pdf/bill-pdf.component';
import { LedgerComponent } from 'src/app/layouts/reports/ledger/ledger.component';
import { MeterReaderDaywiseComponent } from 'src/app/layouts/reports/meter-reader-daywise/meter-reader-daywise.component';
import { AgentWiseLedgerComponent } from 'src/app/layouts/reports/agent-wise-ledger/agent-wise-ledger.component';
import { DCBComponent } from 'src/app/layouts/reports/dcb/dcb.component';
import { WardWisePendingBalanceComponent } from 'src/app/layouts/reports/ward-wise-pending-balance/ward-wise-pending-balance.component';
import { CollectionReportComponent } from 'src/app/layouts/reports/collection-report/collection-report.component';
import { StatusWiseBilledUnbilledComponent } from 'src/app/layouts/reports/status-wise-billed-unbilled/status-wise-billed-unbilled.component';
import { ConnectionTypeComponent } from 'src/app/layouts/reports/connection-type/connection-type.component';
import { WardDetailsComponent } from 'src/app/layouts/reports/ward-details/ward-details.component';
import { CashTransferComponent } from 'src/app/layouts/bank/cash-transfer/cash-transfer.component';
import { DcbDetailsComponent } from 'src/app/layouts/reports/dcb-details/dcb-details.component';
import { CashTransferReportComponent } from 'src/app/layouts/reports/cash-transfer-report/cash-transfer-report.component';
import { ImportExcelComponent } from 'src/app/layouts/import-excel/import-excel.component';
import { SuspenseCashTransferComponent } from 'src/app/layouts/suspense-cash-transfer/suspense-cash-transfer.component';
import { SuspenseTransferDetailsComponent } from 'src/app/layouts/reports/suspense-transfer-details/suspense-transfer-details.component';
import { UnbilledRecordDetailsComponent } from 'src/app/layouts/reports/unbilled-record-details/unbilled-record-details.component';
import { AbstractReportComponent } from 'src/app/layouts/bank/abstract-report/abstract-report.component';
import { AgentWiseLedgerOldComponent } from 'src/app/layouts/reports/agent-wise-ledger-old/agent-wise-ledger-old.component';
import { StatusWiseBilledRecordsComponent } from 'src/app/layouts/reports/status-wise-billed-records/status-wise-billed-records.component';
import { BilledRecordDetailsComponent } from 'src/app/layouts/reports/billed-record-details/billed-record-details.component';
import { LedgerRectifyComponent } from 'src/app/layouts/reports/ledger-rectify/ledger-rectify.component';
import { DuplicateBillsReportComponent } from 'src/app/layouts/reports/duplicate-bills-report/duplicate-bills-report.component';
import { MovePaymentToAnotherLedgerComponent } from 'src/app/layouts/reports/move-payment-to-another-ledger/move-payment-to-another-ledger.component';
import { MeterReaderDaywiseNewComponent } from 'src/app/layouts/reports/meter-reader-daywise-new/meter-reader-daywise-new.component';
export const content: Routes = [
  {
    path: '',
    redirectTo : 'dashboard',
    pathMatch: "full"
  },
  {
    path: 'dashboard',
    component: DashboardComponent,
    canActivate: [AuthGuard]
  }, 
  {
    path: 'find-connection',
    component: FindConnectionComponent,
    canActivate: [AuthGuard]
  },
  {
    path: 'connection',
    component: FindConnectionOneComponent,
    canActivate: [AuthGuard]
  },
  {
    path: 'admins',
    component: AdminsComponent,
    data: {
      onlySuperAdmin: true
    },
    canActivate: [AuthGuard]
  },
  {
    path: 'view-admin',
    component: ViewAdminComponent,
    canActivate: [AuthGuard]
  },
  {
    path: 'supervisors',
    component: SupervisorsComponent,
    data: {
      notAllowed: true
    },
    canActivate: [AuthGuard]
  },
  {
    path: 'view-supervisor',
    component: ViewSupervisorComponent,
    canActivate: [AuthGuard]
  },
  {
    path: 'customers',
    component: CustomersComponent,
    canActivate: [AuthGuard]
  },
  {
    path: 'billing',
    component: BillingLedgerComponent,
    canActivate: [AuthGuard]
  }, 
  {
    path: 'cash-collection',
    component: CashCollectionComponent,
    data: {
      onlyBankStaff: true
    },
    canActivate: [AuthGuard]
  },
  {
    path: 'cash-collection-report',
    component: CashCollectionReportComponent,
    data: {
      onlyBankStaff: true
    },
    canActivate: [AuthGuard]
  },
  {
    path: 'bank-staffs',
    component: BankStaffsComponent,
    canActivate: [AuthGuard]
  },
  {
    path: 'view-bank-staff',
    component: ViewBankStaffsComponent,
    canActivate: [AuthGuard]
  },
  {
    path: 'agent',
    component: AgentsComponent,
    canActivate: [AuthGuard]
  },
  {
    path: 'reports',
    children: [
      {
        path: 'change-details',
        component: ChangeDetailsComponent,
        canActivate: [AuthGuard]
      },
      {
        path:'move-payments-in-ledger',
        component: MovePaymentToAnotherLedgerComponent,
        canActivate:[AuthGuard]
      },
      {
        path: 'ledger',
        component: LedgerComponent,
        canActivate: [AuthGuard]
      },
      // {
      //   path: 'meter-reader',
      //   component: MeterReaderDaywiseComponent,
      //   canActivate: [AuthGuard]
      // },
      {
        path: 'meter-reader',
        component: MeterReaderDaywiseNewComponent,
        canActivate: [AuthGuard]
      },
      {
        path: 'agent-wise-ledger',
        component: AgentWiseLedgerComponent,
        canActivate: [AuthGuard]
      },
      {
        path: 'agent-wise-ledger-old',
        component: AgentWiseLedgerOldComponent,
        canActivate: [AuthGuard]
      },
      {
        path: 'DCB',
        component: DCBComponent,
        canActivate: [AuthGuard]
      },
      {
        path: 'DCB-financial-year',
        component: DcbDetailsComponent,
        canActivate: [AuthGuard]
      },
      {
        path: 'pending-balance',
        component: WardWisePendingBalanceComponent,
        canActivate: [AuthGuard]
      },
      {
        path: 'collection-report',
        component: CollectionReportComponent,
        canActivate: [AuthGuard]
      },
      {
        path: 'billed-unbilled',
        component: StatusWiseBilledUnbilledComponent,
        canActivate: [AuthGuard]
      },
      {
        path: 'statuswise-billed-records',
        component: StatusWiseBilledRecordsComponent,
        canActivate: [AuthGuard]
      },
      {
        path: 'connection-type',
        component: ConnectionTypeComponent,
        canActivate: [AuthGuard]
      },
      {
        path: 'ward-details',
        component: WardDetailsComponent,
        canActivate: [AuthGuard]
      },
      {
        path: 'cash-transfer',
        component: CashTransferReportComponent,
        canActivate: [AuthGuard]
      },
      {
        path: 'suspense-transfer',
        component: SuspenseTransferDetailsComponent,
        canActivate: [AuthGuard]
      },{
        path: 'unbilled-record-details',
        component: UnbilledRecordDetailsComponent,
        canActivate: [AuthGuard]
      },
      {
        path: 'billed-record-details',
        component: BilledRecordDetailsComponent,
        canActivate: [AuthGuard]
      },
      {
        path: 'Ledger-Rectify-details',
        component: LedgerRectifyComponent,
        canActivate: [AuthGuard]
      },{
        path:'duplicate-bills-in-ledger',
        component:DuplicateBillsReportComponent,
        canActivate:[AuthGuard]
      }
      
    ]
  },
  {
    path:'bill-pdf',
    component:BillPdfComponent,
    canActivate:[AuthGuard]
  },
  {
    path: 'cash-transfer',
    component: CashTransferComponent,
    canActivate: [AuthGuard]
  },
  {
    path: 'suspension-cash-transfer',
    component: SuspenseCashTransferComponent,
    canActivate : [AuthGuard]
  },
  {
    path: 'import-excel',
    component: ImportExcelComponent,
    canActivate: [AuthGuard]
  },
  {
    path: 'cash-collection-abstract-report',
    component: AbstractReportComponent
  }
];