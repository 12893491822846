import { Component, OnInit } from '@angular/core';
import { AngularFireAuth } from '@angular/fire/auth';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { ToastrService } from 'ngx-toastr';
import { AuthService } from '../auth/auth.service';
declare var $: any;

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss']
})
export class HeaderComponent implements OnInit {
  data:any;
  validationForm:FormGroup;

  constructor(private authService: AuthService, private modalService:NgbModal, private fb:FormBuilder, private fireAuth:AngularFireAuth, private toastrService:ToastrService) { }

  ngOnInit() {
     $.getScript('assets/js/script.js');
   //  console.log("loggedin",this.authService.isLoggedIn());
    this.validationForm = this.fb.group({
      name: ['', Validators.required],
      phoneNumber: ['', [Validators.required, Validators.minLength(10)]],
      email: ['', [Validators.required,Validators.email]],
      addressLine1: ['', Validators.required],
      addressLine2: ['', Validators.required],
      city: ['', Validators.required],
      state: ['', Validators.required],
      pincode: ['', [Validators.required, Validators.minLength(6)]]
    });
  }

  editProfile(content){
    var userId = localStorage.getItem('user_id');
    this.authService.getUserById(userId).then(response => {
      if(response.code == 200){
        this.data = response.data[0];
        this.modalService.open(content, {centered:true, size:'lg'});
      }
    })
    
  }

  resetPassword(){
    console.log("reset Password", localStorage.getItem("LoggedInUserEmail"));
    var email = localStorage.getItem("LoggedInUserEmail");
    this.fireAuth.auth.sendPasswordResetEmail(email).then(()=>{
      console.log("email sent");
      this.toastrService.success("Please check your email", 'Success!') 
    }).catch((error) =>{
      console.log("error while reseting password", error);
    })
  }

}
