import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { timeout } from "rxjs/operators";
import { environment } from "src/environments/environment";
import { ResponseData } from "../interfaces/response-data";

interface Data {
  code: number;
  message: string;
  data: any;
}

@Injectable({
  providedIn: "root",
})
export class ConnectionService {
  private apiUrl = `${environment.apiUrl + "api/"}`;

  constructor(private http: HttpClient) {}

  getAllConnection() {
    return new Promise<ResponseData>((resolve) => {
      this.http.get<ResponseData>(this.apiUrl + "connections").subscribe(
        (data) => {
          //console.log("data", data);
          resolve(data);
        },
        (error) => {
          console.log("Error", error);
        }
      );
    });
  }

  createConnection(con) {
    return new Promise<Data>((resolve) => {
      this.http.post<Data>(this.apiUrl + "connection", con).subscribe(
        (data) => {
          console.log("Success", data);
          resolve(data);
        },
        (error) => {}
      );
    });
  }





  postDummyLedger(rrno){
    return new Promise<Data>((resolve)=>{
      this.http.post<Data>(this.apiUrl + "create-dummy-ledger", rrno)
      .subscribe(
          (data) => {
            console.log("Success", data);
            resolve(data);
          },
          (error) => {
        });
    })
  }

  checkRrnoExist(rrno) {
    return new Promise<ResponseData>((resolve) => {
      this.http
        .get<ResponseData>(this.apiUrl + "check-rrno-exist" + `/${rrno}`)
        .pipe(timeout(1000000))
        .subscribe(
          (data) => {
            console.log("data", data);
            resolve(data);
          },
          (error) => {
            console.log("Error", error);
          }
        );
    });
  }

  getLedgerByRrno(rrno, year) {
    const val =  encodeURIComponent(rrno);
    return new Promise<ResponseData>((resolve) => {
      this.http
        .get<ResponseData>(this.apiUrl + `ledger-web/${val}/${year}`)
        .pipe(timeout(1000000))
        .subscribe(
          (data) => {
            console.log("data", data);
            resolve(data);
          },
          (error) => {
            console.log("Error", error);
          }
        );
    });
  }

  searchConnection(type, value) {
    const val =  encodeURIComponent(value);
    return new Promise<ResponseData>((resolve) => {
      this.http
        .get<ResponseData>(this.apiUrl + `seach-connection/${type}/${val}`)
        .pipe(timeout(1000000))
        .subscribe(
          (data) => {
            console.log("Data", data);
            resolve(data);
          },
          (error) => {
            console.log("Error", error);
          }
        );
    });
  }

  updateConnection(con) {
    return new Promise<Data>((resolve) => {
      this.http.post<Data>(this.apiUrl + "edit-connection", con).subscribe(
        (data) => {
          console.log("Success", data);
          resolve(data);
        },
        (error) => {}
      );
    });
  }

  getMeterChageDetailsByRrno(rrno) {
    return new Promise<ResponseData>((resolve) => {
      this.http
        .get<ResponseData>(this.apiUrl + `meter-change-details-rrno/${rrno}`)
        .pipe(timeout(1000000))
        .subscribe(
          (data) => {
            console.log("meter change data", data);
            resolve(data);
          },
          (error) => {
            console.log("Error", error);
          }
        );
    });
  }

  getNameChangeDetailsByRrno(rrno) {
    return new Promise<ResponseData>((resolve) => {
      this.http
        .get<ResponseData>(this.apiUrl + `name-change-details-rrno/${rrno}`)
        .pipe(timeout(1000000))
        .subscribe(
          (data) => {
            console.log("name change data", data);
            resolve(data);
          },
          (error) => {
            console.log("Error", error);
          }
        );
    });
  }

  getAddressChangeDetailsByRrno(rrno) {
    return new Promise<ResponseData>((resolve) => {
      this.http
        .get<ResponseData>(this.apiUrl + `address-change-details-rrno/${rrno}`)
        .pipe(timeout(1000000))
        .subscribe(
          (data) => {
            console.log("address data", data);
            resolve(data);
          },
          (error) => {
            console.log("Error", error);
          }
        );
    });
  }

  getMeterDisconnectionChangeDetailsByRrno(rrno) {
    return new Promise<ResponseData>((resolve) => {
      this.http
        .get<ResponseData>(
          this.apiUrl + `meterDisconnection-change-details-rrno/${rrno}`
        )
        .pipe(timeout(1000000))
        .subscribe(
          (data) => {
            console.log("meter disconnection data", data);
            resolve(data);
          },
          (error) => {
            console.log("Error", error);
          }
        );
    });
  }

  getMeterReconnectionChangeDetailsByRrno(rrno) {
    return new Promise<ResponseData>((resolve) => {
      this.http
        .get<ResponseData>(
          this.apiUrl + `meterReconnection-change-details-rrno/${rrno}`
        )
        .pipe(timeout(1000000))
        .subscribe(
          (data) => {
            console.log("meter reconnection data", data);
            resolve(data);
          },
          (error) => {
            console.log("Error", error);
          }
        );
    });
  }

  getRevisonChangeDetailsByRrno(rrno) {
    return new Promise<ResponseData>((resolve) => {
      this.http
        .get<ResponseData>(this.apiUrl + `revisons-change-details-rrno/${rrno}`)
        .pipe(timeout(1000000))
        .subscribe(
          (data) => {
            console.log("revison change data", data);
            resolve(data);
          },
          (error) => {
            console.log("Error", error);
          }
        );
    });
  }


  getNoticeChangeDetailsByRrno(rrno) {
    return new Promise<ResponseData>((resolve) => {
      this.http
        .get<ResponseData>(this.apiUrl + `notice-change-details-rrno/${rrno}`)
        .pipe(timeout(1000000))
        .subscribe(
          (data) => {
            console.log("Notice change data", data);
            resolve(data);
          },
          (error) => {
            console.log("Error", error);
          }
        );
    });
  }


  getTariffChangeDetailsByRrno(rrno) {
    return new Promise<ResponseData>((resolve) => {
      this.http
        .get<ResponseData>(this.apiUrl + `tariff-change-details-rrno/${rrno}`)
        .pipe(timeout(1000000))
        .subscribe(
          (data) => {
            console.log("Tariff change data", data);
            resolve(data);
          },
          (error) => {
            console.log("Error", error);
          }
        );
    });
  }


  getConnectionDateChangeDetailsByRrno(rrno) {
    return new Promise<ResponseData>((resolve) => {
      this.http
        .get<ResponseData>(this.apiUrl + `connection-date-change-details-rrno/${rrno}`)
        .pipe(timeout(1000000))
        .subscribe(
          (data) => {
            console.log("Connection date change data", data);
            resolve(data);
          },
          (error) => {
            console.log("Error", error);
          }
        );
    });
  }


  searchByRrno(rrno) {
    return new Promise<ResponseData>((resolve) => {
      this.http
        .get<ResponseData>(this.apiUrl + "connections" + `/${rrno}`)
        .pipe(timeout(1000000))
        .subscribe(
          (data) => {
            console.log("data", data);
            resolve(data);
          },
          (error) => {
            console.log("Error", error);
          }
        );
    });
  }

  deleteLedger(id) {
    return new Promise<ResponseData>((resolve) => {
      this.http
        .get<ResponseData>(this.apiUrl + "delete-ledger" + `/${id}`)
        .pipe(timeout(1000000))
        .subscribe(
          (data) => {
            console.log("data", data);
            resolve(data);
          },
          (error) => {
            console.log("Error", error);
          }
        );
    });
  }

  getLatestLedger(rrno) {
    const val =  encodeURIComponent(rrno);
    return new Promise<ResponseData>((resolve) => {
      this.http
        .get<ResponseData>(this.apiUrl + "latest-bill-data" + `/${val}`)
        .pipe(timeout(1000000))
        .subscribe(
          (data) => {
            console.log("data", data);
            resolve(data);
          },
          (error) => {
            console.log("Error", error);
          }
        );
    });
  }

  getLatestLedgerById(rrno){
    //console.log('rrno',rrno)
    const val =  encodeURIComponent(rrno);
    return new Promise<ResponseData>((resolve) => {
      this.http
        .get<ResponseData>(this.apiUrl + "latest-ledger" + `/${val}`)
        .pipe(timeout(1000000))
        .subscribe(
          (data) => {
            console.log("data", data);
            resolve(data);
          },
          (error) => {
            console.log("Error", error);
          }
        );
    });
  }
  getLastNormalMeterChangeDate(rrno) {
    return new Promise<ResponseData>((resolve) => {
      this.http
        .get<ResponseData>(this.apiUrl + "last-normal-meter-date" + `/${rrno}`)
        .pipe(timeout(1000000))
        .subscribe(
          (data) => {
            console.log("data", data);
            resolve(data);
          },
          (error) => {
            console.log("Error", error);
          }
        );
    });
  }
}
