import { Injectable } from '@angular/core';
import { AngularFireAuth} from '@angular/fire/auth'
import { Router } from '@angular/router';
import { auth } from 'firebase/app';
import { User } from 'firebase';44444
import { Admin } from './admin.model';
import { AngularFirestore, AngularFirestoreCollection } from '@angular/fire/firestore';
import * as firebase from 'firebase/app';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { ToastrService } from 'ngx-toastr';
import { Upload } from '../upload/upload';
import { AngularFireStorage } from '@angular/fire/storage';
import { finalize } from 'rxjs/operators';
import { Observable } from 'rxjs';
import Swal from 'sweetalert2';

@Injectable({
  providedIn: 'root'
})
export class AdminService {

  //formData : Admin;
  //adminList: AngularFirestoreCollection;

  public basePath:string = '/uploads';
  public showErrorMsg:Boolean;
  public printErrorMsg:string;
  downloadURL: Observable<string>;
  imgPath;

  constructor(public afAuth: AngularFireAuth, public router:Router, public afStore:AngularFirestore, public toastrService: ToastrService, private storage: AngularFireStorage) {

  }
  
  getAdmins(){
    /* this.adminList = this.afStore.collection('/users');
    return this.adminList; */
  return this.afStore.collection('/users', ref => ref.where('role', '==', 'admin')).snapshotChanges();

  }
  getAdminsDummy(){
    /* this.adminList = this.afStore.collection('/users');
    return this.adminList; */
    return this.afStore.collection('/admins').snapshotChanges();
  }

  
  async signup(admin_details, imageUrl, validationForm, hasFile, selectedFiles:FileList){
    console.log("admin email", admin_details.email);
    this.showErrorMsg = false;
    var result = await this.afAuth.auth.createUserWithEmailAndPassword(admin_details.email, admin_details.password).then((success) => {
      var adminId = this.afAuth.auth.currentUser.uid;
      if(hasFile && selectedFiles.length != 0){
        this.uploadpic(adminId, imageUrl, admin_details);
        //this.toastrService.success('Added Successfully!', 'Success!');
        this.reset(validationForm);
        this.imgPath = "assets/images/profile.png";
      }
      else
      {
        this.storeAdmin(null,adminId,admin_details);
        //this.toastrService.success('Added Successfully!', 'Success!');
        this.reset(validationForm);
      }
    }).catch((error) => {
      this.showErrorMsg = true;
      setTimeout(() => this.showErrorMsg = false, 8000);
      this.printErrorMsg = error;
    });
    
   // location.reload();
    //this.afStore.collection('users').add(admin);
  }

  reset(validationForm){
    validationForm.setValue({
      name: "",
      email: "",
      phoneNumber: "",
      password: "",
      confirmPassword: "",
      addressLine1: "",
      addressLine2: "",
      ward: "",
      city: "",
      state: "",
      pincode: ""
    });
  }

  updateAdmin(admin, id){
    console.log("id", admin)
    this.afStore.doc('users/'+id).update(admin);
    location.reload();
    //this.router.navigate(['/admins']);
  }

  deleteAdmin(id){
    Swal({
      title: 'Are you sure?',
      text: "You won't be able to revert this!",
      type: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: 'Yes, delete it!'
    }).then((result) => {
      if (result.value) {
        this.afStore.doc('users/'+id).delete();
        this.afStore.collection('deleteUser').doc(id).set({delete: "yes"});
        Swal(
          'Deleted!',
          'Admin has been deleted.',
          'success'
        )
        //this.toastrService.error('Deleted');
      }
    });
  }

  storeAdmin(imageUrl,adminId,admin_details)
  {
    console.log("inside store admin", imageUrl);
    
    this.afStore.collection('users').doc(adminId).set({
      name: admin_details.name,
      phoneNumber: admin_details.phoneNumber,
      email: admin_details.email,
      password: admin_details.password,
      addressLine1: admin_details.addressLine1, 
      addressLine2: admin_details.addressLine2,
      ward: admin_details.ward,
      city: admin_details.city,
      state: admin_details.state,
      pincode: admin_details.pincode,
      role: "admin",
      imagePath:imageUrl
    }).then((success) => {
      this.toastrService.success('Added Successfully!', 'Success!');
    });
  }

  uploadpic(adminId, imageUrl, admin_details){
    let uniqkey = 'pic' + Math.floor(Math.random() * 1000000);
    const uploadTask = this.storage.upload('/admin/' + uniqkey, imageUrl);
    const ref = this.storage.ref('/admin/' + uniqkey);
    console.log("uploadTask", uploadTask);
    //this.imgsrc = uploadTask.downloadURL()
    uploadTask.snapshotChanges().pipe(
      finalize(() => {
        this.downloadURL = ref.getDownloadURL();
        console.log(this.downloadURL);
        this.downloadURL.subscribe(url => {
          this.imgPath= url;
          console.log("url",this.imgPath)
          this.storeAdmin(this.imgPath,adminId,admin_details);
        });
      })
    ).subscribe();
  }
}
