import { NgModule } from '@angular/core';
import { RouterModule, Routes, PreloadAllModules } from '@angular/router';

// Components
import { ContentComponent } from './layouts/content/content.component';
// Routes
import { content } from "./shared/routes/content.routes";
import { authcontent } from "./shared/routes/authcontent.routes";
import { AuthenticationComponent } from './layouts/authentication/authentication.component';
import { ErrorComponent } from './layouts/error/error.component';
import { error } from './shared/routes/error.routes';

const appRoutes: Routes = [
  {
    path: '',
    redirectTo: '/dashboard',
    pathMatch: 'full',
  },
  { 
  	path: '', 
  	component: ContentComponent,
    children: content
  },
  {
    path: 'authentication',
    component: AuthenticationComponent,
    children: authcontent
  },
  {
    path : '**',
    redirectTo : 'error'
  },
  {       
    path: 'error',
    component: ErrorComponent,
    children : error
  }
];

@NgModule({
  imports: [RouterModule.forRoot(appRoutes, { useHash: true , anchorScrolling: 'enabled', onSameUrlNavigation: 'reload', enableTracing: true, scrollPositionRestoration: 'enabled' })],
  exports: [RouterModule]
})

export class AppRoutingModule {
}

