import { Component, Input, OnInit, EventEmitter, Output } from '@angular/core';
import { AngularFirestore } from '@angular/fire/firestore';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { ToastrService } from 'ngx-toastr';
import { AgentService } from 'src/app/shared/agent/agent.service';
import { AuthService } from 'src/app/shared/auth/auth.service';
import { ConnectionService } from 'src/app/shared/service/connection.service';

@Component({
  selector: 'app-edit-connection',
  templateUrl: './edit-connection.component.html',
  styleUrls: ['./edit-connection.component.scss']
})
export class EditConnectionComponent implements OnInit {
  @Input('editConnectionData') editConnectionData;
  @Output('onCloseModal') onCloseModal = new EventEmitter;

  validationForm: FormGroup;
  ward_collection;
  wardNameArray: any = [];
  area_collection;
  areaNameArray: any = [];
  //userId = localStorage.getItem("user_id");
  userName = localStorage.getItem("user_name");
  dateTime;
  dateTimeToDsplay;
  agentArray:any = [];

  constructor(private firestore: AngularFirestore, public toastrService: ToastrService, private agentService: AgentService, private connectionService: ConnectionService, private userService: AuthService) {
    var today = new Date();
    this.dateTime = ((today.getFullYear()) + "-" + (("0" + (today.getMonth() + 1)).slice(-2)) + "-" + ("0" + today.getDate()).slice(-2)) + " " + (("0" + today.getHours()).slice(-2)) + ":" + (("0" + today.getMinutes()).slice(-2)) + ":" + (("0" + today.getSeconds()).slice(-2));

    this.dateTimeToDsplay = ((("0" + today.getDate()).slice(-2)) + "/" + (("0" + (today.getMonth() + 1)).slice(-2)) + "/" + (today.getFullYear())) + " " + (("0" + today.getHours()).slice(-2)) + ":" + (("0" + today.getMinutes()).slice(-2)) + ":" + (("0" + today.getSeconds()).slice(-2));
    this.getArea();
    this.getWard();
  }

  ngOnInit() {
    this.userService.getAllUsers('agent').then((response)=>{
      if(response.code == 200){
        this.agentArray = response.data;
      }
    })

    console.log("editConnectionData", this.editConnectionData);
    this.validationForm = new FormGroup({
      zoneNumber: new FormControl(this.editConnectionData.zoneNumber, Validators.required),
      ward: new FormControl(this.editConnectionData.ward, Validators.required),
      rrno: new FormControl(this.editConnectionData.rrno, Validators.required),
      name: new FormControl(this.editConnectionData.name, Validators.required),
      doorNumber: new FormControl(this.editConnectionData.doorNumber, Validators.required),
      meterNumber: new FormControl(this.editConnectionData.meterNumber, Validators.required),
      address: new FormControl(this.editConnectionData.address, Validators.required),
      tariff: new FormControl(this.editConnectionData.tariff, Validators.required),
      user: new FormControl({ value: this.userName, disabled: true }, Validators.required),
      connectionDate: new FormControl(this.editConnectionData.connectionDate, Validators.required),
     // enteredDate: new FormControl(this.editConnectionData.enteredDate),
      phoneNumber: new FormControl(this.editConnectionData.phoneNumber),
      remarks: new FormControl(this.editConnectionData.remarks, Validators.required),
      connectionStatus: new FormControl(this.editConnectionData.connectionStatus, Validators.required),
      readingDateTime: new FormControl(this.editConnectionData.readingDateTime, Validators.required),
      meterStatus: new FormControl(this.editConnectionData.meterStatus, Validators.required),
      latitude: new FormControl(this.editConnectionData.latitude),
      longitude: new FormControl(this.editConnectionData.longitude),
      tapDiameter: new FormControl(this.editConnectionData.tapDiameter),
      initialReading: new FormControl(this.editConnectionData.initialReading),
      presentReading: new FormControl(this.editConnectionData.presentReading),
      khathaNumber: new FormControl(this.editConnectionData.khathaNumber),
      fromNode: new FormControl(this.editConnectionData.fromNode),
      toNode: new FormControl(this.editConnectionData.toNode),
      agentId: new FormControl(this.editConnectionData.agentId, Validators.required),
      consumption: new FormControl(this.editConnectionData.consumption, Validators.required),
      noOfDays: new FormControl(this.editConnectionData.noOfDays, Validators.required),
      avgConsumption: new FormControl(this.editConnectionData.avgConsumption, Validators.required)
    });

  }

  onClose(close) {
    this.onCloseModal.emit('close');
  }

  getWard() {
    /* this.ward_collection = this.firestore.collection('ward');
    this.ward_collection.valueChanges().subscribe(w => {
      this.wardNameArray = [];
      w.forEach(ward => {
        this.wardNameArray.push(ward.name);
      });
    }); */

    this.agentService.getWards().then((response) => {
      if (response.code == 200) {
        this.wardNameArray = response.data;
      }
    })
  }

  getArea() {
    /* this.area_collection = this.firestore.collection('area');
    this.area_collection.valueChanges().subscribe(a => {
      this.areaNameArray = [];
      a.forEach(area => {
        this.areaNameArray.push(area.name);
      });
    }); */

    this.agentService.getAreas().then((response) => {
      if (response.code == 200) {
        this.areaNameArray = response.data;
      }
    })
  }

  onSubmit() {

    console.log("this", this.validationForm.value);
    var connection = this.validationForm.value;
    //connection.enteredDate = this.dateTime;
    //connection.userId = this.userId;
    connection.id = this.editConnectionData.id;
    delete connection.user;


    this.connectionService.updateConnection(connection).then(response => {
      if (response.code == 200) {
        this.toastrService.success('Connection updated Successfully', 'Success!');
        this.onCloseModal.emit('close');
      }
      else {
        this.toastrService.error('Connection updation failed', 'Error!')
      }
    })
  }

}
