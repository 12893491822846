import { Component, OnInit } from "@angular/core";
import * as Chartist from "chartist";
import { ChartEvent, ChartType } from "ng-chartist";
import * as chartData from "../../shared/chart/google-chart";
import { AuthService } from "src/app/shared/auth/auth.service";
import { DashboardService } from "src/app/shared/service/dashboard.service";

import { Router } from '@angular/router';
import { analyzeAndValidateNgModules } from "@angular/compiler";

export interface Chart {
  type: ChartType;
  data: Chartist.IChartistData;
  options?: any;
  responsiveOptions?: any;
  events?: ChartEvent;
}

@Component({
  selector: "app-dashboard",
  templateUrl: "./dashboard.component.html",
  styleUrls: ["./dashboard.component.scss"],
})
export class DashboardComponent implements OnInit {
  public connectionCount;
  public liveConnectionCount;
  public disconnectedCount;
  public billsInCurrentMonthCount;
  public untraceableLiveConnectionsCount;
  public unbilledInCurrentMonthCount;

  public pieChart1; 
  public pieChart2;
  public totalCashCollected;
  constructor(
    private authService: AuthService,
    private dashboardService: DashboardService,
    private router: Router
  ) {
  
  
    
  }

  ngOnInit() {
    this.router.routeReuseStrategy.shouldReuseRoute = () => false;
    console.log("to check is logged in method", this.authService.isLoggednIn());
    this.getAllData();
 
  }

  // Simple line chart
  salesChart: Chart = {
    type: "Line",
    data: {
      labels: ["Mon", "Tue", "Wed", "Thu", "Fri"],
      series: [
        [12, 9, 7, 8, 5],
        [2, 1, 3.5, 7, 3],
        [1, 3, 4, 5, 6],
      ],
    },
    options: {
      height: 475,
      fullWidth: true,
      chartPadding: {
        right: 40,
      },
    },
  };

  // Widget Bar Chart
  WidgetBarChart: Chart = {
    type: "Bar",
    data: {
      series: [[5, 10, 9, 2, 4, 9, 5, 7, 5, 6, 6]],
    },
    options: {
      axisX: {
        showGrid: false,
        showLabel: false,
        offset: 0,
      },
      axisY: {
        showGrid: false,
        low: 0,
        showLabel: false,
        offset: 0,
      },
      chartPadding: {
        bottom: 0,
      },
      showArea: true,
      fullWidth: true,
      height: 100,
    },
  };

  ucmcOfficialWebsite(){
    window.open('http://www.hassancity.mrc.gov.in')
  }

  draft4sms(){
    window.open('http://text.draft4sms.com/')
  }

  getAllData() {
    console.log("method");
    /* this.authService.getAllUsers('admin').then(response =>{
      console.log("response", response);
      
    }) */

    this.dashboardService.getDashboardData().then((response) => {
      console.log("response", response);
      var data = response.data;
      if (response.code == 200) {
        this.connectionCount = data.connectionCount;
        this.liveConnectionCount = data.liveConnectionCount;
        this.disconnectedCount = data.disconnectedCount;
        this.billsInCurrentMonthCount = data.billsInCurrentMonthCount;
       // this.untraceableLiveConnectionsCount = data.untraceableLiveConnection;
        this.unbilledInCurrentMonthCount =
        this.liveConnectionCount - this.billsInCurrentMonthCount;

        /* this.pieChart1= {
          chartType: "PieChart",
          dataTable: [
            ["Task", "Hours per Day"],
            [`avg = ${data.avgCount}`, data.avgCount],
            [`normal = ${data.normalCount}`, data.normalCount],
            [`do = ${data.doCount}`, data.doCount],
            [`dc = ${data.dcCount}`, data.dcCount],
            [`ml = ${data.mlCount}`, data.mlCount],
            [`mf = ${data.mfCount}`, data.mfCount],
            [`gl = ${data.glCount}`, data.glCount],
            // ["other", data.otherCount],
            [`mnr = ${data.mnrCount}`, data.mnrCount],
            [`nl = ${data.nlCount}`, data.nlCount],
            [`rnt = ${data.rntCount}`, data.rntCount],
            [`Grand Total = ${this.billsInCurrentMonthCount}`,'']
          ],
          options: {
            title: "MONTHLY WATER BILLS",
            width: "100%",
            height: 400,
            sliceVisibilityThreshold: 0,
            colors: [
              "#ab8ce4",
              "#26c6da",
              "#00c292",
              "#4099ff",
              "#FF5370",
              "#ffed5f",
              "#cc0099",
              "#ff9900",
              "#d4c12d",
              "#337697",
              "#346",
              "#789",
            ],
          },
        };
      
        this.totalCashCollected = Number(data.corporationBankAmountCount) + Number(data.syndicateBankAmountCount)
        this.pieChart2 = {
          chartType: "PieChart",
          dataTable: [
            ["Task", "Hours per Day"],
            [`Total Collection Corporation = ${data.corporationBankAmountCount}`, data.corporationBankAmountCount],
            [`Total bank Collection Syndicate = ${data.syndicateBankAmountCount}`, data.syndicateBankAmountCount],
            [`Grand Total = ${this.totalCashCollected}`,'']
          ],
          options: {
            title: "MONTHLY BANK PAYMENT COLLECTIONS",
            sliceVisibilityThreshold: 0,
            width: "100%",
            height: 400,
            colors: ["#ab8ce4", "#26c6da", "#00c292", "#4099ff", "#FF5370"],
          },
        }; */
        var zone1 = (Number(data.zoneWiseConnectionCount[0]['zoneWiseConnectionCount']));
        var zone3 = (Number(data.zoneWiseConnectionCount[1]['zoneWiseConnectionCount']));
        var zone4 = data.zoneWiseConnectionCount[2]['zoneWiseConnectionCount'];
        var zone5 = data.zoneWiseConnectionCount[3]['zoneWiseConnectionCount'];
        var zone6 = (Number(data.zoneWiseConnectionCount[4]['zoneWiseConnectionCount']));
        var zone202 = (Number(data.zoneWiseConnectionCount[5]['zoneWiseConnectionCount']));
        var zone25 = data.zoneWiseConnectionCount[6]['zoneWiseConnectionCount'];
        this.pieChart1= {
          chartType: "PieChart",
          dataTable: [
            ["Task", "Hours per Day"],
            [`${data.zoneWiseConnectionCount[0]['zoneNumber']} = ${zone1}`, zone1],
            [`${data.zoneWiseConnectionCount[1]['zoneNumber']} = ${zone3}`, zone3],
            [`${data.zoneWiseConnectionCount[2]['zoneNumber']} = ${zone4}`, zone4],
            [`${data.zoneWiseConnectionCount[3]['zoneNumber']} = ${zone5}`, zone5],
            [`${data.zoneWiseConnectionCount[4]['zoneNumber']} = ${zone6}`, zone6],
            [`${data.zoneWiseConnectionCount[5]['zoneNumber']} = ${zone202}`, zone202],
            [`${data.zoneWiseConnectionCount[6]['zoneNumber']} = ${zone25}`, zone25], 
            /* [`mf = 40`, 40],
            [`gl = 30`, 30],
            [`mnr = 50`, 50],
            [`nl = 52`, 52],
            [`rnt = 85`, 85],
            [`Grand Total = 50`,''] */
          ],
          options: {
            title: "MONTHLY WATER BILLS",
            width: "100%",
            height: 400,
            sliceVisibilityThreshold: 0,
            colors: [
              "#ab8ce4",
              "#26c6da",
              "#00c292",
              "#4099ff",
              "#FF5370",
              "#ffed5f",
              "#cc0099",
              "#ff9900",
              "#d4c12d",
              "#337697",
              "#346",
              "#789",
            ],
          },
        };
      }
    });
  }
}
