
import { Component, Input, OnInit, Output, EventEmitter } from "@angular/core";
import { AngularFirestore } from "@angular/fire/firestore";
import { FormControl, FormGroup, Validators } from "@angular/forms";
import { ToastrService } from "ngx-toastr";
import { flatMap } from "rxjs/operators";
import { BillingService } from "src/app/shared/service/billing.service";
@Component({
  selector: 'app-change-details-user-pic',
  templateUrl: './change-details-user-pic.component.html',
  styleUrls: ['./change-details-user-pic.component.scss']
})

export class ChangeDetailsUserPicComponent implements OnInit {
  @Input("userPicData") userPicData;
  @Output("onCloseModal") onCloseModal = new EventEmitter();
  public lat: any;
  public longitude:any;
  public userPic: any;
  constructor() { }

  ngOnInit() {
    this.userPic = this.userPicData
    // console.log(this.userPicData)
  }
  onClose(close) {
    this.onCloseModal.emit("close");
  }

}
