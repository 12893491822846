import { Component, OnInit, ViewChild, Injectable } from "@angular/core";
import { DataTableDirective } from "angular-datatables";
import { FormControl, FormGroup, Validators } from "@angular/forms";

import {
  AngularFirestore,
  AngularFirestoreCollection,
} from "@angular/fire/firestore";
import {
  flatMap,
  catchError,
  debounceTime,
  distinctUntilChanged,
  filter,
  map,
  switchMap,
  tap,
} from "rxjs/operators";
import { Customer } from "src/app/shared/customer/customer.model";
import { Observable } from "rxjs/Observable";
import { Subject, merge, of, observable } from "rxjs";
import { NgbModal, NgbTypeahead } from "@ng-bootstrap/ng-bootstrap";
import { HttpParams, HttpClient } from "@angular/common/http";
import { ToastrService } from "ngx-toastr";
import { data } from "jquery";
import { AgentService } from "src/app/shared/agent/agent.service";
import { ConnectionService } from "src/app/shared/service/connection.service";
import { environment } from "src/environments/environment";
import { LedgerService } from "src/app/shared/service/ledger.service";

import Swal from "sweetalert2";
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { AuthService } from "src/app/shared/auth/auth.service";

declare var require: any;
var moment = require("moment");

const WIKI_URL =
  "https://us-central1-mnccompusolution-83617.cloudfunctions.net/search";
const PARAMS = new HttpParams({
  fromObject: {
    action: "opensearch",
    format: "json",
    origin: "*",
  },
});

@Injectable()

export class WikipediaService {
  constructor(private http: HttpClient) { }

  search(term: string) {
    if (term === "") {
      return of([]);
    }

    return this.http
      .get(WIKI_URL, { params: PARAMS.set("search", term) })
      .pipe(map((response) => response[1]));
  }
}

@Component({
  selector: "app-find-connection-one",
  templateUrl: "./find-connection-one.component.html",
  styleUrls: ["./find-connection-one.component.scss"],
  providers: [WikipediaService],
})
export class FindConnectionOneComponent implements OnInit {
  dateTime;
  dateTimeToDsplay;
  currentDate;

  @ViewChild(DataTableDirective)
  datatableElement: DataTableDirective;
  min: number;
  max: number;
  currentMonth;
  currentYear;
  latestMonth;
  latestYear;
  // title: string = "My first AGM project";
  // lat: number = 12.8745783;
  // lng: number = 74.8464408;
  // zoom: number = 10;

  public isVisible = false;

  dtOptions: DataTables.Settings = {};

  RRNOS: any;
  customer: Customer;
  userPicData: any;
  rrnoFilter: Boolean = true;
  nameFilter: Boolean;
  doorNoFilter: Boolean;
  meterNoFilter: Boolean;

  searching = false;
  searchFailed = false;

  showMessage;
  checkRRNO: boolean = false;

  latestCbaArray = [];
  displayCBA;

  year = moment().format("YYYY");
  years = [
    "2023",
    "2022",
    "2021",
    "2020",
    "2019",
    "2018",
    "2017",
    "2016",
    "2015",
    "2014",
    "2013",
    "2012",
    "2011",
    "2010",
    "2009",
    "2008",
  ];

  rrnoForm = new FormGroup({
    rrno: new FormControl("", Validators.required),
    year: new FormControl(this.year),
  });

  doorNoForm = new FormGroup({
    doorNo: new FormControl("", Validators.required),
    year: new FormControl(this.year),
  });

  meterNoForm = new FormGroup({
    meterNo: new FormControl("", Validators.required),
    year: new FormControl(this.year),
  });

  nameForm = new FormGroup({
    name: new FormControl("", Validators.required),
    year: new FormControl(this.year),
  });

  submitted: Boolean;

  customer_details;
  //connection_details: AngularFirestoreCollection;
  connection_data: any[];
  clients: Observable<any[]>;

  click$ = new Subject<string>();
  @ViewChild("instance") instance: NgbTypeahead;
  focus$ = new Subject<string>();
  cnames;
  crrno;
  cdoorno;
  cmeterno;
  //crrnoArray = [];

  nameChangeData: any;
  meterChangeData: any;
  meterDisconnectionData: any;
  ledgerArray: any;
  typeChangeData: any;
  addressChangeData: any;
  meterReconnectionData: any;
  noticeDetailsData: any;
  editConnectionData: any;
  imageData: any;
  connectionDateChangeData: any;
  billDetailsData: any;
  mapData: any;
  revisionData: any;
  latestBillData: any;

  today: number = Date.now();
  popupMessage: any;
  userId = localStorage.getItem("user_id");
  userName = localStorage.getItem("user_name");
  userRole = localStorage.getItem("loggedInUserRole");

  validationForm = new FormGroup({
    zoneNumber: new FormControl("", Validators.required),
    ward: new FormControl("", Validators.required),
    rrno: new FormControl("", Validators.required),
    name: new FormControl("", Validators.required),
    doorNumber: new FormControl("", Validators.required),
    meterNumber: new FormControl("", Validators.required),
    address: new FormControl("", Validators.required),
    tariff: new FormControl("", Validators.required),
    /* user: new FormControl(
      { value: this.userName, disabled: true },
      Validators.required
    ), */
    connectionDate: new FormControl("", Validators.required),
    //enteredDate: new FormControl(),
    phoneNumber: new FormControl(),
    remarks: new FormControl("", Validators.required),
    connectionStatus: new FormControl(
      { value: "Live", disabled: true },
      Validators.required
    ),
    readingDateTime: new FormControl("", Validators.required),
    meterStatus: new FormControl(
      { value: "Good", disabled: true },
      Validators.required
    ),
    latitude: new FormControl(""),
    longitude: new FormControl(""),
    tapDiameter: new FormControl(""),
    initialReading: new FormControl(),
    presentReading: new FormControl(),
    khathaNumber: new FormControl(""),
    fromNode: new FormControl(""),
    toNode: new FormControl(""),
    agentId: new FormControl("", Validators.required),
    consumption: new FormControl("", Validators.required),
    noOfDays: new FormControl("", Validators.required),
    avgConsumption: new FormControl("", Validators.required)
  });

  formControls = this.validationForm.controls;
  wardNameArray = [];
  ward_collection;
  areaNameArray = [];
  area_collection;
  previousReading;
  previousStatus;
  meterChangeDetails;
  meterChangeDetailsArray = [];
  nameChangeDetailsArray = [];
  addressChangeDetailsArray = [];
  meterDisconnectionChangeDetailsArray = [];
  meterReconnectionChangeDetailArray = [];
  revisonChangeDetailArray = [];
  noticeChangeDetailArray = [];
  tariffChangeDetailArray = [];
  connectionDateChangeDetailArray = [];
  agentArray:any = [];

  showDummyLedger = false
  constructor(
    private firestore: AngularFirestore,
    private _service: WikipediaService,
    public toastrService: ToastrService,
    private modalService: NgbModal,
    private agentService: AgentService,
    private connectionService: ConnectionService,
    private totastrService: ToastrService,
    private ledgerService: LedgerService,
    private userService: AuthService
  ) {
    var today = new Date();
    this.dateTime =
      today.getFullYear() +
      "-" +
      ("0" + (today.getMonth() + 1)).slice(-2) +
      "-" +
      ("0" + today.getDate()).slice(-2) +
      " " +
      ("0" + today.getHours()).slice(-2) +
      ":" +
      ("0" + today.getMinutes()).slice(-2) +
      ":" +
      ("0" + today.getSeconds()).slice(-2);

    this.dateTimeToDsplay =
      ("0" + today.getDate()).slice(-2) +
      "/" +
      ("0" + (today.getMonth() + 1)).slice(-2) +
      "/" +
      today.getFullYear() +
      " " +
      ("0" + today.getHours()).slice(-2) +
      ":" +
      ("0" + today.getMinutes()).slice(-2) +
      ":" +
      ("0" + today.getSeconds()).slice(-2);
    this.currentDate =
      today.getFullYear() +
      "-" +
      ("0" + (today.getMonth() + 1)).slice(-2) +
      "-" +
      ("0" + today.getDate()).slice(-2);
  }

  ngOnInit() {
    this.currentMonth = moment().format('MM');
    this.currentYear = moment().format('YYYY');
    console.log('ng on init called ');
    $.fn["dataTable"].ext.search.push((settings, data, dataIndex) => {
      const id = parseFloat(data[0]) || 0; // use data for the id column
      if (
        (isNaN(this.min) && isNaN(this.max)) ||
        (isNaN(this.min) && id <= this.max) ||
        (this.min <= id && isNaN(this.max)) ||
        (this.min <= id && id <= this.max)
      ) {
        return true;
      }
      return false;
    });

    this.dtOptions = {};

    /* this.ward_collection = this.firestore.collection('ward');
    this.ward_collection.valueChanges().subscribe(w => {
      this.wardNameArray = [];
      w.forEach(ward => {
        this.wardNameArray.push(ward.name);
      });
    }); */

    /* this.area_collection = this.firestore.collection('area');
    this.area_collection.valueChanges().subscribe(a => {
      this.areaNameArray = [];
      a.forEach(area => {
        this.areaNameArray.push(area.name);
      });
    }); */

    // this.agentService.getWards().then((response) => {
    //   if (response.code == 200) {
    //     this.wardNameArray = response.data;
    //   }
    // });

    this.agentService.getAreas().then((response) => {
      if (response.code == 200) {
        this.areaNameArray = response.data;
      }
    });

    this.userService.getAllUsers('agent').then((response)=>{
      if(response.code == 200){
        this.agentArray = response.data;
      }
    })
  }

  ngOnDestroy(): void { }

  onSelect(event) {
    this.customer_details = null;
    this.rrnoFilter = false;
    this.doorNoFilter = false;
    this.meterNoFilter = false;
    this.nameFilter = false;
    this.isVisible = false;
    this.submitted = false;
    //console.log("event is", event.target.value);
    if (event.target.value == 1) {
      this.rrnoFilter = true;
    } else if (event.target.value == 2) {
      this.doorNoFilter = true;
    } else if (event.target.value == 3) {
      this.meterNoFilter = true;
    } else {
      this.nameFilter = true;
    }
  }

  loadData(type, value) {
    this.showDummyLedger = false;
    this.connection_data = null;

    this.meterChangeDetailsArray = null;
    this.ledgerArray = null;
    this.nameChangeDetailsArray = null;
    this.meterReconnectionChangeDetailArray = null;
    this.meterDisconnectionChangeDetailsArray = null;
    this.revisonChangeDetailArray = null;
    this.addressChangeDetailsArray = null;
    this.customer_details = null;
    this.noticeChangeDetailArray = null;
    this.tariffChangeDetailArray = null;
    this.connectionDateChangeDetailArray = null;
    //console.log(this.connection_data, this.meterChangeDetailsArray, this.ledgerArray, this.nameChangeDetailsArray, this.meterReconnectionChangeDetailArray, this.revisonChangeDetailArray, this.addressChangeDetailsArray)
    console.log("log", value);
    
    this.connectionService.searchConnection(type, value).then((response) => {
      if (response.code == 200) {
        this.connection_data = [];
        this.meterChangeDetailsArray = [];
        this.ledgerArray = [];
        this.nameChangeDetailsArray = [];
        this.meterReconnectionChangeDetailArray = [];
        this.meterDisconnectionChangeDetailsArray = [];
        this.revisonChangeDetailArray = [];
        this.addressChangeDetailsArray = [];
        this.customer_details;

        this.noticeChangeDetailArray = [];
        this.tariffChangeDetailArray = [];
        this.connectionDateChangeDetailArray = []
        //console.log(this.connection_data, this.meterChangeDetailsArray, this.ledgerArray, this.nameChangeDetailsArray, this.meterReconnectionChangeDetailArray, this.revisonChangeDetailArray, this.addressChangeDetailsArray)
        console.log("conection details", response.data[0]);
        this.customer_details = response.data[0];
        var rrno = response.data[0].rrno;

        this.connectionService
          .getLedgerByRrno(rrno, this.year)
          .then((response) => {
            if (response.code == 200) {
              if (response.data.length > 0) {
                this.pushToConnectionData(response.data);
                this.latestCBA(response.data);
              }
            }
          });
        this.connectionService.getLatestLedger(rrno).then((response) => {
          if (response.code == 200) {

            // console.log("latest bill data", response);
            this.latestBillData = response.data;
          }
        });
      }
    });
  }


  latestCBA(ledger) {

    ledger.forEach((element) => {
      this.ledgerArray.push(element);
    });
    if (this.ledgerArray.length > 0) {
      this.previousReading = this.ledgerArray[0].presentReading;
      this.previousStatus = this.ledgerArray[0].status;
    }
  }

  pushToConnectionData(data) {
    /* connection_details.snapshotChanges().subscribe((data)=>{ */

    // console.log("bil da", data);
    data.map((d) => {
      var bill = {
        id: d.id,
        latitude: d.latitude,
        longitude: d.longitude,
        previousBillDate: d.previousBillDate,
        billDateTime: d.billDateTime,
        previousReadingDate: d.readingDate,
        noOfDays: d.noOfDays,
        previousReading: d.previousReading,
        presentReading: d.presentReading,
        consumption: d.consumption,
        status: d.status,
        OBA: d.oba,
        waterCharge: d.waterCharge,
        serviceCharge: d.serviceCharge,
        penalty: d.penalty,
        arrears: d.arrears,
        adjustment: d.adjustment,
        demand: d.demand,
        CBA: d.cba,
        billNo: d.billNo,
        paidAmount: d.paidAmount,
        receiptDate: d.receiptDate,
        receiptNo: d.receiptNo,
        agent: d.agent,
        year: d.year,
        month: d.month,
        total: d.total,
        dueDate: d.dueDate == undefined ? "" : d.dueDate,
        image: d.image,
        rrno: d.rrno,
        remarks: d.remarks,
        deleteShow: false,
        receiptDetails: d.receiptDetails,
        revision: d.revision,
        userImageName: d.imageName,
        revisionRemark: d.revisionRemark,
      };
      this.connection_data.push(bill);
      //console.log("this.connection_pdf data", this.connection_data);
    });
    if (data.length > 0) {
      this.connection_data[data.length - 1]["deleteShow"] = true;
    }
    /*  }); */
  }

  pushToMeterCahangeData(data) {

    data.forEach((element) => {
      var mcd = {
        oldMeterSerialNumber: element.oldMeterSerialNumber,
      };
      this.meterChangeDetailsArray.push(element);
    });
  }

  pushToNameCahangeData(data) {

    data.forEach((element) => {
      var ncd = {
        oldName: element.oldName,
      };

      this.nameChangeDetailsArray.push(element);
    });
  }

  pushToMeterReconnectionCahangeData(data) {

    data.forEach((element) => {
      this.meterReconnectionChangeDetailArray.push(element);
    });
  }

  pushToMeterDisconnectionCahangeData(data) {

    data.forEach((element) => {
      this.meterDisconnectionChangeDetailsArray.push(element);
    });
  }

  pushToRevisonCahangeData(data) {

    data.forEach((element) => {
      this.revisonChangeDetailArray.push(element);
    });
  }

  pushToNoticeCahangeData(data) {

    data.forEach((element) => {
      this.noticeChangeDetailArray.push(element);
    });
  }

  pushToTariffCahangeData(data) {

    data.forEach((element) => {
      this.tariffChangeDetailArray.push(element);
    });
  }

  pushToConnectionDateCahangeData(data) {

    data.forEach((element) => {
      this.connectionDateChangeDetailArray.push(element);
    });
  }

  pushToAddressCahangeData(data) {

    data.forEach((element) => {
      var acd = {
        oldStreetName: element.oldStreetName,
      };

      this.addressChangeDetailsArray.push(element);
    });
  }

  filterByDoorNo() {
    this.submitted = true;
    if (this.doorNoForm.valid) {
      this.isVisible = true;

      let doorNoFormValue: string = this.doorNoForm.get("doorNo").value;
      console.log("rrno", doorNoFormValue["RRNO"]);
      this.year = this.doorNoForm.get("year").value;
      let doorNo = doorNoFormValue["doorNo"];
      let rrno = doorNoFormValue["RRNO"];
      this.RRNOS = rrno;
      this.loadData("rrno", rrno);
      this.getLatestLedger(rrno);
      this.submitted = false;
      // this.doorNoForm.reset();
      this.doorNoForm.controls.year.setValue(this.year);
    }
  }

  filterByRRNO() {
    this.submitted = true;
    if (this.rrnoForm.valid) {
      this.isVisible = true;

      let rrnoFormValue: string = this.rrnoForm.get("rrno").value;
      console.log("rrno", rrnoFormValue["rrno"]);
      let rrno = rrnoFormValue["rrno"];
      this.year = this.rrnoForm.get("year").value;
      this.RRNOS = rrno;
      this.loadData("rrno", rrno);
      this.getLatestLedger(rrno);
      this.submitted = false;
      //this.rrnoForm.reset();
      this.rrnoForm.controls.year.setValue(this.year);
    }
  }

  filterByMeterNo() {
    this.submitted = true;
    if (this.meterNoForm.valid) {
      this.isVisible = true;

      // let meterNo: string = this.meterNoForm.get("meterNo").value;
      let meterNo: string = this.meterNoForm.get("meterNo").value;
      this.year = this.meterNoForm.get("year").value;
      let rrno = meterNo["RRNO"];
      let meterSerialNumber = meterNo["meterSerialNumber"]
      // console.log('meteerrrrrrr',rrno)
      this.RRNOS = rrno;
      this.loadData("rrno", rrno);
      this.getLatestLedger(rrno);
      this.submitted = false;
      // this.meterNoForm.reset();
      this.meterNoForm.controls.year.setValue(this.year);
    }
  }

  filterByName() {
    this.submitted = true;
    if (this.nameForm.valid) {
      this.isVisible = true;

      let name: string = this.nameForm.get("name").value;
      this.year = this.nameForm.get("year").value;

      let rrno = name["RRNO"];
      this.RRNOS = rrno;
      this.loadData("rrno", rrno);
      this.getLatestLedger(rrno);
      this.submitted = false;
      // this.nameForm.reset();
      this.nameForm.controls.year.setValue(this.year);
    }
  }

  searchFocusOnName = (text$: Observable<string>) => {
    const debouncedText$ = text$.pipe(
      debounceTime(200),
      distinctUntilChanged()
    );
    const clicksWithClosedPopup$ = this.click$.pipe(
      filter(() => !this.instance.isPopupOpen())
    );
    const inputFocus$ = this.focus$;

    let cnamesArray = [];

    this.connectionService.getAllConnection().then((response) => {
      if (response.code == 200) {
        response.data.forEach((element) => {
          cnamesArray.push({ RRNO: element.rrno, name: element.name });
        });
      }
    });
    return merge(debouncedText$, inputFocus$, clicksWithClosedPopup$).pipe(
      map((term) =>
        (term === "" ? cnamesArray
          : cnamesArray.filter(
            (v) => v.name.toLowerCase().indexOf(term.toLowerCase()) > -1
          )
        ).slice(0, 25)
      )
    );
  };

  searchFocusOnRRNO = (text$: Observable<string>) => {
    const debouncedText$ = text$.pipe(
      debounceTime(200),
      distinctUntilChanged()
    );
    const clicksWithClosedPopup$ = this.click$.pipe(
      filter(() => !this.instance.isPopupOpen())
    );
    const inputFocus$ = this.focus$;

    let crrnoArray = [];

    this.connectionService.getAllConnection().then((response) => {
      if (response.code == 200) {
        response.data.forEach((element) => {
          crrnoArray.push({ rrno: element.rrno, name: element.name });
        });
      }
    });
    return merge(debouncedText$, inputFocus$, clicksWithClosedPopup$).pipe(
      map((term) =>
        (term === ""
          ? crrnoArray
          : crrnoArray.filter(
            (v) => 
             // console.log("test", v);
              v.rrno.toLowerCase().indexOf(term.toLowerCase()) > -1
             
            
          )
        ).slice(0, 25)
      )
    );
  };

  // searchFocusOnRRNO = (text$: Observable<string>) => {
  //   console.log("x", text$);
    
  //   const debouncedText$ = text$.pipe(
  //     debounceTime(200),
  //     distinctUntilChanged()
  //   );
  //   const clicksWithClosedPopup$ = this.click$.pipe(
  //     filter(() => !this.instance.isPopupOpen())
  //   );
  //   const inputFocus$ = this.focus$;

  //   console.log("search_text ", inputFocus$);

  //   let crrnoArray = [];

  //   this.connectionService.getAllConnection().then((response) => {
  //     if (response.code == 200) {
  //      console.log("hai");
       
  //       response.data.forEach((element) => {
  //         crrnoArray.push({ RRNO: element.rrno, name: element.name });
  //       });
  //     }
  //   });
  
  //   return merge(debouncedText$, inputFocus$, clicksWithClosedPopup$).pipe(
  //     map((term) =>
  //       (term === ""
  //         ? crrnoArray
  //         : crrnoArray.filter(
  //           (v) => v.RRNO.toLowerCase().indexOf(term.toLowerCase()) > -1
  //         )
  //       ).slice(0, 25)
  //     )
  //   );
  // };

  rrnoFormatter = (x: { rrno: string }) => x.rrno;
  doorNoFormatter = (x: { doorNo: string }) => x.doorNo;
  nameFormatter = (x: { name: string }) => x.name;
  meterNoFormatter = (x: { meterSerialNumber: string }) => x.meterSerialNumber;
  searchFocusOnDoorNo = (text$: Observable<string>) => {
    const debouncedText$ = text$.pipe(
      debounceTime(200),
      distinctUntilChanged()
    );
    const clicksWithClosedPopup$ = this.click$.pipe(
      filter(() => !this.instance.isPopupOpen())
    );
    const inputFocus$ = this.focus$;

    let cdoornoArray = [];

    this.connectionService.getAllConnection().then((response) => {
      if (response.code == 200) {
        response.data.forEach((element) => {
          cdoornoArray.push({
            rrno: element.rrno,
            doorNo: element.doorNumber,
            name: element.name,
          });
        });
      }
    });
    return merge(debouncedText$, inputFocus$, clicksWithClosedPopup$).pipe(
      map((term) =>
        (term === ""
          ? cdoornoArray
          : cdoornoArray.filter(
            (v) => v.doorNo.toLowerCase().indexOf(term.toLowerCase()) > -1
          )
        ).slice(0, 25)
      )
    );
  };

  searchFocusOnMeterNo = (text$: Observable<string>) => {
    const debouncedText$ = text$.pipe(
      debounceTime(200),
      distinctUntilChanged()
    );
    const clicksWithClosedPopup$ = this.click$.pipe(
      filter(() => !this.instance.isPopupOpen())
    );
    const inputFocus$ = this.focus$;

    let cmeternoArray = [];

    this.connectionService.getAllConnection().then((response) => {
      if (response.code == 200) {
        response.data.forEach((element) => {
          cmeternoArray.push({ RRNO: element.rrno, meterSerialNumber: element.meterNumber });
        });
      }
    });
    return merge(debouncedText$, inputFocus$, clicksWithClosedPopup$).pipe(
      map((term) => (term === "" ? [] : cmeternoArray.filter((v) => v.meterSerialNumber == term)))
    );
  };

  searchTemp = (text$: Observable<string>) => {
    const debouncedText$ = text$.pipe(
      debounceTime(200),
      distinctUntilChanged()
    );
    const clicksWithClosedPopup$ = this.click$.pipe(
      filter(() => !this.instance.isPopupOpen())
    );
    const inputFocus$ = this.focus$;

    let crrnoArray = [];

    this.connectionService.getAllConnection().then((response) => {
      if (response.code == 200) {
        response.data.forEach((element) => {
          crrnoArray.push({ rrno: element.rrno, name: element.name });
        });
      }
    });
    return merge(debouncedText$, inputFocus$, clicksWithClosedPopup$).pipe(
      map((term) =>
        (term === ""
          ? []
          : crrnoArray.filter(
            (v) => v.rrno.toLowerCase().indexOf(term.toLowerCase()) > -1
          )
        ).slice(0, 10)
      )
    );
  };

  formatter = (x: { rrno: string }) => x.rrno;

  searchWikipedia = (text$: Observable<string>) =>
    text$.pipe(
      debounceTime(300),
      distinctUntilChanged(),
      tap(() => (this.searching = true)),
      switchMap((term) =>
        this._service.search(term).pipe(
          tap(() => (this.searchFailed = false)),
          catchError(() => {
            this.searchFailed = true;
            return of([]);
          })
        )
      ),
      tap(() => (this.searching = false))
    );

  reset() {
    this.validationForm.setValue({
      zoneNumber: "",
      ward: "",
      rrno: "",
      name: "",
      doorNumber: "",
      meterNumber: "",
      address: "",
      tariff: "",
      connectionDate: "",
      phoneNumber: "",
      remarks: "",
      connectionStatus: "Live",
      readingDateTime: "",
      meterStatus: "Good",
      latitude: "",
      longitude: "",
      tapDiameter: "",
      initialReading: "",
      presentReading: "",
      khathaNumber: "",
      fromNode: "",
      toNode: "",
      agentId: "",
      consumption: "",
      noOfDays: "",
      avgConsumption: ""
    });
  }

  onSubmit() {
    this.submitted = true;
    this.checkRRNO = false;
    console.log("connection details", this.validationForm.value);
    if (this.validationForm.valid) {
      if (this.submitted == true) {
        var con = {
          zoneNumber: this.validationForm.value.zoneNumber,
          ward: this.validationForm.value.ward,
          rrno: this.validationForm.value.rrno,
          name: this.validationForm.value.name,
          doorNumber: this.validationForm.value.doorNumber,
          meterNumber: this.validationForm.value.meterNumber,
          address: this.validationForm.value.address,
          tariff: this.validationForm.value.tariff,
          connectionDate: this.validationForm.value.connectionDate,
          phoneNumber: this.validationForm.value.phoneNumber,
          remarks: this.validationForm.value.remarks,
          connectionStatus: this.validationForm.value.connectionStatus,
          readingDateTime: this.validationForm.value.readingDateTime,
          meterStatus: this.validationForm.value.meterStatus,
          latitude: this.validationForm.value.latitude,
          longitude: this.validationForm.value.longitude,
          tapDiameter: this.validationForm.value.tapDiameter,
          initialReading: this.validationForm.value.initialReading,
          presentReading: this.validationForm.value.presentReading,
          khathaNumber: this.validationForm.value.khathaNumber,
          fromNode: this.validationForm.value.fromNode,
          toNode: this.validationForm.value.toNode,
          agentId: this.validationForm.value.agentId,
          consumption: this.validationForm.value.consumption,
          noOfDays: this.validationForm.value.noOfDays,
          avgConsumption: this.validationForm.value.avgConsumption
        };

        this.connectionService.createConnection(con).then((response) => {
          if (response.code == 200) {
            this.toastrService.success(
              "Connection Added Successfully!",
              "Success!"
            );
            this.submitted = false;
            this.reset();
            this.checkRRNO = false;
            //this.searchFocusOnRRNO('event')
            setTimeout(() => {
              location.reload();
            }, 1000);
          }
        });
      }
    }
  }

  checkRRNOexist(event) {
    //console.log(event.target.value);
    var rrno = event.target.value;
    this.checkRRNO = false;
    var res = rrno.charAt(0).toUpperCase() + rrno.slice(1);

    this.connectionService.checkRrnoExist(res).then((response) => {
      if (response.code == 200) {
        if (response.data.length > 0) {
          this.showMessage = "Already exist";

          this.checkRRNO = true;
          this.submitted = false;

          this.validationForm.get("zoneNumber").disable();
          this.validationForm.get("ward").disable();
          this.validationForm.get("rrno").disable();
          this.validationForm.get("name").disable();
          this.validationForm.get("doorNumber").disable();
          this.validationForm.get("meterNumber").disable();
          this.validationForm.get("address").disable();
          this.validationForm.get("tariff").disable();
          this.validationForm.get("connectionDate").disable();
          this.validationForm.get("phoneNumber").disable();
          this.validationForm.get("remarks").disable();
          this.validationForm.get("connectionStatus").disable();
          this.validationForm.get("readingDateTime").disable();
          this.validationForm.get("meterStatus").disable();
          this.validationForm.get("latitude").disable();
          this.validationForm.get("longitude").disable();
          this.validationForm.get("tapDiameter").disable();
          this.validationForm.get("initialReading").disable();
          this.validationForm.get("presentReading").disable();
          this.validationForm.get("khathaNumber").disable();
          this.validationForm.get("fromNode").disable();
          this.validationForm.get("toNode").disable();
          this.validationForm.get("agentId").disable();
           this.validationForm.get("consumption").disable();
          this.validationForm.get("noOfDays").disable();
          this.validationForm.get("avgConsumption").disable();
        } else {
          this.validationForm.get("zoneNumber").enable();
          this.validationForm.get("ward").enable();
          this.validationForm.get("rrno").enable();
          this.validationForm.get("name").enable();
          this.validationForm.get("doorNumber").enable();
          this.validationForm.get("meterNumber").enable();
          this.validationForm.get("address").enable();
          this.validationForm.get("tariff").enable();
          this.validationForm.get("connectionDate").enable();
          this.validationForm.get("phoneNumber").enable();
          this.validationForm.get("remarks").enable();
          this.validationForm.get("connectionStatus").enable();
          this.validationForm.get("readingDateTime").enable();
          this.validationForm.get("meterStatus").enable();
          this.validationForm.get("latitude").enable();
          this.validationForm.get("longitude").enable();
          this.validationForm.get("tapDiameter").enable();
          this.validationForm.get("initialReading").enable();
          this.validationForm.get("presentReading").enable();
          this.validationForm.get("khathaNumber").enable();
          this.validationForm.get("fromNode").enable();
          this.validationForm.get("toNode").enable();
          this.validationForm.get("agentId").enable();
           this.validationForm.get("consumption").enable();
          this.validationForm.get("noOfDays").enable();
          this.validationForm.get("avgConsumption").enable();
        }
      }
    });
  }

  delete(data) {
    //console.log("data delete", data.id);
    Swal({
      title: "Are you sure?",
      text: "You won't be able to revert this!",
      type: "warning",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Yes, delete it!",
    }).then((result) => {
      console.log("swal", result);

      if (result.value) {
        this.connectionService.deleteLedger(data.id).then((response) => {
          if (response.code == 200) {
            this.loadData("rrno", this.customer_details.rrno);

            Swal("Deleted!", "Ledger has been deleted.", "success");
          }
        });
      }
    });
  }

  nameChange(data, content) {
    this.nameChangeData = data;
    this.modalService.open(content, { centered: true, size: "lg" });
  }
  onCloseModal() {
    this.loadData("rrno", this.customer_details.rrno);
    this.modalService.dismissAll();
  }

  meterChange(data, content) {
    //console.log('this.latestBilLDate.status = ', this.latestBillData)
    data.previousStatus = this.latestBillData.status;
    data.previousReading = this.latestBillData.presentReading;
    this.meterChangeData = data;
    this.modalService.open(content, { centered: true, size: "lg" });
  }

  meterDisconnect(data, content) {
    // console.log('this.latestBilLDate.status = ', this.latestBillData)
    data.previousStatus = this.latestBillData.status;
    data.previousReading = this.latestBillData.presentReading;
    this.meterDisconnectionData = data;
    this.modalService.open(content, { centered: true, size: "lg" });
  }

  typeChange(data, content) {
    this.typeChangeData = data;
    this.modalService.open(content, { centered: true, size: "lg" });
  }

  addressChange(data, content) {
    this.addressChangeData = data;
    console.log('click address change', data)
    this.modalService.open(content, { centered: true, size: "lg" });
  }

  meterReconnection(data, content) {
    data.previousReading = this.latestBillData.presentReading;
    this.meterReconnectionData = data;
    this.modalService.open(content, { centered: true, size: "lg" });
  }

  noticeDetails(data, content) {
    this.noticeDetailsData = data;
    this.modalService.open(content, { centered: true, size: "lg" });
  }

  editConnection(content) {
    this.editConnectionData = this.customer_details;
    this.modalService.open(content, { centered: true, size: "lg" });
  }

  viewImage(dataItem) {
    //console.log("data", dataItem);

    var imageData;
    if (dataItem.image) {
      imageData = `${environment.apiUrl + "images/reasons/" + dataItem.image}`;
      window.open(imageData);
    } else {
      alert("No image");
    }
  }

  viewLocation(content, dataItem) {
    //console.log("data", dataItem);


    var imageData;
    if (dataItem.latitude && dataItem.longitude) {
      //console.log('inside ')
      var lat = dataItem.latitude
      var longitude = dataItem.longitude
      var a = document.createElement('a');
      // var url = `https://maps.google.com/maps?q=${lat},${longitude}&z=21&ie=UTF8`; 


      this.mapData = dataItem;
      this.modalService.open(content, { centered: true, size: "sm" });
      // a.target="_blank"
      // document.body.appendChild(a);
      // window.open(url, "_blank")
    } else {
      alert("No Location");
    }
  }






  bill(dataItem) {
    //console.log("data item", dataItem);
    var bill = dataItem;
    //delete bill.receiptDate;
    var receiptDetailsArray = [];

    bill.receiptDate = !!bill.receiptDate
      ? moment(bill.receiptDate, "YYYY/MM/DD HH:mm:ss").format(
        "YYYY-MM-DD HH:mm:ss"
      )
      : "";
    bill.receiptDateTime = !!bill.receiptDateTime
      ? moment(bill.receiptDateTime, "YYYY/MM/DD HH:mm:ss").format(
        "YYYY-MM-DD HH:mm:ss"
      )
      : "";

    if (bill.receiptDetails.length > 0) {
      bill.receiptDetails.forEach((element) => {
        receiptDetailsArray.push({
          receiptNo: element.receiptNo,
          receiptDateTime: moment(
            element.receiptDateTime,
            "YYYY/MM/DD HH:mm:ss"
          ).format("YYYY-MM-DD HH:mm:ss"),
          amount: element.amount,
        });
      });
      //delete bill.receiptDetails;
      bill["receiptDetails"] = receiptDetailsArray;
    }

    bill["connectionId"] = this.customer_details.RRNO;
    bill["subDivision"] = this.customer_details.subDivision;
    bill["name"] = this.customer_details.name.replace(/\\|\//g, "-");
    bill["doorNo"] = this.customer_details.doorNo.replace(/\\|\//g, "-");
    bill["ward"] = this.customer_details.ward;
    bill["tariff"] = this.customer_details.tariff;
    bill["meterNo"] = this.customer_details.meterSerialNumber;
    bill["meterConstant"] = this.customer_details.meterConstant;
    bill["oba"] = bill.OBA;

    var receipt = JSON.stringify(bill);

    window.open(
      "https://printbill.nixies.in/public/mn-compu-solution-bill-pdf/" + receipt
    );
    //window.open('https://printbill.nixies.in/public/mn-compu-solution-cash-collect-pdf-test');
    //window.open('https://ideal-chicken.farmdelico.com/mn-compu-solution-pdf/public/bill/'+receipt);
  }

  connectionDateChange(data, content) {
    this.connectionDateChangeData = data;
    this.modalService.open(content, { centered: true, size: "lg" });
  }

  editBill(content, dataItem) {
    this.billDetailsData = dataItem;
    this.modalService.open(content, { centered: true, size: "lg" });
  }

  revision(data, content) {
    data.latestCBA = this.displayCBA;
    this.revisionData = data;
    this.modalService.open(content, { centered: true, size: "lg" });
  }

  viewchangeDetailsUserPic(data, content) {
    //console.log('ddd',data)
    if (data) {
      var url = `${environment.apiUrl}` + "images/agents" + "/" + data;
      this.userPicData = url;
      this.modalService.open(content, { centered: true, size: "lg" });
      //   window.open(url);
    } else {
      alert('No Picture')
    }

  }
  viewPopupmodal(data, content, type) {
    // console.log('ddd',data)
    var popups;
    if (type == 'adjustment') {
      if ((data.adjustment > '0' || data.adjustment > '0.0') && data.previousReading == '0' && data.presentReading > '0') {
        var msg = `Adjustment amount ${data.adjustment} for MNR/RNT/NL/AVG from meter fixed date`;
        popups = {
          msg: msg,
          type: type
        }
        this.popupMessage = popups;
        this.modalService.open(content, { centered: true, size: "lg" });
        //   window.open(url);
      } else if (data.adjustment >= '1') {
        var msg = `Adjustment amount ${data.adjustment} for NL/DL/RNT/ML/GL/AVG since last normal bill`;
        popups = {
          msg: msg,
          type: type
        }
        this.popupMessage = popups;
        this.modalService.open(content, { centered: true, size: "lg" });
      } else {
        console.log('adjustment')
      }
    } else if (type == 'revision') {

      if (data.revision != '' && data.revision != '0') {
        var msg = `${data.revisionRemark}`;
        popups = {
          msg: msg,
          type: type
        }

        this.popupMessage = popups;
        this.modalService.open(content, { centered: true, size: "lg" });
        //   window.open(url);
      } else {
        //  console.log('adjustment')
      }
    }
  }


  viewchangeDetailsFile(data, type) {
    var path =
      type == "name_change"
        ? "name-change"
        : type == "meter_change"
          ? "meter_change"
          : type == "meter_disconnect"
            ? "meter-disconnection"
            : type == "meter_reconnect"
              ? "meter-reconnection"
              : type == "revision"
                ? "revision"
                : "address-change";
    var url = `${environment.apiUrl}` + "images/" + path + "/" + data;
    window.open(url);
  }


  viewChangeDetailsData(type) {

    if (type == 'meter_change') {
      this.connectionService
        .getMeterChageDetailsByRrno(this.RRNOS)
        .then((response) => {
          if (response.code == 200) {
            if (response.data.length == 0) {
              this.toastrService.error(
                `No Data Found for meter change`,
                "error!"
              );
            }
            this.pushToMeterCahangeData(response.data);
          }
        });
    } else if (type == 'name_change') {
      this.connectionService
        .getNameChangeDetailsByRrno(this.RRNOS)
        .then((response) => {
          if (response.code == 200) {
            if (response.data.length == 0) {
              this.toastrService.error(
                `No Data Found for name change`,
                "error!"
              );
            }
            console.log('response of name', response.data.length)
            this.pushToNameCahangeData(response.data);
          }
        });
    } else if (type == 'address_change') {
      this.connectionService
        .getAddressChangeDetailsByRrno(this.RRNOS)
        .then((response) => {
          if (response.code == 200) {
            if (response.data.length == 0) {
              this.toastrService.error(
                `No Data Found for address change`,
                "error!"
              );
            }
            this.pushToAddressCahangeData(response.data);
          }
        });
    } else if (type == 'meter_disconnect') {
      this.connectionService
        .getMeterDisconnectionChangeDetailsByRrno(this.RRNOS)
        .then((response) => {
          if (response.code == 200) {
            if (response.data.length == 0) {
              this.toastrService.error(
                `No Data Found for meter disconnection`,
                "error!"
              );
            }
            this.pushToMeterDisconnectionCahangeData(response.data);
          }
        });
    } else if (type == 'meter_reconnect') {
      this.connectionService
        .getMeterReconnectionChangeDetailsByRrno(this.RRNOS)
        .then((response) => {
          if (response.code == 200) {
            if (response.data.length == 0) {
              this.toastrService.error(
                `No Data Found for meter reconnection`,
                "error!"
              );
            }
            this.pushToMeterReconnectionCahangeData(response.data);
          }
        });
    } else if (type == 'revision_change') {
      this.connectionService
        .getRevisonChangeDetailsByRrno(this.RRNOS)
        .then((response) => {
          if (response.code == 200) {
            if (response.data.length == 0) {
              this.toastrService.error(
                `No Data Found for revision change`,
                "error!"
              );
            }
            this.pushToRevisonCahangeData(response.data);
          }
        });
    } else if (type == 'notice_change') {
      this.connectionService
        .getNoticeChangeDetailsByRrno(this.RRNOS)
        .then((response) => {
          if (response.code == 200) {
            if (response.data.length == 0) {
              this.toastrService.error(
                `No Data Found for notice change`,
                "error!"
              );
            }
            this.pushToNoticeCahangeData(response.data);
          }
        });
    } else if (type == 'tariff_change') {
      this.connectionService
        .getTariffChangeDetailsByRrno(this.RRNOS)
        .then((response) => {
          if (response.code == 200) {
            if (response.data.length == 0) {
              this.toastrService.error(
                `No Data Found for tariff change`,
                "error!"
              );
            }
            this.pushToTariffCahangeData(response.data);
          }
        });
    } else if (type == 'connection_date_change') {
      this.connectionService
        .getConnectionDateChangeDetailsByRrno(this.RRNOS)
        .then((response) => {
          if (response.code == 200) {
            if (response.data.length == 0) {
              this.toastrService.error(
                `No Data Found for connection date change`,
                "error!"
              );
            }
            this.pushToConnectionDateCahangeData(response.data);
          }
        });
    }
  }


  createDummyLedger() {
    var latestLedger = {
      rrno: this.RRNOS
    };

    this.connectionService
      .postDummyLedger(latestLedger)
      .then((response) => {
        if (response.code == 200) {
          this.loadData("rrno", this.RRNOS);
          this.toastrService.success(
            `Dummy Ledger created`,
            "success!"
          );
        }
      });
  }
  getLatestLedger(rrno) {
    // console.log('this.meterDisconnectionData.rrno',rrno)
    var yearMonth = this.currentYear + '-' + this.currentMonth
    this.connectionService.getLatestLedgerById(rrno).then((response) => {
      if (response.code == 200) {
        this.latestCbaArray = response.data;

        this.latestMonth = this.latestCbaArray[0].month;
        this.latestYear = this.latestCbaArray[0].year;
        var checkYear = this.latestYear + '-' + this.latestMonth
        console.log('checkYear > yearMonth', yearMonth > checkYear, yearMonth, checkYear)
        if (yearMonth > checkYear) {
          console.log('checkYear > yearMonth', yearMonth > checkYear)
          this.showDummyLedger = true;
        }
        if (this.latestCbaArray.length > 0) {
          this.displayCBA = this.latestCbaArray[0].cba
        }
      }
    });
  }

  async deleteChangeDetails(dataItem, type) {
    //console.log("delete data", await dataItem);
    dataItem.type = type;
    //console.log("type", type);
    //console.log("dataitem type", dataItem);

    await this.ledgerService
      .deleteChangedDetails(dataItem)
      .then(async (response) => {
        if (response.code == 200) {
          this.loadData('rrno', this.RRNOS);
          this.totastrService.success("Deleted Successfully");
        }
      });
  }


}

