import { Component, OnInit } from '@angular/core';
import { AngularFirestore } from '@angular/fire/firestore';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ToastrService } from 'ngx-toastr';
import { flatMap } from 'rxjs/operators';
import { CashTransferService } from 'src/app/shared/service/cash-transfer.service';
import * as XLSX from 'ts-xlsx';

declare var require:any;
var moment = require('moment');

@Component({
  selector: 'app-import-excel',
  templateUrl: './import-excel.component.html',
  styleUrls: ['./import-excel.component.scss']
})
export class ImportExcelComponent implements OnInit {
  validationForm:FormGroup;

  file:File;
  arrayBuffer:any;
  detailsArray:any = [];
  receiptNo;

  ledgerDetail;
  file_name ='Choose File';

  dateTime

  constructor(private fb:FormBuilder, private firestore:AngularFirestore, private toastrService:ToastrService, private cashTransferService:CashTransferService) { 
    var today = new Date();
    this.dateTime = ((today.getFullYear()) +"-"+ (("0"+(today.getMonth()+1)).slice(-2)) +"-"+("0"+today.getDate()).slice(-2)) +" "+ (("0"+today.getHours()).slice(-2)) +":"+ (("0"+today.getMinutes()).slice(-2)) +":"+ (("0"+today.getSeconds()).slice(-2));
  }

  ngOnInit() {
    this.validationForm = this.fb.group({
      file:['', Validators.required]
    })

    /* var date = moment('6/30/21', 'M/D/YYYY')
    console.log(date.format('YYYY-MM-DD')) */
    this.getLastId();
  }

  onSubmit(){
    this.detailsArray = [];
    let fileReader = new FileReader();
        fileReader.onload = (e) => {
            this.arrayBuffer = fileReader.result;
            var data = new Uint8Array(this.arrayBuffer);
            var arr = new Array();
            for(var i = 0; i != data.length; ++i) arr[i] = String.fromCharCode(data[i]);
            var bstr = arr.join("");
            var workbook = XLSX.read(bstr, {type:"binary"});
            var first_sheet_name = workbook.SheetNames[0];
            var worksheet = workbook.Sheets[first_sheet_name];
            var detailsArray = XLSX.utils.sheet_to_json(worksheet,{raw: false,})
            console.log("array", detailsArray);
            this.formatData(detailsArray);
        }
        fileReader.readAsArrayBuffer(this.file);
        //this.validationForm.reset();
        this.file_name ='Choose File';
        
  }

  formatData(detailsArray){

    detailsArray.forEach(element => {
      console.log("element.receiptDate", element.receiptDate);
      
      var receiptDate = moment(element.receiptDate, 'DD/MM/YYYY').format('YYYY-MM-DD')

      var data = {
        name:element.name,
        RRNO:element.RRNO.charAt(0).toUpperCase()+ element.RRNO.slice(1),
        receiptDate: receiptDate,
        mode:element.mode,
        amount:element.amount,
        paymentReceivedBank:element.paymentReceivedBank
      }
      //console.log("data", data);
      
      this.detailsArray.push(data);
    });
  }

  detectFiles(event){
    this.file= event.target.files[0]; 
    this.file_name = event.target.files.item(0).name;
  }

  all()
  {
    this.cashTransferService.suspenseTransfer(this.detailsArray).then(async response=>{
      if (response.code == 200) {
        this.detailsArray = [];
        this.validationForm.reset();
        console.log("total", response.data);
        
        this.toastrService.success("Total "+response.data.totalNumberOfCashTransfered+" records transferd to cash collection and total amounts "+response.data.totalAmountOfCashTransfered, "Success",
        {timeOut: 5000})
      }
      else{
        this.toastrService.error("Suspense Transfer Failed", "Failed")
      }
    });

    /* this.detailsArray.forEach(element => {
      this.transfer(element);
    }); */
  }

 /*  async transfer(data){
    
    if(!!data.RRNO){
      await this.getConnectionDetail(data);
    }
    else{
      console.log("suspense");
      this.storeToSuspense(data);
    }
  }

  async storeToSuspense(data){
    console.log("suspense data", data.receiptDate, data.name);
 
  
    var billDateTime =await  moment(data.receiptDate).format('YYYY-MM-DD HH:mm:ss');
    var keepGoing = true;
      await  this.firestore.collection('suspense', ref => ref.where('name', '==', data.name).where('date', '==', data.receiptDate).limit(1)).valueChanges().subscribe(async bc =>{
        if(bc.length > 0 && keepGoing){
          keepGoing = false;
          this.toastrService.error("This receipt Already added", "Error!")
        }
        else if(keepGoing){
          keepGoing = false;
          await this.firestore.collection('suspense').add({
            RRNO:!!data.RRNO == true ? data.RRNO : "",
            name:data.name,
            billDateTime : billDateTime,
            mode: data.mode,
            amount: data.amount,
            chequeDdNo: !!data.chequeNo == true ?  data.chequeNo : "",
            chequeDdDate: !!data.chequeDate == true? data.chequeDate : "",
            chequeDdBranch: !!data.chequeBranch == true ? data.chequeBranch: "",
            paymentReceivedBank: data.paymentReceivedBank,
            user_name: localStorage.getItem('user_name'),
            user_id: localStorage.getItem('user_id'),
            createdDate: this.dateTime,
            date: data.receiptDate,
            createdAt:moment().format('YYYY-MM-DD HH:mm:ss')
          }).then(success =>{
    
            var itemIndex = this.detailsArray.findIndex(item => item.receiptDate == data.receiptDate && item.name == data.name);
    
            if(itemIndex >= 0){
              this.detailsArray.splice(itemIndex, 1);
            }
            this.toastrService.success("Added Successfully", "Success")
          })
        }
      })
  } */


  /* async getConnectionDetail(data){
  
    var RRNO = await data.RRNO;
      
    
    await this.firestore.collection('connections', ref => ref.where('RRNO', '==', data.RRNO).limit(1)).valueChanges().subscribe(async con =>{
      console.log("owner ma", con.length, RRNO);
      
      if(con.length > 0){
        var ownerName = await con[0]["name"];
        var houseNo = await con[0]["doorNo"];
        var phoneNumber =await con[0]["phoneNumber"];

        var recept_no = this.receiptNo;
        var thenum = recept_no.replace( /^\D+/g, '');
        var addOne = Number(thenum) + 1;
        var receiptNumber = 'OFFLINE ' + addOne; 
        this.receiptNo = receiptNumber;
        console.log("rrr", receiptNumber);
        
        
        var billDateTime = await  moment(data.receiptDate).format('YYYY-MM-DD HH:mm:ss');
        var receiptDateTime = await  moment(data.receiptDate).format('YYYY/MM/DD HH:mm:ss');

        var keepGoing = true;
        await  this.firestore.collection('bankCashCollection', ref => ref.where('RRNO', '==', data.RRNO).where('date', '==', data.receiptDate).limit(1)).valueChanges().subscribe(async bc =>{
          if(bc.length > 0 && keepGoing){
            keepGoing = false;
            this.toastrService.error("This receipt Already added", "Error!")
          }
          else if(keepGoing){
            keepGoing = false;
            await this.firestore.collection('bankCashCollection').add({
              RRNO : data.RRNO,
              name: ownerName,
              doorNo: houseNo,
              phoneNumber: phoneNumber,
              receiptNo: receiptNumber,
              billDateTime : billDateTime,
              mode: data.mode,
              amount: data.amount,
              chequeDdNo: !!data.chequeNo == true ?  data.chequeNo : "",
              chequeDdDate: !!data.chequeDate == true? data.chequeDate : "",
              chequeDdBranch: !!data.chequeBranch == true ? data.chequeBranch: "",
              paymentReceivedBank: data.paymentReceivedBank,
              user_name: localStorage.getItem('user_name'),
              user_id: localStorage.getItem('user_id'),
              createdDate: this.dateTime,
              date: data.receiptDate
            }).then(success =>{
      
              var itemIndex = this.detailsArray.findIndex(item => item.receiptDate == data.receiptDate && item.RRNO == data.RRNO);
      
              if(itemIndex >= 0){
                this.detailsArray.splice(itemIndex, 1);
              }


              var keepGoing1 = true;
              this.ledgerDetail = this.firestore.collection('ledger', ref => ref.where('connectionId', '==', data.RRNO).where('billDateTime', '<=', billDateTime).orderBy('billDateTime', 'desc').limit(1)).snapshotChanges().pipe(flatMap(clients => clients));
              this.ledgerDetail.subscribe(doc => {
                var ledgerData = doc.payload.doc.data();
                if(keepGoing1){
                  keepGoing1 = false;
                  var newCBA = Number(ledgerData['CBA']) - Number(data.amount);
                  var paidAmount;
                  var receiptDate;
                  var receiptNo;
                  if(ledgerData['paidAmount'] != ''){
                    paidAmount = ledgerData['paidAmount'] + ',' + data.amount;
                    receiptDate = ledgerData['receiptDate'] + ',' + receiptDateTime;
                    receiptNo = ledgerData['receiptNo'] + ',' + receiptNumber;
                  }
                  else{
                    paidAmount = data.amount;
                    receiptDate = receiptDateTime;
                    receiptNo = receiptNumber
                  }
               
                  this.firestore.collection('ledger').doc(doc.payload.doc.id).update({'CBA':newCBA.toString(), 'paidAmount': paidAmount, 'receiptDate':receiptDate, 'receiptNo':receiptNo}).then(response =>{
                    console.log("updated");
                  })
                }
              });

              this.toastrService.success("Added Successfully", "Success")
            })
          }
        })
      }
      else{
        this.storeToSuspense(data);
      } 
      
    })
  } */

  

  /* async getLastId(){
    console.log("2");
    
    var cash_collection = await this.firestore.collection('/bankCashCollection', ref => ref.orderBy('receiptNo', 'desc'));
    cash_collection.valueChanges().subscribe(async (data) => {
     
      if(data.length > 0){
      
        var recept_no = data[0]['receiptNo'];
        var thenum = recept_no.replace( /^\D+/g, '');
        var addOne = Number(thenum) + 1;
        this.receiptNo = 'OFFLINE ' + addOne; 
      }
      else{
        this.receiptNo =  'OFFLINE ' + 1;
      }
      console.log("this.receiptNo", this.receiptNo);
    }); 
  } */

  async getLastId(){
    console.log("2");
    this.cashTransferService.getLastId().then(async response=>{
      if(response.code == 200){
        console.log("max id ", response.data);
        
        var receptNo = response.data[0]['receiptNo'];
        this.receiptNo  = Number(receptNo) + 1;
      }
      else{
        this.receiptNo =  '202100001'
      }
    })
  }

  incrementReceiptNo(){
    var recept_no = this.receiptNo;
    var thenum = recept_no.replace( /^\D+/g, '');
    var addOne = Number(thenum) + 1;
    this.receiptNo = 'OFFLINE ' + addOne; 
    console.log("rrr", this.receiptNo);
    
  }

}
