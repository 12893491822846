import { Component, Input, OnInit, Output, EventEmitter } from '@angular/core';
import { AngularFirestore } from '@angular/fire/firestore';
import { AngularFireStorage } from '@angular/fire/storage';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ToastrService } from 'ngx-toastr';
import { Observable } from 'rxjs';
import { finalize } from 'rxjs/operators';
import { ChangeDetailsService } from 'src/app/shared/service/change-details.service';

@Component({
  selector: 'app-notice-details',
  templateUrl: './notice-details.component.html',
  styleUrls: ['./notice-details.component.scss']
})
export class NoticeDetailsComponent implements OnInit {
  @Input('noticeDetailsData') noticeDetailsData;
  @Output('onCloseModal') onCloseModal  = new EventEmitter<string>();

  validationForm:FormGroup;

  dateTime;
  dateTimeToDisplay;
  user_id;
  user_name;
  dateTimeToDsplay;
  hasFile:boolean;
  selectedFiles:FileList;
  downloadURL:Observable<String>;
  imgPath;

  constructor(private fb:FormBuilder, private firestore: AngularFirestore, private toastrService: ToastrService,  private storage:AngularFireStorage,
    private changeService : ChangeDetailsService) { 
    var today = new Date();
    this.dateTime = ((today.getFullYear()) +"-"+ (("0"+(today.getMonth()+1)).slice(-2)) +"-"+("0"+today.getDate()).slice(-2)) +" "+ (("0"+today.getHours()).slice(-2)) +":"+ (("0"+today.getMinutes()).slice(-2)) +":"+ (("0"+today.getSeconds()).slice(-2));
    this.user_id = localStorage.getItem("user_id");
    this.user_name = localStorage.getItem("user_name");
    this.dateTimeToDsplay = ((("0"+today.getDate()).slice(-2)) +"/"+ (("0"+(today.getMonth()+1)).slice(-2)) +"/"+(today.getFullYear())) +" "+ (("0"+today.getHours()).slice(-2)) +":"+ (("0"+today.getMinutes()).slice(-2)) +":"+ (("0"+today.getSeconds()).slice(-2));
  }

  ngOnInit() {
    this.validationForm = this.fb.group({
      rrno:[this.noticeDetailsData.rrno],
      consumerName: [this.noticeDetailsData.name],
      noticeDate: ['', Validators.required],
      remarks: ['', Validators.required],
      enteredDate: [this.dateTime],
      userId: [this.user_name],
      file: ['']
    }) 
  }

  onClose(close)
  {
    this.onCloseModal.emit('close');
  }

  detectFiles(event){
    this.hasFile = true;
    this.selectedFiles = event.target.files;
    this.validationForm.controls.file.setValue(event.target.files[0].name)
  }

  onSubmit(){
  //console.log("id", this.nameChangeData.id);
  //this.validationForm.controls.userId.setValue(this.user_id);
    
    if(this.validationForm.valid){
      this.store();
     /*  if(this.hasFile){
        var file = this.selectedFiles.item(0);
        this.uploadFile(file);
      }
      else{
        this.store();
      } */
    }
  }

  uploadFile(imageUrl){
    let uniqkey = 'pic' + Math.floor(Math.random() * 1000000);
    const uploadTask = this.storage.upload('/notice-details/' + uniqkey, imageUrl);
    const ref = this.storage.ref('/notice-details/' + uniqkey);
    //console.log("uploadTask", uploadTask);
    uploadTask.snapshotChanges().pipe(
      finalize(() => {
        this.downloadURL = ref.getDownloadURL();
        console.log(this.downloadURL);
        this.downloadURL.subscribe(url => {
          this.imgPath = url;
          //console.log("url",this.imgPath)
          this.toastrService.success("Image uploaded succcessfully!", "Success!", {
            timeOut:3000
          });
          this.store();
        });
      })
    ).subscribe();
  }

  store(){
    var formValue = this.validationForm.value;
    delete formValue.userId;
    formValue.userId = this.user_id;

   /*  if(this.hasFile){
      formValue.file = this.imgPath;
    }
   */
  /*   this.firestore.collection('notice-details').add(formValue).then(response=>{
      this.toastrService.success("Notice created Successfully", "Success!", {
        timeOut: 8000
      });
      this.onCloseModal.emit('close');
    }).catch(error=>{
      this.toastrService.error(error, "Error!", {
        timeOut: 8000
      });
    }); */

    this.changeService.noticeDetails(formValue,this.hasFile,this.selectedFiles).then(async response=>{
      console.log("notice_details_response",response.code);
      if (response.code == 200) {
        this.toastrService.success("Notice Added Successfully", "Success!", {
          timeOut: 8000
        });
        this.onCloseModal.emit('close');
      }else{
        this.toastrService.error(response.message, "Error!", {
          timeOut: 8000
        });
      }
    })
  }

}
