import { THIS_EXPR } from '@angular/compiler/src/output/output_ast';
import { Component, OnInit } from '@angular/core';
import { AngularFirestore } from '@angular/fire/firestore';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { flatMap } from 'rxjs/operators';
import { AgentService } from 'src/app/shared/agent/agent.service';
import { LedgerComponent } from '../ledger/ledger.component';
import { DataStateChangeEvent, GridDataResult } from '@progress/kendo-angular-grid';
import { process, State } from '@progress/kendo-data-query';
import { LedgerService } from 'src/app/shared/service/ledger.service';

declare var require:any;
var moment = require('moment');

@Component({
  selector: 'app-status-wise-billed-unbilled',
  templateUrl: './status-wise-billed-unbilled.component.html',
  styleUrls: ['./status-wise-billed-unbilled.component.scss']
})
export class StatusWiseBilledUnbilledComponent implements OnInit {
  wards:any = [];
  filterForm:FormGroup;
  collection;
  connectioArray: any = [];
  detailsArray: any = [];
  ledgerCollection: any = [];
  ledgerArray: any = [];
  lastDate;
  filterType;
  zoneNumbers:any = [];
  agents:any = [];
  xlsxName;
  showLoader:boolean = false;
  
  totalUnbilled: number = 0;

  public gridData: GridDataResult;
  public state: State = {
    filter: {
      logic: 'and',
      filters: []
    }
  };



  constructor(private agentService:AgentService, private fb:FormBuilder, private firestore:AngularFirestore, private ledgerService:LedgerService) { }

  ngOnInit() {
    this.getAllWards();
    this.getAllAgents();
    this.getAllZoneNumbers();
    this.filterForm = this.fb.group({
      filterType: ['', Validators.required],
      zoneNumber: [''],
      agent: [''],
      ward: [''],
      month: ['', Validators.required],
      year: ['', Validators.required]
    });
   
  }

  getAllWards(){
   
    this.agentService.getWards().then((response) =>{
      if (response.code == 200) {
        this.wards = response.data;
        this.wards.push({"name":'All',"code":"All"})
      }
    });
  }

  getAllAgents(){
    /* this.agentService.getAgents().subscribe((data)=>{
      this.agents = data.map(item => { 
        var agent = item.payload.doc.data();
        const name = agent["name"];
        const id = item.payload.doc.id;
        return { name };
      });
    }); */
    this.agentService.getAgents().then((response)=>{
      if (response.code == 200) {
        this.agents =  response.data;
        this.agents.push({"name":"All"})
      }
    });
    
  }

  getAllZoneNumbers(){
    /* this.agentService.getAreas().subscribe((data)=>{
      this.areas = data.map(item => { 
        var area = item.payload.doc.data();
        const name = area["name"];
        const id = item.payload.doc.id;
        return { name };
      });
    }); */
    this.agentService.getAllZoneNumbers().then((response) =>{
      // console.log("Response of zobe", response);
      // if (response.code == 200) {
        this.zoneNumbers = response.data;
        console.log("zones sdsfs",   this.zoneNumbers);
        this.zoneNumbers.push({"zoneNumber":'All'})
      // }
     
      
    })
  }

  getDaysInMonth(month, year) {
    month = month - 1;
    var date = new Date(year, month, 1);
   
    var d = new Date(year, month + 1, 0);
    this.lastDate = moment(d.toString()).format('YYYY-MM-DD HH:mm:ss');
    console.log("last date", this.lastDate);
  
   //return days;
  }


  onchangeFilter(e){
    this.filterType = e.target.value;
    console.log('onchange', e.target.value)
  }

  async onFilterSubmit(){
    this.showLoader = true;
    this.detailsArray = [];
    this.connectioArray = [];
    console.log("month, year", this.filterForm.value);
    let month = this.filterForm.value.month;
    let year = this.filterForm.value.year;
    let ward =  this.filterForm.value.ward;
    let agent = this.filterForm.value.agent == "" ? "" :this.filterForm.value.agent.toString();
    let zoneNumber = this.filterForm.value.zoneNumber;
    
    let m;

    this.collection

    this.getDaysInMonth(month, year);

    if(month < 10){
      m = "0"+month;
    }
    else{
      m = month;
    }
    var data

    if(this.filterType == 'ward'){
      //this.collection =  await this.firestore.collection('connections', ref=>ref.where('ward', '==', ward));
      if(ward == 'All'){
        this.xlsxName = month?ward+'-'+month+'-'+year:ward;
     
      data = {
        'type': 'ward',
        'value': 'All',
        'year' : year,
        'month' : month
      }
      }else{
      this.xlsxName = month?ward+'-'+month+'-'+year:ward;
      data = {
        'type': 'ward',
        'value': ward,
        'year' : year,
        'month' : month
      }
    }
    }
    else if(this.filterType == 'agent'){
      //this.collection =  await this.firestore.collection('connections', ref=>ref.where('meterReaderName', '==', agent));
      if(agent == 'All'){
        this.xlsxName = month?agent+'-'+month+'-'+year:agent;
     
      data = {
        'type': 'agentId',
        'value': 'All',
        'year' : year,
        'month' : month
      }
      }else{
        this.xlsxName = month?agent+'-'+month+'-'+year:agent;
     
        data = {
          'type': 'agentId',
          'value': agent,
          'year' : year,
          'month' : month
        }
      }
      
    }
    else if(this.filterType == 'zoneNumber'){
      //this.collection =  await this.firestore.collection('connections', ref=>ref.where('areaName', '==', area));
      if(zoneNumber == 'All'){
        this.xlsxName = month?zoneNumber+'-'+month+'-'+year:zoneNumber;
     
        data = {
          'type': 'zoneNumber',
          'value': 'All',
          'year' : year,
          'month' : month
        }
      }else{
      this.xlsxName = month?zoneNumber+'-'+month+'-'+year:zoneNumber;
      data = {
        'type': 'zoneNumber',
        'value': zoneNumber,
        'year' : year,
        'month' : month
      }
     }
    }
    
    this.ledgerService.getUnbilledRecord(data).then(async response=>{
      if (response.code == 200) {
        this.totalUnbilled = 0;
        this.detailsArray = await response.data;
        console.log("this.detailsArray", await this.detailsArray)
       
        this.detailsArray.map((d,index) => {
          this.totalUnbilled = Number(this.totalUnbilled) + Number(d.unbilled) 
        })
        
        console.log(this.totalUnbilled)
        this.showLoader = false
        this.gridData =  await process(this.detailsArray, this.state);
      }
    });


    /* this.collection.valueChanges().subscribe(con => {
      console.log("length", con.length)
      con.forEach(element => {
        this.connectioArray.push(element);
      });
      
      this.connectioArray.forEach(async c => {
        console.log("c", c);
        
        var billed = 0;
        var unbilled = 0;
        var d;
        console.log("this.detailsArray1", this.detailsArray);
        if(this.detailsArray.length > 0){
          console.log("this.detailsArray2", this.detailsArray);
          
          
          await this.firestore.collection('ledger', ref=>ref.where('connectionId', '==', c.RRNO).where('billDateTime', '<=', this.lastDate).orderBy('billDateTime', 'desc')).valueChanges().subscribe(l =>{
            console.log("l", l, );
            if(l.length > 0){
              l.findIndex(item => item['month']==month && item[year] == year);
            
              
              if(l[0]['month'] == month && l[0]['year'] == year){
                console.log("billed");
              
               
                let itemIndex =  this.detailsArray.findIndex(item => item.status == l[0]['status']);
              
                if(itemIndex >= 0){
                  this.detailsArray[itemIndex].billed = this.detailsArray[itemIndex].billed + 1;
                }
                else{
                  billed = billed + 1;
                  d = {
                    status:l[0]['status'],
                    billed: billed,
                    unbilled:unbilled
                  }
                  this.detailsArray.push(d);
                  this.gridData =  process( this.detailsArray, this.state);
                }
              }
              else{
                console.log("unbilled", unbilled);
                let itemIndex =  this.detailsArray.findIndex(item => item.status == l[1]['status']);
             
                
                if(itemIndex >= 0){
                  this.detailsArray[itemIndex].unbilled = this.detailsArray[itemIndex].unbilled + 1;
                }
                else{
                  unbilled = unbilled + 1;
                  d = {
                    status:l[1]['status'],
                    billed: billed,
                    unbilled:unbilled
                  }
                  this.detailsArray.push(d);
                  this.gridData =  process( this.detailsArray, this.state);
                }
              }  
            }
          })
        }
        else{
         console.log("else");

         
         d = {
          status:'NORMAL',
          billed: billed,
          unbilled: unbilled
        }
        this.detailsArray.push(d);
        this.gridData =  process( this.detailsArray, this.state);
      }             
    });      
           
          
        
      
    });  */
  }

  refresh(){
    location.reload()
  }

  public dataStateChange(state: DataStateChangeEvent): void {
    this.state = state;
    this.gridData = process( this.detailsArray, this.state);
  }

}
