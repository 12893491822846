import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';

interface Data  {
  code: number,
  message: string,
  data: any
}

@Injectable({
  providedIn: 'root'
})

export class DashboardService {
  apiUrl = `${environment.apiUrl + 'api/'}`
  constructor(private http:HttpClient) { }

  getDashboardData(){
    return new Promise<Data>((resolve)=>{
      this.http.get<Data>(this.apiUrl + 'dashboard')
      .subscribe(
        data => {
          console.log("data",data);            
          resolve(data);
        },
        error => {
          console.log("Error", error);
        }
      );
    })
  }
}
