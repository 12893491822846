import { Component, Input, OnInit, Output, EventEmitter } from "@angular/core";
import { AngularFirestore } from "@angular/fire/firestore";
import { AngularFireStorage } from "@angular/fire/storage";
import { FormBuilder, FormGroup, Validators } from "@angular/forms";
import { ToastrService } from "ngx-toastr";
import { Observable } from "rxjs";
import { finalize } from "rxjs/operators";
import { ChangeDetailsService } from "src/app/shared/service/change-details.service";
import { ConnectionService } from "src/app/shared/service/connection.service";

@Component({
  selector: "app-meter-change",
  templateUrl: "./meter-change.component.html",
  styleUrls: ["./meter-change.component.scss"],
})
export class MeterChangeComponent implements OnInit {
  @Input("meterChangeData") meterChangeData;
  @Output("onCloseModal") onCloseModal = new EventEmitter<String>();

  validationForm: FormGroup;
  dateTime;
  hasFile: boolean;
  selectedFiles: FileList;
  userId;
  userName;
  downloadURL: Observable<String>;
  imgPath;
  maxDate;
  minDate;
  lastNormalMeterStatus;

  constructor(
    private fb: FormBuilder,
    private firestore: AngularFirestore,
    private toastrService: ToastrService,
    private storage: AngularFireStorage,
    private changeService: ChangeDetailsService,
    private connectionService: ConnectionService
  ) {
    var today = new Date();
    this.dateTime =
      today.getFullYear() +
      "-" +
      ("0" + (today.getMonth() + 1)).slice(-2) +
      "-" +
      ("0" + today.getDate()).slice(-2) +
      " " +
      ("0" + today.getHours()).slice(-2) +
      ":" +
      ("0" + today.getMinutes()).slice(-2) +
      ":" +
      ("0" + today.getSeconds()).slice(-2);
    this.userId = localStorage.getItem("user_id");
    this.userName = localStorage.getItem("user_name");
    // console.log("date, user_id, name", this.dateTime, this.user_id, this.user_name )
  }

  getMaxDate(){
    var today = new Date();
    this.maxDate =today.getFullYear() +
      "-" +
      ("0" + (today.getMonth() + 1)).slice(-2) +
      "-" +
      ("0" + today.getDate()).slice(-2)

    console.log('max date is',this.maxDate);

  
  }
  ngOnInit() {
    this.lastNormalMeterDate();
    this.getMaxDate();

    this.validationForm = this.fb.group({
      rrno: [this.meterChangeData.rrno],
      consumerName: [this.meterChangeData.name],
      userId: [this.userName],
      enteredDate: [this.dateTime],
      oldMeterSerialNo: [this.meterChangeData.meterSerialNumber],
      newMeterSerialNo: ["", Validators.required],
      meterFixedDate: ["", Validators.required],
      orderNo: ["", Validators.required],
      remarks: ["", Validators.required],
      previousReading: [this.meterChangeData.previousReading],
      presentReading: ["", Validators.required],
      previousStatus: [this.meterChangeData.previousStatus],
      status: ["normal", Validators.required],
      phoneNumber: [this.meterChangeData.phoneNumber],
      file: [""],
      type: ["", Validators.required],
    });
    console.log('datetime',this.dateTime);
  }

  onClose(close) {
    this.onCloseModal.emit("close");
    console.log('datetime',this.dateTime);
  }

  onSubmit() {
    console.log("id", this.meterChangeData.id);
    //this.validationForm.controls.userId.setValue(this.userId);

    if (this.validationForm.valid) {
      this.store();

      /* if(this.hasFile){
        var file = this.selectedFiles.item(0);
        this.uploadFile(file);
      }
      else{
        this.store();
      } */
    }
  }

  uploadFile(imageUrl) {
    let uniqkey = "pic" + Math.floor(Math.random() * 1000000);
    const uploadTask = this.storage.upload(
      "/meter-change/" + uniqkey,
      imageUrl
    );
    const ref = this.storage.ref("/meter-change/" + uniqkey);
    //console.log("uploadTask", uploadTask);
    uploadTask
      .snapshotChanges()
      .pipe(
        finalize(() => {
          this.downloadURL = ref.getDownloadURL();
          console.log(this.downloadURL);
          this.downloadURL.subscribe((url) => {
            this.imgPath = url;
            //console.log("url",this.imgPath)
            this.toastrService.success(
              "Image uploaded succcessfully!",
              "Success!",
              {
                timeOut: 3000,
              }
            );
            this.store();
          });
        })
      )
      .subscribe();
  }

  store() {
    var formValue = this.validationForm.value;
    delete formValue.userId;
    formValue.userId = this.userId;

    /*   if(this.hasFile){
      formValue.file = this.imgPath;
    } */

    /*  this.firestore.collection('meter-change').add(formValue).then(response=>{
      this.firestore.collection('connections').doc(this.meterChangeData.id).update({meterSerialNumber:this.validationForm.value.newMeterSerialNumber}).then(response =>{
        this.toastrService.success("Meter Changed Successfully", "Success!", {
        timeOut: 8000
      });
      this.onCloseModal.emit('close');
      })
      
    }).catch(error=>{
      this.toastrService.error(error, "Error!", {
        timeOut: 8000
      });
    }); */

    this.changeService
      .meterChange(formValue, this.hasFile, this.selectedFiles)
      .then(async (response) => {
        console.log("meterchange_response", response.code);
        if (response.code == 200) {
          /* this.meterChangeData.meterSerialNumber = formValue.newMeterSerialNumber;
        this.changeService.updateConnection(this.meterChangeData).then(async response=>{
          this.toastrService.success("Meter Changed Successfully", "Success!", {
            timeOut: 8000
          });
          this.onCloseModal.emit('close');
        }) */
          this.toastrService.success("Meter Changed Successfully", "Success!", {
            timeOut: 8000,
          });
          this.onCloseModal.emit("close");
        } else {
          this.toastrService.error(response.message, "Error!", {
            timeOut: 8000,
          });
        }
      });
  }

  detectFiles(event) {
    this.hasFile = true;
    this.selectedFiles = event.target.files;
    //console.log("event.target.files", event.target.files[0].name);

    this.validationForm.controls.file.setValue(event.target.files[0].name);
  }
  lastNormalMeterDate() {
    // this.disconnectionDetails = this.firestore.collection('/meter-disconnect', ref => ref.where('rrno', '==', this.meterReconnectionData.RRNO).orderBy('enteredDate', 'desc'));
    // this.disconnectionDetails.valueChanges().subscribe((data) => {
    //   data.forEach(element => {
    //     console.log("element reconnection",element);
    //     this.disconnectionDetailsArray.push(element);
    //   });
    //   if(this.disconnectionDetailsArray.length > 0){
    //     this.disconnectDate = this.disconnectionDetailsArray[0].disconnectionDate;
    //   }
    // })

    this.connectionService
      .getLastNormalMeterChangeDate(this.meterChangeData.rrno)
      .then((response) => {
        this.lastNormalMeterStatus = response.data;

        console.log('last normal meter  status', response.data)
        if (this.lastNormalMeterStatus.length > 0) {
          this.minDate =
            this.lastNormalMeterStatus[
              this.lastNormalMeterStatus.length - 1
            ].billDateTime;
        }
        
        // console.log("meter disconnection details", response);
      });
  }
}
