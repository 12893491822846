import { Injectable } from "@angular/core";
import { environment } from "src/environments/environment";
import { ResponseData } from "../interfaces/response-data";
import { HttpClient } from "@angular/common/http";
import { timeout } from "rxjs/operators";

interface Data {
  code: number;
  message: string;
  data: any;
}

@Injectable({
  providedIn: "root",
})
export class ChangeDetailsService {
  private apiUrl = `${environment.apiUrl + "api/"}`;
  constructor(private http: HttpClient) {}

  namechanged(data, hasFile, selectedFiles) {
    console.log("has", hasFile);
    console.log("data of ----", data);
    let formData = new FormData();
    formData.append("rrno", data.rrno);
    formData.append("oldName", data.oldName);
    formData.append("newName", data.newName);
    //formData.append("applicationDate",data.applicationDate);
    formData.append("enteredDate", data.enteredDate);
    formData.append("remarks", data.remarks);
    formData.append("userId", data.userId);
    formData.append("orderNo", data.orderNo);
    formData.append("table", "name_change");
    formData.append("phoneNumber", data.phoneNumber);

    if (hasFile) {
      var fileToUpload = selectedFiles.item(0);
      console.log("fileToUpload", fileToUpload);
      formData.append("file", fileToUpload, fileToUpload.name);
    }

    return new Promise<Data>((resolve) => {
      this.http
        .post<Data>(this.apiUrl + "post-change-details", formData)
        .subscribe(
          (res) => {
            console.log("Success", res.code);
            resolve(res);
          },
          (error) => {
            console.log("Error", error);
          }
        );
    });
  }

  updateConnection(data) {
    return new Promise<Data>((resolve) => {
      this.http.post<Data>(this.apiUrl + "update-connection", data).subscribe(
        (res) => {
          console.log("Success", res);
          resolve(res);
        },
        (error) => {
          console.log("Error", error);
        }
      );
    });
  }

  meterChange(data, hasFile, selectedFiles) {
    let formData = new FormData();
    formData.append("rrno", data.rrno);
    formData.append("oldMeterSerialNo", data.oldMeterSerialNo);
    formData.append("newMeterSerialNo", data.newMeterSerialNo);
    formData.append("previousStatus", data.previousStatus);
    formData.append("status", data.status);
    formData.append("meterFixedDate", data.meterFixedDate);
    formData.append("enteredDate", data.enteredDate);
    formData.append("previousReading", data.previousReading);
    formData.append("presentReading", data.presentReading);
    formData.append("orderNo", data.orderNo);
    formData.append("file", data.file);
    formData.append("remarks", data.remarks);
    formData.append("userId", data.userId);
    formData.append("table", "meter_change");
    formData.append("phoneNumber", data.phoneNumber);

    if (hasFile) {
      var fileToUpload = selectedFiles.item(0);
      console.log("fileToUpload", fileToUpload);
      formData.append("file", fileToUpload, fileToUpload.name);
    }

    return new Promise<Data>((resolve) => {
      this.http
        .post<Data>(this.apiUrl + "post-change-details", formData)
        .subscribe(
          (data) => {
            console.log("Success", data.code);
            resolve(data);
          },
          (error) => {
            console.log("Error", error);
          }
        );
    });
  }

  meterDisconnection(data, hasFile, selectedFiles) {
    let formData = new FormData();
    formData.append("rrno", data.rrno);
    /* formData.append("oldMeterSerialNo",data.oldMeterSerialNo);
  formData.append("newMeterSerialNo",data.newMeterSerialNo); */
    formData.append("disconnectionDate", data.disconnectionDate);
    //formData.append("reconnectionDate",data.reconnectionDate);
    formData.append("meterStatus", data.connection_status);
    formData.append("connection_status", data.connection_status);
    formData.append("enteredDate", data.enteredDate);
    formData.append("orderNo", data.orderNo);
    formData.append("file", data.file);
    formData.append("remarks", data.remarks);
    formData.append("userId", data.userId);
    formData.append("table", "meter_disconnection");
    formData.append("phoneNumber", data.phoneNumber);
    if (hasFile) {
      var fileToUpload = selectedFiles.item(0);
      console.log("fileToUpload", fileToUpload);
      formData.append("file", fileToUpload, fileToUpload.name);
    }

    return new Promise<Data>((resolve) => {
      this.http
        .post<Data>(this.apiUrl + "post-change-details", formData)
        .subscribe(
          (data) => {
            console.log("Success", data.code);
            resolve(data);
          },
          (error) => {
            console.log("Error", error);
          }
        );
    });
  }

  meterReconnection(data, hasFile, selectedFiles) {
    let formData = new FormData();
    formData.append("rrno", data.rrno);
    formData.append("oldMeterSerialNo", data.oldMeterSerialNo);
    formData.append("newMeterSerialNo", data.newMeterSerialNo);
    formData.append("reconnectionDate", data.reconnectionDate);
    formData.append("connectionStatus", data.connectionStatus);
    formData.append("enteredDate", data.enteredDate);
    formData.append("previousReading", data.previousReading);
    formData.append("presentReading", data.presentReading);
    formData.append("orderNo", data.orderNo);
    formData.append("remarks", data.remarks);
    formData.append("userId", data.userId);
    formData.append("table", "meter_reconnection");
    formData.append("phoneNumber", data.phoneNumber);

    if (hasFile) {
      var fileToUpload = selectedFiles.item(0);
      console.log("fileToUpload", fileToUpload);
      formData.append("file", fileToUpload, fileToUpload.name);
    }

    return new Promise<Data>((resolve) => {
      this.http
        .post<Data>(this.apiUrl + "post-change-details", formData)
        .subscribe(
          (data) => {
            console.log("Success", data.code);
            resolve(data);
          },
          (error) => {
            console.log("Error", error);
          }
        );
    });
  }

  tariffChange(data, hasFile, selectedFiles) {
    let formData = new FormData();
    formData.append("rrno", data.rrno);
    formData.append("oldTariff", data.oldTariff);
    formData.append("newTariff", data.newTariff);
    formData.append("oldDoorNo", data.oldDoorNo);
    formData.append("newDoorNo", data.newDoorNo);
    formData.append("enteredDate", data.enteredDate);
    formData.append("orderNo", data.orderNo);
    formData.append("remarks", data.remarks);
    formData.append("userId", data.userId);
    formData.append("table", "tariff_change");
    formData.append("phoneNumber", data.phoneNumber);

    if (hasFile) {
      var fileToUpload = selectedFiles.item(0);
      console.log("fileToUpload", fileToUpload);
      formData.append("file", fileToUpload, fileToUpload.name);
    }

    return new Promise<Data>((resolve) => {
      this.http
        .post<Data>(this.apiUrl + "post-change-details", formData)
        .subscribe(
          (data) => {
            console.log("Success", data.code);
            resolve(data);
          },
          (error) => {
            console.log("Error", error);
          }
        );
    });
  }

  addressChange(data, hasFile, selectedFiles) {
    console.log("adddress change --?", data);
    let formData = new FormData();
    formData.append("rrno", data.rrno);
    formData.append("oldStreetName", data.oldStreetName);
    formData.append("newStreetName", data.newStreetName);
    formData.append("oldDoorNo", data.oldDoorNo);
    formData.append("newDoorNo", data.newDoorNo);
    formData.append("enteredDate", data.enteredDate);
    formData.append("oldOrderNo", data.oldOrderNo);
    formData.append("newOrderNo", data.newOrderNo);
    formData.append("remarks", data.remarks);
    formData.append("userId", data.userId);
    formData.append("table", "address_change");
    formData.append("phoneNumber", data.phoneNumber);

    if (hasFile) {
      var fileToUpload = selectedFiles.item(0);
      console.log("fileToUpload", fileToUpload);
      formData.append("file", fileToUpload, fileToUpload.name);
    }

    return new Promise<Data>((resolve) => {
      this.http
        .post<Data>(this.apiUrl + "post-change-details", formData)
        .subscribe(
          (data) => {
            console.log("Success", data.code);
            resolve(data);
          },
          (error) => {
            console.log("Error", error);
          }
        );
    });
  }

  noticeDetails(data, hasFile, selectedFiles) {
    let formData = new FormData();
    formData.append("rrno", data.rrno);
    formData.append("noticeDate", data.noticeDate);
    formData.append("enteredDate", data.enteredDate);
    formData.append("remarks", data.remarks);
    formData.append("userId", data.userId);
    formData.append("table", "notice_detail");
    if (hasFile) {
      var fileToUpload = selectedFiles.item(0);
      console.log("fileToUpload", fileToUpload);
      formData.append("file", fileToUpload, fileToUpload.name);
    }

    return new Promise<Data>((resolve) => {
      this.http
        .post<Data>(this.apiUrl + "post-change-details", formData)
        .subscribe(
          (data) => {
            console.log("Success", data.code);
            resolve(data);
          },
          (error) => {
            console.log("Error", error);
          }
        );
    });
  }

  connectionDetails(data, hasFile, selectedFiles) {
    let formData = new FormData();
    formData.append("rrno", data.rrno);
    formData.append("oldServiceDate", data.oldServiceDate);
    formData.append("newServiceDate", data.newServiceDate);
    formData.append("enteredDate", data.enteredDate);
    formData.append("orderNo", data.orderNo);
    formData.append("remarks", data.remarks);
    formData.append("userId", data.userId);
    formData.append("table", "connection_date_change");
    formData.append("phoneNumber", data.phoneNumber);

    if (hasFile) {
      var fileToUpload = selectedFiles.item(0);
      console.log("fileToUpload", fileToUpload);
      formData.append("file", fileToUpload, fileToUpload.name);
    }
    return new Promise<Data>((resolve) => {
      this.http
        .post<Data>(this.apiUrl + "post-change-details", formData)
        .subscribe(
          (data) => {
            console.log("Success", data.code);
            resolve(data);
          },
          (error) => {
            console.log("Error", error);
          }
        );
    });
  }

  revision(data, hasFile, selectedFiles) {
    var newRebate;
    if(data.type == "add"){
      newRebate = `-${data.rebate}`
    }
    else{
      newRebate = `${data.rebate}`
    }

    let formData = new FormData();
    formData.append("rrno", data.rrno);
    formData.append("balance", data.balance);
    formData.append("rebate", newRebate);
    formData.append("revisionType", data.type)
    //formData.append("orderNo",data.orderNo);
    formData.append("remarks", data.remarks);
    formData.append("userId", data.userId);
    formData.append("newCBA", data.newCBA);
    formData.append("table", "revision");
    formData.append("enteredDate", data.enteredDate)

    if (hasFile) {
      var fileToUpload = selectedFiles.item(0);
      console.log("fileToUpload", fileToUpload);
      formData.append("file", fileToUpload, fileToUpload.name);
    }
    return new Promise<Data>((resolve) => {
      this.http
        .post<Data>(this.apiUrl + "post-change-details", formData)
        .subscribe(
          (data) => {
            console.log("Success", data.code);
            resolve(data);
          },
          (error) => {
            console.log("Error", error);
          }
        );
    });
  }

  updateLedger(data) {
    return new Promise<Data>((resolve) => {
      this.http.post<Data>(this.apiUrl + "update-ledger-cba", data).subscribe(
        (data) => {
          console.log("Success", data.code);
          resolve(data);
        },
        (error) => {
          console.log("Error", error);
        }
      );
    });
  }
}
