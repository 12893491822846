import { Component, OnInit } from '@angular/core';
import { AngularFirestore } from '@angular/fire/firestore';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { LedgerService } from 'src/app/shared/service/ledger.service';

declare var require: any;
var moment = require("moment");

@Component({
  selector: 'app-meter-reader-daywise-new',
  templateUrl: './meter-reader-daywise-new.component.html',
  styleUrls: ['./meter-reader-daywise-new.component.scss']
})
export class MeterReaderDaywiseNewComponent implements OnInit {
  filterForm: FormGroup;
  detailsArray: any = [];
  days;
  dayCountArray: any = [];
  masterArray: any = [];
  collection;
  agents;
  conCollection;
  grandTotalAray: any = [];
  totalBillCount = 0;
  actualBillCount = 0;
  PendingBillCount = 0;
  month;
  year;

  connectionsDetails;
  connectionDetailsArray: any = [];
  agentConnectionArray: any = [];

  showLoader: boolean = false;

  constructor(private fb: FormBuilder,
    private firestore: AngularFirestore,
    private ledgerService: LedgerService) { }

  ngOnInit() {
    this.filterForm = this.fb.group({
      month: ["", Validators.required],
      year: ["", Validators.required],
    });
    //this.getDaysInMonth(1, 2021)
   
  }

  getAgentConnections(data) {
    this.ledgerService.getAgentLedger(data).then(async (response) => {
      if (response.code == 200) {
        this.agentConnectionArray =  response.data;
        console.log(
          "this.agentConnectionArray",
           this.agentConnectionArray
        );
      }
    });
  }

  onFilterSubmit() {
    //console.log("agentConnectionArray", this.getAgentsTotalConnection());
    this.showLoader = true;

    this.masterArray = [];
    this.dayCountArray = [];
    this.grandTotalAray = [];
    this.totalBillCount = 0;
    this.actualBillCount = 0;
    this.PendingBillCount = 0;

    let month = this.filterForm.value.month;
    let year = this.filterForm.value.year;
    this.month = month;
    this.year = year;

    this.getDaysInMonth(month, year);

    console.log("Form Value", this.dayCountArray);

    var data = {
      month: month,
      year: year,
    };

    
    this.getAgentConnections(data);
    this.ledgerService.getMeterReaderDayWise(data).then(async (response) => {
      if (response.code == 200) {
        this.dayCountArray = await response.data;
        // console.log("this.detailsArray", await this.dayCountArray);
        this.showLoader = false;
        this.agentConnectionArray.forEach((agent) => {
          console.log("agent", agent);

          var actualBill = agent.totalConnection;
          var c = 0;
          var cp = 0;
          this.detailsArray = [];
          this.days.forEach((d) => {
            var temp = {
              count: 0,
            };
            this.detailsArray.push(temp);
          });
          this.dayCountArray.forEach((element, index) => {
            if (element.agent == agent.meterReaderName) {
              c = c + element.count;
              this.detailsArray.splice(element.day - 1, 1, element);
            }
          });

          console.log("pushed detail array pushed =>", this.detailsArray);

        
          var pendingBill = actualBill - c;
          this.masterArray.push({
            listDays: this.days,
            agent: agent.meterReaderName,
            count: c,
            noOfBill: this.detailsArray,
            actualBill: actualBill,
            pendingBill: pendingBill,
          });
          this.grandTotal();
        });
      }
    });

    
  }

  getDaysInMonth(month, year) {
    month = month - 1;
    var date = new Date(year, month, 1);

    var days = [];
    while (date.getMonth() === month) {
      var newFormat = moment(date).format("YYYY-MM-DD");
      var dt = moment(date, "YYYY-MM-DD HH:mm:ss");
      var dayInAWeek = dt.format("dddd");
      days.push({ date: newFormat, week: dayInAWeek });
      date.setDate(date.getDate() + 1);
    }
    console.log("Days", days);
    this.days = days;
    //return days;
  }

  grandTotal() {
    this.grandTotalAray = [];
    this.days.forEach((dayEle) => {
      var count = 0;

      this.dayCountArray.forEach((dayCountEle) => {
        console.log("day of days", dayEle.date);
        console.log("day of query", dayCountEle.day);
        if (Number(dayEle.date.split("-")[2]) == dayCountEle.day) {
          count = count + dayCountEle.count;
        }
      });

      this.grandTotalAray.push(count);
    });

    this.totalBillCount = 0;
    this.actualBillCount = 0;
    this.PendingBillCount = 0;

    this.masterArray.forEach((m) => {
      this.totalBillCount = this.totalBillCount + m.count;
      this.actualBillCount = this.actualBillCount + m.actualBill;
      this.PendingBillCount = this.PendingBillCount + m.pendingBill;
    });
    console.log(
      "This.grandTotal",
      this.grandTotalAray,
      this.totalBillCount,
      this.actualBillCount,
      this.PendingBillCount
    );
  }

  
  refresh() {
    location.reload();
  }

 

}
