import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { AgentService } from 'src/app/shared/agent/agent.service';
import { DataStateChangeEvent, GridDataResult} from '@progress/kendo-angular-grid';
import { process, State } from '@progress/kendo-data-query';
import { LedgerService } from 'src/app/shared/service/ledger.service';


@Component({
  selector: 'app-billed-record-details',
  templateUrl: './billed-record-details.component.html',
  styleUrls: ['./billed-record-details.component.scss']
})
export class BilledRecordDetailsComponent implements OnInit {
  filterForm:FormGroup;

  wards:any  = [];
  agents:any = [];
  zoneNumbers:any = [];
  filterType;
  xlsxName;
  showLoader:boolean = false;
  detailsArray: any = [];

  public gridData: GridDataResult;
  public state: State = {
    filter: {
      logic: 'and',
      filters: []
    }
  };

  constructor(private fb:FormBuilder, private agentService:AgentService, private ledgerService:LedgerService) { }

  ngOnInit() {

    this.getAllWards();
    this.getAllAgents();
    this.getAllZoneNumbers();
    this.filterForm = this.fb.group({
      filterType: ['', Validators.required],
      zoneNumber: [''],
      agent: [''],
      ward: [''],
      month: ['', Validators.required],
      year: ['', Validators.required]
    });
  }

  getAllWards(){
    this.agentService.getWards().then(async (response) =>{
      this.wards =await  response.data;
      this.wards.push({"name":'All',"code":"All"})
      
    })
  }

  getAllAgents(){
    /* this.agentService.getAgents().subscribe((data)=>{
      this.agents = data.map(item => { 
        var agent = item.payload.doc.data();
        const name = agent["name"];
        const id = item.payload.doc.id;
        return { name };
      });
    }); */
    this.agentService.getAgents().then(async (response)=>{
     
        this.agents = await response.data;
        this.agents.push({"name":'All'})
      
    });
    
  }

  getAllZoneNumbers(){
    // this.agentService.getAreas().then(async (response) =>{
      
    //   this.areas =await  response.data;
    //   console.log("areas sdsfs",  await this.areas);
    //   this.areas.push({"name":'All',"code":"All"})
      
    // })
    this.agentService.getAllZoneNumbers().then((response) =>{
      // console.log("Response of zobe", response);
      // if (response.code == 200) {
        this.zoneNumbers = response.data;
        console.log("zones sdsfs",   this.zoneNumbers);
        this.zoneNumbers.push({"zoneNumber":'All'})
      // }
     
    })
  }

  onchangeFilter(e){
    this.filterType = e.target.value;
  }

  async onFilterSubmit(){
    this.showLoader = true;
    this.detailsArray = [];
    console.log("month, year", this.filterForm.value);
    let month = this.filterForm.value.month;
    let year = this.filterForm.value.year;
    let ward =  this.filterForm.value.ward;
    let agent;
    let zoneNumber = this.filterForm.value.zoneNumber;
    if(this.filterForm.value.agent){
      agent = this.filterForm.value.agent.toString();
    }
    
    let m;



   // this.getDaysInMonth(month, year);

    if(month < 10){
      m = "0"+month;
    }
    else{
      m = month;
    }
    var data

    if(this.filterType == 'ward'){
      if(ward == 'All'){
        this.xlsxName = month?ward+'-'+month+'-'+year:ward;
     
      data = {
        'type': 'ward',
        'value': 'All',
        'year' : year,
        'month' : month
      }
      }else{
        this.xlsxName = month?ward+'-'+month+'-'+year:ward;
        data = {
          'type': 'ward',
          'value': ward,
          'year' : year,
          'month' : month
        }
      }
     
    }
    else if(this.filterType == 'agent'){
      if(agent == 'All'){
        this.xlsxName = month?agent+'-'+month+'-'+year:agent;
     
      data = {
        'type': 'agentId',
        'value': 'All',
        'year' : year,
        'month' : month
      }
      }else{
        this.xlsxName = month?agent+'-'+month+'-'+year:agent;
        data = {
          'type': 'agentId',
          'value': agent,
          'year' : year,
          'month' : month
        }
      }
    }
    else if(this.filterType == 'zoneNumber'){

      if(zoneNumber == 'All'){
        this.xlsxName = month?zoneNumber+'-'+month+'-'+year:zoneNumber;
     
        data = {
          'type': 'zoneNumber',
          'value': 'All',
          'year' : year,
          'month' : month
        }
      }else{
        this.xlsxName = month?zoneNumber+'-'+month+'-'+year:zoneNumber;
        data = {
          'type': 'zoneNumber',
          'value': zoneNumber,
          'year' : year,
          'month' : month
        }
      }
      
    }
    
    this.ledgerService.getBilledRecordDetails(data).then(async response=>{
      if (response.code == 200) {

        this.detailsArray = await response.data;
        console.log("this.detailsArray", await this.detailsArray)
        this.showLoader = false
        this.gridData =  await process(this.detailsArray, this.state);
      }
    });
  }

  refresh(){
    location.reload()
  }

  public dataStateChange(state: DataStateChangeEvent): void {
    this.state = state;
    this.gridData = process( this.detailsArray, this.state);
  }


}
