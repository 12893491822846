import { Component, Input, OnInit, Output, EventEmitter } from "@angular/core";
import { AngularFirestore } from "@angular/fire/firestore";
import { FormControl, FormGroup, Validators } from "@angular/forms";
import { ToastrService } from "ngx-toastr";
import { flatMap } from "rxjs/operators";
import { BillingService } from "src/app/shared/service/billing.service";

@Component({
  selector: 'app-popup-message-modal',
  templateUrl: './popup-message-modal.component.html',
  styleUrls: ['./popup-message-modal.component.scss']
})
export class PopupMessageModalComponent implements OnInit {
  @Input("popupMessage") popupMessage;
  @Output("onCloseModal") onCloseModal = new EventEmitter();
  public lat: any;
  public longitude:any;
  public msg: any;
  public types: any;
  constructor() { }

  ngOnInit() {
    // this.userPic = this.userPicData
    // console.log(this.userPicData)
    console.log(this.popupMessage)
    this.msg = this.popupMessage.msg
    this.types = this.popupMessage.type
  }
  onClose(close) {
    this.onCloseModal.emit("close");
  }

}
