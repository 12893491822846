import { Component, Input, OnInit } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { CustomValidators } from "ng2-validation";
import { ToastrService } from 'ngx-toastr';
import { AuthService } from 'src/app/shared/auth/auth.service';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-bank-staff-form',
  templateUrl: './bank-staff-form.component.html',
  styleUrls: ['./bank-staff-form.component.scss']
})
export class BankStaffFormComponent implements OnInit {
  @Input('from') from = 'add';
  @Input('editableData') editableData;
  validationForm:FormGroup;

  password= new FormControl('', [Validators.required, Validators.minLength(6)]);

  apiUrl = `${environment.apiUrl}`

  imgsrc = "assets/images/profile.png";
  hasFile: Boolean = false;
  selectedFiles: FileList;

  constructor(private fb:FormBuilder, private authService:AuthService, private toastrService:ToastrService) { }

  ngOnInit() {
    this.validationForm = this.fb.group({
      name: ['', Validators.required],
      phoneNumber: ['', [Validators.required, Validators.minLength(10)]],
      email: ['', [Validators.required,Validators.email]],
      password: this.password, 
      confirmPassword: ['', [Validators.required, CustomValidators.equalTo(this.password)]]
    });
   // console.log("Editable data", this.editableData);

    if(this.editableData)
        this.fillInForm();
  }

  fillInForm()
  {
    this.imgsrc = this.editableData.image? this.apiUrl + "/images/bank-staffs/"+ this.editableData.image : "assets/images/profile.png";
    this.validationForm.controls.email.disable();
    this.validationForm.setValue({
      name:this.editableData.name,
      phoneNumber:this.editableData.phoneNumber,
      email: this.editableData.email,
      password: 'secret',
      confirmPassword: 'secret'
    })
  }

  detectFiles(event){
    this.hasFile = true;
    this.selectedFiles = event.target.files;
   
    if(event.target.files && event.target.files[0]){
      var reader = new FileReader();
      reader.onload = (event:any) =>{
        this.imgsrc = event.target.result;
      }
      reader.readAsDataURL(event.target.files[0]);
    }
  }

  onSubmit(){
    this.from === 'add'?this.addUser():this.editUser();
  }

  addUser(){
    console.log("form value", this.validationForm.value);

    var formValue = this.validationForm.value;
    formValue.role = 'bankstaff';
      this.authService.createUser(formValue,this.selectedFiles, this.hasFile).then((response)=>{
      
        response.code === 200 ? this.toastrService.success(response.message, 'Success!') : this.toastrService.error(response.message, 'Error!'); 
        if(response.code === 200){
          this.validationForm.reset();
          this.imgsrc = "assets/images/profile.png";
        }
      }).catch((err)=>{console.log("error",err);
    })
    
  }

  editUser(){
    var user = this.validationForm.value;
    user.id = this.editableData.id;
    user.selectedFiles = this.selectedFiles;
    user.hasFile = this.hasFile;
    user.role = 'bankstaff';
    
    this.authService.updateUser(user).then((response)=>{
      response.code === 200 ? this.toastrService.success(response.message, 'Success!') 
      : this.toastrService.error(response.message, 'Error!'); 
    }).catch((err)=>{console.log("Error", err);
    })
  }

}
