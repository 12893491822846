import { Component, OnInit } from '@angular/core';
import { MENUITEMS, Menu, ADMIN_MENUITEMS, SUPERVISOR_MENUITEMS, BANKSTAFF_MENUITEMS,  UCMC_MENUITEMS} from './sidebar-items';
import { Router, ActivatedRoute } from "@angular/router";
import * as $ from 'jquery';
import { AuthService } from '../../auth/auth.service';
import { AngularFirestore } from '@angular/fire/firestore';
import { environment } from "src/environments/environment";
@Component({
  selector: 'app-sidebar',
  templateUrl: './sidebar.component.html',
  styleUrls: ['./sidebar.component.scss']
})
export class SidebarComponent implements OnInit {
   
   public menuItems: Menu[];
   public admin_menuItems: Menu[];
   public user_data;
   public user_name;
   public user_role;
   public user_image;
   public imgsrc = "assets/images/profile.png";
   public directory = "agents";
   apiUrl = `${environment.apiUrl}`
   constructor(private router: Router,private route: ActivatedRoute,private authService: AuthService, private firestore: AngularFirestore) {
      
    }

   ngOnInit() {
    console.log(this.authService.role);
     this.user_name = localStorage.getItem('user_name');
     this.user_image = localStorage.getItem('userImage');
     this.user_role = localStorage.getItem('loggedInUserRole')

     this.imgsrc = this.user_image?this.user_image:"assets/images/profile.png";

     if(this.user_role == 'bankstaff'){
       this.directory = 'bank-staffs'
     }else if(this.user_role == 'agents'){
       this.directory = 'agents'
     }else if(this.user_role == 'admin'){
       this.directory = 'admins'
     }else if(this.user_role == 'staff'){
       this.directory = 'supervisors'
     }else{
       this.directory = 'agents'
     }
     
     this.imgsrc = this.user_image == '' ? "assets/images/profile.png" : `${environment.apiUrl}` + `images/${this.directory}` + "/" + this.user_image;
     console.log(this.imgsrc)
    //  if(this.user_role == 'admin' && this.user_image != ''){
    //   this.image_src = `${environment.apiUrl}` + "images/agents" + "/" + this.user_image;
    //  }else if(this.user_role=='agent' && this.user_image != ''){

    //  }else if(this.user_role =='superadmin' && this.user_image != ''){

    //  }else if(this.user_role == 'bankstaff' && this.user_image != ''){

    //  }else {
    //   this.image_src = 'assets/images/user/1.jpg'
    //  }
    /* this.user_data = this.firestore.collection('/users').doc(user_id).valueChanges();
    this.user_data.subscribe(data => {
         console.log("side user", data.name);
         this.user_name = data.name;
         this.user_role = data.role;
         localStorage.setItem('user_name', data.name);
         localStorage.setItem('user_role', data.role);
    });  */
    $.getScript('./assets/js/sidebar-menu.js');
    this.menuItems = localStorage.getItem("loggedInUserRole") == "superadmin" ? MENUITEMS.filter(menuItem => menuItem) : localStorage.getItem("loggedInUserRole") == "admin" ? ADMIN_MENUITEMS.filter(menuItem => menuItem) : localStorage.getItem("loggedInUserRole") == "bankstaff" ? BANKSTAFF_MENUITEMS.filter(menuItem => menuItem): SUPERVISOR_MENUITEMS.filter(menuItem => menuItem); 

    /* if(localStorage.getItem("loggedInUserRole") == "superadmin")  
    {
      this.menuItems = MENUITEMS.filter(menuItem => menuItem);
    }
    else if(localStorage.getItem("loggedInUserRole") == "admin"){
      this.menuItems = ADMIN_MENUITEMS.filter(menuItem => menuItem);
    }
    else{
      this.menuItems = SUPERVISOR_MENUITEMS.filter(menuItem => menuItem);
    }   */

   }

}
