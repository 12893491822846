import { Component, OnInit, ViewChild } from '@angular/core';
import { Supervisor } from 'src/app/shared/supervisor/supervisor.model';
import { DataTableDirective } from 'angular-datatables';
import { Upload } from 'src/app/shared/upload/upload';
import { Observable } from 'rxjs';
import { FormControl, Validators, FormGroup } from '@angular/forms';
import { CustomValidators } from 'ng2-validation';
import { SupervisorService } from 'src/app/shared/supervisor/supervisor.service';
import { Router } from '@angular/router';
import { AngularFireStorage } from '@angular/fire/storage';
import { ToastrService } from 'ngx-toastr';
import { AngularFirestore } from '@angular/fire/firestore';
import { DataStateChangeEvent, GridDataResult } from '@progress/kendo-angular-grid';
import { process, State } from '@progress/kendo-data-query';
import { AuthService } from 'src/app/shared/auth/auth.service';

import Swal from 'sweetalert2';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { AngularFireAuth } from '@angular/fire/auth';

@Component({
  selector: 'app-supervisors',
  templateUrl: './supervisors.component.html',
  styleUrls: ['./supervisors.component.scss']
})
export class SupervisorsComponent implements OnInit {

  supervisors: Supervisor[];
  @ViewChild(DataTableDirective)
  datatableElement: DataTableDirective;
  min: number;
  max: number;
  data:any;

  show = 'list';

  public isVisible = false;

  dtOptions: DataTables.Settings = {};

  submitted:boolean;

  imgsrc = "assets/images/profile.png";
  hasFile: Boolean = false;
  selectedFiles: FileList;
  currentUpload:Upload;
  
  file;

  public gridData: GridDataResult;
  public state: State = {
    filter: {
      logic: 'and',
      filters: []
    }
  };


  constructor(private supervisorService:SupervisorService, private router:Router, private toastrService:ToastrService, private authService:AuthService, private modalService:NgbModal, private fireAuth:AngularFireAuth) {
    
  }

  ngOnInit() {
    this.getAllUsers();
  }

  getAllUsers(){
    this.authService.getAllUsers('staff').then((response)=>{
      if(response.code == 200){
        this.supervisors = response.data;
        this.gridData =  process( this.supervisors, this.state);
      }
    })
  }


  view(data, content){
    //this.router.navigate(['/view-supervisor'], { queryParams : {key: supervisor.id} });
    this.data = data;
    this.modalService.open(content, {centered:true, size:'lg'});
  }

  delete(data){
    //this.supervisorService.deleteSupervisor(data.id);
    Swal({
      title: 'Are you sure?',
      text: "You won't be able to revert this!",
      type: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: 'Yes, delete it!'
    }).then((result) => {
      if (result.value) {
        this.authService.deleteUser(data).then(response => {
          if(response.code == 200){
            this.getAllUsers();
            Swal(
              'Deleted!',
              'User has been deleted.',
              'success'
            )
          }
        })
       
      }
    });
  }


  ngOnDestroy(): void {
  }

  filterById(): void {
    this.isVisible = true;
  }

  public dataStateChange(state: DataStateChangeEvent): void {
    this.state = state;
    this.gridData = process( this.supervisors, this.state);
  }

  edit(data)
  {
    this.data = data;
    this.show = 'edit'
  }

  close()
  {
    this.show = 'list'
    this.getAllUsers();
  }

  onChangeTab(){
    this.getAllUsers();
  }

  onCloseModal(){
    this.modalService.dismissAll();
  }

  resetPassword(){
    console.log("reset Password", localStorage.getItem("LoggedInUserEmail"));
    var email = localStorage.getItem("LoggedInUserEmail");
    this.fireAuth.auth.sendPasswordResetEmail(email).then(()=>{
      console.log("email sent");
      this.toastrService.success("Please check your email", 'Success!') 
    }).catch((error) =>{
      console.log("error while reseting password", error);
    })
  }

}
