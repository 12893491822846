import {
  Component,
  OnInit,
  ViewChild,
  ComponentFactoryResolver,
} from "@angular/core";
import { FormGroup, FormControl, Validators } from "@angular/forms";
import { Subject, merge } from "rxjs";
import {
  debounceTime,
  distinctUntilChanged,
  filter,
  flatMap,
  map,
} from "rxjs/operators";
import { NgbTypeahead } from "@ng-bootstrap/ng-bootstrap";
import {
  AngularFirestore,
  AngularFirestoreCollection,
} from "@angular/fire/firestore";
import { Observable } from "rxjs/Observable";
import { whenRendered } from "@angular/core/src/render3";
import { ToastrService } from "ngx-toastr";
import { Router } from "@angular/router";
import { BillingService } from "src/app/shared/service/billing.service";
import { ConnectionService } from "src/app/shared/service/connection.service";
import { Billing } from "src/app/shared/billing/billing.model";

declare var require: any;
var moment = require("moment");

@Component({
  selector: "app-billing-ledger",
  templateUrl: "./billing-ledger.component.html",
  styleUrls: ["./billing-ledger.component.scss"],
})
export class BillingLedgerComponent implements OnInit {
  dateTime;
  dateTimeDifferntFormat;
  billNo;
  //1594184801105

  currentMonth = moment().format("MM");
  currentYear = moment().format("YYYY");

  readonlyForBillDateTime: boolean = true;
  meterConstant;
  connectionId;
  selectedItem;
  connectionDetail;
  ownerName;
  houseNo;
  address;
  meterNo;
  ward;
  tariff;
  previousStatus;
  subDivision;
  userName;
  phoneNumber;
  readingDate;
  billDetails;
  noOfDays;
  billDetailsArray = [];
  previousReading;
  consumption;
  waterCharge = 0;
  serviceCharge = 5;
  penalty = 0;
  adjustment = 0;
  CBA = 0;
  OBA = 0;
  demand = 0;
  isVisible: boolean = false;
  btnCalclate: boolean;
  previousCBA = 0;
  customerConnectionDetails;
  fixedCharge = 0;
  slab1 = 0;
  slab2 = 0;
  slab3 = 0;
  presentReadingValue;
  //rrnoFilter:boolean = true;
  crrno;
  RRNOsubmitted: boolean;
  dueDate;
  sumOfNormalDays = 0;
  sumOfAbnormalDays = 0;
  sumOfNormalConsumption = 0;
  sumOfAbnormalConsumption = 0;
  sumOfWaterCharges = 0;
  userId;
  total = 0;
  meterFixedDate;
  meterFixedEnterDate;
  serviceDate;
  connectionType;
  connectionReadingDate;
  meterReaderName;
  day;

  validationForm: FormGroup;
  formControls;
  //formControls = validationForm.controls;

  click$ = new Subject<string>();
  @ViewChild("instance") instance: NgbTypeahead;
  focus$ = new Subject<string>();
  normalConsumptionArray = [];
  normalNoOfDaysArray = [];
  abnormalConsumptionArray = [];
  abnormalNoOfDaysArray = [];
  waterChargeArray = [];
  lastNormalBillDate = [];
  meterChangeDetails;
  meterChangeDetailsArray = [];
  beforeMeterFixedBillDetails;
  beforeMeterFixedBillDetailsArray = [];
  previousStatusOfMeterFixed;
  previousReadingDateOfMeterFixed;
  inBetweenAdjustmentDetailsIssuedDetails;
  inBetweenAdjustmentWaterChargeArray = [];
  inBetweenAdjustmentConsumptionArray = [];
  sumOfAdjustmentConsumption = 0;
  sumOfAdjustmentWaterCharge = 0;
  reconnectionDetails;
  reconnectionDetailsArray = [];

  submitted: boolean;

  rrnoForm = new FormGroup({
    rrno: new FormControl("", Validators.required),
  });

  constructor(private firestore: AngularFirestore, private toastrService: ToastrService, private router: Router, private billingService: BillingService, private connectionService: ConnectionService) {
    var today = new Date();
    this.dateTime =
      today.getFullYear() +
      "-" +
      ("0" + (today.getMonth() + 1)).slice(-2) +
      "-" +
      ("0" + today.getDate()).slice(-2) +
      " " +
      ("0" + today.getHours()).slice(-2) +
      ":" +
      ("0" + today.getMinutes()).slice(-2) +
      ":" +
      ("0" + today.getSeconds()).slice(-2);
    // this.dateTime = "2019-04-20";
    this.dateTimeDifferntFormat = moment(
      this.dateTime,
      "YYYY-MM-DD HH:mm:ss"
    ).format("YYYY-MM-DDTHH:mm:ss");

    //console.log("cureent time", moment().format("YYYY-MM-DD HH:mm:ss"));

    this.validationForm = new FormGroup({
      connectionId: new FormControl({
        value: this.connectionId,
        disabled: true,
      }),
      ownerName: new FormControl({ value: this.ownerName, disabled: true }),
      houseNo: new FormControl({ value: this.houseNo, disabled: true }),
      phoneNumber: new FormControl({ value: this.phoneNumber, disabled: true }),
      address: new FormControl({ value: this.address, disabled: true }),
      meterNo: new FormControl({ value: this.meterNo, disabled: true }),
      wardNo: new FormControl({ value: this.ward, disabled: true }),
      tariff: new FormControl({ value: this.tariff, disabled: true }),
      subDivision: new FormControl({ value: this.subDivision, disabled: true }),
      userName: new FormControl({ value: this.userName, disabled: true }),
      previousStatus: new FormControl({
        value: this.previousStatus,
        disabled: true,
      }),
      billDateTime: new FormControl(this.dateTime),
      billNo: new FormControl("", Validators.required),
      //noOfDays : new FormControl({value: this.noOfDays, disabled: true}),
      noOfDays: new FormControl(""),
      status: new FormControl("normal", Validators.required),
      previousReading: new FormControl({
        value: this.previousReading,
        disabled: true,
      }),
      presentReading: new FormControl(
        { value: this.presentReadingValue },
        Validators.required
      ),
      consumption: new FormControl({ value: this.consumption, disabled: true }),
      OBA: new FormControl({ value: this.OBA, disabled: true }),
      demand: new FormControl({ value: this.demand, disabled: true }),
      previousReadingDate: new FormControl({
        value: this.readingDate,
        disabled: true,
      }),
      waterCharge: new FormControl({ value: this.waterCharge, disabled: true }),
      serviceCharge: new FormControl({
        value: this.serviceCharge,
        disabled: true,
      }),
      penalty: new FormControl({ value: this.penalty, disabled: true }),
      adjustment: new FormControl({ value: this.adjustment, disabled: true }),
      CBA: new FormControl({ value: this.CBA, disabled: true }),
      total: new FormControl({ value: this.total, disabled: true }),
      dueDate: new FormControl({ value: this.dueDate, disabled: true }),
      remarks: new FormControl("", Validators.required),
    });
  }

  ngOnInit() {
    this.formControls = this.validationForm.controls;
    this.userId = localStorage.getItem("user_id");
    this.userName = localStorage.getItem("user_name");
    //this.getLastBillId();
    //this.delete();
  }

  delete() {
    let start = "2021-06-01";
    let end = "2021-06-30";

    this.firestore
      .collection("ledger", (ref) =>
        ref.where("billDateTime", ">=", start).where("billDateTime", "<=", end)
      )
      .get()
      .subscribe((querySnapshot) => {
        console.log("test", querySnapshot.size);

        querySnapshot.forEach((doc) => {
          console.log("doc", doc);

          doc.ref.delete().then((s) => {
            console.log("deleted");
          });
        });
      });
  }

  // checkDate() {
  //   var today = new Date();
  //   this.day = ("0" + today.getDate()).slice(-2);
  //   console.log("this day", this.day);
  //   console.log("prev day", this.validationForm.value.noOfDays);
  //   // if (
  //   //   this.validationForm.value.previousReadingDate.split("-")[2] - this.day <
  //   //   20
  //   // ) {
  //   //   this.btnCalclate = false;
  //   //   this.reset();
  //   // } else {
  //   //   this.btnCalclate = true;
  //   // }
  // }

  calculate() {
    console.log("pp", this.validationForm.value.status, this.previousStatus, this.noOfDays);
    //this.presentReadingValue = this.validationForm.value.presentReading;
    this.isVisible = true;
    this.btnCalclate = false;
    this.consumption = 0;
    this.sumOfWaterCharges = 0;
    if (this.noOfDays >= 60) {
      console.log("inside no of daya");

      this.serviceCharge = Math.floor(this.noOfDays / 30) * 5;
    }

    console.log( "testing", this.presentReadingValue, this.previousStatusOfMeterFixed, this.validationForm.value.status, this.meterFixedEnterDate, this.readingDate, this.consumption );

    if (this.validationForm.value.status == "normal" && this.meterFixedEnterDate > this.readingDate) {
      console.log("meter changed", this.meterFixedDate);
      this.sumOfAdjustmentConsumption = 0;
      this.serviceCharge = 5;
      const a = new Date(this.meterFixedDate),
        b = new Date(this.dateTime);
      var noOfDaysAfterMeterfixed = this.dateDiffInDays(a, b);
      this.noOfDays = noOfDaysAfterMeterfixed;
      var presentReading = this.validationForm.value.presentReading;
      this.consumption = (presentReading - this.previousReading) * 1000;
      var waterChargeAfterMeterfixedDate = this.slabsForMeterChange(this.consumption);
      console.log("noOfDaysAfterMeterfixed", noOfDaysAfterMeterfixed, this.consumption, waterChargeAfterMeterfixedDate);

      if (waterChargeAfterMeterfixedDate > 0) {
        console.log( "beforeMeterFixed calculation", this.previousReadingDateOfMeterFixed, this.meterFixedDate, this.inBetweenAdjustmentConsumptionArray );
        const c = new Date(this.previousReadingDateOfMeterFixed),
          d = new Date(this.meterFixedDate);
        var noOfDayBeforeMeterFixed = this.dateDiffInDays(c, d);
        this.noOfDays = noOfDayBeforeMeterFixed;
        this.consumption = (this.beforeMeterFixedBillDetailsArray[0].consumption / this.beforeMeterFixedBillDetailsArray[0].noOfDays) * noOfDayBeforeMeterFixed;
        var waterChargeBeforeMeterfixedDate = 0;
        if (this.consumption > 0) {
          waterChargeBeforeMeterfixedDate = this.slabsForMeterChange(
            this.consumption
          );
        }

        console.log( "noOfDaysBeforeMeterfixed", noOfDayBeforeMeterFixed, this.consumption, waterChargeBeforeMeterfixedDate );

        this.noOfDays = noOfDaysAfterMeterfixed + noOfDayBeforeMeterFixed;
        this.waterCharge = waterChargeAfterMeterfixedDate + waterChargeBeforeMeterfixedDate;
        console.log( "sas", waterChargeAfterMeterfixedDate, waterChargeBeforeMeterfixedDate );
        if (this.inBetweenAdjustmentConsumptionArray.length > 0) {
          this.inBetweenAdjustmentConsumptionArray.forEach((consumption) => {
            this.sumOfAdjustmentConsumption =
              this.sumOfAdjustmentConsumption + Number(consumption);
          });

          this.inBetweenAdjustmentWaterChargeArray.forEach((consumption) => {
            this.sumOfAdjustmentWaterCharge =
              this.sumOfAdjustmentWaterCharge + Number(consumption);
          });
        }

        this.consumption = Math.round( (this.validationForm.value.presentReading - this.previousReading) * 1000 + (this.beforeMeterFixedBillDetailsArray[0].consumption / this.beforeMeterFixedBillDetailsArray[0].noOfDays) * noOfDayBeforeMeterFixed - this.sumOfAdjustmentConsumption );
        this.adjustment = this.sumOfAdjustmentWaterCharge;
        this.demand = this.waterCharge + this.serviceCharge + this.penalty - this.sumOfAdjustmentWaterCharge;
        this.OBA = this.previousCBA;
        this.CBA = this.OBA + this.waterCharge + this.serviceCharge - this.sumOfAdjustmentWaterCharge;
        var date = new Date(this.dateTime);
        var newdate = new Date(date);
        newdate.setDate(newdate.getDate() + 15);
        this.dueDate = newdate.getFullYear() + "-" + ("0" + (newdate.getMonth() + 1)).slice(-2) + "-" + ("0" + newdate.getDate()).slice(-2);
        this.total = this.OBA + this.demand;
      }
    } else {
      if (this.validationForm.value.status == "dc") {
        this.consumption = 0;
        this.slabs(this.consumption);
      } else if (this.validationForm.value.status == "do") {
        console.log( "abnormal to do cons", this.consumption, this.previousReading, this.validationForm.value.presentReading );
        var defaultValue = this.previousReading.toString().length == 5 ? 100000 : 10000;
        console.log("defaultValue", defaultValue);

        if (this.previousStatus.toLowerCase() == "normal") {
          this.consumption = Math.round( (defaultValue - this.previousReading + this.validationForm.value.presentReading) * 1000 );
          this.slabs(this.consumption);
        } else {
          var keepGoing = true;
          this.waterChargeArray = [];
          this.abnormalConsumptionArray = [];
          this.sumOfWaterCharges = 0;
          this.lastNormalBillDate = [];
          this.adjustment = 0;
          this.sumOfAbnormalConsumption = 0;

          this.consumption = Math.round( (defaultValue - this.previousReading + this.validationForm.value.presentReading) * 1000 );
          //console.log("abnormal to do", this.previousReading, this.presentReadingValue, this.consumption);

          this.billingService.getLedgerByRrno(this.connectionId).then((response) => {
              if (response.code == 200) {
                var billings = response.data;
                billings.forEach((e) => {
                  if (e["status"] != null) {
                    if (e["status"].toLowerCase() == "normal" && keepGoing) {
                      if (this.lastNormalBillDate.length < 1) {
                        this.lastNormalBillDate.push(e["billDateTime"]);
                      }
                    }

                    if (e["status"].toLowerCase() == "normal" && keepGoing) {
                      keepGoing = false;
                    } else if (
                      e["status"].toLowerCase() != "normal" &&
                      keepGoing
                    ) {
                      this.abnormalConsumptionArray.push(e["consumption"]);
                      this.waterChargeArray.push(e["waterCharge"]);
                    }
                  }
                });

                this.abnormalConsumptionArray.forEach((consumption) => {
                  this.sumOfAbnormalConsumption =
                    this.sumOfAbnormalConsumption + Number(consumption);
                });

                //console.log("abnormalConsumptionArray", this.abnormalConsumptionArray, this.sumOfAbnormalConsumption);

                this.waterChargeArray.forEach((charge) => {
                  this.sumOfWaterCharges = this.sumOfWaterCharges + Number(charge);
                });

                this.adjustment = this.sumOfWaterCharges;
                this.lastNormalBillDate.forEach((lastNormalBilldate) => {
                  const a = new Date(lastNormalBilldate),
                    b = new Date(this.dateTime);
                  this.noOfDays = this.dateDiffInDays(a, b);
                });
                var consumption = (defaultValue - this.previousReading + this.validationForm.value.presentReading) * 1000 - this.sumOfAbnormalConsumption;
                this.slabs(consumption);
              }
            });
        }
      } else if ( this.validationForm.value.status == "normal" && this.previousStatus.toLowerCase() == "normal" ) {
        console.log("no of days", this.noOfDays);

        var presentReading = this.validationForm.value.presentReading;
        this.consumption = Math.round( (presentReading - this.previousReading) * 1000 );
        this.slabs(this.consumption);
      } else if ( this.validationForm.value.status == "normal" && this.previousStatus.toLowerCase() != "normal" ) {
        console.log("comes here", this.noOfDays);
        this.abnormalConsumptionArray = [];
        this.waterChargeArray = [];
        this.sumOfAbnormalConsumption = 0;
        this.sumOfWaterCharges = 0;
        var keepGoing = true;
        
        this.serviceCharge = 5;

        this.billingService .getLedgerByRrno(this.connectionId) .then((response) => {
            if (response.code == 200) {
              var billings = response.data;
              console.log("e", billings);

              var consumption;
              if(this.previousStatus.toLowerCase() == "do"){
                consumption = Math.round( (this.validationForm.value.presentReading - this.previousReading) * 1000 );
              }
              else{
                this.noOfDays = 0;
                billings.forEach((e) => {
                  if (e["status"] != null) {
                    if (e["status"].toLowerCase() == "normal" && keepGoing) {
                      if (this.lastNormalBillDate.length < 1) {
                        this.lastNormalBillDate.push(e["billDateTime"]);
                      }
                    }

                    if (e["status"].toLowerCase() == "normal" && keepGoing) {
                      keepGoing = false;
                    } else if (
                      e["status"].toLowerCase() != "normal" &&
                      keepGoing
                    ) {
                      this.abnormalConsumptionArray.push(e["consumption"]);
                      this.waterChargeArray.push(e["waterCharge"]);
                    }
                  }
                });

                console.log( "last normal bill date", this.lastNormalBillDate, this.abnormalConsumptionArray, this.waterChargeArray );

                if (this.lastNormalBillDate.length > 0) {
                  this.lastNormalBillDate.forEach((lastNormalBilldate) => {
                    const a = new Date(lastNormalBilldate),
                      b = new Date(this.dateTime);
                    this.noOfDays = this.dateDiffInDays(a, b);
                    // this.readingDate = lastNormalBilldate;
                    console.log( "number of days for abnormal connections", this.noOfDays, lastNormalBilldate, this.dateTime, keepGoing );
                  });
                } else {
                  console.log("service date");

                  const a = new Date(this.serviceDate),
                    b = new Date(this.dateTime);
                  this.noOfDays = this.dateDiffInDays(a, b);
                  this.readingDate = this.serviceDate;
                }

                this.abnormalConsumptionArray.forEach((consumption) => {
                  this.sumOfAbnormalConsumption = this.sumOfAbnormalConsumption + Number(consumption);
                });
                this.abnormalNoOfDaysArray.forEach((n) => {
                  this.sumOfAbnormalDays = this.sumOfAbnormalDays + Number(n);
                });

                this.waterChargeArray.forEach((charge) => {
                  this.sumOfWaterCharges = this.sumOfWaterCharges + Number(charge); this.adjustment = this.sumOfWaterCharges;
                });

                console.log( "Abnormal consumption array", this.abnormalConsumptionArray );
                // this.consumption = Math.round((this.presentReadingValue -this.previousReading)*1000);
                consumption = (this.validationForm.value.presentReading - this.previousReading) * 1000 - this.sumOfAbnormalConsumption;
                console.log( "sum of abnormalConsumption", this.sumOfAbnormalConsumption, this.presentReadingValue, this.previousReading );
            }
           
            this.consumption = Math.round( (this.validationForm.value.presentReading - this.previousReading) * 1000 );
            setTimeout(() => {
              this.slabs(consumption);
            }, 2000);
            }
          });
      } else if (this.validationForm.value.status != "normal") {
        console.log("abnormal 1 ghgh",  this.normalConsumptionArray, this.sumOfNormalConsumption, this.sumOfNormalDays);

        this.normalConsumptionArray = [];
        this.normalNoOfDaysArray = [];
        this.sumOfNormalConsumption = 0;
        this.sumOfNormalDays = 0;

        this.billingService.getLedgerByRrno(this.connectionId).then((response) => {
            if (response.code == 200) {
              var consumption = response.data;
              console.log("consumption 2", consumption);
              var noOfDays = 0;
              consumption.forEach((e) => {
                
                if (e["consumption"] > "0") {
                  if (e["noOfDays"] < 90) {
                    /*  if (
                       this.normalConsumptionArray.length < 3 &&
                       this.normalNoOfDaysArray.length < 3
                     ) { */
                    if (noOfDays < 90) {
                      noOfDays = noOfDays + Number(e["noOfDays"]);
                      this.normalConsumptionArray.push(e["consumption"]);
                      this.normalNoOfDaysArray.push(e["noOfDays"]);
                    }
                    /* } */
                  } else {
                    this.sumOfNormalDays = Number(e["noOfDays"]);
                  }

                }
              });

              console.log( "3 normal consumption array", this.normalConsumptionArray,  this.normalNoOfDaysArray, this.sumOfNormalDays);

              if ( this.normalConsumptionArray.length > 0 && this.normalNoOfDaysArray.length > 0 ) {
                this.sumOfNormalDays = 0;
                this.normalConsumptionArray.forEach((c) => {
                  this.sumOfNormalConsumption =
                    this.sumOfNormalConsumption + Number(c);
                });

                this.normalNoOfDaysArray.forEach((n) => {
                 
                  this.sumOfNormalDays = this.sumOfNormalDays + Number(n);
                });
              }

              setTimeout(() => {
                this.consumption = Math.round( (this.sumOfNormalConsumption / this.sumOfNormalDays) * this.noOfDays );
                if (isNaN(this.consumption)) this.consumption = 0;
                console.log( "sumOfNormalConsumption 4", this.sumOfNormalConsumption, this.sumOfNormalDays, this.noOfDays, this.consumption );
                this.slabs(this.consumption);
              }, 2000);
            }
          });
      }
    }
  }

  slabs(consumption) {
    console.log("slab inside consumption", consumption, this.noOfDays);

    // this.consumption = consumption;
    switch (this.tariff.toLowerCase()) {
      case "residential":
        this.tariffConsumption( (this.fixedCharge = 56), (this.slab1 = 9), (this.slab2 = 11), (this.slab3 = 13) );
        break;
      case "non-domestic":
        this.tariffConsumption( (this.fixedCharge = 112), (this.slab1 = 18), (this.slab2 = 22), (this.slab3 = 26) );
        break;
      case "commercial":
        this.tariffConsumption( (this.fixedCharge = 224), (this.slab1 = 36), (this.slab2 = 44), (this.slab3 = 52) );
        break;
      case "industrial":
        this.tariffConsumption( (this.fixedCharge = 224), (this.slab1 = 36), (this.slab2 = 44), (this.slab3 = 52) );
        break;
      case "panchayath":
        if (this.noOfDays == 30) {
          this.consumptionFor30DaysPanchayath(this.consumption);
        } else {
          var perDayConsumption = this.consumption / this.noOfDays;
          var for30DaysConsumption = perDayConsumption * 30;
          var charge =
            this.consumptionFor30DaysPanchayath(for30DaysConsumption);
          var perDayCharge = charge / 30;
          var forNdaysCharge = perDayCharge * this.noOfDays;
          this.waterCharge = Math.round(forNdaysCharge);
        }

        break;
      case "tebma shipping yard":
        if (this.noOfDays == 30) {
          this.consumptionFor30DaysTebma(this.consumption);
        } else {
          var perDayConsumption = this.consumption / this.noOfDays;
          var for30DaysConsumption = perDayConsumption * 30;
          var charge = this.consumptionFor30DaysTebma(for30DaysConsumption);
          var perDayCharge = charge / 30;
          var forNdaysCharge = perDayCharge * this.noOfDays;
          this.waterCharge = Math.round(forNdaysCharge);
        }
        break;
    }

    console.log("this.sumOfWaterCharges", this.sumOfWaterCharges);

    // this.demand = this.waterCharge + this.serviceCharge + this.penalty  + this.previousCBA - this.sumOfWaterCharges;
    this.demand =
      this.waterCharge +
      this.serviceCharge +
      this.penalty -
      this.sumOfWaterCharges;

    // console.log("after tariff",this.waterCharge, this.demand );
    this.OBA = this.previousCBA;
    //var taotal =
    this.CBA =
      this.OBA + this.waterCharge + this.serviceCharge - this.sumOfWaterCharges;
    var date = new Date(this.dateTime);
    var newdate = new Date(date);

    newdate.setDate(newdate.getDate() + 15);
    this.dueDate =
      newdate.getFullYear() +
      "-" +
      ("0" + (newdate.getMonth() + 1)).slice(-2) +
      "-" +
      ("0" + newdate.getDate()).slice(-2);
    console.log("someFormattedDate", this.dueDate);
    this.total = this.OBA + this.demand;
    this.consumption = consumption;
    return this.waterCharge;
  }

  slabsForMeterChange(consumption) {
    console.log("slab inside consumption", consumption);

    this.consumption = consumption;
    switch (this.tariff.toLowerCase()) {
      case "residential":
        this.tariffConsumption(
          (this.fixedCharge = 56),
          (this.slab1 = 9),
          (this.slab2 = 11),
          (this.slab3 = 13)
        );
        break;
      case "non-domestic":
        this.tariffConsumption(
          (this.fixedCharge = 112),
          (this.slab1 = 18),
          (this.slab2 = 22),
          (this.slab3 = 26)
        );
        break;
      case "commercial":
        this.tariffConsumption(
          (this.fixedCharge = 224),
          (this.slab1 = 36),
          (this.slab2 = 44),
          (this.slab3 = 52)
        );
        break;
      case "industrial":
        this.tariffConsumption(
          (this.fixedCharge = 224),
          (this.slab1 = 36),
          (this.slab2 = 44),
          (this.slab3 = 52)
        );
        break;
      case "panchayath":
        if (this.noOfDays == 30) {
          this.consumptionFor30DaysPanchayath(this.consumption);
        } else {
          var perDayConsumption = this.consumption / this.noOfDays;
          var for30DaysConsumption = perDayConsumption * 30;
          var charge =
            this.consumptionFor30DaysPanchayath(for30DaysConsumption);
          var perDayCharge = charge / 30;
          var forNdaysCharge = perDayCharge * this.noOfDays;
          this.waterCharge = Math.round(forNdaysCharge);
        }

        break;
      case "tebma-shipping-yard":
        if (this.noOfDays == 30) {
          this.consumptionFor30DaysTebma(this.consumption);
        } else {
          console.log("cba1", this.previousCBA);
          var perDayConsumption = this.consumption / this.noOfDays;
          var for30DaysConsumption = perDayConsumption * 30;
          var charge = this.consumptionFor30DaysTebma(for30DaysConsumption);
          var perDayCharge = charge / 30;
          var forNdaysCharge = perDayCharge * this.noOfDays;
          this.waterCharge = Math.round(forNdaysCharge);
        }
        break;
    }
    return this.waterCharge;
  }

  tariffConsumption(fixedCharge, slab1, slab2, slab3) {
    console.log("fixed charge", fixedCharge, this.consumption, this.noOfDays);
    if (this.noOfDays == 30) {
      this.consumptionFor30Days(
        this.consumption,
        fixedCharge,
        slab1,
        slab2,
        slab3
      );
    } else {
      var perDayConsumption = this.consumption / this.noOfDays;
     
      if ((isNaN(perDayConsumption)) || (perDayConsumption === Infinity) ) perDayConsumption = 0;
      var for30DaysConsumption = perDayConsumption * 30;
      if (isNaN(for30DaysConsumption)|| for30DaysConsumption === Infinity) for30DaysConsumption = 0;
    
      var charge = this.consumptionFor30Days(
        for30DaysConsumption,
        fixedCharge,
        slab1,
        slab2,
        slab3
      );
      var perDayCharge = charge / 30;
      var forNdaysCharge = perDayCharge * this.noOfDays;
      if (isNaN(forNdaysCharge)) forNdaysCharge = 0;
      this.waterCharge = Math.round(forNdaysCharge);
      console.log("water chargeqq", charge, forNdaysCharge, this.waterCharge);
    }
  }

  consumptionFor30Days(consumption, fixedCharge, slab1, slab2, slab3) {
    if (consumption <= 8000) {
     
      
      this.waterCharge = fixedCharge;
      return fixedCharge;
    } else if (consumption > 8000 && consumption <= 15000) {
      var minimumWaterCharge = fixedCharge;
      var remainingConsumption = consumption - 8000;
      var remainingCharge = (remainingConsumption * slab1) / 1000;
      //this.waterCharge = minimumWaterCharge + remainingCharge;
      this.waterCharge = Math.round(minimumWaterCharge + remainingCharge);
      return minimumWaterCharge + remainingCharge;
    } else if (consumption > 15000 && consumption <= 25000) {
      var minimumWaterCharge = fixedCharge + 7 * slab1;
      var remainingConsumption = consumption - 15000;
      var remainingCharge = (remainingConsumption * slab2) / 1000;
      //this.waterCharge = minimumWaterCharge + remainingCharge;
      this.waterCharge = Math.round(minimumWaterCharge + remainingCharge);
      return minimumWaterCharge + remainingCharge;
    } else if (consumption > 25000) {
      var minimumWaterCharge = fixedCharge + 7 * slab1 + 10 * slab2;
      var remainingConsumption = consumption - 25000;
      var remainingCharge = (remainingConsumption * slab3) / 1000;
      // this.waterCharge = minimumWaterCharge + remainingCharge;
      this.waterCharge = Math.round(minimumWaterCharge + remainingCharge);
      return minimumWaterCharge + remainingCharge;
    }
  }

  consumptionFor30DaysPanchayath(consumption) {
    if (consumption <= 5000000) {
      this.waterCharge = Math.round((consumption * 4) / 1000);
      return (consumption * 4) / 1000;
    } else {
      var minimumWaterCharge = 20000;
      var remainingConsumption = consumption - 5000000;
      var remainingCharge = (remainingConsumption * 7.5) / 1000;
      //this.waterCharge = minimumWaterCharge + remainingCharge;
      this.waterCharge = Math.round(minimumWaterCharge + remainingCharge);
      return minimumWaterCharge + remainingCharge;
    }
  }

  consumptionFor30DaysTebma(consumption) {
    if (consumption <= 30000) {
      this.waterCharge = Math.round((consumption * 20) / 1000);
      return (consumption * 20) / 1000;
    } else if (consumption > 30000 && consumption <= 91000) {
      var minimumWaterCharge = 600;
      var remainingConsumption = consumption - 30000;
      var remainingCharge = (remainingConsumption * 30) / 1000;
      //this.waterCharge = minimumWaterCharge + remainingCharge;
      this.waterCharge = Math.round(minimumWaterCharge + remainingCharge);
      return minimumWaterCharge + remainingCharge;
    } else {
      var minimumWaterCharge = 600 + 1830;
      var remainingConsumption = consumption - 91000;
      var remainingCharge = (remainingConsumption * 50) / 1000;
      //this.waterCharge = minimumWaterCharge + remainingCharge;
      this.waterCharge = Math.round(minimumWaterCharge + remainingCharge);
      return minimumWaterCharge + remainingCharge;
    }
  }

  onSubmit() {
    this.submitted = true;
    var today = new Date();
    var year = today.getFullYear();
    var month = moment().format("MM");
    var day = today.getDate();
    this.validationForm.get("status").enable();
    this.validationForm.get("presentReading").enable();

    if (this.tariff == "KMC") {
      this.consumption = this.validationForm.value.consumption;
      this.OBA = this.validationForm.value.OBA;
      this.waterCharge = this.validationForm.value.waterCharge;
      this.serviceCharge = this.validationForm.value.serviceCharge;
      this.penalty = this.validationForm.value.penalty;
      this.demand = this.validationForm.value.demand;
      this.CBA = this.validationForm.value.CBA;
      this.adjustment = this.validationForm.value.adjustment;
      this.total = this.validationForm.value.total;
    }

    var billDateTime = moment(
      this.validationForm.value.billDateTime,
      "YYYY-MM-DDTHH:mm:ss"
    ).format("YYYY-MM-DD HH:mm:ss");

    var billing = {
      rrno: this.connectionId,
      billDateTime: moment().format("YYYY-MM-DD HH:mm:ss"),
      billNo: this.billNo,
      noOfDays: this.noOfDays,
      status: this.validationForm.value.status.toUpperCase(),
      previousReading: this.previousReading,
      presentReading: this.validationForm.value.presentReading.toString(),
      consumption: this.consumption.toString(),
      oba: this.OBA.toString(),
      demand: this.demand.toString(),
      waterCharge: this.waterCharge.toString(),
      serviceCharge: this.serviceCharge.toString(),
      penalty: this.penalty.toString(),
      adjustment: this.adjustment.toString(),
      CBA: this.CBA.toString(),
      dueDate: this.dueDate,
      remarks: this.validationForm.value.remarks,
      year: year.toString(),
      month: month.toString(),
      readingDate: this.connectionReadingDate,
      previousBillDate: this.readingDate,
      userId: this.userId,
      total: this.total.toString(),
      meterReaderName: this.meterReaderName,
    };

    console.log(
      "bill details",
      billing,
      this.previousReading,
      this.presentReadingValue
    );

    this.billingService.createBill(billing).then((response) => {
      if (response.code == 200) {
        this.submitted = false;
        this.reset();
        this.isVisible = false;
        this.btnCalclate = false;

        console.log("bill data 11", billing, this.subDivision);
        billing["subDivision"] = this.subDivision;
        billing["name"] = this.ownerName.replace("/", "");
        billing["doorNo"] = this.houseNo;
        billing["ward"] = this.ward;
        billing["tariff"] = this.tariff;
        billing["meterNo"] = this.meterNo;
        billing["meterConstant"] = this.meterConstant;
        var receipt = JSON.stringify(billing);
        window.open(
          "https://printbill.nixies.in/public/mn-compu-solution-bill-pdf/" +
          receipt,
          "_blank"
        );
      }
    });
  }

  reset() {
    this.presentReadingValue = "";
    this.validationForm.setValue({
      connectionId: "",
      ownerName: "",
      houseNo: "",
      phoneNumber: "",
      address: "",
      meterNo: "",
      wardNo: "",
      tariff: "",
      subDivision: "",
      userName: "",
      previousStatus: "",
      billDateTime: this.dateTimeDifferntFormat,
      billNo: "",
      noOfDays: "",
      status: "normal",
      previousReading: "",
      presentReading: "",
      consumption: "",
      OBA: "",
      demand: "",
      previousReadingDate: "",
      waterCharge: "",
      serviceCharge: "",
      penalty: "",
      adjustment: "",
      CBA: "",
      dueDate: "",
      remarks: "",
      total: "",
      //meterConstant: ""
    });
  }

  filterByRRNO() {
    this.RRNOsubmitted = true;
    this.presentReadingValue = "";
    this.previousReading = "";
    this.meterChangeDetailsArray = [];
    this.beforeMeterFixedBillDetailsArray = [];
    this.inBetweenAdjustmentConsumptionArray = [];
    this.previousStatusOfMeterFixed = "";
    this.meterFixedEnterDate = "";
    this.previousReadingDateOfMeterFixed = "";
    this.meterFixedDate = "";
    this.readingDate = "";
    this.OBA = 0;
    this.CBA = 0;
    this.demand = 0;
    this.waterCharge = 0;
    this.penalty = 0;
    this.adjustment = 0;
    this.validationForm.get("status").enable();
    this.consumption = 0;
    this.serviceDate = "";
    this.connectionType = "";
    this.validationForm.get("presentReading").enable();
    this.reconnectionDetailsArray = [];
    this.readonlyForBillDateTime = true;
    this.validationForm.controls.presentReading.setValue("");
    this.validationForm.controls.status.setValue("normal");
    console.log("filter by rrno");
    this.noOfDays = 0;
    if (this.rrnoForm.valid) {
      this.isVisible = true;

      let rrnoFormValue: string = this.rrnoForm.get("rrno").value;
      console.log("rrno", rrnoFormValue["RRNO"]);
      let rrno = rrnoFormValue["RRNO"];

      this.RRNOsubmitted = false;
      this.rrnoForm.reset();

      this.connectionId = rrno;
      // this.presentReadingValue = 0;
      console.log("present reading", this.connectionId);

      this.consumption = 0;

      this.isVisible = false;

      this.billDetailsArray = [];

      this.billingService.searchRRono(rrno).then((response) => {
        if (response.code == 200) {
          console.log("result", response.data);
          var connection = response.data["connectionResult"];
          this.billDetailsArray = response.data["ledgerResult"];
          if (
            this.billDetailsArray.length > 0 &&
            this.billDetailsArray[0].year == this.currentYear &&
            this.billDetailsArray[0].month == this.currentMonth
          ) {
            this.reset();
            this.toastrService.error("Already Billed", "Error!", {
              timeOut: 8000,
            });
          } else {
            if (connection[0].connectionStatus == "Live") {
              this.btnCalclate = true;

              this.connectionId = rrno;
              this.ownerName = connection[0].name;
              this.houseNo = connection[0].doorNo;
              this.address = connection[0].streetName;
              this.meterNo = connection[0].meterSerialNumber;
              this.ward = connection[0].ward;
              this.tariff = connection[0].tariff;
              this.subDivision = connection[0].subDivision;
              this.phoneNumber = connection[0].phoneNumber;
              this.serviceDate = connection[0].serviceDate;
              this.connectionType = connection[0].consumerConnectionStatusType;
              this.meterConstant = connection[0].meterConstant;
              this.connectionReadingDate = connection[0].readingDate;
              this.meterReaderName = connection[0].meterReaderName;

              if (connection[0].tariff == "KMC") {
                //this.validationForm.get("billDateTime").enable();
                this.readonlyForBillDateTime = false;
                this.isVisible = true;
                this.btnCalclate = false;
                this.validationForm.get("consumption").enable();
                this.validationForm.get("OBA").enable();
                this.validationForm.get("waterCharge").enable();
                this.validationForm.get("serviceCharge").enable();
                this.validationForm.get("penalty").enable();
                this.validationForm.get("demand").enable();
                this.validationForm.get("CBA").enable();
                this.validationForm.get("adjustment").enable();
                this.validationForm.get("total").enable();

                var date = new Date(this.dateTime);
                var newdate = new Date(date);
                newdate.setDate(newdate.getDate() + 15);
                this.dueDate =
                  newdate.getFullYear() +
                  "-" +
                  ("0" + (newdate.getMonth() + 1)).slice(-2) +
                  "-" +
                  ("0" + newdate.getDate()).slice(-2);
              }

              //this.billDetailsArray = response.data["ledgerResult"];

              if (this.billDetailsArray.length == 0) {
                let serviceDate = connection[0].serviceDate;
                const a = new Date(serviceDate),
                  b = new Date(this.dateTime);
                this.noOfDays = this.dateDiffInDays(a, b);
                this.readingDate = serviceDate;
                console.log("difference 1", this.noOfDays, serviceDate);
                this.previousReading = 0;

                this.previousStatus = "normal";
              } else {
                console.log("haiiiiiii", this.billDetailsArray[0]);
                var previousBillDateTime =
                  this.billDetailsArray[0].billDateTime;
                var presentReading = this.billDetailsArray[0].presentReading;

                this.previousCBA = Number(this.billDetailsArray[0].cba);
                this.reconnectionDetailsArray =
                  response.data["meterReconnectionResult"];
                if (
                  this.reconnectionDetailsArray.length > 0 &&
                  this.reconnectionDetailsArray[0].reconnectionDate >
                  this.billDetailsArray[0].billDateTime
                ) {
                  console.log("data meterchange");

                  var reconnectionDate =
                    this.reconnectionDetailsArray[0].reconnectionDate;
                  this.readingDate =
                    this.reconnectionDetailsArray[0].reconnectionDate;
                  const a = new Date(reconnectionDate),
                    b = new Date(this.dateTime);
                  this.noOfDays = this.dateDiffInDays(a, b);
                } else {
                  this.readingDate =
                    this.billDetailsArray[0].billDateTime == null ||
                      this.billDetailsArray[0].billDateTime == ""
                      ? this.billDetailsArray[0].previousBillDate
                      : this.billDetailsArray[0].billDateTime;
                  const a =
                    this.billDetailsArray[0].billDateTime == null ||
                      this.billDetailsArray[0].billDateTime == ""
                      ? new Date(this.billDetailsArray[0].previousBillDate)
                      : new Date(previousBillDateTime),
                    b = new Date(this.dateTime);
                  this.noOfDays = this.dateDiffInDays(a, b);
                }

                this.previousReading =
                  this.billDetailsArray[0].presentReading == null ||
                    this.billDetailsArray[0].presentReading == ""
                    ? 0
                    : this.billDetailsArray[0].presentReading;
                this.previousStatus = this.billDetailsArray[0].status;
                if (this.billDetailsArray[0].status.toLowerCase() == "mnr") {
                  this.validationForm.controls.status.setValue("mnr");
                  this.validationForm.get("status").disable();
                  this.validationForm.get("presentReading").disable();
                  this.presentReadingValue =
                    this.billDetailsArray[0].presentReading;
                } else if (
                  this.billDetailsArray[0].status.toLowerCase() == "dc"
                ) {
                  this.validationForm.controls.status.setValue("dc");
                  this.validationForm.get("status").disable();
                  this.presentReadingValue =
                    this.billDetailsArray[0].presentReading;
                }

                this.meterChangeDetailsArray =
                  response.data["meterChangeResult"];
                if (this.meterChangeDetailsArray.length > 0) {
                  console.log(
                    "this.meterChangeDetailsArray",
                    this.meterChangeDetailsArray
                  );

                  this.meterFixedEnterDate =
                    this.meterChangeDetailsArray[0].enteredDate;

                  this.meterFixedDate =
                    this.meterChangeDetailsArray[0].meterFixedDate;

                  var meterfixedMonthYear = this.meterFixedEnterDate.split('-')[0]+'-'+this.meterFixedEnterDate.split('-')[1]
                  var readingDateMonthYear = this.readingDate.split('-')[0]+'-'+this.readingDate.split('-')[1]
                  this.billingService
                    .getMeterFixedDetails(rrno, this.meterFixedDate)
                    .then((response) => {
                      this.beforeMeterFixedBillDetailsArray =
                        response.data["beforeMeterFixedBillDetails"];

                      if (this.beforeMeterFixedBillDetailsArray.length > 0) {
                        console.log(
                          "beforeMeterFixedBillDetailsArray",
                          this.meterChangeDetailsArray
                        );

                        this.previousStatusOfMeterFixed =
                          this.beforeMeterFixedBillDetailsArray[0].status;
                        this.previousReadingDateOfMeterFixed =
                          this.beforeMeterFixedBillDetailsArray[0].billDateTime;

                          console.log('meterfixedMonthYear > readingDateMonthYear',meterfixedMonthYear , readingDateMonthYear)
                        if (this.meterFixedEnterDate > this.readingDate && meterfixedMonthYear > readingDateMonthYear) {
                          this.previousReading =
                            this.meterChangeDetailsArray[0].presentReading;
                          this.validationForm.get("status").enable();
                          this.validationForm.controls.status.setValue(
                            "normal"
                          );
                          this.validationForm.get("presentReading").enable();
                          this.presentReadingValue = "";
                        }
                      }

                      response.data["inBetweenAdjustmentIssuedDetails"].forEach(
                        (element) => {
                          this.inBetweenAdjustmentConsumptionArray.push(
                            element["consumption"]
                          );
                          this.inBetweenAdjustmentWaterChargeArray.push(
                            element["waterCharge"]
                          );
                        }
                      );
                    });
                }
              }

              if (response.data["billResult"].length > 0) {
                var billNo = response.data["billResult"][0]["billNo"];
                var thenum = billNo.replace(/^\D+/g, "");

                var addOne = Number(thenum) + 1;
                this.billNo = "office" + addOne;
              } else {
                this.billNo = "office1000001";
              }
              console.log("billNo", response.data["billResult"][0]["billNo"]);
            } else {
              this.reset();
              this.toastrService.error("Meter Disconnected", "Error!", {
                timeOut: 8000,
              });
            }
          }
        }
      });

      /* var connectionDetail = this.firestore.collection('/connections', ref => ref.where('RRNO', '==', rrno));
      connectionDetail.valueChanges().subscribe((con) => {
        if(con.length > 0){
          
          if(con[0]['consumerConnectionStatusType'] == 'Live'){
            this.btnCalclate = true;
            this.connectionDetail = this.firestore.collection('/connections', ref => ref.where('RRNO', '==', rrno));
            this.connectionDetail.valueChanges().subscribe((data) => {
              console.log("connections detail", data);
               data.forEach(e => {
                 this.connectionId = rrno;
                 this.ownerName = e.name;
                 this.houseNo = e.doorNo;
                 this.address = e.streetName;
                 this.meterNo = e.meterSerialNumber;
                 this.ward = e.ward;
                 this.tariff = e.tariff;
               
                 this.subDivision = e.subDivision;
                
                 this.phoneNumber = e.phoneNumber;
                 this.serviceDate = e.serviceDate;
                 this.connectionType = e.consumerConnectionStatusType;
                 this.meterConstant = e.meterConstant;
               })
             });
             
             this.billDetails = this.firestore.collection('/ledger', ref => ref.where('connectionId', '==', rrno).orderBy('billDateTime', 'desc'));
             this.billDetails.valueChanges().subscribe((data) => {
               data.forEach(element => {
                 console.log("element",element);
                 this.billDetailsArray.push(element);
               });
       
               this.customerConnectionDetails = this.firestore.collection('/connections', ref => ref.where('RRNO', '==', rrno));
               if(this.billDetailsArray.length == 0){
                 
                 this.customerConnectionDetails.valueChanges().subscribe((data)=> {
                   data.forEach(e => {
                     let serviceDate = e.serviceDate;
                     const a = new Date(serviceDate),  
                     b = new Date(this.dateTime);
                     this.noOfDays = this.dateDiffInDays(a, b);
                     this.readingDate = serviceDate;
                     console.log("difference 1", this.noOfDays, serviceDate);
                     this.previousReading = 0;
                     
                     this.previousStatus = "normal";
                   });
                 });
                 
               }
               else
               {
                 console.log("bill array", this.billDetailsArray);
                 var previousBillDateTime = this.billDetailsArray[0].billDateTime;
                 var presentReading = this.billDetailsArray[0].presentReading;
                 
                 
                 this.previousCBA = Number(this.billDetailsArray[0].CBA);
       
                 this.reconnectionDetails = this.firestore.collection('/meter-reconnection', ref => ref.where('rrno', '==', rrno).orderBy('enteredDate', 'desc'));
                 this.reconnectionDetails.valueChanges().subscribe((data) => {
                   data.forEach(element => {
                     console.log("element reconnection",element);
                     this.reconnectionDetailsArray.push(element);
                   });
       
                   if(this.reconnectionDetailsArray.length > 0 && this.reconnectionDetailsArray[0].meterReconnectionDate > this.billDetailsArray[0].billDateTime){
                     var reconnectionDate = this.reconnectionDetailsArray[0].meterReconnectionDate
                     this.readingDate = this.reconnectionDetailsArray[0].meterReconnectionDate
                     const a = new Date(reconnectionDate),
                     b = new Date(this.dateTime);
                     this.noOfDays = this.dateDiffInDays(a, b);
                     
                   }
                   else{
                     this.readingDate = this.billDetailsArray[0].billDateTime;
                     const a = new Date(previousBillDateTime),
                     b = new Date(this.dateTime);
                     this.noOfDays = this.dateDiffInDays(a, b);
                     
                     
                   }
                 })
      
                 this.previousReading = presentReading;
                 this.previousStatus = this.billDetailsArray[0].status;
                 if(this.billDetailsArray[0].status.toLowerCase() == 'mnr'){
                    this.validationForm.controls.status.setValue('mnr');
                    this.validationForm.get('status').disable();
                    this.validationForm.get('presentReading').disable();
                    this.presentReadingValue = presentReading;
                   
                 }
                 else if(this.billDetailsArray[0].status.toLowerCase() == 'dc'){
                   this.validationForm.controls.status.setValue('dc');
                   this.validationForm.get('status').disable();
                   this.presentReadingValue = presentReading;
                 }
               
               }
             
             });
             
             this.meterChangeDetails = this.firestore.collection('/meter-change', ref => ref.where('rrno', '==', rrno).orderBy('enteredDate', 'desc'));
             this.meterChangeDetails.valueChanges().subscribe((data) => {
               data.forEach(element => {
                 console.log("element",element);
                 this.meterChangeDetailsArray.push(element);
               });
       
               
       
               if(this.meterChangeDetailsArray.length > 0){
                 this.meterFixedEnterDate = this.meterChangeDetailsArray[0].enteredDate;
               
                 this.meterFixedDate = this.meterChangeDetailsArray[0].meterFixedDate;
                 this.beforeMeterFixedBillDetails = this.firestore.collection('/ledger', ref => ref.where('connectionId', '==', rrno).where('billDateTime', '<=' ,this.meterFixedDate).orderBy('billDateTime', 'desc'));
                 this.beforeMeterFixedBillDetails.valueChanges().subscribe((data)=>{
                   data.forEach(element => {
                     this.beforeMeterFixedBillDetailsArray.push(element);
                   });
       
                   if(this.beforeMeterFixedBillDetailsArray.length > 0){
                     console.log("beforeMeterFixedBillDetailsArray", this.meterChangeDetailsArray);
                     
                     this.previousStatusOfMeterFixed = this.beforeMeterFixedBillDetailsArray[0].status;
                     this.previousReadingDateOfMeterFixed = this.beforeMeterFixedBillDetailsArray[0].billDateTime;
                  
                     if(this.meterFixedEnterDate > this.readingDate){  
                       this.previousReading = this.meterChangeDetailsArray[0].fr;
                       this.validationForm.get('status').enable();
                       this.validationForm.controls.status.setValue('normal');
                       this.validationForm.get('presentReading').enable();
                       this.presentReadingValue = '';
                     }
                   }
       
                   this.inBetweenAdjustmentDetailsIssuedDetails = this.firestore.collection('/ledger', ref => ref.where('connectionId', '==', rrno).where('billDateTime', '>' ,this.meterFixedDate).where('billDateTime', '<' ,this.dateTime).orderBy('billDateTime', 'desc'));
                   this.inBetweenAdjustmentDetailsIssuedDetails.valueChanges().subscribe((data)=>{
                     
                     data.forEach(element => {
                      
                       this.inBetweenAdjustmentConsumptionArray.push(element['consumption']);
                       this.inBetweenAdjustmentWaterChargeArray.push(element['waterCharge']);
                     });
                   })
                 })
               }
             })
       
             this.firestore.collection('/ledger', ref => ref.orderBy('billNo', 'desc').limit(1)).valueChanges().subscribe((data)=>{
               
               if(data.length > 0){
                 var billNo = data[0]['billNo'];
                 var thenum = billNo.replace( /^\D+/g, '');
               
                 var addOne = Number(thenum) + 1;
                 this.billNo = 'office' + addOne; 
                 
               }
               else{
                 this.billNo =  'office1000001'
               }
             })
          }
          else{
            this.reset();
            this.toastrService.error("Meter Disconnected", "Error!", {
              timeOut: 8000
            });
         
          }
        }
      }) */
    }
  }

  searchFocusOnRRNO = (text$: Observable<string>) => {
    const debouncedText$ = text$.pipe(
      debounceTime(200),
      distinctUntilChanged()
    );
    const clicksWithClosedPopup$ = this.click$.pipe(
      filter(() => !this.instance.isPopupOpen())
    );
    const inputFocus$ = this.focus$;
    //this.crrno = this.firestore.collection('/connections');
    let crrnoArray = [];
    /*  this.crrno.valueChanges().subscribe((data)=>{
      data.forEach(element => {
        crrnoArray.push({RRNO:element.RRNO, name:element.name});
      });
    }); */
    this.connectionService.getAllConnection().then((response) => {
      if (response.code == 200) {
        response.data.forEach((element) => {
          crrnoArray.push({ RRNO: element.rrno, name: element.name });
        });
      }
    });
    return merge(debouncedText$, inputFocus$, clicksWithClosedPopup$).pipe(
      map((term) =>
        term === ""
          ? []
          : crrnoArray.filter((v) => v.RRNO.toLowerCase() == term.toLowerCase())
      )
    );
  };

  // crrnoArray.filter((v) => v.RRNO.toLowerCase() == term.toLowerCase())
  rrnoFormatter = (x: { RRNO: string }) => x.RRNO;

  browseConnectionDetail(event) {
    this.btnCalclate = false;
    //this.reset();
    this.isVisible = false;
  }

  onStatusSelect(event) {
    this.validationForm.get("presentReading").enable();
    if (this.meterFixedEnterDate > this.readingDate) {
      this.previousReading = this.meterChangeDetailsArray[0].fr;
    } else {
      if (this.billDetailsArray.length > 0) {
        this.previousReading = this.billDetailsArray[0].presentReading;
      }
    }

    if (
      this.validationForm.value.status == "do" ||
      this.validationForm.value.status == "normal"
    ) {
      this.presentReadingValue = "";
    } else {
      this.presentReadingValue = this.previousReading;
      this.validationForm.controls.presentReading.setValue(
        this.previousReading
      );
      this.validationForm.get("presentReading").disable();
    }

    var previousDate;
    if (
      this.reconnectionDetailsArray.length > 0 &&
      this.reconnectionDetailsArray[0].reconnectionDate >
      this.billDetailsArray[0].billDateTime
    ) {
      previousDate = this.reconnectionDetailsArray[0].reconnectionDate;
    } else if (this.billDetailsArray.length > 0) {
      previousDate =
        this.billDetailsArray[0].billDateTime == null ||
          this.billDetailsArray[0].billDateTime == ""
          ? this.billDetailsArray[0].previousBillDate
          : this.billDetailsArray[0].billDateTime;
    } else {
      previousDate = this.serviceDate;
    }

    const a = new Date(previousDate),
      b = new Date(this.dateTime);
    this.noOfDays = this.dateDiffInDays(a, b);
    console.log("this.no.of days", this.noOfDays, previousDate);

    this.validationForm.value.presentReading = this.previousReading;
    this.consumption = 0;
    this.OBA = 0;
    this.CBA = 0;
    this.demand = 0;
    this.waterCharge = 0;
    this.penalty = 0;
    this.adjustment = 0;
    this.btnCalclate = true;
  }

  dateDiffInDays(a, b) {
    const _MS_PER_DAY = 1000 * 60 * 60 * 24;
    const utc1 = Date.UTC(a.getFullYear(), a.getMonth(), a.getDate());
    const utc2 = Date.UTC(b.getFullYear(), b.getMonth(), b.getDate());
    return Math.floor((utc2 - utc1) / _MS_PER_DAY);
  }

  test() {
    this.noOfDays = this.validationForm.controls.noOfDays.value;
  }
}
