import { Component, OnInit, ViewChild } from "@angular/core";
import { AngularFirestore } from "@angular/fire/firestore";
import { FormControl, FormGroup, Validators } from "@angular/forms";
import { Observable } from "rxjs/Observable";
import {
  debounceTime,
  distinctUntilChanged,
  filter,
  flatMap,
  map,
} from "rxjs/operators";
import { NgbTypeahead } from "@ng-bootstrap/ng-bootstrap";
import { Subject, merge } from "rxjs";
import { ToastrService } from "ngx-toastr";
import { ConnectionService } from "src/app/shared/service/connection.service";
import { CashTransferService } from "src/app/shared/service/cash-transfer.service";

declare var require: any;
var moment = require("moment");

@Component({
  selector: "app-cash-transfer",
  templateUrl: "./cash-transfer.component.html",
  styleUrls: ["./cash-transfer.component.scss"],
})
export class CashTransferComponent implements OnInit {
  rrnoForm = new FormGroup({
    fromRrno: new FormControl("", Validators.required),
    toRrno: new FormControl("", Validators.required),
    receiptDate: new FormControl("", Validators.required),
  });

  click$ = new Subject<string>();
  @ViewChild("instance") instance: NgbTypeahead;
  focus$ = new Subject<string>();

  validationForm: FormGroup;

  RRNOsubmitted: boolean = false;

  connectionId;
  ownerName;
  houseNo;
  address;
  meterNo;
  ward;
  tariff;
  subDivision;
  userName;
  phoneNumber;
  receiptNo;
  dateTime;
  amount;
  chequeDdNo;
  chequeDdDate;
  chequeDdBranch;
  paymentReceivedBank;
  crrno;
  isVisible: boolean = false;
  oldRRNO;
  receiptDate;
  bankCashCollectionId;

  bankCashCollection;
  connectionDetail;
  ledgerDetail;
  oldRrnoLedgerArray: any = [];

  constructor(
    private firestore: AngularFirestore,
    private totastrService: ToastrService,
    private connectionService: ConnectionService,
    private cashTransferService: CashTransferService
  ) {
    var today = new Date();
    this.dateTime =
      today.getFullYear() +
      "/" +
      ("0" + (today.getMonth() + 1)).slice(-2) +
      "/" +
      ("0" + today.getDate()).slice(-2) +
      " " +
      ("0" + today.getHours()).slice(-2) +
      ":" +
      ("0" + today.getMinutes()).slice(-2) +
      ":" +
      ("0" + today.getSeconds()).slice(-2);
  }

  ngOnInit() {
    this.validationForm = new FormGroup({
      transferedFromRrno: new FormControl(),
      transferedFromName: new FormControl(),
      transferedFromDoorNo: new FormControl(),
      transferedFromPhoneNumber: new FormControl(),
      transferedToRrno: new FormControl(),
      transferedToName: new FormControl(),
      transferedToDoorNo: new FormControl(),
      transferedToPhoneNumber: new FormControl(),
      receiptNo: new FormControl(),
      receiptDateTime: new FormControl(),
      mode: new FormControl(),
      amount: new FormControl(),
      chequeDdNo: new FormControl(""),
      chequeDdDate: new FormControl(""),
      chequeDdBranch: new FormControl(""),
      paymentReceivedBank: new FormControl(),
      receiptDate: new FormControl(),
    });
  }

  searchFocusOnRRNO = (text$: Observable<string>) => {
    const debouncedText$ = text$.pipe(
      debounceTime(200),
      distinctUntilChanged()
    );
    const clicksWithClosedPopup$ = this.click$.pipe(
      filter(() => !this.instance.isPopupOpen())
    );
    const inputFocus$ = this.focus$;
    /* this.crrno = this.firestore.collection('/connections');
    let crrnoArray = [];
    this.crrno.valueChanges().subscribe((data)=>{
      data.forEach(element => {
        crrnoArray.push({RRNO:element.RRNO, name:element.name});
      });
    }); */
    let crrnoArray = [];
    this.connectionService.getAllConnection().then((response) => {
      if (response.code == 200) {
        response.data.forEach((element) => {
          crrnoArray.push({ RRNO: element.rrno, name: element.name });
        });
      }
    });
    return merge(debouncedText$, inputFocus$, clicksWithClosedPopup$).pipe(
      map((term) =>
        term === ""
          ? []
          : crrnoArray.filter((v) => v.RRNO.toLowerCase() == term.toLowerCase())
      )
    );
  };
  // crrnoArray.filter((v) => v.RRNO.toLowerCase() == term.toLowerCase())
  rrnoFormatter = (x: { RRNO: string }) => x.RRNO;

  browseConnectionDetail(event, from) {
    //this.btnCalclate = false;
    //this.reset();
    this.isVisible = false;
    console.log("value", event);
  }

  filterByRRNO() {
    this.RRNOsubmitted = true;
    console.log("filter by rrno");
    if (this.rrnoForm.valid) {
      //this.isVisible = true;

      let rrnoFromValue: string = this.rrnoForm.get("fromRrno").value;
      let rrnoToValue: string = this.rrnoForm.get("toRrno").value;

      let fromRrno = rrnoFromValue["RRNO"];
      let toRrno = rrnoToValue["RRNO"];

      let receiptDate = this.rrnoForm.value.receiptDate;

      this.RRNOsubmitted = false;
      this.rrnoForm.reset();

      this.connectionId = rrnoFromValue;
      console.log("connectionId", this.connectionId, receiptDate);
      var data1 = {
        fromRrno: fromRrno,
        toRrno: toRrno,
        receiptDate: receiptDate,
      };
      console.log("rrno", data1);

      this.cashTransferService
        .getCashCollectionDetails(data1)
        .then(async (response) => {
          if (response.code == 200) {
            var res = response.data;
            console.log(" res[0].rrno", res);
            this.bankCashCollectionId = res[0].id;
            this.validationForm.patchValue({
              transferedFromRrno: res[0].rrno,
              transferedFromName: res[0].name,
              transferedFromDoorNo: res[0].doorNo,
              transferedFromPhoneNumber: res[0].phoneNumber,
              transferedToRrno: toRrno,
              transferedToName: res[0].toName,
              transferedToDoorNo: res[0].toDoorNo,
              transferedToPhoneNumber: res[0].toPhoneNumber,
              receiptNo: res[0].receiptNo,
              amount: res[0].amount,
              chequeDdNo: res[0].chequeDdNo,
              chequeDdDate: res[0].chequeDdDate,
              chequeDdBranch: res[0].chequeDdBranch,
              paymentReceivedBank: res[0].paymentReceivedBank,
              receiptDateTime: res[0].receiptDateTime,
              mode: res[0].mode,
              receiptDate: res[0].receiptDate,
            });
          }
        });

      /* this.bankCashCollection = this.firestore.collection('/bankCashCollection', ref => ref.where('RRNO', '==', fromRrno).where('date', '==', receiptDate).limit(1));
      this.bankCashCollection.snapshotChanges().subscribe((data) => {
       
        data.map(c => {
          this.bankCashCollectionId = c.payload.doc.id;
          var con = c.payload.doc.data();
          this.validationForm.patchValue({
            transferFromConnectionId : con["RRNO"],
            transferFromOwnerName : con["name"],
            transferFromHouseNo : con["doorNo"],
            transferFromPhoneNumber : con["phoneNumber"],
            receiptNo : con["receiptNo"],
            amount : con["amount"],
            chequeDdNo : con["chequeDdNo"],
            chequeDdDate : con["chequeDdDate"],
            chequeDdBranch : con["chequeDdBranch"],
            paymentReceivedBank : con["paymentReceivedBank"],
            billDateTime: con["billDateTime"],
            mode: con["mode"],
            receiptDate: con["date"]
          })
          this.oldRRNO = con["RRNO"];
          this.receiptDate = con["date"];
        })

      
        
        this.connectionDetail = this.firestore.collection('/connections', ref => ref.where('RRNO', '==',toRrno).limit(1));
        this.connectionDetail.valueChanges().subscribe((data) => {
   
          data.forEach(e => {
            console.log("transfer to rrno details", e.name);
            
            this.validationForm.patchValue({
              transferToConnectionId :e.RRNO,
              transferToOwnerName : e.name,
              transferToHouseNo : e.doorNo,
              transferToPhoneNumber : e.phoneNumber,
            })
          })
        });
      }); */
    }
  }

  onSubmit() {
    // console.log("this.receiptDate", this.receiptDate, this.validationForm.value.connectionId);
    var billDate = moment(this.receiptDate).format("YYYY-MM-DD HH:mm:ss");
    var data = this.validationForm.value;
    data.userName = localStorage.getItem("user_name");
    data.userId = localStorage.getItem("user_id");
    data.id = this.bankCashCollectionId;

    this.cashTransferService.cashTransfer(data).then(async (response) => {
      if (response.code == 200) {
        this.validationForm.reset();
        this.totastrService.success("Cash Transfered successfully", "Success");
      } else {
        this.totastrService.error("Cash Transfer Failed", "Failed");
      }
    });

    /* this.firestore.collection('cash-transfer').add(data);

    this.firestore.collection('bankCashCollection').doc(this.bankCashCollectionId).update({
      RRNO:this.validationForm.value.transferToConnectionId,
      name:this.validationForm.value.transferToOwnerName,
      phoneNumber:this.validationForm.value.transferToPhoneNumber,
      doorNo: this.validationForm.value.transferToHouseNo
    }) */

    //updating wrong RRNO Ledger
    /* var keepGoing = true;
    this.ledgerDetail = this.firestore.collection('ledger', ref => ref.where('connectionId', '==', this.validationForm.value.transferFromConnectionId).where('billDateTime', '<=', billDate).orderBy('billDateTime', 'desc').limit(1)).snapshotChanges().pipe(flatMap(clients => clients));
    this.ledgerDetail.subscribe(doc => {
      var data = doc.payload.doc.data();
      if(keepGoing){
        keepGoing = false;

        var newCBA = Number(data['CBA']) + Number(this.validationForm.value.amount) ;
        console.log("doc.payload.doc.id", doc.payload.doc.id, newCBA, keepGoing);
        this.firestore.collection('ledger').doc(doc.payload.doc.id).update({'CBA':newCBA.toString(),'paidAmount':'','receiptDate':'', 'receiptNo':''}).then(response =>{
          console.log("updated");
         
          var billDate1 = moment(Number(data['billDateTime'])).format('YYYY-MM-DD HH:mm:ss');
          var keepGoingForOldConnection = true;
          this.firestore.collection('ledger', ref => ref.where('connectionId', '==', this.validationForm.value.transferFromConnectionId).where('billDateTime', '>', data['billDateTime']).orderBy('billDateTime', 'asc')).snapshotChanges().subscribe(update=>{
           update.map((ul, key) =>{
              
              if(keepGoingForOldConnection){
                if(key == update.length - 1){
                  keepGoingForOldConnection = false;
                }
                console.log("inside id", ul.payload.doc.id, ul.payload.doc.data(), key);
                var ulData = ul.payload.doc.data()
                var OBA = newCBA;
                var CBA = OBA + Number(ulData['demand']);
                var paidAmount = ulData['paidAmount'] == ''?'': CBA - Number(ulData['paidAmount']);
                console.log("paidAmount", paidAmount);
                
                newCBA = CBA;
                this.firestore.collection('ledger').doc(ul.payload.doc.id).update({'OBA':OBA.toString(),'CBA':CBA.toString(), 'paidAmount':paidAmount}).then(response =>{

                })
              }
              
            })
          })
        })
      }
    }); */

    //updating correct RRNO Ledger
    /* var keepGoing1 = true;
    this.ledgerDetail = this.firestore.collection('ledger', ref => ref.where('connectionId', '==', this.validationForm.value.transferToConnectionId).where('billDateTime', '<=', billDate).orderBy('billDateTime', 'desc').limit(1)).snapshotChanges().pipe(flatMap(clients => clients));
    this.ledgerDetail.subscribe(doc => {
      var data = doc.payload.doc.data();
      if(keepGoing1){
        keepGoing1 = false;
        var newCBA = Number(data['CBA']) - Number(this.validationForm.value.amount);

        var paidAmount;
        var receiptDate;
        var receiptNo;
        if(data['paidAmount'] != ''){
          paidAmount = data['paidAmount'] + ',' + data.amount;
          receiptDate = data['receiptDate'] + ',' + receiptDateTime;
          receiptNo = data['receiptNo'] + ',' + this.receiptNo;
        }
        else{
          paidAmount = data.amount;
          receiptDate = receiptDateTime;
          receiptNo = this.receiptNo
        }
        console.log("doc.payload.doc.id", doc.payload.doc.id, newCBA, keepGoing1);
        this.firestore.collection('ledger').doc(doc.payload.doc.id).update({'CBA':newCBA.toString(), 'paidAmount':paidAmount,'receiptDate':receiptDate, 'receiptNo':receiptNo}).then(response =>{
          console.log("updated");
         
          var billDate1 = moment(Number(data['billDateTime'])).format('YYYY-MM-DD HH:mm:ss');
          var keepGoingForOldConnection1 = true;
          this.firestore.collection('ledger', ref => ref.where('connectionId', '==', this.validationForm.value.transferToConnectionId).where('billDateTime', '>', data['billDateTime']).orderBy('billDateTime', 'asc')).snapshotChanges().subscribe(update=>{
           update.map((ul, key) =>{
              
              if(keepGoingForOldConnection1){
                if(key == update.length - 1){
                  keepGoingForOldConnection1 = false;
                  this.validationForm.reset();
                  this.totastrService.success("Cash Transfered successfully", "Success")
                }
                console.log("inside id", ul.payload.doc.id, ul.payload.doc.data(), key);
                var ulData = ul.payload.doc.data()
                var OBA = newCBA;
                var CBA = OBA + Number(ulData['demand']);
                var paidAmount = ulData['paidAmount'] == ''?'':Number(ulData['paidAmount']) + Number(this.validationForm.value.amount);
                console.log("paidAmount", paidAmount);
                newCBA = CBA;
                this.firestore.collection('ledger').doc(ul.payload.doc.id).update({'OBA':OBA.toString(),'CBA':CBA.toString(), 'paidAmount': paidAmount}).then(response =>{

                })
              }
              
            })
          })
        })
      }
    }); */
  }

  cancel() {
    location.reload();
  }
}
