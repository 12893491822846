import { Component, Input, OnInit, Output, EventEmitter } from '@angular/core';

@Component({
  selector: 'app-view-image',
  templateUrl: './view-image.component.html',
  styleUrls: ['./view-image.component.scss']
})
export class ViewImageComponent implements OnInit {
  @Input('imageData') imageData;
  @Output('onCloseModal') onCloseModal  = new EventEmitter<string>();
  constructor() { }

  ngOnInit() {
  }

}
