// Menu
export interface Menu {
  path?: string;
  title?: string;
  icon?: string;
  type?: string;
  headTitle?: string,
  badgeType?: string;
  badgeValue?: string;
  children?: Menu[];
  role?:string;
}

export const MENUITEMS: Menu[] = [
  { 
    headTitle: 'General'
  }, 
  {
    path: '/dashboard', title: 'Dashboard', icon: 'icon-desktop', type: 'link'
  },
  /* {
    path:'/find-connection', title: 'Connection', icon: 'icofont icofont-connection', type: 'link'
  }, */
  {
    path:'/connection', title: 'Connection', icon: 'icofont icofont-connection', type: 'link'
  },
  {
    path:'/admins', title: 'Admins', icon: 'icofont icofont-users-alt-2', type: 'link'
  },
  {
    path:'/supervisors', title: 'Staffs', icon: 'icofont icofont-users-alt-5', type: 'link'
  },
  /* {
    path:'/bank-staffs', title: 'Bank Staffs', icon: 'icofont icofont-business-man-alt-1', type: 'link'
  }, */
  /* {
    path:'/billing', title: 'Billing', icon: 'icofont icofont-architecture-alt', type: 'link'
  }, */
  {
    path:'/agent', title: 'Agents', icon:'icofont icofont-users-social', type: 'link'
  },
 /*  {
    path:'/cash-transfer', title: 'Cash Transfer', icon:'icofont icofont-bank-transfer', type: 'link'
  },
  {
    path:'/import-excel', title: 'Import Excel', icon:'icofont icofont-file-excel', type: 'link'
  },
  {
    path:'/suspension-cash-transfer',title: 'Suspense', icon:'icofont icofont-notebook', type: 'link'
  }, */
  {
    title: 'Reports', icon: 'icofont icofont-ebook', type: 'sub', children: [
      { path: '/reports/change-details', title: 'Change Details', type: 'link' },
      { path: '/reports/ledger', title: 'Ledger', type: 'link' },
      { path: '/reports/meter-reader', title: 'Meter Reader Day Wise Bill', type: 'link' },
      { path: '/reports/agent-wise-ledger', title: 'Daily Billing', type: 'link' },
      /*{ path: '/reports/agent-wise-ledger-old', title: 'Daily Billing Old', type: 'link' },*/
      { path: '/reports/DCB', title: 'DCB', type: 'link' },
      { path: '/reports/DCB-financial-year', title:'DCB Financial Year', type:'link'},
      { path: '/reports/pending-balance', title:'ward-wise-pending-balance', type:'link'},
      /* { path: '/reports/collection-report', title:'Collection Report', type:'link'}, */
      { path: '/reports/billed-unbilled', title:'Unbilled record', type:'link'},
      { path: '/reports/unbilled-record-details', title: 'Unbilled record details', type: 'link'},
      { path: '/reports/statuswise-billed-records', title: 'Billed Records', type: 'link'},
      {path: '/reports/billed-record-details', title:'Billed Record Details', type: 'link'}, 
      { path: '/reports/connection-type', title:'Connection Type', type:'link'},
      { path: '/reports/ward-details', title:'Ward Details', type:'link'},
      /*{ path: '/reports/cash-transfer', title:'Cash Transfer', type:'link'},
      { path:'/reports/suspense-transfer', title:'Suspense Transfer', type:'link'},
      { path:'/reports/Ledger-Rectify-details', title:'Ledger rectify', type:'link'},
      { path:'/reports/duplicate-bills-in-ledger', title:'Duplicate Bills in ledger', type:'link'},
      { path:'/reports/move-payments-in-ledger', title:'Move Payments to latest ledger', type:'link'}, */
    ]
  },
]

export const ADMIN_MENUITEMS: Menu[] = [
  { 
    headTitle: 'General'
  }, 
  {
    path: '/dashboard', title: 'Dashboard', icon: 'icon-desktop', type: 'link'
  },
  /* {
    path:'/find-connection', title: 'Connection', icon: 'icofont icofont-connection', type: 'link'
  }, */
  {
    path:'/connection', title: 'Connection', icon: 'icofont icofont-connection', type: 'link'
  },
  {
    path:'/supervisors', title: 'Staffs', icon: 'icofont icofont-users-alt-5', type: 'link'
  },
 /*  {
    path:'/bank-staffs', title: 'Bank Staffs', icon: 'icofont icofont-business-man-alt-1', type: 'link'
  }, */
  /* {
    path:'/billing', title: 'Billing', icon: 'icofont icofont-architecture-alt', type: 'link'
  }, */
  {
    path:'/agent', title: 'Agents', icon:'icofont icofont-users-social', type: 'link'
  },
  /* {
    path:'/cash-transfer', title: 'Cash Transfer', icon:'icofont icofont-bank-transfer', type: 'link'
  },
  {
    path:'/import-excel', title: 'Import Excel', icon:'icofont icofont-file-excel', type: 'link'
  },
  {
    path:'/suspension-cash-transfer',title: 'Suspense', icon:'icofont icofont-notebook', type: 'link'
  }, */
  {
    title: 'Reports', icon: 'icofont icofont-ebook', type: 'sub', children: [
      { path: '/reports/change-details', title: 'Change Details', type: 'link' },
      { path: '/reports/ledger', title: 'Ledger', type: 'link' },
      { path: '/reports/meter-reader', title: 'Meter Reader Day Wise Bill', type: 'link' },
      { path: '/reports/agent-wise-ledger', title: 'Daily Billing', type: 'link' },
      /*{ path: '/reports/agent-wise-ledger-old', title: 'Daily Billing Old', type: 'link' },*/
      { path: '/reports/DCB', title: 'DCB', type: 'link' },
      { path: '/reports/DCB-financial-year', title:'DCB Financial Year', type:'link'},
      { path: '/reports/pending-balance', title:'ward-wise-pending-balance', type:'link'},
     /*  { path: '/reports/collection-report', title:'Collection Report', type:'link'}, */
      { path: '/reports/billed-unbilled', title:'Unbilled record', type:'link'},
      { path: '/reports/unbilled-record-details', title: 'Unbilled record details', type: 'link'},
      { path: '/reports/statuswise-billed-records', title: 'Billed Records', type: 'link'},
      {path: '/reports/billed-record-details', title:'Billed Record Details', type: 'link'},
      { path: '/reports/connection-type', title:'Connection Type', type:'link'},
      { path: '/reports/ward-details', title:'Ward Details', type:'link'},
     /* { path: '/reports/cash-transfer', title:'Cash Transfer', type:'link'},
      { path:'/reports/suspense-transfer', title:'Suspense Transfer', type:'link'},
      { path:'/reports/Ledger-Rectify-details', title:'Ledger rectify', type:'link'},
      { path:'/reports/duplicate-bills-in-ledger', title:'Duplicate Bills in ledger', type:'link'},    */  
    ]
  },
]

export const UCMC_MENUITEMS: Menu[] = [
  { 
    headTitle: 'General'
  }, 
  {
    path: '/dashboard', title: 'Dashboard', icon: 'icon-desktop', type: 'link'
  },
  {
    path:'/connection', title: 'Connection', icon: 'icofont icofont-connection', type: 'link'
  },
  {
    title: 'Reports', icon: 'icofont icofont-ebook', type: 'sub', children: [
      { path: '/reports/collection-report', title:'Collection Report', type:'link'}
    ]
  }
]


export const SUPERVISOR_MENUITEMS: Menu[] = [
  { 
    headTitle: 'General'
  }, 
  {
    path: '/dashboard', title: 'Dashboard', icon: 'icon-desktop', type: 'link'
  },
  {
    path:'/connection', title: 'Connection', icon: 'icofont icofont-connection', type: 'link'
  },
  {
    title: 'Reports', icon: 'icofont icofont-ebook', type: 'sub', children: [
      { path: '/reports/change-details', title: 'Change Details', type: 'link' },
      { path: '/reports/ledger', title: 'Ledger', type: 'link' },
      { path: '/reports/meter-reader', title: 'Meter Reader Day Wise Bill', type: 'link' },
      { path: '/reports/agent-wise-ledger', title: 'Daily Billing', type: 'link' },
      { path: '/reports/DCB', title: 'DCB', type: 'link' },
      { path: '/reports/DCB-financial-year', title:'DCB Financial Year', type:'link'},
      { path: '/reports/pending-balance', title:'ward-wise-pending-balance', type:'link'},
      /* { path: '/reports/collection-report', title:'Collection Report', type:'link'}, */
      { path: '/reports/billed-unbilled', title:'Unbilled record', type:'link'},
      { path: '/reports/unbilled-record-details', title: 'Unbilled record details', type: 'link'},
      { path: '/reports/statuswise-billed-records', title: 'Billed Records', type: 'link'},
      {path: '/reports/billed-record-details', title:'Billed Record Details', type: 'link'}, 
      { path: '/reports/connection-type', title:'Connection Type', type:'link'},
      { path: '/reports/ward-details', title:'Ward Details', type:'link'},
     /* { path: '/reports/cash-transfer', title:'Cash Transfer', type:'link'},
      { path:'/reports/suspense-transfer', title:'Suspense Transfer', type:'link'},
      { path:'/reports/Ledger-Rectify-details', title:'Ledger rectify', type:'link'},
      { path:'/reports/duplicate-bills-in-ledger', title:'Duplicate Bills in ledger', type:'link'}, */
      // { path:'/reports/Ledger-Rectify-details', title:'Ledger rectify Transfer', type:'link'}

    ]
  }
]

export const BANKSTAFF_MENUITEMS: Menu[] = [
  { 
    headTitle: 'General'
  }, 
  {
    path:'/cash-collection-report', title: 'Cash Collection Report', icon: 'icofont icofont-list', type:'link'
  },
  {
    path:'/cash-collection', title: 'Cash Collection', icon: 'icofont icofont-architecture-alt', type: 'link'
  },
  {
    path:'/cash-collection-abstract-report', title: 'Abstract Report', icon: 'icofont icofont-architecture-alt', type: 'link'
  }
]

