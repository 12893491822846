import { Injectable } from '@angular/core';
import { Observable } from 'rxjs/Observable';
import { HttpClient } from '@angular/common/http';

@Injectable({
  providedIn: 'root'
})
export class JsonService {

  constructor(private http: HttpClient) {

   }

  public getCustomerJson(): Observable<any>{
    return this.http.get("./assets/json/customer_master.json");
  }

  public getLedgerTableJson(): Observable<any> {
    return this.http.get("./assets/json/ledger_table.json");
  }

  public getConnectionJosnForBank(): Observable<any>{
    return this.http.get("./assets/json/connections.json");
  }

  public getMetrChnageJson(): Observable<any>{
    return this.http.get("./assets/json/meter_change.json");
  }

  public getBankCollectionJson(): Observable<any>{
    return this.http.get("./assets/json/cash_collection.json");
  }

  public getNameChangeJson(): Observable<any>{
    return this.http.get("./assets/json/name_change.json");
  }

  public getMeterDisconnectionJson(): Observable<any>{
    return this.http.get("./assets/json/meter_diconnection.json");
  }

  public getAddressChangeJson(): Observable<any>{
    return this.http.get("./assets/json/address_change.json");
  }

  public getNoticeDetailsJson(): Observable<any>{
    return this.http.get("./assets/json/notice_details.json");
  }

  public getConnectionDateChangeJson(): Observable<any>{
    return this.http.get("./assets/json/connection_date_change.json");
  }

  public getRevisionJson(): Observable<any>{
    return this.http.get("./assets/json/revision.json");
  }
}
