import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { timeout } from "rxjs/operators";
import { environment } from "src/environments/environment";
import { ResponseData } from "../interfaces/response-data";

interface Data {
  code: number;
  message: string;
  data: any;
}

@Injectable({
  providedIn: "root",
})
export class CashTransferService {
  private apiUrl = `${environment.apiUrl + "api/"}`;
  constructor(private http: HttpClient) {}

  getCashCollectionDetails(data) {
    console.log("data", data);

    return new Promise<ResponseData>((resolve) => {
      this.http
        .get<ResponseData>(
          this.apiUrl +
            "cash-collection-detail" +
            `/${data.fromRrno}/${data.toRrno}/${data.receiptDate}`
        )
        .pipe(timeout(1000000))
        .subscribe(
          (data) => {
            console.log("data", data);
            resolve(data);
          },
          (error) => {
            console.log("Error", error);
          }
        );
    });
  }

  cashTransfer(data) {
    console.log("formValue", data, this.apiUrl + "cash-transfer");

    return new Promise<Data>((resolve) => {
      this.http.post<Data>(this.apiUrl + "cash-transfer", data).subscribe(
        (data) => {
          console.log("Success", data);
          resolve(data);
        },
        (error) => {}
      );
    });
  }

  suspenseTransfer(data) {
    return new Promise<Data>((resolve) => {
      this.http.post<Data>(this.apiUrl + "                                                               ", data).subscribe(
        (data) => {
          console.log("Success", data);
          resolve(data);
        },
        (error) => {}
      );
    });
  }

  getSuspense(data) {
    var filter = JSON.stringify(data);
    console.log("url", this.apiUrl + "suspense" + `/${data}`);

    return new Promise<ResponseData>((resolve) => {
      this.http
        .get<ResponseData>(this.apiUrl + "suspense" + `/${filter}`)
        .pipe(timeout(1000000))
        .subscribe(
          (data) => {
            console.log("data", data);
            resolve(data);
          },
          (error) => {
            console.log("Error", error);
          }
        );
    });
  }

  getSuspenseDetail(name) {
    return new Promise<ResponseData>((resolve) => {
      this.http
        .get<ResponseData>(this.apiUrl + "suspense-detail" + `/${name}`)
        .pipe(timeout(1000000))
        .subscribe(
          (data) => {
            console.log("data", data);
            resolve(data);
          },
          (error) => {
            console.log("Error", error);
          }
        );
    });
  }

  getLastId() {
    return new Promise<ResponseData>((resolve) => {
      this.http
        .get<ResponseData>(this.apiUrl + "cash-collection-max-id")
        .pipe(timeout(1000000))
        .subscribe(
          (data) => {
            console.log("data", data);
            resolve(data);
          },
          (error) => {
            console.log("Error", error);
          }
        );
    });
  }

  createSuspenseTransfer(formValue) {
    return new Promise<Data>((resolve) => {
      this.http
        .post<Data>(this.apiUrl + "create-suspense-transfer", formValue)
        .subscribe(
          (data) => {
            console.log("Success", data);
            resolve(data);
          },
          (error) => {}
        );
    });
  }
}
