import { Component, OnInit } from "@angular/core";
import { AngularFirestore } from "@angular/fire/firestore";
import { FormBuilder, FormGroup, Validators } from "@angular/forms";
import { LedgerService } from "src/app/shared/service/ledger.service";
import { DataStateChangeEvent, GridDataResult} from '@progress/kendo-angular-grid';
import { process, State } from '@progress/kendo-data-query';
import { ToastrService } from 'ngx-toastr';
declare var require: any;
var moment = require("moment");
@Component({
  selector: 'app-ledger-rectify',
  templateUrl: './ledger-rectify.component.html',
  styleUrls: ['./ledger-rectify.component.scss']
})
export class LedgerRectifyComponent implements OnInit {
  filterForm:FormGroup;
  filterType;
  xlsxName;

  public gridData: GridDataResult;

  detailsArray: any = [];
  month;
  year;
  data:any = []
  showLoader: boolean = false;
  public state: State = {
    filter: {
      logic: 'and',
      filters: []
    }
  };
  constructor(
    private fb: FormBuilder,
    private firestore: AngularFirestore,
    private ledgerService: LedgerService,
    private toastrService:ToastrService
  ) {}

  ngOnInit() {
    this.filterForm = this.fb.group({
      month: ["", Validators.required],
      year: ["", Validators.required],
    });
  }



  onFilterSubmit() {
    //console.log("agentConnectionArray", this.getAgentsTotalConnection());
    this.showLoader = true;

    let month = this.filterForm.value.month;
    let year = this.filterForm.value.year;
    this.month = month;
    this.year = year;



    var data = {
      month: month,
      year: year,
    };

    this.ledgerService.getLedgerRectify(data).then(async (response) => {
      if (response.code == 200) {
        this.showLoader = false;
        this.detailsArray = await response.data;
        console.log("this.detailsArray", this.detailsArray);
          this.gridData =  await process(this.detailsArray, this.state);
      }
    });
  }

  RectifyLedger(dataItem){
      console.log(dataItem);
      this.ledgerService.rectifyLedger(dataItem).then(async (response) => {
        if (response.code == 200) {
          this.showLoader = false;
        }
      });
  }

  RectifyLedgerAll(){
    this.showLoader = true;
    this.ledgerService.rectifyLedger(this.detailsArray).then(async (response) => {
      if (response.code == 200) {
        this.showLoader = false;
        this.detailsArray = [];
        console.log("total", response.data);   
      this.toastrService.success("Total ledger rectified is "+response.data+"  ", "Success",
      {timeOut: 5000})
      }else{
        this.toastrService.error("Failed", "Failed")
      }
    })
  }

  refresh() {
    location.reload();
  }
  public dataStateChange(state: DataStateChangeEvent): void {
    this.state = state;
    this.gridData = process( this.detailsArray, this.state);
  }

}
