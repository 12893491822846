import { Component, OnInit } from '@angular/core';
import { AngularFirestore } from '@angular/fire/firestore';
import { FormBuilder, FormGroup } from '@angular/forms';
import { DataStateChangeEvent, GridDataResult, PageChangeEvent } from '@progress/kendo-angular-grid';
import { process, State } from '@progress/kendo-data-query';
import { LedgerService } from 'src/app/shared/service/ledger.service';
import { ToastrService } from 'ngx-toastr';
declare var require:any;
var moment = require('moment');

@Component({
  selector: 'app-duplicate-bills-report',
  templateUrl: './duplicate-bills-report.component.html',
  styleUrls: ['./duplicate-bills-report.component.scss']
})
export class DuplicateBillsReportComponent implements OnInit {
  filterForm:FormGroup;
  detailsArray:any = [];
  xlsxName;
  showLoader: boolean = false;
  public gridData: GridDataResult;
  public state: State = {
    skip: 0,
    take:10,
    filter: {
      logic: 'and',
      filters: []
    }
  };
  public buttonCount = 5;
  public info = true;
  public type: 'numeric' | 'input' = 'numeric';
  public previousNext = true;
  public pageSizes = [200,200,300,400, 500,600,700,800,900,1000, 50000];

  constructor(private fb:FormBuilder, private firestore:AngularFirestore, private ledgerService:LedgerService,private toastrService:ToastrService) { }

  ngOnInit() {
    this.filterForm = this.fb.group({
      from_date: [],
    });
  }

  onFilterSubmit(){
    this.showLoader = true;
    this.detailsArray = [];
    this.gridData =  process(this.detailsArray, this.state);
    let fromDate = this.filterForm.value.from_date;

    var fromDateTime =  moment(fromDate).format('YYYY-MM-DD');
    // var toDateTime = toDate + ' ' + moment('2021-06-05 23:59:59').format('HH:mm:ss');

  
    // this.xlsxName = fromDate+'-to-'+toDate;

    console.log("Form Value" , fromDateTime);
    var data = {
      'fromDate': fromDateTime,
    }

    this.ledgerService.getDuplicateBills(data).then(async response=>{
      console.log(response)
      if (response.code == 200) {
        this.showLoader = false;
        this.detailsArray = await response.data;
        console.log("this.detailsArray", await this.detailsArray)
        this.gridData =  await process(this.detailsArray, this.state);
      }
    });
    
  }

  deleteDuplicateBills(){
    
    this.showLoader = true;
    this.ledgerService.deleteDuplicateBills(this.detailsArray).then(async (response) => {
      if (response.code == 200) {
        this.showLoader = false;
        this.detailsArray = [];
        console.log("total", response.data);   
      this.toastrService.success("Total Duplicate bills deleted "+response.data+"  ", "Success",
      {timeOut: 5000})
      }else{
        this.toastrService.error("Failed", "Failed")
      }
    })
  }

  refresh(){
    location.reload();
  }

  onchangeDate(e){
    this.detailsArray = [];
  }

  public dataStateChange(state: DataStateChangeEvent): void {
    this.state = state;
    this.gridData = process( this.detailsArray, this.state);
  }

  public pageChange({ skip, take }: PageChangeEvent): void {
    console.log("skip",skip,take )
    this.state.skip = skip;
    this.state.take = take; 
    this.gridData = process( this.detailsArray, this.state);
  }

  public allData = () => {
    return process(this.detailsArray, {
      filter: this.state.filter
    });
  }

}
