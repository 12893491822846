import { Component, OnInit } from '@angular/core';
import { AngularFirestore } from '@angular/fire/firestore';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { DataStateChangeEvent, GridDataResult, PageChangeEvent } from '@progress/kendo-angular-grid';
import { process, State } from '@progress/kendo-data-query';
import { LedgerService } from 'src/app/shared/service/ledger.service';

declare var require:any;
var moment = require('moment');

@Component({
  selector: 'app-connection-type',
  templateUrl: './connection-type.component.html',
  styleUrls: ['./connection-type.component.scss']
})
export class ConnectionTypeComponent implements OnInit {
  filterForm:FormGroup;
  detailsArray:any = [];
  collection;
  xlsxName;

  public gridData: GridDataResult;
  public state: State = {
    skip: 0,
    take:10,
    filter: {
      logic: 'and',
      filters: []
    }
  };
  public buttonCount = 5;
  public info = true;
  public type: 'numeric' | 'input' = 'numeric';
  public previousNext = true;
  public pageSizes = [200,200,300,400, 500,600,700,800,900,1000, 50000];

  constructor(private fb:FormBuilder, private firestore:AngularFirestore, private ledgerService:LedgerService) { }

  ngOnInit() {
    this.filterForm = this.fb.group({
      type: ['', Validators.required],
      status: ['', Validators.required],
      /* from_date: [''],
      to_date: [''] */
    });
  }

  onFilterSubmit(){
    this.detailsArray = [];
    this.gridData =  process(this.detailsArray, this.state);

    let status = this.filterForm.value.status;
    let type = this.filterForm.value.type;

    var data;

   
    console.log("filter form", this.filterForm.value);

    if(status != 'All' && type != 'All'){
      console.log("7");
      this.xlsxName = type+'-'+status
      data = {
        'filterBy':'both',
        'status': status,
        'type': type,
      }
    }
    else if(status == 'All' && type == 'All'){
      data = {
        'filterBy':'All',
      }
    }
    else if(status == 'All'){
      data = {
        'filterBy': 'single',
        'feild': 'tariff',
        'value': type
      }
    }
    else if(type == 'All'){
      data = {
        'filterBy': 'single',
        'feild': 'connectionStatus',
        'value': status
      }
    }
    else if(status != ''){
      console.log("5");
      this.xlsxName = status
      data = {
        'filterBy': 'single',
        'feild': 'connectionStatus',
        'value': status
      }
    }
    else if(type != ''){
      console.log("6");
      this.xlsxName = type
      
      data = {
        'filterBy': 'single',
        'feild': 'tariff',
        'value': type
      }
    }
    
    this.ledgerService.connection(data).then(async response=>{
      if (response.code == 200) {
        this.detailsArray = await response.data;
        console.log("this.detailsArray", await this.detailsArray)
        this.gridData =  await process(this.detailsArray, this.state);
      }
    });
    
  }

  refresh(){
    location.reload();
  }

  public dataStateChange(state: DataStateChangeEvent): void {
    this.state = state;
    this.gridData = process( this.detailsArray, this.state);
  }

  public pageChange({ skip, take }: PageChangeEvent): void {
    console.log("skip",skip,take )
    this.state.skip = skip;
    this.state.take = take; 
    this.gridData = process( this.detailsArray, this.state);
  }

  public allData = () => {
    return process(this.detailsArray, {
      filter: this.state.filter
    });
  }

}
