import { Component, OnInit } from "@angular/core";
import {
  AngularFirestore,
  AngularFirestoreCollection,
} from "@angular/fire/firestore";
import { FormControl, FormGroup, Validators } from "@angular/forms";
import { NgbModal } from "@ng-bootstrap/ng-bootstrap";
import { data } from "jquery";
import { BankStaffService } from "src/app/shared/bank-staff/bank-staff.service";
import {
  DataStateChangeEvent,
  GridDataResult,
  PageChangeEvent,
} from "@progress/kendo-angular-grid";
import { process, State } from "@progress/kendo-data-query";

declare var require: any;
var converter = require("number-to-words");
import Swal from "sweetalert2";
var moment = require("moment");
import { ToWords } from "to-words";
const toWords = new ToWords({
  localeCode: "en-IN",
  converterOptions: {
    currency: true,
    ignoreDecimal: false,
    ignoreZeroCurrency: false,
    doNotAddOnly: true,
  },
});
@Component({
  selector: "app-cash-collection-report",
  templateUrl: "./cash-collection-report.component.html",
  styleUrls: ["./cash-collection-report.component.scss"],
})
export class CashCollectionReportComponent implements OnInit {
  filterForm = new FormGroup({
    fromDate: new FormControl("", Validators.required),
    toDate: new FormControl("", Validators.required),
  });
  submitted: Boolean;
  isVisible: Boolean;

  transaction_details: AngularFirestoreCollection;
  transaction_data: any[];
  customer_data;
  transaction: any;

  receiptNo;
  billDateTime;
  ownerName;
  houseNo;
  amount;
  amountInWords;
  mode;
  rrno;
  chequeDdNo;
  chequeDdDate;
  chequeDdBranch;
  receiptDate;

  validationForm;
  id;

  fromDate;
  toDate;
  amountEdited: boolean = false;

  public gridData: GridDataResult;
  public state: State = {
    skip: 0,
    take: 10,
    filter: {
      logic: "and",
      filters: [],
    },
  };
  public buttonCount = 5;
  public info = true;
  public type: "numeric" | "input" = "numeric";
  public previousNext = true;
  public pageSizes = [200, 200, 300, 400, 500, 600, 700, 800, 900, 1000, 50000];

  constructor(
    private firestore: AngularFirestore,
    private modalService: NgbModal,
    private bankStaffService: BankStaffService
  ) {}

  ngOnInit() {
    this.validationForm = new FormGroup({
      rrno: new FormControl("", Validators.required),
      ownerName: new FormControl("", Validators.required),
      houseNo: new FormControl("", Validators.required),
      phoneNumber: new FormControl(""),
      receiptNo: new FormControl("", Validators.required),
      receiptDateTime: new FormControl("", Validators.required),
      mode: new FormControl("", Validators.required),
      amount: new FormControl("", Validators.required),
      chequeDdNumber: new FormControl(""),
      chequeDdDate: new FormControl(""),
      chequeDdBranch: new FormControl(""),
      paymentReceivedBank: new FormControl(Validators.required),
    });
  }

  onChangeDate(e, type) {
    var date = new Date(e.target.value);
    var changed_date =
      date.getFullYear() +
      "/" +
      ("0" + (date.getMonth() + 1)).slice(-2) +
      "/" +
      ("0" + date.getDate()).slice(-2);
    if (type == "from") {
      this.fromDate = changed_date;
    } else {
      this.toDate = changed_date;
    }
  }

  filter() {
    this.transaction_data = [];
    this.gridData = process(this.transaction_data, this.state);
    this.submitted = true;
    if (this.filterForm.valid) {
      this.isVisible = true;

      this.fromDate = this.filterForm.value.fromDate;
      this.toDate = this.filterForm.value.toDate;
      var data = {
        fromDate: this.fromDate,
        toDate: this.toDate,
      };

      this.getFilterData(data);
    }
  }

  getFilterData(data) {
    console.log('from and to',data)
    this.bankStaffService.getCashCollection(data).then((response) => {
      if (response.code == 200) {
        console.log("response data", response.data);
        this.transaction_data = response.data;
        this.gridData = process(this.transaction_data, this.state);
        this.submitted = false;
        this.filterForm.reset();
      }
    });
  }

  async print(dataItem) {
    console.log("dataItem", dataItem);

    // dataItem.amountInWords = converter.toWords(dataItem.amount);
    dataItem.amountInWords = toWords.convert(dataItem.amount);
    dataItem.billDate = moment(dataItem.receiptDateTime).format(
      "YYYY-MM-DD HH:mm:ss"
    );
    delete dataItem.receiptDateTime;
    dataItem.chequeDdBranch =
      dataItem.chequeDdBranch != null
        ? dataItem.chequeDdBranch.replace(/\/+/g, "")
        : dataItem.chequeDdBranch;
    dataItem.chequeDdNo = dataItem.chequeDdNumber;
    dataItem.name = dataItem.name.replace(/\/+/g, "");
    dataItem.doorNo = dataItem.doorNo.replace(/\/+/g, "-");
    var receipt = JSON.stringify(dataItem);

    console.log("receipt of cash collection report", receipt);
    window.open(
      "https://printbill.nixies.in/public/mn-compu-solution-cash-collect-pdf/" +
        receipt,
      "_blank"
    );
    dataItem.receiptDateTime = moment(dataItem.billDate).format(
      "YYYY/MM/DD HH:mm:ss"
    );
  }

  edit(dataItem, content) {
    console.log("dataItem", dataItem);
    this.id = dataItem.id;
    this.receiptDate = dataItem.receiptDate;
    this.validationForm.setValue({
      rrno: dataItem.rrno,
      ownerName: dataItem.name,
      houseNo: dataItem.doorNo,
      phoneNumber: dataItem.phoneNumber,
      receiptNo: dataItem.receiptNo,
      receiptDateTime: dataItem.receiptDateTime,
      mode: dataItem.mode,
      amount: dataItem.amount,
      chequeDdNumber: dataItem.chequeDdNumber,
      chequeDdDate: dataItem.chequeDdDate,
      chequeDdBranch: dataItem.chequeDdBranch,
      paymentReceivedBank: dataItem.paymentReceivedBank,
    });
    setTimeout(() => {
      console.log(this.validationForm.value);

      this.modalService.open(content, { centered: true, size: "lg" });
    }, 2000);
  }

  onClose() {
    this.modalService.dismissAll();
  }

  onSubmit() {
    //console.log("dsds", this.transaction.id);
    var bank = this.validationForm.value;
    delete bank.ownerName;
    delete bank.houseNo;
    delete bank.phoneNumber;
    bank.amountEdited = this.amountEdited;
    bank.receiptDate = this.receiptDate;
    bank.userId = localStorage.getItem("user_id");
    bank.id = this.id;
    this.bankStaffService.updateCashCollection(bank).then((response) => {
      if (response.code == 200) {
        var data = {
          fromDate: this.fromDate,
          toDate: this.toDate,
        };

        this.getFilterData(data);
        this.modalService.dismissAll();
      }
    });
    /* this.firestore.doc('bankCashCollection/'+this.id).update(this.validationForm.value).then((res)=>{
      
    }); */
  }

  delete(data) {
    Swal({
      title: "Are you sure?",
      text: "You won't be able to revert this!",
      type: "warning",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Yes, delete it!",
    }).then((result) => {
      if (result.value) {
        //this.firestore.doc('bankCashCollection/'+id).delete();
        this.bankStaffService.deleteCashCollection(data).then((response) => {
          if (response.code == 200) {
            var data = {
              fromDate: this.fromDate,
              toDate: this.toDate,
            };

            this.getFilterData(data);
            Swal("Deleted!", "Cash collection has been deleted.", "success");
          }
        });
      }
    });
  }

  public dataStateChange(state: DataStateChangeEvent): void {
    this.state = state;
    this.gridData = process(this.transaction_data, this.state);
  }
  public pageChange({ skip, take }: PageChangeEvent): void {
    console.log("skip", skip, take);
    this.state.skip = skip;
    this.state.take = take;
    this.gridData = process(this.transaction_data, this.state);
  }

  public allData = () => {
    return process(this.transaction_data, {
      filter: this.state.filter,
    });
  };

  onChangeAmount(e) {
    this.amountEdited = true;
  }
}
