import { Component, Input, OnInit, Output, EventEmitter } from '@angular/core';
import { AngularFirestore } from '@angular/fire/firestore';
import { AngularFireStorage } from '@angular/fire/storage';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ToastrService } from 'ngx-toastr';
import { Observable } from 'rxjs';
import { finalize, flatMap } from 'rxjs/operators';
import { ChangeDetailsService } from 'src/app/shared/service/change-details.service';

@Component({
  selector: 'app-rivision',
  templateUrl: './rivision.component.html',
  styleUrls: ['./rivision.component.scss']
})
export class RivisionComponent implements OnInit {
  @Input('revisionData') revisionData;
  @Output('onCloseModal') onCloseModal  = new EventEmitter<string>();

  validationForm:FormGroup;
  dateTime;
  userId;
  userName;
  type;
  hasFile:boolean = false;
  selectedFiles:FileList;
  downloadURL: Observable<String>;
  imgPath;

  constructor(private fb:FormBuilder, private storage:AngularFireStorage, private toastrService:ToastrService, private firestore:AngularFirestore,
    private changeService : ChangeDetailsService) { 
    var today = new Date();
    this.dateTime = ((today.getFullYear()) +"-"+ (("0"+(today.getMonth()+1)).slice(-2)) +"-"+("0"+today.getDate()).slice(-2)) +" "+ (("0"+today.getHours()).slice(-2)) +":"+ (("0"+today.getMinutes()).slice(-2)) +":"+ (("0"+today.getSeconds()).slice(-2));
    this.userId = localStorage.getItem("user_id");
    this.userName = localStorage.getItem("user_name");
  }

  ngOnInit() {   
    this.validationForm = this.fb.group({
      rrno:[this.revisionData.rrno],
      consumerName: [this.revisionData.name],
      address: [this.revisionData.address],
      balance: [this.revisionData.latestCBA],
      type: ['', Validators.required],
      rebate: ['', Validators.required],
      newCBA: ['', Validators.required],
      userId: [this.userName],
      enteredDate: [this.dateTime],
      remarks: ['', Validators.required],
      file: ['']
    }) 
  }

  onChangeType(e){
    this.type = e.target.value;
  }

  onChangeRebate(){
    var newCBA;
    if(this.type == "add"){
      newCBA = Number(this.revisionData.latestCBA) + Number(this.validationForm.value.rebate);
    }
    else{
      newCBA = Number(this.revisionData.latestCBA) - Number(this.validationForm.value.rebate);
    }
    this.validationForm.controls.newCBA.setValue(newCBA)
  }

  detectFiles(event){
    this.hasFile = true;
    this.selectedFiles = event.target.files;
   this.validationForm.controls.file.setValue(event.target.files[0].name)
  }

  onSubmit(){
    console.log("id", this.revisionData.id);
    //this.validationForm.controls.userId.setValue(this.userId);
    
    if(this.validationForm.valid){
      this.store();
      /* if(this.hasFile){
        var file = this.selectedFiles.item(0);
        this.uploadFile(file);
      }
      else{
        this.store();
      } */
    }
  }

  uploadFile(imageUrl){
    let uniqkey = 'pic' + Math.floor(Math.random() * 1000000);
    const uploadTask = this.storage.upload('/type-change/' + uniqkey, imageUrl);
    const ref = this.storage.ref('/type-change/' + uniqkey);
    uploadTask.snapshotChanges().pipe(
      finalize(() => {
        this.downloadURL = ref.getDownloadURL();
        console.log(this.downloadURL);
        this.downloadURL.subscribe(url => {
          this.imgPath = url;
          this.toastrService.success("Image uploaded succcessfully!", "Success!", {
            timeOut:3000
          });
          this.store();
        });
      })
    ).subscribe();
  }

  store(){
    var formValue = this.validationForm.value;
    delete formValue.userId;
    formValue.userId = this.userId;
    

    /* if(this.hasFile){
      formValue.file = this.imgPath;
    } */
    

   /*  this.firestore.collection('revision').add(formValue).then(response=>{
      var updateLedger = this.firestore.collection('ledger', ref=> ref.where('connectionId', '==', this.validationForm.value.rrno).orderBy('billDateTime', 'desc').limit(1)).snapshotChanges().pipe(flatMap(clients => clients));
      updateLedger.subscribe(doc => {
        console.log("doc.payload.doc.id", doc.payload.doc.id);
        
        this.firestore.collection('ledger').doc(doc.payload.doc.id).update({'CBA':this.validationForm.value.newCB}).then(response =>{})
      });
      this.toastrService.success("Type Changed Successfully", "Success!", {
        timeOut: 8000
      });
      this.onCloseModal.emit('close');
    }).catch(error=>{
      this.toastrService.error(error, "Error!", {
        timeOut: 8000
      });
    }); */

    this.changeService.revision(formValue,this.hasFile,this.selectedFiles).then(async response=>{
      console.log("revision_response",response.code);
      if (response.code == 200) {
        this.toastrService.success("Revision done Successfully", "Success!", {
          timeOut: 8000
        });
        this.onCloseModal.emit('close');
       /* this.changeService.updateLedger(formValue).then(async res=>{
         if(res.code == 200){
          this.toastrService.success("Revision done Successfully", "Success!", {
            timeOut: 8000
          });
          this.onCloseModal.emit('close');
         }
       }) */
      }else{
        this.toastrService.error(response.message, "Error!", {
          timeOut: 8000
        });
      }
    })


  }

  onClose(close)
  {
    this.onCloseModal.emit('close');
  }

}
