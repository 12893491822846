import { Component, Input, OnInit, Output, EventEmitter } from '@angular/core';
import { FirebaseFirestore, FirebaseStorage } from '@angular/fire';
import { AngularFirestore } from '@angular/fire/firestore';
import { AngularFireStorage } from '@angular/fire/storage';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ToastrService } from 'ngx-toastr';
import { Observable } from 'rxjs';
import { finalize } from 'rxjs/operators';
import { ChangeDetailsService } from 'src/app/shared/service/change-details.service';

@Component({
  selector: 'app-connection-date-change',
  templateUrl: './connection-date-change.component.html',
  styleUrls: ['./connection-date-change.component.scss']
})
export class ConnectionDateChangeComponent implements OnInit {
  @Input('connectionDateChangeData') connectionDateChangeData;
  @Output('onCloseModal') onCloseModal = new EventEmitter;

  validationForm:FormGroup;
  dateTime;
  hasFile:boolean;
  selectedFiles:FileList;
  userId;
  userName;
  downloadURL:Observable<String>;
  imgPath;
  fileName;

  constructor(private fb:FormBuilder, private storage:AngularFireStorage, private toastrService:ToastrService, private firestore:AngularFirestore,
    private changeService : ChangeDetailsService) { 
    var today = new Date();
    this.dateTime = ((today.getFullYear()) +"-"+ (("0"+(today.getMonth()+1)).slice(-2)) +"-"+("0"+today.getDate()).slice(-2)) +" "+ (("0"+today.getHours()).slice(-2)) +":"+ (("0"+today.getMinutes()).slice(-2)) +":"+ (("0"+today.getSeconds()).slice(-2));
    this.userId = localStorage.getItem("user_id");
    this.userName = localStorage.getItem("user_name");
  }

  ngOnInit() {
    this.validationForm = this.fb.group({
      rrno:[this.connectionDateChangeData.rrno],
      consumerName: [this.connectionDateChangeData.name],
      oldServiceDate: [this.connectionDateChangeData.serviceDate],
      newServiceDate: ['', Validators.required],
      userId: [this.userName],
      enteredDate: [this.dateTime],
      orderNo: ['', Validators.required],
      remarks: ['', Validators.required],
      phoneNumber: [this.connectionDateChangeData.phoneNumber],
      file: ['']
    }) 
  }

  detectFiles(event){
    this.hasFile = true;
    this.selectedFiles = event.target.files;
    //console.log("event.target.files", event.target.files[0].name);
    
   this.validationForm.controls.file.setValue(event.target.files[0].name)
  }

  onSubmit(){
    console.log("id", this.connectionDateChangeData.id);
 
    if(this.validationForm.valid){
      this.store();
     /*  if(this.hasFile){
        var file = this.selectedFiles.item(0);
        this.uploadFile(file);
      }
      else{
        this.store();
      } */
    }
  }

  uploadFile(imageUrl){
    //console.log("image url", imageUrl.name);
    this.fileName = imageUrl.name;
    let uniqkey = 'pic' + Math.floor(Math.random() * 1000000);
    const uploadTask = this.storage.upload('/connection-date-change/' + uniqkey, imageUrl);
    const ref = this.storage.ref('/connection-date-change/' + uniqkey);
    //console.log("uploadTask", uploadTask);
    uploadTask.snapshotChanges().pipe(
      finalize(() => {
        this.downloadURL = ref.getDownloadURL();
        console.log(this.downloadURL);
        this.downloadURL.subscribe(url => {
          this.imgPath = url;
          //console.log("url",this.imgPath)
          this.toastrService.success("Image uploaded succcessfully!", "Success!", {
            timeOut:3000
          });
          this.store();
        });
      })
    ).subscribe();
  }

  store(){
    var formValue = this.validationForm.value;
    delete formValue.userId;
    formValue.userId = this.userId;
    
   /*  if(this.hasFile){
      formValue.file = this.imgPath;
      formValue.fileName = this.fileName;
    } */
    
   /*  this.firestore.collection('connection-date-change').add(formValue).then(response=>{
      var data = {
        serviceDate:this.validationForm.value.newServiceDate
      }
      this.firestore.collection('connections').doc(this.connectionDateChangeData.id).update(data).then(response =>{
        this.toastrService.success("Connecton Date Changed Successfully", "Success!", {
          timeOut: 8000
        });
        this.onCloseModal.emit('close');
      })
    }).catch(error=>{
      this.toastrService.error(error, "Error!", {
        timeOut: 8000
      });
    }); */

    this.changeService.connectionDetails(formValue,this.hasFile,this.selectedFiles).then(async response=>{
      console.log("connection_date_change_response",response.code);
      if (response.code == 200) {
        /* this.connectionDateChangeData.serviceDate = formValue.newServiceDate;
        this.changeService.updateConnection(this.connectionDateChangeData).then(async response=>{
          this.toastrService.success("Connecton Date Changed Successfully", "Success!", {
            timeOut: 8000
          });
          this.onCloseModal.emit('close');
        }) */
        this.toastrService.success("Connecton Date Changed Successfully", "Success!", {
          timeOut: 8000
        });
        this.onCloseModal.emit('close');
      }else{

      }
    })
  }

  onClose(close)
  {
    this.onCloseModal.emit('close');
  }
}
