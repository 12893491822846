import { Component, OnInit, ViewChild } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Subject, merge} from 'rxjs';
import { Observable } from 'rxjs/Observable';
import { AgentService } from 'src/app/shared/agent/agent.service';

import { debounceTime, distinctUntilChanged, filter, flatMap, map} from 'rxjs/operators';
import { NgbTypeahead } from '@ng-bootstrap/ng-bootstrap';
import { AngularFirestore } from '@angular/fire/firestore';

import { DataStateChangeEvent, GridDataResult, PageChangeEvent } from '@progress/kendo-angular-grid';
import { process, State } from '@progress/kendo-data-query';
import { LedgerService } from 'src/app/shared/service/ledger.service';
import { ToastrService } from 'ngx-toastr';


@Component({
  selector: 'app-ward-wise-pending-balance',
  templateUrl: './ward-wise-pending-balance.component.html',
  styleUrls: ['./ward-wise-pending-balance.component.scss']
})
export class WardWisePendingBalanceComponent implements OnInit {
  selected:any = [];
  filterForm:FormGroup;
  wards:any = [];
  type;
  ward;
  connections:any = [];
  selected1:any = [];
  detailsArray:any = [];
  connectionsDetails;
  connectionDetailsArray:any = [];
  ledgerDetailsArray = [];

  crrno;
  xlsx;
  

  click$ = new Subject<string>();
  @ViewChild('instance') instance: NgbTypeahead;
  focus$ = new Subject<string>();

  public gridData: GridDataResult;
  public state: State = {
    skip: 0,
    take:10,
    filter: {
      logic: 'and',
      filters: []
    }
  };
  public buttonCount = 5;
  public info = true;
  public gridType: 'numeric' | 'input' = 'numeric';
  public previousNext = true;
  public pageSizes = [200,200,300,400, 500,600,700,800,900,1000, 50000];


  showLoader:boolean = false;

  constructor(private fb:FormBuilder, private agentService:AgentService, private firestore:AngularFirestore, private ledgerService:LedgerService, private toastrService: ToastrService) { }

  ngOnInit() {
    this.getAllWards();

    this.filterForm = this.fb.group({
      type:['', Validators.required],
      rangeFrom:[''],
      rangeTo:[''],
      ward: ['',],
      rrno:['']
    });
  }

  getAllWards(){
    /* this.agentService.getWards().subscribe((data)=>{
      this.wards = data.map(item => { 
      
        var ward = item.payload.doc.data();
        const name = ward["name"];
        const id = item.payload.doc.id;
        return { name };
      });
    }); */
    this.agentService.getWards().then(async (response) =>{
       console.log("areas sdsfs", response.data);
      this.wards =await  response.data;
      this.wards.push({"name":'All'})
     
    })
  }

  onChangeType(e){
    console.log("Event", e.target.value);
    this.type = e.target.value;
    if(this.type == 'all'){
      this.filterForm.controls.rangeFrom.setValue('');
      this.filterForm.controls.rangeTo.setValue('');
    }
  }

  onChangeWard(e){
    //console.log("Event", e.code);
    console.log(e.target.value);
    //this.ward = e.name;
    this.ward = e.target.value;
    /* this.firestore.collection('connections',ref=>ref.where('ward', '==', e.code)).snapshotChanges().subscribe((con)=>{
      this.connections = con.map(item =>{
        var connection = item.payload.doc.data();
        var rrno = connection["RRNO"];
        const id = item.payload.doc.id;
        return { rrno };
      })
    }) */
    this.ledgerService.getConnectionByWard(e.target.value).then((response)=>{

      this.connections  = response.data; 
    })

  }

  /* onFilterSubmit(){
      this.detailsArray = [];
  
      let rrno =  this.filterForm.value.rrno.rrno;
      let ward =  this.filterForm.value.ward.name;
      let type = this.filterForm.value.type;
      let rangeFrom = this.filterForm.value.rangeFrom;
      let rangeTo =  this.filterForm.value.rangeTo;
      console.log("filter form", rrno, ward, rangeFrom, rangeTo, type);
      
      if((type =='all' && rrno == undefined && ward == undefined) || (type =='range' && rrno == undefined && ward == undefined)){
        console.log("all");
        this.xlsx = type=='all'?'pending-balance':rangeFrom+'-'+rangeTo;
        this.connectionsDetails = this.firestore.collection('/connections');
      }
      else if(rrno != undefined && ward != undefined){
        console.log("both");
        this.xlsx  = type=='all'? rrno+'-'+ward:rrno+'-'+ward+'-'+rangeFrom+'-'+rangeTo;
        this.connectionsDetails = this.firestore.collection('/connections', ref => ref.where('ward', '==', ward).where('RRNO', '==', rrno));
      }
      else{
        console.log("Single");
        this.xlsx  = type=='all'? ward:ward+'-'+rangeFrom+'-'+rangeTo;
        this.connectionsDetails = this.firestore.collection('/connections', ref => ref.where('ward', '==', ward));
      }
      
      this.connectionsDetails.valueChanges().subscribe((data) => {
        data.forEach(element => {
          console.log("element",element);
          this.connectionDetailsArray.push(element);
        });
        if(this.connectionDetailsArray.length > 0){
          console.log("this.connectionDetailsArray", this.connectionDetailsArray.length)
          this.connectionDetailsArray.forEach(connection => {

            this.firestore.collection('/ledger', ref => ref.where('connectionId', '==', connection.RRNO).orderBy('billDateTime', 'desc')).valueChanges().subscribe((ledgerData) =>{
              if(ledgerData.length > 0){
                console.log("ledgerData[0]['CBA']", ledgerData[0]['CBA']);
                
                connection['CBA'] = ledgerData[0]['CBA'];
                connection['billDateTime'] = ledgerData[0]['billDateTime'];
                connection['status'] = ledgerData[0]['status'];

                if(type=='all'){
                  this.detailsArray.push(connection);
                  this.gridData = process( this.detailsArray, this.state);
                }
                else{
                  console.log("rangeFrom", rangeFrom, rangeTo, connection.CBA);
                  
                  if(Number(connection.CBA) >= rangeFrom && Number(connection.CBA) <= rangeTo){
                    console.log("yes");
                    
                    this.detailsArray.push(connection);
                    this.gridData = process( this.detailsArray, this.state);
                  }
                }
              }
            });
            
          });
        }

       
      });
      
  } */

  onFilterSubmit(){
    this.showLoader = true;

    this.detailsArray = [];

    let rrno =  this.filterForm.value.rrno.rrno;
    let ward =  this.filterForm.value.ward;
    let type = this.filterForm.value.type;
    let rangeFrom = this.filterForm.value.rangeFrom;
    let rangeTo =  this.filterForm.value.rangeTo;
   // console.log("filter form", rrno, ward, rangeFrom, rangeTo, type);
    var data;
    console.log('rrno',rrno);
    console.log('ward',ward)
    if((type =='all' && rrno == undefined && ward == undefined) || (type =='range' && rrno == undefined && ward == undefined) || (ward == 'All')){
      console.log("all");
      console.log('rrno',rrno);
      console.log('ward',ward)
      this.xlsx = type=='all'?'pending-balance':rangeFrom+'-'+rangeTo;
  
      data = {
        'filterBy':'all',
        'type':type,
        'rangeFrom': rangeFrom,
        'rangeTo': rangeTo
      }
    }
    else if(rrno != undefined && ward != undefined){
      console.log("both");
      this.xlsx  = type=='all'? rrno+'-'+ward:rrno+'-'+ward+'-'+rangeFrom+'-'+rangeTo;
      data = {
        'filterBy': 'ward-rrno',
        'type': type,
        'ward': ward,
        'rrno': rrno,
        'rangeFrom': rangeFrom,
        'rangeTo': rangeTo
      }
    }
    else{
      console.log("Single");
      this.xlsx  = type=='all'? ward:ward+'-'+rangeFrom+'-'+rangeTo;
    
      data = {
        'filterBy': 'ward',
        'type': type,
        'ward': ward,
        'rangeFrom': rangeFrom,
        'rangeTo': rangeTo
      }
    }
    
    this.ledgerService.getPendingBalance(data).then(async response=>{
      if (response.code == 200) {

        this.detailsArray = await response.data;
        this.showLoader = false;
        console.log("this.detailsArray", await this.detailsArray)
        this.gridData =  await process(this.detailsArray, this.state);
      
      }
      else{
        this.showLoader = false;
        this.toastrService.error("No records", 'Error!'); 
      }
    });
    
}

  
  refresh(){
    location.reload();
  }

  public dataStateChange(state: DataStateChangeEvent): void {
    this.state = state;
    this.gridData = process( this.detailsArray, this.state);
  }

  public pageChange({ skip, take }: PageChangeEvent): void {
    console.log("skip",skip,take )
    this.state.skip = skip;
    this.state.take = take; 
    this.gridData = process( this.detailsArray, this.state);
  }

  public allData = () => {
    return process(this.detailsArray, {
      filter: this.state.filter
    });
  }

}
