import { Component, OnInit } from '@angular/core';
import { AngularFirestore } from '@angular/fire/firestore';
import { FormBuilder, FormGroup } from '@angular/forms';
import { CashTransferService } from 'src/app/shared/service/cash-transfer.service';

declare var require:any;
var moment = require('moment');

@Component({
  selector: 'app-suspense-cash-transfer',
  templateUrl: './suspense-cash-transfer.component.html',
  styleUrls: ['./suspense-cash-transfer.component.scss']
})
export class SuspenseCashTransferComponent implements OnInit {
  detailsArray:any = [];
  suspensionCollection;
  connectionCollection;
  show = 'list';
  suspensionData:any;
  filterForm:FormGroup;

  constructor(private firestore:AngularFirestore, private fb:FormBuilder, private cashTransferService:CashTransferService) { }

  ngOnInit() {
    this.filterForm = this.fb.group({
      from_date: [],
      to_date: []
    });
    this.getAllSuspenseData('all');
  }

  getAllSuspenseData(type){
    var data;
    this.detailsArray = [];
    if(type=='all'){
      
      data = {
        'type': 'all'
      }
    }
    else{
      let fromDate = this.filterForm.value.from_date;
      let toDate = this.filterForm.value.to_date;

      data = {
        'type':'filter',
        'fromDate': fromDate,
        'toDate': toDate
      }

    }
    
    this.cashTransferService.getSuspense(data).then(response=>{
      if (response.code == 200) {
        this.detailsArray = response.data;
      }
    });
  }

  transfer(data){
    this.show = 'view';
    this.suspensionData = data;
    
  }

  display(e){
    this.show = 'list';
    this.getAllSuspenseData('all');
  }

  onFilterSubmit(){
    this.getAllSuspenseData('filter');
  }

  refresh(){
    location.reload();
  }

}
